@use "../utilities/css-variables" as cv;
@use "../utilities/initial-variables" as iv;

$footer-background-color: cv.getVar("scheme-main-bis") !default;
$footer-color: false !default;
$footer-padding: 3rem 1.5rem 6rem !default;

.#{iv.$class-prefix}footer {
  @include cv.register-vars(
    (
      "footer-background-color": #{$footer-background-color},
      "footer-color": #{$footer-color},
      "footer-padding": #{$footer-padding},
    )
  );

  background-color: cv.getVar("footer-background-color");
  padding: cv.getVar("footer-padding");

  @if $footer-color {
    color: cv.getVar("footer-color");
  }
}
