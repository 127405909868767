@import ../node_modules/bulma/bulma

// === THEME ===
:root
  --bg: #fff
  --text: #111

body
  font-family: "SF Pro Display", sans-serif
  font-size: 12pt
  background-color: var(--bg)
  color: var(--text)
  transition: background-color 0.3s, color 0.3s

// === TYPOGRAPHY ===
h1,h2,h3,h4,h5,h6
  font-weight: bold

h1
  font-size: 1.8em
h2
  font-size: 1.6em
h3
  font-size: 1.4em
h4
  font-size: 1.3em
h5
  font-size: 1.2em
h6
  font-size: 1.1em

h1,h2,h3,h4,h5,h6,p
  margin: 0.33em 0 0 0

li
  margin: 0.5em 0

ul, ol
  list-style-position: inside

ol
  list-style-type: decimal

ul
  list-style-type: disc

a
  text-decoration: underline

// === BRANDING ===
.has-branding-background
  background-color: #272B32

.has-text-muted
  color: #676B72

.has-text-muted strong
  color: #878B92

footer.footer
  font-size: 0.95rem

// === LOGO STYLING ===
.image
  max-width: 450px
  margin: 0 auto

.image img
  width: 100%
  height: auto
  display: block

.fade-in
  opacity: 0
  transform: perspective(600px) translateZ(-120px) rotateX(12deg)
  animation: enter3D 1.8s cubic-bezier(0.25, 1, 0.5, 1) forwards

@keyframes enter3D
  to
    opacity: 1
    transform: perspective(800px) translateZ(0) rotateX(0deg)