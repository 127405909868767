@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes pulsate {
  50% {
    opacity: 0.5;
  }
}
