@use "../utilities/css-variables" as cv;
@use "../utilities/initial-variables" as iv;
@use "../utilities/extends";
@use "../utilities/mixins" as mx;

$menu-item-h: cv.getVar("scheme-h");
$menu-item-s: cv.getVar("scheme-s");
$menu-item-l: cv.getVar("scheme-main-l");
$menu-item-background-l: cv.getVar("scheme-main-l");
$menu-item-background-l-delta: 0%;
$menu-item-hover-background-l-delta: cv.getVar("hover-background-l-delta");
$menu-item-active-background-l-delta: cv.getVar("active-background-l-delta");
$menu-item-color-l: cv.getVar("text-l");
$menu-item-radius: cv.getVar("radius-small") !default;
$menu-item-selected-h: cv.getVar("link-h");
$menu-item-selected-s: cv.getVar("link-s");
$menu-item-selected-l: cv.getVar("link-l");
$menu-item-selected-background-l: cv.getVar("link-l");
$menu-item-selected-color-l: cv.getVar("link-invert-l");

$menu-list-border-left: 1px solid cv.getVar("border") !default;
$menu-list-line-height: 1.25 !default;
$menu-list-link-padding: 0.5em 0.75em !default;
$menu-nested-list-margin: 0.75em !default;
$menu-nested-list-padding-left: 0.75em !default;

$menu-label-color: cv.getVar("text-weak") !default;
$menu-label-font-size: 0.75em !default;
$menu-label-letter-spacing: 0.1em !default;
$menu-label-spacing: 1em !default;

.#{iv.$class-prefix}menu {
  @include cv.register-vars(
    (
      "menu-item-h": #{$menu-item-h},
      "menu-item-s": #{$menu-item-s},
      "menu-item-l": #{$menu-item-l},
      "menu-item-background-l": #{$menu-item-background-l},
      "menu-item-background-l-delta": #{$menu-item-background-l-delta},
      "menu-item-hover-background-l-delta": #{$menu-item-hover-background-l-delta},
      "menu-item-active-background-l-delta": #{$menu-item-active-background-l-delta},
      "menu-item-color-l": #{$menu-item-color-l},
      "menu-item-radius": #{$menu-item-radius},
      "menu-item-selected-h": #{$menu-item-selected-h},
      "menu-item-selected-s": #{$menu-item-selected-s},
      "menu-item-selected-l": #{$menu-item-selected-l},
      "menu-item-selected-background-l": #{$menu-item-selected-background-l},
      "menu-item-selected-color-l": #{$menu-item-selected-color-l},
      "menu-list-border-left": #{$menu-list-border-left},
      "menu-list-line-height": #{$menu-list-line-height},
      "menu-list-link-padding": #{$menu-list-link-padding},
      "menu-nested-list-margin": #{$menu-nested-list-margin},
      "menu-nested-list-padding-left": #{$menu-nested-list-padding-left},
      "menu-label-color": #{$menu-label-color},
      "menu-label-font-size": #{$menu-label-font-size},
      "menu-label-letter-spacing": #{$menu-label-letter-spacing},
      "menu-label-spacing": #{$menu-label-spacing},
    )
  );
}

.#{iv.$class-prefix}menu {
  font-size: cv.getVar("size-normal");

  // Sizes
  &.#{iv.$class-prefix}is-small {
    font-size: cv.getVar("size-small");
  }

  &.#{iv.$class-prefix}is-medium {
    font-size: cv.getVar("size-medium");
  }

  &.#{iv.$class-prefix}is-large {
    font-size: cv.getVar("size-large");
  }
}

.#{iv.$class-prefix}menu-list {
  line-height: cv.getVar("menu-list-line-height");

  a,
  button,
  .#{iv.$class-prefix}menu-item {
    @extend %reset;
    background-color: hsl(
      #{cv.getVar("menu-item-h")},
      #{cv.getVar("menu-item-s")},
      calc(
        #{cv.getVar("menu-item-background-l")} + #{cv.getVar(
            "menu-item-background-l-delta"
          )}
      )
    );
    border-radius: cv.getVar("menu-item-radius");
    color: hsl(
      #{cv.getVar("menu-item-h")},
      #{cv.getVar("menu-item-s")},
      #{cv.getVar("menu-item-color-l")}
    );
    display: block;
    padding: cv.getVar("menu-list-link-padding");
    text-align: left;
    width: 100%;

    &:hover {
      @include cv.register-vars(
        (
          "menu-item-background-l-delta": #{cv.getVar(
              "menu-item-hover-background-l-delta"
            )},
        )
      );
    }

    &:active {
      @include cv.register-vars(
        (
          "menu-item-background-l-delta": #{cv.getVar(
              "menu-item-active-background-l-delta"
            )},
        )
      );
    }

    // Modifiers
    &.#{iv.$class-prefix}is-active,
    &.#{iv.$class-prefix}is-selected {
      @include cv.register-vars(
        (
          "menu-item-h": #{cv.getVar("menu-item-selected-h")},
          "menu-item-s": #{cv.getVar("menu-item-selected-s")},
          "menu-item-l": #{cv.getVar("menu-item-selected-l")},
          "menu-item-background-l": #{cv.getVar(
              "menu-item-selected-background-l"
            )},
          "menu-item-color-l": #{cv.getVar("menu-item-selected-color-l")},
        )
      );
    }
  }

  li {
    ul {
      border-inline-start: cv.getVar("menu-list-border-left");
      margin: cv.getVar("menu-nested-list-margin");
      padding-inline-start: cv.getVar("menu-nested-list-padding-left");
    }
  }
}

.#{iv.$class-prefix}menu-label {
  color: cv.getVar("menu-label-color");
  font-size: cv.getVar("menu-label-font-size");
  letter-spacing: cv.getVar("menu-label-letter-spacing");
  text-transform: uppercase;

  &:not(:first-child) {
    margin-top: cv.getVar("menu-label-spacing");
  }

  &:not(:last-child) {
    margin-bottom: cv.getVar("menu-label-spacing");
  }
}
