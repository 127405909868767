@import ../node_modules/bulma/bulma

body
  font-family: "SF Pro Display", sans-serif
  font-size: 12pt

h1,h2,h3,h4,h5,h6
  font-weight: bold

h1
  font-size: 1.8em
h2
  font-size: 1.6em
h3
  font-size: 1.4em
h4
  font-size: 1.3em
h5
  font-size: 1.2em
h6
  font-size: 1.1em

h1,h2,h3,h4,h5,h6,p
  margin: 0.33em 0 0 0

li
  margin: 0.5em 0

ul, ol
  list-style-position: inside

ol
  list-style-type: decimal

ul
  list-style-type: disc

a
  text-decoration: underline

.has-branding-background
  background-color: #272B32 !important

