@use "../utilities/css-variables" as cv;
@use "../utilities/initial-variables" as iv;

$icon-dimensions: 1.5rem !default;
$icon-dimensions-small: 1rem !default;
$icon-dimensions-medium: 2rem !default;
$icon-dimensions-large: 3rem !default;
$icon-text-spacing: 0.25em !default;

.#{iv.$class-prefix}icon,
.#{iv.$class-prefix}icon-text {
  @include cv.register-vars(
    (
      "icon-dimensions": #{$icon-dimensions},
      "icon-dimensions-small": #{$icon-dimensions-small},
      "icon-dimensions-medium": #{$icon-dimensions-medium},
      "icon-dimensions-large": #{$icon-dimensions-large},
      "icon-text-spacing": #{$icon-text-spacing},
    )
  );
}

.#{iv.$class-prefix}icon {
  align-items: center;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  height: cv.getVar("icon-dimensions");
  transition-duration: cv.getVar("duration");
  transition-property: color;
  width: cv.getVar("icon-dimensions");

  // Sizes
  &.#{iv.$class-prefix}is-small {
    height: cv.getVar("icon-dimensions-small");
    width: cv.getVar("icon-dimensions-small");
  }

  &.#{iv.$class-prefix}is-medium {
    height: cv.getVar("icon-dimensions-medium");
    width: cv.getVar("icon-dimensions-medium");
  }

  &.#{iv.$class-prefix}is-large {
    height: cv.getVar("icon-dimensions-large");
    width: cv.getVar("icon-dimensions-large");
  }
}

.#{iv.$class-prefix}icon-text {
  align-items: flex-start;
  color: inherit;
  display: inline-flex;
  flex-wrap: wrap;
  gap: cv.getVar("icon-text-spacing");
  line-height: cv.getVar("icon-dimensions");
  vertical-align: top;

  .#{iv.$class-prefix}icon {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

div.#{iv.$class-prefix}icon-text {
  display: flex;
}
