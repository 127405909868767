@use "../utilities/initial-variables" as iv;

.#{iv.$class-prefix}#{iv.$helpers-prefix}clipped {
  overflow: hidden !important;
}

$overflows: auto clip hidden scroll visible;

@each $overflow in $overflows {
  .#{iv.$class-prefix}#{iv.$helpers-prefix}overflow-#{$overflow} {
    overflow: $overflow !important;
  }

  .#{iv.$class-prefix}#{iv.$helpers-prefix}overflow-x-#{$overflow} {
    overflow-x: $overflow !important;
  }

  .#{iv.$class-prefix}#{iv.$helpers-prefix}overflow-y-#{$overflow} {
    overflow-y: $overflow !important;
  }
}
