:root {
  --bulma-control-radius: var(--bulma-radius);
  --bulma-control-radius-small: var(--bulma-radius-small);
  --bulma-control-border-width: 1px;
  --bulma-control-height: 2.5em;
  --bulma-control-line-height: 1.5;
  --bulma-control-padding-vertical: calc(.5em - 1px);
  --bulma-control-padding-horizontal: calc(.75em - 1px);
  --bulma-control-size: var(--bulma-size-normal);
  --bulma-control-focus-shadow-l: 50%;
  --bulma-scheme-h: 221;
  --bulma-scheme-s: 14%;
  --bulma-light-l: 96%;
  --bulma-light-invert-l: 21%;
  --bulma-dark-l: 21%;
  --bulma-dark-invert-l: 96%;
  --bulma-soft-l: 90%;
  --bulma-bold-l: 20%;
  --bulma-soft-invert-l: 20%;
  --bulma-bold-invert-l: 90%;
  --bulma-hover-background-l-delta: -5%;
  --bulma-active-background-l-delta: -10%;
  --bulma-hover-border-l-delta: -10%;
  --bulma-active-border-l-delta: -20%;
  --bulma-hover-color-l-delta: -5%;
  --bulma-active-color-l-delta: -10%;
  --bulma-hover-shadow-a-delta: -.05;
  --bulma-active-shadow-a-delta: -.1;
  --bulma-scheme-brightness: light;
  --bulma-scheme-main-l: 100%;
  --bulma-scheme-main-bis-l: 98%;
  --bulma-scheme-main-ter-l: 96%;
  --bulma-background-l: 96%;
  --bulma-border-weak-l: 93%;
  --bulma-border-l: 86%;
  --bulma-text-weak-l: 48%;
  --bulma-text-l: 29%;
  --bulma-text-strong-l: 21%;
  --bulma-text-title-l: 14%;
  --bulma-scheme-invert-ter-l: 14%;
  --bulma-scheme-invert-bis-l: 7%;
  --bulma-scheme-invert-l: 4%;
  --bulma-family-primary: Inter, SF Pro, Segoe UI, Roboto, Oxygen, Ubuntu, Helvetica Neue, Helvetica, Arial, sans-serif;
  --bulma-family-secondary: Inter, SF Pro, Segoe UI, Roboto, Oxygen, Ubuntu, Helvetica Neue, Helvetica, Arial, sans-serif;
  --bulma-family-code: Inconsolata, Hack, SF Mono, Roboto Mono, Source Code Pro, Ubuntu Mono, monospace;
  --bulma-size-small: .75rem;
  --bulma-size-normal: 1rem;
  --bulma-size-medium: 1.25rem;
  --bulma-size-large: 1.5rem;
  --bulma-weight-light: 300;
  --bulma-weight-normal: 400;
  --bulma-weight-medium: 500;
  --bulma-weight-semibold: 600;
  --bulma-weight-bold: 700;
  --bulma-weight-extrabold: 800;
  --bulma-block-spacing: 1.5rem;
  --bulma-duration: .294s;
  --bulma-easing: ease-out;
  --bulma-radius-small: .25rem;
  --bulma-radius: .375rem;
  --bulma-radius-medium: .5em;
  --bulma-radius-large: .75rem;
  --bulma-radius-rounded: 9999px;
  --bulma-speed: 86ms;
  --bulma-arrow-color: var(--bulma-link);
  --bulma-loading-color: var(--bulma-border);
  --bulma-burger-h: var(--bulma-link-h);
  --bulma-burger-s: var(--bulma-link-s);
  --bulma-burger-l: var(--bulma-link-l);
  --bulma-burger-border-radius: .5em;
  --bulma-burger-gap: 5px;
  --bulma-burger-item-height: 2px;
  --bulma-burger-item-width: 20px;
  --bulma-white: hsla(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-l), 1);
  --bulma-white-base: hsla(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-l), 1);
  --bulma-white-rgb: 255, 255, 255;
  --bulma-white-h: 221deg;
  --bulma-white-s: 14%;
  --bulma-white-l: 100%;
  --bulma-white-invert-l: 4%;
  --bulma-white-invert: #090a0c;
  --bulma-white-on-scheme-l: 35%;
  --bulma-white-on-scheme: hsla(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-on-scheme-l), 1);
  --bulma-black: hsla(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-l), 1);
  --bulma-black-base: hsla(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-l), 1);
  --bulma-black-rgb: 8.772, 9.6764, 11.628;
  --bulma-black-h: 221deg;
  --bulma-black-s: 14%;
  --bulma-black-l: 4%;
  --bulma-black-invert-l: 100%;
  --bulma-black-invert: #fff;
  --bulma-black-on-scheme-l: 4%;
  --bulma-black-on-scheme: hsla(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-on-scheme-l), 1);
  --bulma-light: hsla(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-l), 1);
  --bulma-light-base: hsla(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-l), 1);
  --bulma-light-rgb: 243.372, 244.276, 246.228;
  --bulma-light-h: 221deg;
  --bulma-light-s: 14%;
  --bulma-light-invert: #2e333d;
  --bulma-light-on-scheme-l: 36%;
  --bulma-light-on-scheme: hsla(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-on-scheme-l), 1);
  --bulma-dark: hsla(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-l), 1);
  --bulma-dark-base: hsla(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-l), 1);
  --bulma-dark-rgb: 46.053, 50.8011, 61.047;
  --bulma-dark-h: 221deg;
  --bulma-dark-s: 14%;
  --bulma-dark-invert: #f3f4f6;
  --bulma-dark-on-scheme-l: 21%;
  --bulma-dark-on-scheme: hsla(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-on-scheme-l), 1);
  --bulma-text: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-l));
  --bulma-text-base: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-l), 1);
  --bulma-text-rgb: 63.597, 70.1539, 84.303;
  --bulma-text-h: 221deg;
  --bulma-text-s: 14%;
  --bulma-text-00-l: 0%;
  --bulma-text-05-l: 4%;
  --bulma-text-10-l: 9%;
  --bulma-text-15-l: 14%;
  --bulma-text-20-l: 19%;
  --bulma-text-25-l: 24%;
  --bulma-text-30-l: 29%;
  --bulma-text-35-l: 34%;
  --bulma-text-40-l: 39%;
  --bulma-text-45-l: 44%;
  --bulma-text-50-l: 49%;
  --bulma-text-55-l: 54%;
  --bulma-text-60-l: 59%;
  --bulma-text-65-l: 64%;
  --bulma-text-70-l: 69%;
  --bulma-text-75-l: 74%;
  --bulma-text-80-l: 79%;
  --bulma-text-85-l: 84%;
  --bulma-text-90-l: 89%;
  --bulma-text-95-l: 94%;
  --bulma-text-100-l: 99%;
  --bulma-text-00: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-00-l), 1);
  --bulma-text-00-invert-l: var(--bulma-text-60-l);
  --bulma-text-00-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-00-invert-l), 1);
  --bulma-text-05: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-05-l), 1);
  --bulma-text-05-invert-l: var(--bulma-text-60-l);
  --bulma-text-05-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-05-invert-l), 1);
  --bulma-text-10: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-10-l), 1);
  --bulma-text-10-invert-l: var(--bulma-text-70-l);
  --bulma-text-10-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-10-invert-l), 1);
  --bulma-text-15: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-15-l), 1);
  --bulma-text-15-invert-l: var(--bulma-text-75-l);
  --bulma-text-15-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-15-invert-l), 1);
  --bulma-text-20: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-20-l), 1);
  --bulma-text-20-invert-l: var(--bulma-text-85-l);
  --bulma-text-20-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-20-invert-l), 1);
  --bulma-text-25: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-25-l), 1);
  --bulma-text-25-invert-l: var(--bulma-text-95-l);
  --bulma-text-25-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-25-invert-l), 1);
  --bulma-text-30: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-30-l), 1);
  --bulma-text-30-invert-l: var(--bulma-text-100-l);
  --bulma-text-30-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-30-invert-l), 1);
  --bulma-text-35: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-35-l), 1);
  --bulma-text-35-invert-l: var(--bulma-text-100-l);
  --bulma-text-35-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-35-invert-l), 1);
  --bulma-text-40: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-40-l), 1);
  --bulma-text-40-invert-l: var(--bulma-text-100-l);
  --bulma-text-40-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-40-invert-l), 1);
  --bulma-text-45: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-45-l), 1);
  --bulma-text-45-invert-l: var(--bulma-text-100-l);
  --bulma-text-45-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-45-invert-l), 1);
  --bulma-text-50: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-50-l), 1);
  --bulma-text-50-invert-l: var(--bulma-text-100-l);
  --bulma-text-50-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-50-invert-l), 1);
  --bulma-text-55: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-55-l), 1);
  --bulma-text-55-invert-l: var(--bulma-text-100-l);
  --bulma-text-55-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-55-invert-l), 1);
  --bulma-text-60: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-60-l), 1);
  --bulma-text-60-invert-l: var(--bulma-text-05-l);
  --bulma-text-60-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-60-invert-l), 1);
  --bulma-text-65: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-65-l), 1);
  --bulma-text-65-invert-l: var(--bulma-text-05-l);
  --bulma-text-65-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-65-invert-l), 1);
  --bulma-text-70: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-70-l), 1);
  --bulma-text-70-invert-l: var(--bulma-text-10-l);
  --bulma-text-70-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-70-invert-l), 1);
  --bulma-text-75: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-75-l), 1);
  --bulma-text-75-invert-l: var(--bulma-text-15-l);
  --bulma-text-75-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-75-invert-l), 1);
  --bulma-text-80: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-80-l), 1);
  --bulma-text-80-invert-l: var(--bulma-text-15-l);
  --bulma-text-80-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-80-invert-l), 1);
  --bulma-text-85: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-85-l), 1);
  --bulma-text-85-invert-l: var(--bulma-text-20-l);
  --bulma-text-85-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-85-invert-l), 1);
  --bulma-text-90: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-90-l), 1);
  --bulma-text-90-invert-l: var(--bulma-text-20-l);
  --bulma-text-90-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-90-invert-l), 1);
  --bulma-text-95: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-95-l), 1);
  --bulma-text-95-invert-l: var(--bulma-text-25-l);
  --bulma-text-95-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-95-invert-l), 1);
  --bulma-text-100: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-100-l), 1);
  --bulma-text-100-invert-l: var(--bulma-text-25-l);
  --bulma-text-100-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-100-invert-l), 1);
  --bulma-text-invert-l: var(--bulma-text-100-l);
  --bulma-text-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-invert-l), 1);
  --bulma-text-light-l: var(--bulma-text-90-l);
  --bulma-text-light: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-light-l), 1);
  --bulma-text-light-invert-l: var(--bulma-text-20-l);
  --bulma-text-light-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-light-invert-l), 1);
  --bulma-text-dark-l: var(--bulma-text-10-l);
  --bulma-text-dark: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-dark-l), 1);
  --bulma-text-dark-invert-l: var(--bulma-text-70-l);
  --bulma-text-dark-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-dark-invert-l), 1);
  --bulma-text-soft: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-soft-l), 1);
  --bulma-text-bold: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-bold-l), 1);
  --bulma-text-soft-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-soft-invert-l), 1);
  --bulma-text-bold-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-bold-invert-l), 1);
  --bulma-text-on-scheme-l: 29%;
  --bulma-text-on-scheme: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-on-scheme-l), 1);
  --bulma-primary: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-l), 1);
  --bulma-primary-base: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-l), 1);
  --bulma-primary-rgb: 0, 209.1, 177.735;
  --bulma-primary-h: 171deg;
  --bulma-primary-s: 100%;
  --bulma-primary-l: 41%;
  --bulma-primary-00-l: 1%;
  --bulma-primary-05-l: 6%;
  --bulma-primary-10-l: 11%;
  --bulma-primary-15-l: 16%;
  --bulma-primary-20-l: 21%;
  --bulma-primary-25-l: 26%;
  --bulma-primary-30-l: 31%;
  --bulma-primary-35-l: 36%;
  --bulma-primary-40-l: 41%;
  --bulma-primary-45-l: 46%;
  --bulma-primary-50-l: 51%;
  --bulma-primary-55-l: 56%;
  --bulma-primary-60-l: 61%;
  --bulma-primary-65-l: 66%;
  --bulma-primary-70-l: 71%;
  --bulma-primary-75-l: 76%;
  --bulma-primary-80-l: 81%;
  --bulma-primary-85-l: 86%;
  --bulma-primary-90-l: 91%;
  --bulma-primary-95-l: 96%;
  --bulma-primary-100-l: 100%;
  --bulma-primary-00: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-00-l), 1);
  --bulma-primary-00-invert-l: var(--bulma-primary-30-l);
  --bulma-primary-00-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-00-invert-l), 1);
  --bulma-primary-05: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-05-l), 1);
  --bulma-primary-05-invert-l: var(--bulma-primary-40-l);
  --bulma-primary-05-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-05-invert-l), 1);
  --bulma-primary-10: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-10-l), 1);
  --bulma-primary-10-invert-l: var(--bulma-primary-50-l);
  --bulma-primary-10-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-10-invert-l), 1);
  --bulma-primary-15: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-15-l), 1);
  --bulma-primary-15-invert-l: var(--bulma-primary-100-l);
  --bulma-primary-15-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-15-invert-l), 1);
  --bulma-primary-20: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-20-l), 1);
  --bulma-primary-20-invert-l: var(--bulma-primary-100-l);
  --bulma-primary-20-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-20-invert-l), 1);
  --bulma-primary-25: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-25-l), 1);
  --bulma-primary-25-invert-l: var(--bulma-primary-100-l);
  --bulma-primary-25-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-25-invert-l), 1);
  --bulma-primary-30: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-30-l), 1);
  --bulma-primary-30-invert-l: var(--bulma-primary-00-l);
  --bulma-primary-30-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-30-invert-l), 1);
  --bulma-primary-35: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-35-l), 1);
  --bulma-primary-35-invert-l: var(--bulma-primary-00-l);
  --bulma-primary-35-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-35-invert-l), 1);
  --bulma-primary-40: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-40-l), 1);
  --bulma-primary-40-invert-l: var(--bulma-primary-05-l);
  --bulma-primary-40-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-40-invert-l), 1);
  --bulma-primary-45: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-45-l), 1);
  --bulma-primary-45-invert-l: var(--bulma-primary-05-l);
  --bulma-primary-45-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-45-invert-l), 1);
  --bulma-primary-50: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-50-l), 1);
  --bulma-primary-50-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-50-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-50-invert-l), 1);
  --bulma-primary-55: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-55-l), 1);
  --bulma-primary-55-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-55-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-55-invert-l), 1);
  --bulma-primary-60: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-60-l), 1);
  --bulma-primary-60-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-60-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-60-invert-l), 1);
  --bulma-primary-65: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-65-l), 1);
  --bulma-primary-65-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-65-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-65-invert-l), 1);
  --bulma-primary-70: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-70-l), 1);
  --bulma-primary-70-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-70-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-70-invert-l), 1);
  --bulma-primary-75: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-75-l), 1);
  --bulma-primary-75-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-75-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-75-invert-l), 1);
  --bulma-primary-80: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-80-l), 1);
  --bulma-primary-80-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-80-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-80-invert-l), 1);
  --bulma-primary-85: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-85-l), 1);
  --bulma-primary-85-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-85-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-85-invert-l), 1);
  --bulma-primary-90: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-90-l), 1);
  --bulma-primary-90-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-90-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-90-invert-l), 1);
  --bulma-primary-95: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-95-l), 1);
  --bulma-primary-95-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-95-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-95-invert-l), 1);
  --bulma-primary-100: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-100-l), 1);
  --bulma-primary-100-invert-l: var(--bulma-primary-15-l);
  --bulma-primary-100-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-100-invert-l), 1);
  --bulma-primary-invert-l: var(--bulma-primary-05-l);
  --bulma-primary-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-invert-l), 1);
  --bulma-primary-light-l: var(--bulma-primary-90-l);
  --bulma-primary-light: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-light-l), 1);
  --bulma-primary-light-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-light-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-light-invert-l), 1);
  --bulma-primary-dark-l: var(--bulma-primary-10-l);
  --bulma-primary-dark: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-dark-l), 1);
  --bulma-primary-dark-invert-l: var(--bulma-primary-50-l);
  --bulma-primary-dark-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-dark-invert-l), 1);
  --bulma-primary-soft: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-soft-l), 1);
  --bulma-primary-bold: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-bold-l), 1);
  --bulma-primary-soft-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-soft-invert-l), 1);
  --bulma-primary-bold-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-bold-invert-l), 1);
  --bulma-primary-on-scheme-l: 21%;
  --bulma-primary-on-scheme: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-on-scheme-l), 1);
  --bulma-link: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-l));
  --bulma-link-base: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-l), 1);
  --bulma-link-rgb: 66.3, 88.315, 255;
  --bulma-link-h: 233deg;
  --bulma-link-s: 100%;
  --bulma-link-l: 63%;
  --bulma-link-00-l: 0%;
  --bulma-link-05-l: 3%;
  --bulma-link-10-l: 8%;
  --bulma-link-15-l: 13%;
  --bulma-link-20-l: 18%;
  --bulma-link-25-l: 23%;
  --bulma-link-30-l: 28%;
  --bulma-link-35-l: 33%;
  --bulma-link-40-l: 38%;
  --bulma-link-45-l: 43%;
  --bulma-link-50-l: 48%;
  --bulma-link-55-l: 53%;
  --bulma-link-60-l: 58%;
  --bulma-link-65-l: 63%;
  --bulma-link-70-l: 68%;
  --bulma-link-75-l: 73%;
  --bulma-link-80-l: 78%;
  --bulma-link-85-l: 83%;
  --bulma-link-90-l: 88%;
  --bulma-link-95-l: 93%;
  --bulma-link-100-l: 98%;
  --bulma-link-00: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-00-l), 1);
  --bulma-link-00-invert-l: var(--bulma-link-75-l);
  --bulma-link-00-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-00-invert-l), 1);
  --bulma-link-05: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-05-l), 1);
  --bulma-link-05-invert-l: var(--bulma-link-75-l);
  --bulma-link-05-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-05-invert-l), 1);
  --bulma-link-10: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-10-l), 1);
  --bulma-link-10-invert-l: var(--bulma-link-75-l);
  --bulma-link-10-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-10-invert-l), 1);
  --bulma-link-15: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-15-l), 1);
  --bulma-link-15-invert-l: var(--bulma-link-80-l);
  --bulma-link-15-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-15-invert-l), 1);
  --bulma-link-20: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-20-l), 1);
  --bulma-link-20-invert-l: var(--bulma-link-80-l);
  --bulma-link-20-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-20-invert-l), 1);
  --bulma-link-25: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-25-l), 1);
  --bulma-link-25-invert-l: var(--bulma-link-85-l);
  --bulma-link-25-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-25-invert-l), 1);
  --bulma-link-30: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-30-l), 1);
  --bulma-link-30-invert-l: var(--bulma-link-90-l);
  --bulma-link-30-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-30-invert-l), 1);
  --bulma-link-35: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-35-l), 1);
  --bulma-link-35-invert-l: var(--bulma-link-90-l);
  --bulma-link-35-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-35-invert-l), 1);
  --bulma-link-40: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-40-l), 1);
  --bulma-link-40-invert-l: var(--bulma-link-95-l);
  --bulma-link-40-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-40-invert-l), 1);
  --bulma-link-45: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-45-l), 1);
  --bulma-link-45-invert-l: var(--bulma-link-95-l);
  --bulma-link-45-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-45-invert-l), 1);
  --bulma-link-50: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-50-l), 1);
  --bulma-link-50-invert-l: var(--bulma-link-100-l);
  --bulma-link-50-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-50-invert-l), 1);
  --bulma-link-55: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-55-l), 1);
  --bulma-link-55-invert-l: var(--bulma-link-100-l);
  --bulma-link-55-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-55-invert-l), 1);
  --bulma-link-60: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-60-l), 1);
  --bulma-link-60-invert-l: var(--bulma-link-100-l);
  --bulma-link-60-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-60-invert-l), 1);
  --bulma-link-65: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-65-l), 1);
  --bulma-link-65-invert-l: var(--bulma-link-100-l);
  --bulma-link-65-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-65-invert-l), 1);
  --bulma-link-70: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-70-l), 1);
  --bulma-link-70-invert-l: var(--bulma-link-100-l);
  --bulma-link-70-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-70-invert-l), 1);
  --bulma-link-75: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-75-l), 1);
  --bulma-link-75-invert-l: var(--bulma-link-10-l);
  --bulma-link-75-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-75-invert-l), 1);
  --bulma-link-80: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-80-l), 1);
  --bulma-link-80-invert-l: var(--bulma-link-20-l);
  --bulma-link-80-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-80-invert-l), 1);
  --bulma-link-85: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-85-l), 1);
  --bulma-link-85-invert-l: var(--bulma-link-25-l);
  --bulma-link-85-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-85-invert-l), 1);
  --bulma-link-90: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-90-l), 1);
  --bulma-link-90-invert-l: var(--bulma-link-35-l);
  --bulma-link-90-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-90-invert-l), 1);
  --bulma-link-95: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-95-l), 1);
  --bulma-link-95-invert-l: var(--bulma-link-45-l);
  --bulma-link-95-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-95-invert-l), 1);
  --bulma-link-100: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-100-l), 1);
  --bulma-link-100-invert-l: var(--bulma-link-50-l);
  --bulma-link-100-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-100-invert-l), 1);
  --bulma-link-invert-l: var(--bulma-link-100-l);
  --bulma-link-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-invert-l), 1);
  --bulma-link-light-l: var(--bulma-link-90-l);
  --bulma-link-light: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-light-l), 1);
  --bulma-link-light-invert-l: var(--bulma-link-35-l);
  --bulma-link-light-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-light-invert-l), 1);
  --bulma-link-dark-l: var(--bulma-link-10-l);
  --bulma-link-dark: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-dark-l), 1);
  --bulma-link-dark-invert-l: var(--bulma-link-75-l);
  --bulma-link-dark-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-dark-invert-l), 1);
  --bulma-link-soft: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-soft-l), 1);
  --bulma-link-bold: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-bold-l), 1);
  --bulma-link-soft-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-soft-invert-l), 1);
  --bulma-link-bold-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-bold-invert-l), 1);
  --bulma-link-on-scheme-l: 58%;
  --bulma-link-on-scheme: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-on-scheme-l), 1);
  --bulma-info: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-l), 1);
  --bulma-info-base: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-l), 1);
  --bulma-info-rgb: 102, 209.1, 255;
  --bulma-info-h: 198deg;
  --bulma-info-s: 100%;
  --bulma-info-l: 70%;
  --bulma-info-00-l: 0%;
  --bulma-info-05-l: 5%;
  --bulma-info-10-l: 10%;
  --bulma-info-15-l: 15%;
  --bulma-info-20-l: 20%;
  --bulma-info-25-l: 25%;
  --bulma-info-30-l: 30%;
  --bulma-info-35-l: 35%;
  --bulma-info-40-l: 40%;
  --bulma-info-45-l: 45%;
  --bulma-info-50-l: 50%;
  --bulma-info-55-l: 55%;
  --bulma-info-60-l: 60%;
  --bulma-info-65-l: 65%;
  --bulma-info-70-l: 70%;
  --bulma-info-75-l: 75%;
  --bulma-info-80-l: 80%;
  --bulma-info-85-l: 85%;
  --bulma-info-90-l: 90%;
  --bulma-info-95-l: 95%;
  --bulma-info-100-l: 100%;
  --bulma-info-00: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-00-l), 1);
  --bulma-info-00-invert-l: var(--bulma-info-45-l);
  --bulma-info-00-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-00-invert-l), 1);
  --bulma-info-05: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-05-l), 1);
  --bulma-info-05-invert-l: var(--bulma-info-50-l);
  --bulma-info-05-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-05-invert-l), 1);
  --bulma-info-10: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-10-l), 1);
  --bulma-info-10-invert-l: var(--bulma-info-60-l);
  --bulma-info-10-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-10-invert-l), 1);
  --bulma-info-15: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-15-l), 1);
  --bulma-info-15-invert-l: var(--bulma-info-80-l);
  --bulma-info-15-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-15-invert-l), 1);
  --bulma-info-20: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-20-l), 1);
  --bulma-info-20-invert-l: var(--bulma-info-95-l);
  --bulma-info-20-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-20-invert-l), 1);
  --bulma-info-25: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-25-l), 1);
  --bulma-info-25-invert-l: var(--bulma-info-100-l);
  --bulma-info-25-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-25-invert-l), 1);
  --bulma-info-30: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-30-l), 1);
  --bulma-info-30-invert-l: var(--bulma-info-100-l);
  --bulma-info-30-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-30-invert-l), 1);
  --bulma-info-35: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-35-l), 1);
  --bulma-info-35-invert-l: var(--bulma-info-100-l);
  --bulma-info-35-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-35-invert-l), 1);
  --bulma-info-40: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-40-l), 1);
  --bulma-info-40-invert-l: var(--bulma-info-100-l);
  --bulma-info-40-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-40-invert-l), 1);
  --bulma-info-45: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-45-l), 1);
  --bulma-info-45-invert-l: var(--bulma-info-00-l);
  --bulma-info-45-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-45-invert-l), 1);
  --bulma-info-50: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-50-l), 1);
  --bulma-info-50-invert-l: var(--bulma-info-05-l);
  --bulma-info-50-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-50-invert-l), 1);
  --bulma-info-55: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-55-l), 1);
  --bulma-info-55-invert-l: var(--bulma-info-05-l);
  --bulma-info-55-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-55-invert-l), 1);
  --bulma-info-60: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-60-l), 1);
  --bulma-info-60-invert-l: var(--bulma-info-10-l);
  --bulma-info-60-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-60-invert-l), 1);
  --bulma-info-65: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-65-l), 1);
  --bulma-info-65-invert-l: var(--bulma-info-10-l);
  --bulma-info-65-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-65-invert-l), 1);
  --bulma-info-70: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-70-l), 1);
  --bulma-info-70-invert-l: var(--bulma-info-10-l);
  --bulma-info-70-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-70-invert-l), 1);
  --bulma-info-75: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-75-l), 1);
  --bulma-info-75-invert-l: var(--bulma-info-10-l);
  --bulma-info-75-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-75-invert-l), 1);
  --bulma-info-80: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-80-l), 1);
  --bulma-info-80-invert-l: var(--bulma-info-15-l);
  --bulma-info-80-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-80-invert-l), 1);
  --bulma-info-85: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-85-l), 1);
  --bulma-info-85-invert-l: var(--bulma-info-15-l);
  --bulma-info-85-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-85-invert-l), 1);
  --bulma-info-90: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-90-l), 1);
  --bulma-info-90-invert-l: var(--bulma-info-15-l);
  --bulma-info-90-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-90-invert-l), 1);
  --bulma-info-95: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-95-l), 1);
  --bulma-info-95-invert-l: var(--bulma-info-20-l);
  --bulma-info-95-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-95-invert-l), 1);
  --bulma-info-100: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-100-l), 1);
  --bulma-info-100-invert-l: var(--bulma-info-20-l);
  --bulma-info-100-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-100-invert-l), 1);
  --bulma-info-invert-l: var(--bulma-info-10-l);
  --bulma-info-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-invert-l), 1);
  --bulma-info-light-l: var(--bulma-info-90-l);
  --bulma-info-light: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-light-l), 1);
  --bulma-info-light-invert-l: var(--bulma-info-15-l);
  --bulma-info-light-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-light-invert-l), 1);
  --bulma-info-dark-l: var(--bulma-info-10-l);
  --bulma-info-dark: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-dark-l), 1);
  --bulma-info-dark-invert-l: var(--bulma-info-60-l);
  --bulma-info-dark-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-dark-invert-l), 1);
  --bulma-info-soft: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-soft-l), 1);
  --bulma-info-bold: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-bold-l), 1);
  --bulma-info-soft-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-soft-invert-l), 1);
  --bulma-info-bold-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-bold-invert-l), 1);
  --bulma-info-on-scheme-l: 25%;
  --bulma-info-on-scheme: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-on-scheme-l), 1);
  --bulma-success: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-l), 1);
  --bulma-success-base: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-l), 1);
  --bulma-success-rgb: 71.6295, 198.671, 141.502;
  --bulma-success-h: 153deg;
  --bulma-success-s: 53%;
  --bulma-success-l: 53%;
  --bulma-success-00-l: 0%;
  --bulma-success-05-l: 3%;
  --bulma-success-10-l: 8%;
  --bulma-success-15-l: 13%;
  --bulma-success-20-l: 18%;
  --bulma-success-25-l: 23%;
  --bulma-success-30-l: 28%;
  --bulma-success-35-l: 33%;
  --bulma-success-40-l: 38%;
  --bulma-success-45-l: 43%;
  --bulma-success-50-l: 48%;
  --bulma-success-55-l: 53%;
  --bulma-success-60-l: 58%;
  --bulma-success-65-l: 63%;
  --bulma-success-70-l: 68%;
  --bulma-success-75-l: 73%;
  --bulma-success-80-l: 78%;
  --bulma-success-85-l: 83%;
  --bulma-success-90-l: 88%;
  --bulma-success-95-l: 93%;
  --bulma-success-100-l: 98%;
  --bulma-success-00: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-00-l), 1);
  --bulma-success-00-invert-l: var(--bulma-success-45-l);
  --bulma-success-00-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-00-invert-l), 1);
  --bulma-success-05: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-05-l), 1);
  --bulma-success-05-invert-l: var(--bulma-success-45-l);
  --bulma-success-05-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-05-invert-l), 1);
  --bulma-success-10: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-10-l), 1);
  --bulma-success-10-invert-l: var(--bulma-success-55-l);
  --bulma-success-10-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-10-invert-l), 1);
  --bulma-success-15: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-15-l), 1);
  --bulma-success-15-invert-l: var(--bulma-success-75-l);
  --bulma-success-15-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-15-invert-l), 1);
  --bulma-success-20: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-20-l), 1);
  --bulma-success-20-invert-l: var(--bulma-success-95-l);
  --bulma-success-20-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-20-invert-l), 1);
  --bulma-success-25: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-25-l), 1);
  --bulma-success-25-invert-l: var(--bulma-success-100-l);
  --bulma-success-25-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-25-invert-l), 1);
  --bulma-success-30: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-30-l), 1);
  --bulma-success-30-invert-l: var(--bulma-success-100-l);
  --bulma-success-30-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-30-invert-l), 1);
  --bulma-success-35: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-35-l), 1);
  --bulma-success-35-invert-l: var(--bulma-success-100-l);
  --bulma-success-35-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-35-invert-l), 1);
  --bulma-success-40: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-40-l), 1);
  --bulma-success-40-invert-l: var(--bulma-success-100-l);
  --bulma-success-40-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-40-invert-l), 1);
  --bulma-success-45: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-45-l), 1);
  --bulma-success-45-invert-l: var(--bulma-success-05-l);
  --bulma-success-45-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-45-invert-l), 1);
  --bulma-success-50: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-50-l), 1);
  --bulma-success-50-invert-l: var(--bulma-success-05-l);
  --bulma-success-50-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-50-invert-l), 1);
  --bulma-success-55: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-55-l), 1);
  --bulma-success-55-invert-l: var(--bulma-success-10-l);
  --bulma-success-55-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-55-invert-l), 1);
  --bulma-success-60: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-60-l), 1);
  --bulma-success-60-invert-l: var(--bulma-success-10-l);
  --bulma-success-60-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-60-invert-l), 1);
  --bulma-success-65: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-65-l), 1);
  --bulma-success-65-invert-l: var(--bulma-success-10-l);
  --bulma-success-65-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-65-invert-l), 1);
  --bulma-success-70: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-70-l), 1);
  --bulma-success-70-invert-l: var(--bulma-success-10-l);
  --bulma-success-70-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-70-invert-l), 1);
  --bulma-success-75: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-75-l), 1);
  --bulma-success-75-invert-l: var(--bulma-success-15-l);
  --bulma-success-75-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-75-invert-l), 1);
  --bulma-success-80: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-80-l), 1);
  --bulma-success-80-invert-l: var(--bulma-success-15-l);
  --bulma-success-80-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-80-invert-l), 1);
  --bulma-success-85: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-85-l), 1);
  --bulma-success-85-invert-l: var(--bulma-success-15-l);
  --bulma-success-85-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-85-invert-l), 1);
  --bulma-success-90: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-90-l), 1);
  --bulma-success-90-invert-l: var(--bulma-success-15-l);
  --bulma-success-90-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-90-invert-l), 1);
  --bulma-success-95: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-95-l), 1);
  --bulma-success-95-invert-l: var(--bulma-success-20-l);
  --bulma-success-95-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-95-invert-l), 1);
  --bulma-success-100: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-100-l), 1);
  --bulma-success-100-invert-l: var(--bulma-success-20-l);
  --bulma-success-100-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-100-invert-l), 1);
  --bulma-success-invert-l: var(--bulma-success-10-l);
  --bulma-success-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-invert-l), 1);
  --bulma-success-light-l: var(--bulma-success-90-l);
  --bulma-success-light: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-light-l), 1);
  --bulma-success-light-invert-l: var(--bulma-success-15-l);
  --bulma-success-light-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-light-invert-l), 1);
  --bulma-success-dark-l: var(--bulma-success-10-l);
  --bulma-success-dark: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-dark-l), 1);
  --bulma-success-dark-invert-l: var(--bulma-success-55-l);
  --bulma-success-dark-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-dark-invert-l), 1);
  --bulma-success-soft: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-soft-l), 1);
  --bulma-success-bold: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-bold-l), 1);
  --bulma-success-soft-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-soft-invert-l), 1);
  --bulma-success-bold-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-bold-invert-l), 1);
  --bulma-success-on-scheme-l: 23%;
  --bulma-success-on-scheme: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-on-scheme-l), 1);
  --bulma-warning: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-l), 1);
  --bulma-warning-base: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-l), 1);
  --bulma-warning-rgb: 255, 183.09, 15.3;
  --bulma-warning-h: 42deg;
  --bulma-warning-s: 100%;
  --bulma-warning-l: 53%;
  --bulma-warning-00-l: 0%;
  --bulma-warning-05-l: 3%;
  --bulma-warning-10-l: 8%;
  --bulma-warning-15-l: 13%;
  --bulma-warning-20-l: 18%;
  --bulma-warning-25-l: 23%;
  --bulma-warning-30-l: 28%;
  --bulma-warning-35-l: 33%;
  --bulma-warning-40-l: 38%;
  --bulma-warning-45-l: 43%;
  --bulma-warning-50-l: 48%;
  --bulma-warning-55-l: 53%;
  --bulma-warning-60-l: 58%;
  --bulma-warning-65-l: 63%;
  --bulma-warning-70-l: 68%;
  --bulma-warning-75-l: 73%;
  --bulma-warning-80-l: 78%;
  --bulma-warning-85-l: 83%;
  --bulma-warning-90-l: 88%;
  --bulma-warning-95-l: 93%;
  --bulma-warning-100-l: 98%;
  --bulma-warning-00: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-00-l), 1);
  --bulma-warning-00-invert-l: var(--bulma-warning-40-l);
  --bulma-warning-00-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-00-invert-l), 1);
  --bulma-warning-05: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-05-l), 1);
  --bulma-warning-05-invert-l: var(--bulma-warning-45-l);
  --bulma-warning-05-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-05-invert-l), 1);
  --bulma-warning-10: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-10-l), 1);
  --bulma-warning-10-invert-l: var(--bulma-warning-50-l);
  --bulma-warning-10-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-10-invert-l), 1);
  --bulma-warning-15: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-15-l), 1);
  --bulma-warning-15-invert-l: var(--bulma-warning-70-l);
  --bulma-warning-15-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-15-invert-l), 1);
  --bulma-warning-20: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-20-l), 1);
  --bulma-warning-20-invert-l: var(--bulma-warning-100-l);
  --bulma-warning-20-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-20-invert-l), 1);
  --bulma-warning-25: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-25-l), 1);
  --bulma-warning-25-invert-l: var(--bulma-warning-100-l);
  --bulma-warning-25-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-25-invert-l), 1);
  --bulma-warning-30: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-30-l), 1);
  --bulma-warning-30-invert-l: var(--bulma-warning-100-l);
  --bulma-warning-30-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-30-invert-l), 1);
  --bulma-warning-35: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-35-l), 1);
  --bulma-warning-35-invert-l: var(--bulma-warning-100-l);
  --bulma-warning-35-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-35-invert-l), 1);
  --bulma-warning-40: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-40-l), 1);
  --bulma-warning-40-invert-l: var(--bulma-warning-00-l);
  --bulma-warning-40-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-40-invert-l), 1);
  --bulma-warning-45: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-45-l), 1);
  --bulma-warning-45-invert-l: var(--bulma-warning-05-l);
  --bulma-warning-45-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-45-invert-l), 1);
  --bulma-warning-50: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-50-l), 1);
  --bulma-warning-50-invert-l: var(--bulma-warning-10-l);
  --bulma-warning-50-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-50-invert-l), 1);
  --bulma-warning-55: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-55-l), 1);
  --bulma-warning-55-invert-l: var(--bulma-warning-10-l);
  --bulma-warning-55-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-55-invert-l), 1);
  --bulma-warning-60: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-60-l), 1);
  --bulma-warning-60-invert-l: var(--bulma-warning-10-l);
  --bulma-warning-60-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-60-invert-l), 1);
  --bulma-warning-65: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-65-l), 1);
  --bulma-warning-65-invert-l: var(--bulma-warning-10-l);
  --bulma-warning-65-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-65-invert-l), 1);
  --bulma-warning-70: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-70-l), 1);
  --bulma-warning-70-invert-l: var(--bulma-warning-15-l);
  --bulma-warning-70-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-70-invert-l), 1);
  --bulma-warning-75: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-75-l), 1);
  --bulma-warning-75-invert-l: var(--bulma-warning-15-l);
  --bulma-warning-75-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-75-invert-l), 1);
  --bulma-warning-80: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-80-l), 1);
  --bulma-warning-80-invert-l: var(--bulma-warning-15-l);
  --bulma-warning-80-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-80-invert-l), 1);
  --bulma-warning-85: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-85-l), 1);
  --bulma-warning-85-invert-l: var(--bulma-warning-15-l);
  --bulma-warning-85-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-85-invert-l), 1);
  --bulma-warning-90: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-90-l), 1);
  --bulma-warning-90-invert-l: var(--bulma-warning-15-l);
  --bulma-warning-90-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-90-invert-l), 1);
  --bulma-warning-95: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-95-l), 1);
  --bulma-warning-95-invert-l: var(--bulma-warning-15-l);
  --bulma-warning-95-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-95-invert-l), 1);
  --bulma-warning-100: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-100-l), 1);
  --bulma-warning-100-invert-l: var(--bulma-warning-20-l);
  --bulma-warning-100-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-100-invert-l), 1);
  --bulma-warning-invert-l: var(--bulma-warning-10-l);
  --bulma-warning-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-invert-l), 1);
  --bulma-warning-light-l: var(--bulma-warning-90-l);
  --bulma-warning-light: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-light-l), 1);
  --bulma-warning-light-invert-l: var(--bulma-warning-15-l);
  --bulma-warning-light-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-light-invert-l), 1);
  --bulma-warning-dark-l: var(--bulma-warning-10-l);
  --bulma-warning-dark: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-dark-l), 1);
  --bulma-warning-dark-invert-l: var(--bulma-warning-50-l);
  --bulma-warning-dark-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-dark-invert-l), 1);
  --bulma-warning-soft: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-soft-l), 1);
  --bulma-warning-bold: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-bold-l), 1);
  --bulma-warning-soft-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-soft-invert-l), 1);
  --bulma-warning-bold-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-bold-invert-l), 1);
  --bulma-warning-on-scheme-l: 23%;
  --bulma-warning-on-scheme: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-on-scheme-l), 1);
  --bulma-danger: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-l), 1);
  --bulma-danger-base: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-l), 1);
  --bulma-danger-rgb: 255, 102, 132.6;
  --bulma-danger-h: 348deg;
  --bulma-danger-s: 100%;
  --bulma-danger-l: 70%;
  --bulma-danger-00-l: 0%;
  --bulma-danger-05-l: 5%;
  --bulma-danger-10-l: 10%;
  --bulma-danger-15-l: 15%;
  --bulma-danger-20-l: 20%;
  --bulma-danger-25-l: 25%;
  --bulma-danger-30-l: 30%;
  --bulma-danger-35-l: 35%;
  --bulma-danger-40-l: 40%;
  --bulma-danger-45-l: 45%;
  --bulma-danger-50-l: 50%;
  --bulma-danger-55-l: 55%;
  --bulma-danger-60-l: 60%;
  --bulma-danger-65-l: 65%;
  --bulma-danger-70-l: 70%;
  --bulma-danger-75-l: 75%;
  --bulma-danger-80-l: 80%;
  --bulma-danger-85-l: 85%;
  --bulma-danger-90-l: 90%;
  --bulma-danger-95-l: 95%;
  --bulma-danger-100-l: 100%;
  --bulma-danger-00: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-00-l), 1);
  --bulma-danger-00-invert-l: var(--bulma-danger-65-l);
  --bulma-danger-00-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-00-invert-l), 1);
  --bulma-danger-05: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-05-l), 1);
  --bulma-danger-05-invert-l: var(--bulma-danger-70-l);
  --bulma-danger-05-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-05-invert-l), 1);
  --bulma-danger-10: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-10-l), 1);
  --bulma-danger-10-invert-l: var(--bulma-danger-75-l);
  --bulma-danger-10-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-10-invert-l), 1);
  --bulma-danger-15: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-15-l), 1);
  --bulma-danger-15-invert-l: var(--bulma-danger-80-l);
  --bulma-danger-15-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-15-invert-l), 1);
  --bulma-danger-20: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-20-l), 1);
  --bulma-danger-20-invert-l: var(--bulma-danger-85-l);
  --bulma-danger-20-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-20-invert-l), 1);
  --bulma-danger-25: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-25-l), 1);
  --bulma-danger-25-invert-l: var(--bulma-danger-90-l);
  --bulma-danger-25-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-25-invert-l), 1);
  --bulma-danger-30: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-30-l), 1);
  --bulma-danger-30-invert-l: var(--bulma-danger-100-l);
  --bulma-danger-30-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-30-invert-l), 1);
  --bulma-danger-35: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-35-l), 1);
  --bulma-danger-35-invert-l: var(--bulma-danger-100-l);
  --bulma-danger-35-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-35-invert-l), 1);
  --bulma-danger-40: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-40-l), 1);
  --bulma-danger-40-invert-l: var(--bulma-danger-100-l);
  --bulma-danger-40-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-40-invert-l), 1);
  --bulma-danger-45: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-45-l), 1);
  --bulma-danger-45-invert-l: var(--bulma-danger-100-l);
  --bulma-danger-45-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-45-invert-l), 1);
  --bulma-danger-50: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-50-l), 1);
  --bulma-danger-50-invert-l: var(--bulma-danger-100-l);
  --bulma-danger-50-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-50-invert-l), 1);
  --bulma-danger-55: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-55-l), 1);
  --bulma-danger-55-invert-l: var(--bulma-danger-100-l);
  --bulma-danger-55-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-55-invert-l), 1);
  --bulma-danger-60: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-60-l), 1);
  --bulma-danger-60-invert-l: var(--bulma-danger-100-l);
  --bulma-danger-60-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-60-invert-l), 1);
  --bulma-danger-65: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-65-l), 1);
  --bulma-danger-65-invert-l: var(--bulma-danger-00-l);
  --bulma-danger-65-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-65-invert-l), 1);
  --bulma-danger-70: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-70-l), 1);
  --bulma-danger-70-invert-l: var(--bulma-danger-05-l);
  --bulma-danger-70-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-70-invert-l), 1);
  --bulma-danger-75: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-75-l), 1);
  --bulma-danger-75-invert-l: var(--bulma-danger-10-l);
  --bulma-danger-75-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-75-invert-l), 1);
  --bulma-danger-80: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-80-l), 1);
  --bulma-danger-80-invert-l: var(--bulma-danger-15-l);
  --bulma-danger-80-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-80-invert-l), 1);
  --bulma-danger-85: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-85-l), 1);
  --bulma-danger-85-invert-l: var(--bulma-danger-20-l);
  --bulma-danger-85-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-85-invert-l), 1);
  --bulma-danger-90: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-90-l), 1);
  --bulma-danger-90-invert-l: var(--bulma-danger-25-l);
  --bulma-danger-90-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-90-invert-l), 1);
  --bulma-danger-95: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-95-l), 1);
  --bulma-danger-95-invert-l: var(--bulma-danger-25-l);
  --bulma-danger-95-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-95-invert-l), 1);
  --bulma-danger-100: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-100-l), 1);
  --bulma-danger-100-invert-l: var(--bulma-danger-30-l);
  --bulma-danger-100-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-100-invert-l), 1);
  --bulma-danger-invert-l: var(--bulma-danger-05-l);
  --bulma-danger-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-invert-l), 1);
  --bulma-danger-light-l: var(--bulma-danger-90-l);
  --bulma-danger-light: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-light-l), 1);
  --bulma-danger-light-invert-l: var(--bulma-danger-25-l);
  --bulma-danger-light-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-light-invert-l), 1);
  --bulma-danger-dark-l: var(--bulma-danger-10-l);
  --bulma-danger-dark: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-dark-l), 1);
  --bulma-danger-dark-invert-l: var(--bulma-danger-75-l);
  --bulma-danger-dark-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-dark-invert-l), 1);
  --bulma-danger-soft: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-soft-l), 1);
  --bulma-danger-bold: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-bold-l), 1);
  --bulma-danger-soft-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-soft-invert-l), 1);
  --bulma-danger-bold-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-bold-invert-l), 1);
  --bulma-danger-on-scheme-l: 40%;
  --bulma-danger-on-scheme: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-on-scheme-l), 1);
  --bulma-black-bis: #14161a;
  --bulma-black-ter: #1f2229;
  --bulma-grey-darker: #2e333d;
  --bulma-grey-dark: #404654;
  --bulma-grey: #69748c;
  --bulma-grey-light: #abb1bf;
  --bulma-grey-lighter: #d6d9e0;
  --bulma-white-ter: #f3f4f6;
  --bulma-white-bis: #f9fafb;
  --bulma-shadow-h: 221deg;
  --bulma-shadow-s: 14%;
  --bulma-shadow-l: 4%;
  --bulma-size-1: 3rem;
  --bulma-size-2: 2.5rem;
  --bulma-size-3: 2rem;
  --bulma-size-4: 1.5rem;
  --bulma-size-5: 1.25rem;
  --bulma-size-6: 1rem;
  --bulma-size-7: .75rem;
  --bulma-scheme-main: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-main-l));
  --bulma-scheme-main-bis: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-main-bis-l));
  --bulma-scheme-main-ter: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-main-ter-l));
  --bulma-background: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-background-l));
  --bulma-background-hover: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), calc(var(--bulma-background-l)  + var(--bulma-hover-background-l-delta)));
  --bulma-background-active: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), calc(var(--bulma-background-l)  + var(--bulma-active-background-l-delta)));
  --bulma-border-weak: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-border-weak-l));
  --bulma-border: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-border-l));
  --bulma-border-hover: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), calc(var(--bulma-border-l)  + var(--bulma-hover-border-l-delta)));
  --bulma-border-active: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), calc(var(--bulma-border-l)  + var(--bulma-active-border-l-delta)));
  --bulma-text-weak: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-weak-l));
  --bulma-text-strong: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-strong-l));
  --bulma-scheme-invert-ter: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-ter-l));
  --bulma-scheme-invert-bis: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-bis-l));
  --bulma-scheme-invert: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l));
  --bulma-link-text: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-on-scheme-l));
  --bulma-link-text-hover: hsl(var(--bulma-link-h), var(--bulma-link-s), calc(var(--bulma-link-on-scheme-l)  + var(--bulma-hover-color-l-delta)));
  --bulma-link-text-active: hsl(var(--bulma-link-h), var(--bulma-link-s), calc(var(--bulma-link-on-scheme-l)  + var(--bulma-active-color-l-delta)));
  --bulma-focus-h: var(--bulma-link-h);
  --bulma-focus-s: var(--bulma-link-s);
  --bulma-focus-l: var(--bulma-link-l);
  --bulma-focus-offset: 1px;
  --bulma-focus-style: solid;
  --bulma-focus-width: 2px;
  --bulma-focus-shadow-size: 0 0 0 .1875em;
  --bulma-focus-shadow-alpha: .25;
  --bulma-code: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-on-scheme-l));
  --bulma-code-background: var(--bulma-background);
  --bulma-pre: var(--bulma-text);
  --bulma-pre-background: var(--bulma-background);
  --bulma-shadow: 0 .5em 1em -.125em hsla(var(--bulma-shadow-h), var(--bulma-shadow-s), var(--bulma-shadow-l), .1), 0 0px 0 1px hsla(var(--bulma-shadow-h), var(--bulma-shadow-s), var(--bulma-shadow-l), .02);
}

@media (prefers-color-scheme: light) {
  :root {
    --bulma-scheme-h: 221;
    --bulma-scheme-s: 14%;
    --bulma-light-l: 96%;
    --bulma-light-invert-l: 21%;
    --bulma-dark-l: 21%;
    --bulma-dark-invert-l: 96%;
    --bulma-soft-l: 90%;
    --bulma-bold-l: 20%;
    --bulma-soft-invert-l: 20%;
    --bulma-bold-invert-l: 90%;
    --bulma-hover-background-l-delta: -5%;
    --bulma-active-background-l-delta: -10%;
    --bulma-hover-border-l-delta: -10%;
    --bulma-active-border-l-delta: -20%;
    --bulma-hover-color-l-delta: -5%;
    --bulma-active-color-l-delta: -10%;
    --bulma-hover-shadow-a-delta: -.05;
    --bulma-active-shadow-a-delta: -.1;
    --bulma-scheme-brightness: light;
    --bulma-scheme-main-l: 100%;
    --bulma-scheme-main-bis-l: 98%;
    --bulma-scheme-main-ter-l: 96%;
    --bulma-background-l: 96%;
    --bulma-border-weak-l: 93%;
    --bulma-border-l: 86%;
    --bulma-text-weak-l: 48%;
    --bulma-text-l: 29%;
    --bulma-text-strong-l: 21%;
    --bulma-text-title-l: 14%;
    --bulma-scheme-invert-ter-l: 14%;
    --bulma-scheme-invert-bis-l: 7%;
    --bulma-scheme-invert-l: 4%;
    --bulma-family-primary: Inter, SF Pro, Segoe UI, Roboto, Oxygen, Ubuntu, Helvetica Neue, Helvetica, Arial, sans-serif;
    --bulma-family-secondary: Inter, SF Pro, Segoe UI, Roboto, Oxygen, Ubuntu, Helvetica Neue, Helvetica, Arial, sans-serif;
    --bulma-family-code: Inconsolata, Hack, SF Mono, Roboto Mono, Source Code Pro, Ubuntu Mono, monospace;
    --bulma-size-small: .75rem;
    --bulma-size-normal: 1rem;
    --bulma-size-medium: 1.25rem;
    --bulma-size-large: 1.5rem;
    --bulma-weight-light: 300;
    --bulma-weight-normal: 400;
    --bulma-weight-medium: 500;
    --bulma-weight-semibold: 600;
    --bulma-weight-bold: 700;
    --bulma-weight-extrabold: 800;
    --bulma-block-spacing: 1.5rem;
    --bulma-duration: .294s;
    --bulma-easing: ease-out;
    --bulma-radius-small: .25rem;
    --bulma-radius: .375rem;
    --bulma-radius-medium: .5em;
    --bulma-radius-large: .75rem;
    --bulma-radius-rounded: 9999px;
    --bulma-speed: 86ms;
    --bulma-arrow-color: var(--bulma-link);
    --bulma-loading-color: var(--bulma-border);
    --bulma-burger-h: var(--bulma-link-h);
    --bulma-burger-s: var(--bulma-link-s);
    --bulma-burger-l: var(--bulma-link-l);
    --bulma-burger-border-radius: .5em;
    --bulma-burger-gap: 5px;
    --bulma-burger-item-height: 2px;
    --bulma-burger-item-width: 20px;
    --bulma-white: hsla(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-l), 1);
    --bulma-white-base: hsla(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-l), 1);
    --bulma-white-rgb: 255, 255, 255;
    --bulma-white-h: 221deg;
    --bulma-white-s: 14%;
    --bulma-white-l: 100%;
    --bulma-white-invert-l: 4%;
    --bulma-white-invert: #090a0c;
    --bulma-white-on-scheme-l: 35%;
    --bulma-white-on-scheme: hsla(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-on-scheme-l), 1);
    --bulma-black: hsla(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-l), 1);
    --bulma-black-base: hsla(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-l), 1);
    --bulma-black-rgb: 8.772, 9.6764, 11.628;
    --bulma-black-h: 221deg;
    --bulma-black-s: 14%;
    --bulma-black-l: 4%;
    --bulma-black-invert-l: 100%;
    --bulma-black-invert: #fff;
    --bulma-black-on-scheme-l: 4%;
    --bulma-black-on-scheme: hsla(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-on-scheme-l), 1);
    --bulma-light: hsla(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-l), 1);
    --bulma-light-base: hsla(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-l), 1);
    --bulma-light-rgb: 243.372, 244.276, 246.228;
    --bulma-light-h: 221deg;
    --bulma-light-s: 14%;
    --bulma-light-invert: #2e333d;
    --bulma-light-on-scheme-l: 36%;
    --bulma-light-on-scheme: hsla(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-on-scheme-l), 1);
    --bulma-dark: hsla(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-l), 1);
    --bulma-dark-base: hsla(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-l), 1);
    --bulma-dark-rgb: 46.053, 50.8011, 61.047;
    --bulma-dark-h: 221deg;
    --bulma-dark-s: 14%;
    --bulma-dark-invert: #f3f4f6;
    --bulma-dark-on-scheme-l: 21%;
    --bulma-dark-on-scheme: hsla(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-on-scheme-l), 1);
    --bulma-text: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-l), 1);
    --bulma-text-base: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-l), 1);
    --bulma-text-rgb: 63.597, 70.1539, 84.303;
    --bulma-text-h: 221deg;
    --bulma-text-s: 14%;
    --bulma-text-00-l: 0%;
    --bulma-text-05-l: 4%;
    --bulma-text-10-l: 9%;
    --bulma-text-15-l: 14%;
    --bulma-text-20-l: 19%;
    --bulma-text-25-l: 24%;
    --bulma-text-30-l: 29%;
    --bulma-text-35-l: 34%;
    --bulma-text-40-l: 39%;
    --bulma-text-45-l: 44%;
    --bulma-text-50-l: 49%;
    --bulma-text-55-l: 54%;
    --bulma-text-60-l: 59%;
    --bulma-text-65-l: 64%;
    --bulma-text-70-l: 69%;
    --bulma-text-75-l: 74%;
    --bulma-text-80-l: 79%;
    --bulma-text-85-l: 84%;
    --bulma-text-90-l: 89%;
    --bulma-text-95-l: 94%;
    --bulma-text-100-l: 99%;
    --bulma-text-00: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-00-l), 1);
    --bulma-text-00-invert-l: var(--bulma-text-60-l);
    --bulma-text-00-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-00-invert-l), 1);
    --bulma-text-05: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-05-l), 1);
    --bulma-text-05-invert-l: var(--bulma-text-60-l);
    --bulma-text-05-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-05-invert-l), 1);
    --bulma-text-10: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-10-l), 1);
    --bulma-text-10-invert-l: var(--bulma-text-70-l);
    --bulma-text-10-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-10-invert-l), 1);
    --bulma-text-15: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-15-l), 1);
    --bulma-text-15-invert-l: var(--bulma-text-75-l);
    --bulma-text-15-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-15-invert-l), 1);
    --bulma-text-20: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-20-l), 1);
    --bulma-text-20-invert-l: var(--bulma-text-85-l);
    --bulma-text-20-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-20-invert-l), 1);
    --bulma-text-25: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-25-l), 1);
    --bulma-text-25-invert-l: var(--bulma-text-95-l);
    --bulma-text-25-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-25-invert-l), 1);
    --bulma-text-30: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-30-l), 1);
    --bulma-text-30-invert-l: var(--bulma-text-100-l);
    --bulma-text-30-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-30-invert-l), 1);
    --bulma-text-35: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-35-l), 1);
    --bulma-text-35-invert-l: var(--bulma-text-100-l);
    --bulma-text-35-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-35-invert-l), 1);
    --bulma-text-40: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-40-l), 1);
    --bulma-text-40-invert-l: var(--bulma-text-100-l);
    --bulma-text-40-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-40-invert-l), 1);
    --bulma-text-45: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-45-l), 1);
    --bulma-text-45-invert-l: var(--bulma-text-100-l);
    --bulma-text-45-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-45-invert-l), 1);
    --bulma-text-50: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-50-l), 1);
    --bulma-text-50-invert-l: var(--bulma-text-100-l);
    --bulma-text-50-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-50-invert-l), 1);
    --bulma-text-55: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-55-l), 1);
    --bulma-text-55-invert-l: var(--bulma-text-100-l);
    --bulma-text-55-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-55-invert-l), 1);
    --bulma-text-60: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-60-l), 1);
    --bulma-text-60-invert-l: var(--bulma-text-05-l);
    --bulma-text-60-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-60-invert-l), 1);
    --bulma-text-65: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-65-l), 1);
    --bulma-text-65-invert-l: var(--bulma-text-05-l);
    --bulma-text-65-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-65-invert-l), 1);
    --bulma-text-70: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-70-l), 1);
    --bulma-text-70-invert-l: var(--bulma-text-10-l);
    --bulma-text-70-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-70-invert-l), 1);
    --bulma-text-75: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-75-l), 1);
    --bulma-text-75-invert-l: var(--bulma-text-15-l);
    --bulma-text-75-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-75-invert-l), 1);
    --bulma-text-80: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-80-l), 1);
    --bulma-text-80-invert-l: var(--bulma-text-15-l);
    --bulma-text-80-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-80-invert-l), 1);
    --bulma-text-85: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-85-l), 1);
    --bulma-text-85-invert-l: var(--bulma-text-20-l);
    --bulma-text-85-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-85-invert-l), 1);
    --bulma-text-90: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-90-l), 1);
    --bulma-text-90-invert-l: var(--bulma-text-20-l);
    --bulma-text-90-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-90-invert-l), 1);
    --bulma-text-95: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-95-l), 1);
    --bulma-text-95-invert-l: var(--bulma-text-25-l);
    --bulma-text-95-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-95-invert-l), 1);
    --bulma-text-100: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-100-l), 1);
    --bulma-text-100-invert-l: var(--bulma-text-25-l);
    --bulma-text-100-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-100-invert-l), 1);
    --bulma-text-invert-l: var(--bulma-text-100-l);
    --bulma-text-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-invert-l), 1);
    --bulma-text-light-l: var(--bulma-text-90-l);
    --bulma-text-light: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-light-l), 1);
    --bulma-text-light-invert-l: var(--bulma-text-20-l);
    --bulma-text-light-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-light-invert-l), 1);
    --bulma-text-dark-l: var(--bulma-text-10-l);
    --bulma-text-dark: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-dark-l), 1);
    --bulma-text-dark-invert-l: var(--bulma-text-70-l);
    --bulma-text-dark-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-dark-invert-l), 1);
    --bulma-text-soft: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-soft-l), 1);
    --bulma-text-bold: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-bold-l), 1);
    --bulma-text-soft-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-soft-invert-l), 1);
    --bulma-text-bold-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-bold-invert-l), 1);
    --bulma-text-on-scheme-l: 29%;
    --bulma-text-on-scheme: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-on-scheme-l), 1);
    --bulma-primary: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-l), 1);
    --bulma-primary-base: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-l), 1);
    --bulma-primary-rgb: 0, 209.1, 177.735;
    --bulma-primary-h: 171deg;
    --bulma-primary-s: 100%;
    --bulma-primary-l: 41%;
    --bulma-primary-00-l: 1%;
    --bulma-primary-05-l: 6%;
    --bulma-primary-10-l: 11%;
    --bulma-primary-15-l: 16%;
    --bulma-primary-20-l: 21%;
    --bulma-primary-25-l: 26%;
    --bulma-primary-30-l: 31%;
    --bulma-primary-35-l: 36%;
    --bulma-primary-40-l: 41%;
    --bulma-primary-45-l: 46%;
    --bulma-primary-50-l: 51%;
    --bulma-primary-55-l: 56%;
    --bulma-primary-60-l: 61%;
    --bulma-primary-65-l: 66%;
    --bulma-primary-70-l: 71%;
    --bulma-primary-75-l: 76%;
    --bulma-primary-80-l: 81%;
    --bulma-primary-85-l: 86%;
    --bulma-primary-90-l: 91%;
    --bulma-primary-95-l: 96%;
    --bulma-primary-100-l: 100%;
    --bulma-primary-00: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-00-l), 1);
    --bulma-primary-00-invert-l: var(--bulma-primary-30-l);
    --bulma-primary-00-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-00-invert-l), 1);
    --bulma-primary-05: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-05-l), 1);
    --bulma-primary-05-invert-l: var(--bulma-primary-40-l);
    --bulma-primary-05-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-05-invert-l), 1);
    --bulma-primary-10: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-10-l), 1);
    --bulma-primary-10-invert-l: var(--bulma-primary-50-l);
    --bulma-primary-10-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-10-invert-l), 1);
    --bulma-primary-15: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-15-l), 1);
    --bulma-primary-15-invert-l: var(--bulma-primary-100-l);
    --bulma-primary-15-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-15-invert-l), 1);
    --bulma-primary-20: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-20-l), 1);
    --bulma-primary-20-invert-l: var(--bulma-primary-100-l);
    --bulma-primary-20-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-20-invert-l), 1);
    --bulma-primary-25: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-25-l), 1);
    --bulma-primary-25-invert-l: var(--bulma-primary-100-l);
    --bulma-primary-25-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-25-invert-l), 1);
    --bulma-primary-30: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-30-l), 1);
    --bulma-primary-30-invert-l: var(--bulma-primary-00-l);
    --bulma-primary-30-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-30-invert-l), 1);
    --bulma-primary-35: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-35-l), 1);
    --bulma-primary-35-invert-l: var(--bulma-primary-00-l);
    --bulma-primary-35-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-35-invert-l), 1);
    --bulma-primary-40: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-40-l), 1);
    --bulma-primary-40-invert-l: var(--bulma-primary-05-l);
    --bulma-primary-40-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-40-invert-l), 1);
    --bulma-primary-45: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-45-l), 1);
    --bulma-primary-45-invert-l: var(--bulma-primary-05-l);
    --bulma-primary-45-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-45-invert-l), 1);
    --bulma-primary-50: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-50-l), 1);
    --bulma-primary-50-invert-l: var(--bulma-primary-10-l);
    --bulma-primary-50-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-50-invert-l), 1);
    --bulma-primary-55: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-55-l), 1);
    --bulma-primary-55-invert-l: var(--bulma-primary-10-l);
    --bulma-primary-55-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-55-invert-l), 1);
    --bulma-primary-60: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-60-l), 1);
    --bulma-primary-60-invert-l: var(--bulma-primary-10-l);
    --bulma-primary-60-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-60-invert-l), 1);
    --bulma-primary-65: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-65-l), 1);
    --bulma-primary-65-invert-l: var(--bulma-primary-10-l);
    --bulma-primary-65-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-65-invert-l), 1);
    --bulma-primary-70: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-70-l), 1);
    --bulma-primary-70-invert-l: var(--bulma-primary-10-l);
    --bulma-primary-70-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-70-invert-l), 1);
    --bulma-primary-75: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-75-l), 1);
    --bulma-primary-75-invert-l: var(--bulma-primary-10-l);
    --bulma-primary-75-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-75-invert-l), 1);
    --bulma-primary-80: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-80-l), 1);
    --bulma-primary-80-invert-l: var(--bulma-primary-10-l);
    --bulma-primary-80-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-80-invert-l), 1);
    --bulma-primary-85: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-85-l), 1);
    --bulma-primary-85-invert-l: var(--bulma-primary-10-l);
    --bulma-primary-85-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-85-invert-l), 1);
    --bulma-primary-90: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-90-l), 1);
    --bulma-primary-90-invert-l: var(--bulma-primary-10-l);
    --bulma-primary-90-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-90-invert-l), 1);
    --bulma-primary-95: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-95-l), 1);
    --bulma-primary-95-invert-l: var(--bulma-primary-10-l);
    --bulma-primary-95-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-95-invert-l), 1);
    --bulma-primary-100: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-100-l), 1);
    --bulma-primary-100-invert-l: var(--bulma-primary-15-l);
    --bulma-primary-100-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-100-invert-l), 1);
    --bulma-primary-invert-l: var(--bulma-primary-05-l);
    --bulma-primary-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-invert-l), 1);
    --bulma-primary-light-l: var(--bulma-primary-90-l);
    --bulma-primary-light: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-light-l), 1);
    --bulma-primary-light-invert-l: var(--bulma-primary-10-l);
    --bulma-primary-light-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-light-invert-l), 1);
    --bulma-primary-dark-l: var(--bulma-primary-10-l);
    --bulma-primary-dark: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-dark-l), 1);
    --bulma-primary-dark-invert-l: var(--bulma-primary-50-l);
    --bulma-primary-dark-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-dark-invert-l), 1);
    --bulma-primary-soft: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-soft-l), 1);
    --bulma-primary-bold: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-bold-l), 1);
    --bulma-primary-soft-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-soft-invert-l), 1);
    --bulma-primary-bold-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-bold-invert-l), 1);
    --bulma-primary-on-scheme-l: 21%;
    --bulma-primary-on-scheme: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-on-scheme-l), 1);
    --bulma-link: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-l), 1);
    --bulma-link-base: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-l), 1);
    --bulma-link-rgb: 66.3, 88.315, 255;
    --bulma-link-h: 233deg;
    --bulma-link-s: 100%;
    --bulma-link-l: 63%;
    --bulma-link-00-l: 0%;
    --bulma-link-05-l: 3%;
    --bulma-link-10-l: 8%;
    --bulma-link-15-l: 13%;
    --bulma-link-20-l: 18%;
    --bulma-link-25-l: 23%;
    --bulma-link-30-l: 28%;
    --bulma-link-35-l: 33%;
    --bulma-link-40-l: 38%;
    --bulma-link-45-l: 43%;
    --bulma-link-50-l: 48%;
    --bulma-link-55-l: 53%;
    --bulma-link-60-l: 58%;
    --bulma-link-65-l: 63%;
    --bulma-link-70-l: 68%;
    --bulma-link-75-l: 73%;
    --bulma-link-80-l: 78%;
    --bulma-link-85-l: 83%;
    --bulma-link-90-l: 88%;
    --bulma-link-95-l: 93%;
    --bulma-link-100-l: 98%;
    --bulma-link-00: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-00-l), 1);
    --bulma-link-00-invert-l: var(--bulma-link-75-l);
    --bulma-link-00-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-00-invert-l), 1);
    --bulma-link-05: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-05-l), 1);
    --bulma-link-05-invert-l: var(--bulma-link-75-l);
    --bulma-link-05-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-05-invert-l), 1);
    --bulma-link-10: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-10-l), 1);
    --bulma-link-10-invert-l: var(--bulma-link-75-l);
    --bulma-link-10-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-10-invert-l), 1);
    --bulma-link-15: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-15-l), 1);
    --bulma-link-15-invert-l: var(--bulma-link-80-l);
    --bulma-link-15-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-15-invert-l), 1);
    --bulma-link-20: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-20-l), 1);
    --bulma-link-20-invert-l: var(--bulma-link-80-l);
    --bulma-link-20-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-20-invert-l), 1);
    --bulma-link-25: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-25-l), 1);
    --bulma-link-25-invert-l: var(--bulma-link-85-l);
    --bulma-link-25-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-25-invert-l), 1);
    --bulma-link-30: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-30-l), 1);
    --bulma-link-30-invert-l: var(--bulma-link-90-l);
    --bulma-link-30-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-30-invert-l), 1);
    --bulma-link-35: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-35-l), 1);
    --bulma-link-35-invert-l: var(--bulma-link-90-l);
    --bulma-link-35-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-35-invert-l), 1);
    --bulma-link-40: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-40-l), 1);
    --bulma-link-40-invert-l: var(--bulma-link-95-l);
    --bulma-link-40-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-40-invert-l), 1);
    --bulma-link-45: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-45-l), 1);
    --bulma-link-45-invert-l: var(--bulma-link-95-l);
    --bulma-link-45-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-45-invert-l), 1);
    --bulma-link-50: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-50-l), 1);
    --bulma-link-50-invert-l: var(--bulma-link-100-l);
    --bulma-link-50-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-50-invert-l), 1);
    --bulma-link-55: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-55-l), 1);
    --bulma-link-55-invert-l: var(--bulma-link-100-l);
    --bulma-link-55-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-55-invert-l), 1);
    --bulma-link-60: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-60-l), 1);
    --bulma-link-60-invert-l: var(--bulma-link-100-l);
    --bulma-link-60-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-60-invert-l), 1);
    --bulma-link-65: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-65-l), 1);
    --bulma-link-65-invert-l: var(--bulma-link-100-l);
    --bulma-link-65-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-65-invert-l), 1);
    --bulma-link-70: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-70-l), 1);
    --bulma-link-70-invert-l: var(--bulma-link-100-l);
    --bulma-link-70-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-70-invert-l), 1);
    --bulma-link-75: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-75-l), 1);
    --bulma-link-75-invert-l: var(--bulma-link-10-l);
    --bulma-link-75-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-75-invert-l), 1);
    --bulma-link-80: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-80-l), 1);
    --bulma-link-80-invert-l: var(--bulma-link-20-l);
    --bulma-link-80-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-80-invert-l), 1);
    --bulma-link-85: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-85-l), 1);
    --bulma-link-85-invert-l: var(--bulma-link-25-l);
    --bulma-link-85-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-85-invert-l), 1);
    --bulma-link-90: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-90-l), 1);
    --bulma-link-90-invert-l: var(--bulma-link-35-l);
    --bulma-link-90-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-90-invert-l), 1);
    --bulma-link-95: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-95-l), 1);
    --bulma-link-95-invert-l: var(--bulma-link-45-l);
    --bulma-link-95-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-95-invert-l), 1);
    --bulma-link-100: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-100-l), 1);
    --bulma-link-100-invert-l: var(--bulma-link-50-l);
    --bulma-link-100-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-100-invert-l), 1);
    --bulma-link-invert-l: var(--bulma-link-100-l);
    --bulma-link-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-invert-l), 1);
    --bulma-link-light-l: var(--bulma-link-90-l);
    --bulma-link-light: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-light-l), 1);
    --bulma-link-light-invert-l: var(--bulma-link-35-l);
    --bulma-link-light-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-light-invert-l), 1);
    --bulma-link-dark-l: var(--bulma-link-10-l);
    --bulma-link-dark: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-dark-l), 1);
    --bulma-link-dark-invert-l: var(--bulma-link-75-l);
    --bulma-link-dark-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-dark-invert-l), 1);
    --bulma-link-soft: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-soft-l), 1);
    --bulma-link-bold: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-bold-l), 1);
    --bulma-link-soft-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-soft-invert-l), 1);
    --bulma-link-bold-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-bold-invert-l), 1);
    --bulma-link-on-scheme-l: 58%;
    --bulma-link-on-scheme: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-on-scheme-l), 1);
    --bulma-info: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-l), 1);
    --bulma-info-base: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-l), 1);
    --bulma-info-rgb: 102, 209.1, 255;
    --bulma-info-h: 198deg;
    --bulma-info-s: 100%;
    --bulma-info-l: 70%;
    --bulma-info-00-l: 0%;
    --bulma-info-05-l: 5%;
    --bulma-info-10-l: 10%;
    --bulma-info-15-l: 15%;
    --bulma-info-20-l: 20%;
    --bulma-info-25-l: 25%;
    --bulma-info-30-l: 30%;
    --bulma-info-35-l: 35%;
    --bulma-info-40-l: 40%;
    --bulma-info-45-l: 45%;
    --bulma-info-50-l: 50%;
    --bulma-info-55-l: 55%;
    --bulma-info-60-l: 60%;
    --bulma-info-65-l: 65%;
    --bulma-info-70-l: 70%;
    --bulma-info-75-l: 75%;
    --bulma-info-80-l: 80%;
    --bulma-info-85-l: 85%;
    --bulma-info-90-l: 90%;
    --bulma-info-95-l: 95%;
    --bulma-info-100-l: 100%;
    --bulma-info-00: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-00-l), 1);
    --bulma-info-00-invert-l: var(--bulma-info-45-l);
    --bulma-info-00-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-00-invert-l), 1);
    --bulma-info-05: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-05-l), 1);
    --bulma-info-05-invert-l: var(--bulma-info-50-l);
    --bulma-info-05-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-05-invert-l), 1);
    --bulma-info-10: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-10-l), 1);
    --bulma-info-10-invert-l: var(--bulma-info-60-l);
    --bulma-info-10-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-10-invert-l), 1);
    --bulma-info-15: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-15-l), 1);
    --bulma-info-15-invert-l: var(--bulma-info-80-l);
    --bulma-info-15-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-15-invert-l), 1);
    --bulma-info-20: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-20-l), 1);
    --bulma-info-20-invert-l: var(--bulma-info-95-l);
    --bulma-info-20-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-20-invert-l), 1);
    --bulma-info-25: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-25-l), 1);
    --bulma-info-25-invert-l: var(--bulma-info-100-l);
    --bulma-info-25-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-25-invert-l), 1);
    --bulma-info-30: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-30-l), 1);
    --bulma-info-30-invert-l: var(--bulma-info-100-l);
    --bulma-info-30-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-30-invert-l), 1);
    --bulma-info-35: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-35-l), 1);
    --bulma-info-35-invert-l: var(--bulma-info-100-l);
    --bulma-info-35-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-35-invert-l), 1);
    --bulma-info-40: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-40-l), 1);
    --bulma-info-40-invert-l: var(--bulma-info-100-l);
    --bulma-info-40-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-40-invert-l), 1);
    --bulma-info-45: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-45-l), 1);
    --bulma-info-45-invert-l: var(--bulma-info-00-l);
    --bulma-info-45-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-45-invert-l), 1);
    --bulma-info-50: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-50-l), 1);
    --bulma-info-50-invert-l: var(--bulma-info-05-l);
    --bulma-info-50-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-50-invert-l), 1);
    --bulma-info-55: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-55-l), 1);
    --bulma-info-55-invert-l: var(--bulma-info-05-l);
    --bulma-info-55-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-55-invert-l), 1);
    --bulma-info-60: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-60-l), 1);
    --bulma-info-60-invert-l: var(--bulma-info-10-l);
    --bulma-info-60-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-60-invert-l), 1);
    --bulma-info-65: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-65-l), 1);
    --bulma-info-65-invert-l: var(--bulma-info-10-l);
    --bulma-info-65-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-65-invert-l), 1);
    --bulma-info-70: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-70-l), 1);
    --bulma-info-70-invert-l: var(--bulma-info-10-l);
    --bulma-info-70-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-70-invert-l), 1);
    --bulma-info-75: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-75-l), 1);
    --bulma-info-75-invert-l: var(--bulma-info-10-l);
    --bulma-info-75-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-75-invert-l), 1);
    --bulma-info-80: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-80-l), 1);
    --bulma-info-80-invert-l: var(--bulma-info-15-l);
    --bulma-info-80-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-80-invert-l), 1);
    --bulma-info-85: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-85-l), 1);
    --bulma-info-85-invert-l: var(--bulma-info-15-l);
    --bulma-info-85-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-85-invert-l), 1);
    --bulma-info-90: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-90-l), 1);
    --bulma-info-90-invert-l: var(--bulma-info-15-l);
    --bulma-info-90-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-90-invert-l), 1);
    --bulma-info-95: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-95-l), 1);
    --bulma-info-95-invert-l: var(--bulma-info-20-l);
    --bulma-info-95-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-95-invert-l), 1);
    --bulma-info-100: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-100-l), 1);
    --bulma-info-100-invert-l: var(--bulma-info-20-l);
    --bulma-info-100-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-100-invert-l), 1);
    --bulma-info-invert-l: var(--bulma-info-10-l);
    --bulma-info-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-invert-l), 1);
    --bulma-info-light-l: var(--bulma-info-90-l);
    --bulma-info-light: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-light-l), 1);
    --bulma-info-light-invert-l: var(--bulma-info-15-l);
    --bulma-info-light-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-light-invert-l), 1);
    --bulma-info-dark-l: var(--bulma-info-10-l);
    --bulma-info-dark: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-dark-l), 1);
    --bulma-info-dark-invert-l: var(--bulma-info-60-l);
    --bulma-info-dark-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-dark-invert-l), 1);
    --bulma-info-soft: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-soft-l), 1);
    --bulma-info-bold: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-bold-l), 1);
    --bulma-info-soft-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-soft-invert-l), 1);
    --bulma-info-bold-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-bold-invert-l), 1);
    --bulma-info-on-scheme-l: 25%;
    --bulma-info-on-scheme: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-on-scheme-l), 1);
    --bulma-success: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-l), 1);
    --bulma-success-base: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-l), 1);
    --bulma-success-rgb: 71.6295, 198.671, 141.502;
    --bulma-success-h: 153deg;
    --bulma-success-s: 53%;
    --bulma-success-l: 53%;
    --bulma-success-00-l: 0%;
    --bulma-success-05-l: 3%;
    --bulma-success-10-l: 8%;
    --bulma-success-15-l: 13%;
    --bulma-success-20-l: 18%;
    --bulma-success-25-l: 23%;
    --bulma-success-30-l: 28%;
    --bulma-success-35-l: 33%;
    --bulma-success-40-l: 38%;
    --bulma-success-45-l: 43%;
    --bulma-success-50-l: 48%;
    --bulma-success-55-l: 53%;
    --bulma-success-60-l: 58%;
    --bulma-success-65-l: 63%;
    --bulma-success-70-l: 68%;
    --bulma-success-75-l: 73%;
    --bulma-success-80-l: 78%;
    --bulma-success-85-l: 83%;
    --bulma-success-90-l: 88%;
    --bulma-success-95-l: 93%;
    --bulma-success-100-l: 98%;
    --bulma-success-00: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-00-l), 1);
    --bulma-success-00-invert-l: var(--bulma-success-45-l);
    --bulma-success-00-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-00-invert-l), 1);
    --bulma-success-05: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-05-l), 1);
    --bulma-success-05-invert-l: var(--bulma-success-45-l);
    --bulma-success-05-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-05-invert-l), 1);
    --bulma-success-10: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-10-l), 1);
    --bulma-success-10-invert-l: var(--bulma-success-55-l);
    --bulma-success-10-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-10-invert-l), 1);
    --bulma-success-15: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-15-l), 1);
    --bulma-success-15-invert-l: var(--bulma-success-75-l);
    --bulma-success-15-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-15-invert-l), 1);
    --bulma-success-20: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-20-l), 1);
    --bulma-success-20-invert-l: var(--bulma-success-95-l);
    --bulma-success-20-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-20-invert-l), 1);
    --bulma-success-25: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-25-l), 1);
    --bulma-success-25-invert-l: var(--bulma-success-100-l);
    --bulma-success-25-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-25-invert-l), 1);
    --bulma-success-30: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-30-l), 1);
    --bulma-success-30-invert-l: var(--bulma-success-100-l);
    --bulma-success-30-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-30-invert-l), 1);
    --bulma-success-35: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-35-l), 1);
    --bulma-success-35-invert-l: var(--bulma-success-100-l);
    --bulma-success-35-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-35-invert-l), 1);
    --bulma-success-40: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-40-l), 1);
    --bulma-success-40-invert-l: var(--bulma-success-100-l);
    --bulma-success-40-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-40-invert-l), 1);
    --bulma-success-45: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-45-l), 1);
    --bulma-success-45-invert-l: var(--bulma-success-05-l);
    --bulma-success-45-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-45-invert-l), 1);
    --bulma-success-50: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-50-l), 1);
    --bulma-success-50-invert-l: var(--bulma-success-05-l);
    --bulma-success-50-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-50-invert-l), 1);
    --bulma-success-55: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-55-l), 1);
    --bulma-success-55-invert-l: var(--bulma-success-10-l);
    --bulma-success-55-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-55-invert-l), 1);
    --bulma-success-60: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-60-l), 1);
    --bulma-success-60-invert-l: var(--bulma-success-10-l);
    --bulma-success-60-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-60-invert-l), 1);
    --bulma-success-65: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-65-l), 1);
    --bulma-success-65-invert-l: var(--bulma-success-10-l);
    --bulma-success-65-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-65-invert-l), 1);
    --bulma-success-70: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-70-l), 1);
    --bulma-success-70-invert-l: var(--bulma-success-10-l);
    --bulma-success-70-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-70-invert-l), 1);
    --bulma-success-75: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-75-l), 1);
    --bulma-success-75-invert-l: var(--bulma-success-15-l);
    --bulma-success-75-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-75-invert-l), 1);
    --bulma-success-80: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-80-l), 1);
    --bulma-success-80-invert-l: var(--bulma-success-15-l);
    --bulma-success-80-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-80-invert-l), 1);
    --bulma-success-85: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-85-l), 1);
    --bulma-success-85-invert-l: var(--bulma-success-15-l);
    --bulma-success-85-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-85-invert-l), 1);
    --bulma-success-90: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-90-l), 1);
    --bulma-success-90-invert-l: var(--bulma-success-15-l);
    --bulma-success-90-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-90-invert-l), 1);
    --bulma-success-95: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-95-l), 1);
    --bulma-success-95-invert-l: var(--bulma-success-20-l);
    --bulma-success-95-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-95-invert-l), 1);
    --bulma-success-100: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-100-l), 1);
    --bulma-success-100-invert-l: var(--bulma-success-20-l);
    --bulma-success-100-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-100-invert-l), 1);
    --bulma-success-invert-l: var(--bulma-success-10-l);
    --bulma-success-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-invert-l), 1);
    --bulma-success-light-l: var(--bulma-success-90-l);
    --bulma-success-light: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-light-l), 1);
    --bulma-success-light-invert-l: var(--bulma-success-15-l);
    --bulma-success-light-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-light-invert-l), 1);
    --bulma-success-dark-l: var(--bulma-success-10-l);
    --bulma-success-dark: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-dark-l), 1);
    --bulma-success-dark-invert-l: var(--bulma-success-55-l);
    --bulma-success-dark-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-dark-invert-l), 1);
    --bulma-success-soft: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-soft-l), 1);
    --bulma-success-bold: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-bold-l), 1);
    --bulma-success-soft-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-soft-invert-l), 1);
    --bulma-success-bold-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-bold-invert-l), 1);
    --bulma-success-on-scheme-l: 23%;
    --bulma-success-on-scheme: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-on-scheme-l), 1);
    --bulma-warning: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-l), 1);
    --bulma-warning-base: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-l), 1);
    --bulma-warning-rgb: 255, 183.09, 15.3;
    --bulma-warning-h: 42deg;
    --bulma-warning-s: 100%;
    --bulma-warning-l: 53%;
    --bulma-warning-00-l: 0%;
    --bulma-warning-05-l: 3%;
    --bulma-warning-10-l: 8%;
    --bulma-warning-15-l: 13%;
    --bulma-warning-20-l: 18%;
    --bulma-warning-25-l: 23%;
    --bulma-warning-30-l: 28%;
    --bulma-warning-35-l: 33%;
    --bulma-warning-40-l: 38%;
    --bulma-warning-45-l: 43%;
    --bulma-warning-50-l: 48%;
    --bulma-warning-55-l: 53%;
    --bulma-warning-60-l: 58%;
    --bulma-warning-65-l: 63%;
    --bulma-warning-70-l: 68%;
    --bulma-warning-75-l: 73%;
    --bulma-warning-80-l: 78%;
    --bulma-warning-85-l: 83%;
    --bulma-warning-90-l: 88%;
    --bulma-warning-95-l: 93%;
    --bulma-warning-100-l: 98%;
    --bulma-warning-00: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-00-l), 1);
    --bulma-warning-00-invert-l: var(--bulma-warning-40-l);
    --bulma-warning-00-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-00-invert-l), 1);
    --bulma-warning-05: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-05-l), 1);
    --bulma-warning-05-invert-l: var(--bulma-warning-45-l);
    --bulma-warning-05-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-05-invert-l), 1);
    --bulma-warning-10: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-10-l), 1);
    --bulma-warning-10-invert-l: var(--bulma-warning-50-l);
    --bulma-warning-10-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-10-invert-l), 1);
    --bulma-warning-15: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-15-l), 1);
    --bulma-warning-15-invert-l: var(--bulma-warning-70-l);
    --bulma-warning-15-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-15-invert-l), 1);
    --bulma-warning-20: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-20-l), 1);
    --bulma-warning-20-invert-l: var(--bulma-warning-100-l);
    --bulma-warning-20-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-20-invert-l), 1);
    --bulma-warning-25: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-25-l), 1);
    --bulma-warning-25-invert-l: var(--bulma-warning-100-l);
    --bulma-warning-25-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-25-invert-l), 1);
    --bulma-warning-30: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-30-l), 1);
    --bulma-warning-30-invert-l: var(--bulma-warning-100-l);
    --bulma-warning-30-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-30-invert-l), 1);
    --bulma-warning-35: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-35-l), 1);
    --bulma-warning-35-invert-l: var(--bulma-warning-100-l);
    --bulma-warning-35-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-35-invert-l), 1);
    --bulma-warning-40: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-40-l), 1);
    --bulma-warning-40-invert-l: var(--bulma-warning-00-l);
    --bulma-warning-40-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-40-invert-l), 1);
    --bulma-warning-45: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-45-l), 1);
    --bulma-warning-45-invert-l: var(--bulma-warning-05-l);
    --bulma-warning-45-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-45-invert-l), 1);
    --bulma-warning-50: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-50-l), 1);
    --bulma-warning-50-invert-l: var(--bulma-warning-10-l);
    --bulma-warning-50-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-50-invert-l), 1);
    --bulma-warning-55: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-55-l), 1);
    --bulma-warning-55-invert-l: var(--bulma-warning-10-l);
    --bulma-warning-55-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-55-invert-l), 1);
    --bulma-warning-60: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-60-l), 1);
    --bulma-warning-60-invert-l: var(--bulma-warning-10-l);
    --bulma-warning-60-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-60-invert-l), 1);
    --bulma-warning-65: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-65-l), 1);
    --bulma-warning-65-invert-l: var(--bulma-warning-10-l);
    --bulma-warning-65-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-65-invert-l), 1);
    --bulma-warning-70: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-70-l), 1);
    --bulma-warning-70-invert-l: var(--bulma-warning-15-l);
    --bulma-warning-70-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-70-invert-l), 1);
    --bulma-warning-75: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-75-l), 1);
    --bulma-warning-75-invert-l: var(--bulma-warning-15-l);
    --bulma-warning-75-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-75-invert-l), 1);
    --bulma-warning-80: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-80-l), 1);
    --bulma-warning-80-invert-l: var(--bulma-warning-15-l);
    --bulma-warning-80-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-80-invert-l), 1);
    --bulma-warning-85: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-85-l), 1);
    --bulma-warning-85-invert-l: var(--bulma-warning-15-l);
    --bulma-warning-85-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-85-invert-l), 1);
    --bulma-warning-90: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-90-l), 1);
    --bulma-warning-90-invert-l: var(--bulma-warning-15-l);
    --bulma-warning-90-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-90-invert-l), 1);
    --bulma-warning-95: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-95-l), 1);
    --bulma-warning-95-invert-l: var(--bulma-warning-15-l);
    --bulma-warning-95-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-95-invert-l), 1);
    --bulma-warning-100: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-100-l), 1);
    --bulma-warning-100-invert-l: var(--bulma-warning-20-l);
    --bulma-warning-100-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-100-invert-l), 1);
    --bulma-warning-invert-l: var(--bulma-warning-10-l);
    --bulma-warning-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-invert-l), 1);
    --bulma-warning-light-l: var(--bulma-warning-90-l);
    --bulma-warning-light: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-light-l), 1);
    --bulma-warning-light-invert-l: var(--bulma-warning-15-l);
    --bulma-warning-light-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-light-invert-l), 1);
    --bulma-warning-dark-l: var(--bulma-warning-10-l);
    --bulma-warning-dark: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-dark-l), 1);
    --bulma-warning-dark-invert-l: var(--bulma-warning-50-l);
    --bulma-warning-dark-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-dark-invert-l), 1);
    --bulma-warning-soft: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-soft-l), 1);
    --bulma-warning-bold: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-bold-l), 1);
    --bulma-warning-soft-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-soft-invert-l), 1);
    --bulma-warning-bold-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-bold-invert-l), 1);
    --bulma-warning-on-scheme-l: 23%;
    --bulma-warning-on-scheme: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-on-scheme-l), 1);
    --bulma-danger: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-l), 1);
    --bulma-danger-base: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-l), 1);
    --bulma-danger-rgb: 255, 102, 132.6;
    --bulma-danger-h: 348deg;
    --bulma-danger-s: 100%;
    --bulma-danger-l: 70%;
    --bulma-danger-00-l: 0%;
    --bulma-danger-05-l: 5%;
    --bulma-danger-10-l: 10%;
    --bulma-danger-15-l: 15%;
    --bulma-danger-20-l: 20%;
    --bulma-danger-25-l: 25%;
    --bulma-danger-30-l: 30%;
    --bulma-danger-35-l: 35%;
    --bulma-danger-40-l: 40%;
    --bulma-danger-45-l: 45%;
    --bulma-danger-50-l: 50%;
    --bulma-danger-55-l: 55%;
    --bulma-danger-60-l: 60%;
    --bulma-danger-65-l: 65%;
    --bulma-danger-70-l: 70%;
    --bulma-danger-75-l: 75%;
    --bulma-danger-80-l: 80%;
    --bulma-danger-85-l: 85%;
    --bulma-danger-90-l: 90%;
    --bulma-danger-95-l: 95%;
    --bulma-danger-100-l: 100%;
    --bulma-danger-00: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-00-l), 1);
    --bulma-danger-00-invert-l: var(--bulma-danger-65-l);
    --bulma-danger-00-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-00-invert-l), 1);
    --bulma-danger-05: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-05-l), 1);
    --bulma-danger-05-invert-l: var(--bulma-danger-70-l);
    --bulma-danger-05-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-05-invert-l), 1);
    --bulma-danger-10: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-10-l), 1);
    --bulma-danger-10-invert-l: var(--bulma-danger-75-l);
    --bulma-danger-10-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-10-invert-l), 1);
    --bulma-danger-15: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-15-l), 1);
    --bulma-danger-15-invert-l: var(--bulma-danger-80-l);
    --bulma-danger-15-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-15-invert-l), 1);
    --bulma-danger-20: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-20-l), 1);
    --bulma-danger-20-invert-l: var(--bulma-danger-85-l);
    --bulma-danger-20-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-20-invert-l), 1);
    --bulma-danger-25: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-25-l), 1);
    --bulma-danger-25-invert-l: var(--bulma-danger-90-l);
    --bulma-danger-25-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-25-invert-l), 1);
    --bulma-danger-30: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-30-l), 1);
    --bulma-danger-30-invert-l: var(--bulma-danger-100-l);
    --bulma-danger-30-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-30-invert-l), 1);
    --bulma-danger-35: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-35-l), 1);
    --bulma-danger-35-invert-l: var(--bulma-danger-100-l);
    --bulma-danger-35-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-35-invert-l), 1);
    --bulma-danger-40: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-40-l), 1);
    --bulma-danger-40-invert-l: var(--bulma-danger-100-l);
    --bulma-danger-40-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-40-invert-l), 1);
    --bulma-danger-45: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-45-l), 1);
    --bulma-danger-45-invert-l: var(--bulma-danger-100-l);
    --bulma-danger-45-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-45-invert-l), 1);
    --bulma-danger-50: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-50-l), 1);
    --bulma-danger-50-invert-l: var(--bulma-danger-100-l);
    --bulma-danger-50-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-50-invert-l), 1);
    --bulma-danger-55: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-55-l), 1);
    --bulma-danger-55-invert-l: var(--bulma-danger-100-l);
    --bulma-danger-55-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-55-invert-l), 1);
    --bulma-danger-60: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-60-l), 1);
    --bulma-danger-60-invert-l: var(--bulma-danger-100-l);
    --bulma-danger-60-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-60-invert-l), 1);
    --bulma-danger-65: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-65-l), 1);
    --bulma-danger-65-invert-l: var(--bulma-danger-00-l);
    --bulma-danger-65-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-65-invert-l), 1);
    --bulma-danger-70: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-70-l), 1);
    --bulma-danger-70-invert-l: var(--bulma-danger-05-l);
    --bulma-danger-70-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-70-invert-l), 1);
    --bulma-danger-75: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-75-l), 1);
    --bulma-danger-75-invert-l: var(--bulma-danger-10-l);
    --bulma-danger-75-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-75-invert-l), 1);
    --bulma-danger-80: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-80-l), 1);
    --bulma-danger-80-invert-l: var(--bulma-danger-15-l);
    --bulma-danger-80-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-80-invert-l), 1);
    --bulma-danger-85: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-85-l), 1);
    --bulma-danger-85-invert-l: var(--bulma-danger-20-l);
    --bulma-danger-85-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-85-invert-l), 1);
    --bulma-danger-90: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-90-l), 1);
    --bulma-danger-90-invert-l: var(--bulma-danger-25-l);
    --bulma-danger-90-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-90-invert-l), 1);
    --bulma-danger-95: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-95-l), 1);
    --bulma-danger-95-invert-l: var(--bulma-danger-25-l);
    --bulma-danger-95-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-95-invert-l), 1);
    --bulma-danger-100: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-100-l), 1);
    --bulma-danger-100-invert-l: var(--bulma-danger-30-l);
    --bulma-danger-100-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-100-invert-l), 1);
    --bulma-danger-invert-l: var(--bulma-danger-05-l);
    --bulma-danger-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-invert-l), 1);
    --bulma-danger-light-l: var(--bulma-danger-90-l);
    --bulma-danger-light: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-light-l), 1);
    --bulma-danger-light-invert-l: var(--bulma-danger-25-l);
    --bulma-danger-light-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-light-invert-l), 1);
    --bulma-danger-dark-l: var(--bulma-danger-10-l);
    --bulma-danger-dark: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-dark-l), 1);
    --bulma-danger-dark-invert-l: var(--bulma-danger-75-l);
    --bulma-danger-dark-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-dark-invert-l), 1);
    --bulma-danger-soft: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-soft-l), 1);
    --bulma-danger-bold: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-bold-l), 1);
    --bulma-danger-soft-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-soft-invert-l), 1);
    --bulma-danger-bold-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-bold-invert-l), 1);
    --bulma-danger-on-scheme-l: 40%;
    --bulma-danger-on-scheme: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-on-scheme-l), 1);
    --bulma-black-bis: #14161a;
    --bulma-black-ter: #1f2229;
    --bulma-grey-darker: #2e333d;
    --bulma-grey-dark: #404654;
    --bulma-grey: #69748c;
    --bulma-grey-light: #abb1bf;
    --bulma-grey-lighter: #d6d9e0;
    --bulma-white-ter: #f3f4f6;
    --bulma-white-bis: #f9fafb;
    --bulma-shadow-h: 221deg;
    --bulma-shadow-s: 14%;
    --bulma-shadow-l: 4%;
    --bulma-size-1: 3rem;
    --bulma-size-2: 2.5rem;
    --bulma-size-3: 2rem;
    --bulma-size-4: 1.5rem;
    --bulma-size-5: 1.25rem;
    --bulma-size-6: 1rem;
    --bulma-size-7: .75rem;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --bulma-white-on-scheme-l: 100%;
    --bulma-white-on-scheme: hsla(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-on-scheme-l), 1);
    --bulma-black-on-scheme-l: -51%;
    --bulma-black-on-scheme: hsla(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-on-scheme-l), 1);
    --bulma-light-on-scheme-l: 96%;
    --bulma-light-on-scheme: hsla(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-on-scheme-l), 1);
    --bulma-dark-on-scheme-l: 56%;
    --bulma-dark-on-scheme: hsla(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-on-scheme-l), 1);
    --bulma-text-on-scheme-l: 54%;
    --bulma-text-on-scheme: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-on-scheme-l), 1);
    --bulma-primary-on-scheme-l: 41%;
    --bulma-primary-on-scheme: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-on-scheme-l), 1);
    --bulma-link-on-scheme-l: 73%;
    --bulma-link-on-scheme: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-on-scheme-l), 1);
    --bulma-info-on-scheme-l: 70%;
    --bulma-info-on-scheme: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-on-scheme-l), 1);
    --bulma-success-on-scheme-l: 53%;
    --bulma-success-on-scheme: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-on-scheme-l), 1);
    --bulma-warning-on-scheme-l: 53%;
    --bulma-warning-on-scheme: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-on-scheme-l), 1);
    --bulma-danger-on-scheme-l: 70%;
    --bulma-danger-on-scheme: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-on-scheme-l), 1);
    --bulma-scheme-brightness: dark;
    --bulma-scheme-main-l: 9%;
    --bulma-scheme-main-bis-l: 11%;
    --bulma-scheme-main-ter-l: 13%;
    --bulma-soft-l: 20%;
    --bulma-bold-l: 90%;
    --bulma-soft-invert-l: 90%;
    --bulma-bold-invert-l: 20%;
    --bulma-background-l: 14%;
    --bulma-border-weak-l: 21%;
    --bulma-border-l: 24%;
    --bulma-text-weak-l: 53%;
    --bulma-text-l: 71%;
    --bulma-text-strong-l: 93%;
    --bulma-text-title-l: 100%;
    --bulma-hover-background-l-delta: 5%;
    --bulma-active-background-l-delta: 10%;
    --bulma-hover-border-l-delta: 10%;
    --bulma-active-border-l-delta: 20%;
    --bulma-hover-color-l-delta: 5%;
    --bulma-active-color-l-delta: 10%;
    --bulma-shadow-h: 0deg;
    --bulma-shadow-s: 0%;
    --bulma-shadow-l: 100%;
  }
}

[data-theme="light"], .theme-light {
  --bulma-scheme-h: 221;
  --bulma-scheme-s: 14%;
  --bulma-light-l: 96%;
  --bulma-light-invert-l: 21%;
  --bulma-dark-l: 21%;
  --bulma-dark-invert-l: 96%;
  --bulma-soft-l: 90%;
  --bulma-bold-l: 20%;
  --bulma-soft-invert-l: 20%;
  --bulma-bold-invert-l: 90%;
  --bulma-hover-background-l-delta: -5%;
  --bulma-active-background-l-delta: -10%;
  --bulma-hover-border-l-delta: -10%;
  --bulma-active-border-l-delta: -20%;
  --bulma-hover-color-l-delta: -5%;
  --bulma-active-color-l-delta: -10%;
  --bulma-hover-shadow-a-delta: -.05;
  --bulma-active-shadow-a-delta: -.1;
  --bulma-scheme-brightness: light;
  --bulma-scheme-main-l: 100%;
  --bulma-scheme-main-bis-l: 98%;
  --bulma-scheme-main-ter-l: 96%;
  --bulma-background-l: 96%;
  --bulma-border-weak-l: 93%;
  --bulma-border-l: 86%;
  --bulma-text-weak-l: 48%;
  --bulma-text-l: 29%;
  --bulma-text-strong-l: 21%;
  --bulma-text-title-l: 14%;
  --bulma-scheme-invert-ter-l: 14%;
  --bulma-scheme-invert-bis-l: 7%;
  --bulma-scheme-invert-l: 4%;
  --bulma-family-primary: Inter, SF Pro, Segoe UI, Roboto, Oxygen, Ubuntu, Helvetica Neue, Helvetica, Arial, sans-serif;
  --bulma-family-secondary: Inter, SF Pro, Segoe UI, Roboto, Oxygen, Ubuntu, Helvetica Neue, Helvetica, Arial, sans-serif;
  --bulma-family-code: Inconsolata, Hack, SF Mono, Roboto Mono, Source Code Pro, Ubuntu Mono, monospace;
  --bulma-size-small: .75rem;
  --bulma-size-normal: 1rem;
  --bulma-size-medium: 1.25rem;
  --bulma-size-large: 1.5rem;
  --bulma-weight-light: 300;
  --bulma-weight-normal: 400;
  --bulma-weight-medium: 500;
  --bulma-weight-semibold: 600;
  --bulma-weight-bold: 700;
  --bulma-weight-extrabold: 800;
  --bulma-block-spacing: 1.5rem;
  --bulma-duration: .294s;
  --bulma-easing: ease-out;
  --bulma-radius-small: .25rem;
  --bulma-radius: .375rem;
  --bulma-radius-medium: .5em;
  --bulma-radius-large: .75rem;
  --bulma-radius-rounded: 9999px;
  --bulma-speed: 86ms;
  --bulma-arrow-color: var(--bulma-link);
  --bulma-loading-color: var(--bulma-border);
  --bulma-burger-h: var(--bulma-link-h);
  --bulma-burger-s: var(--bulma-link-s);
  --bulma-burger-l: var(--bulma-link-l);
  --bulma-burger-border-radius: .5em;
  --bulma-burger-gap: 5px;
  --bulma-burger-item-height: 2px;
  --bulma-burger-item-width: 20px;
  --bulma-white: hsla(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-l), 1);
  --bulma-white-base: hsla(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-l), 1);
  --bulma-white-rgb: 255, 255, 255;
  --bulma-white-h: 221deg;
  --bulma-white-s: 14%;
  --bulma-white-l: 100%;
  --bulma-white-invert-l: 4%;
  --bulma-white-invert: #090a0c;
  --bulma-white-on-scheme-l: 35%;
  --bulma-white-on-scheme: hsla(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-on-scheme-l), 1);
  --bulma-black: hsla(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-l), 1);
  --bulma-black-base: hsla(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-l), 1);
  --bulma-black-rgb: 8.772, 9.6764, 11.628;
  --bulma-black-h: 221deg;
  --bulma-black-s: 14%;
  --bulma-black-l: 4%;
  --bulma-black-invert-l: 100%;
  --bulma-black-invert: #fff;
  --bulma-black-on-scheme-l: 4%;
  --bulma-black-on-scheme: hsla(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-on-scheme-l), 1);
  --bulma-light: hsla(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-l), 1);
  --bulma-light-base: hsla(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-l), 1);
  --bulma-light-rgb: 243.372, 244.276, 246.228;
  --bulma-light-h: 221deg;
  --bulma-light-s: 14%;
  --bulma-light-invert: #2e333d;
  --bulma-light-on-scheme-l: 36%;
  --bulma-light-on-scheme: hsla(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-on-scheme-l), 1);
  --bulma-dark: hsla(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-l), 1);
  --bulma-dark-base: hsla(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-l), 1);
  --bulma-dark-rgb: 46.053, 50.8011, 61.047;
  --bulma-dark-h: 221deg;
  --bulma-dark-s: 14%;
  --bulma-dark-invert: #f3f4f6;
  --bulma-dark-on-scheme-l: 21%;
  --bulma-dark-on-scheme: hsla(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-on-scheme-l), 1);
  --bulma-text: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-l));
  --bulma-text-base: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-l), 1);
  --bulma-text-rgb: 63.597, 70.1539, 84.303;
  --bulma-text-h: 221deg;
  --bulma-text-s: 14%;
  --bulma-text-00-l: 0%;
  --bulma-text-05-l: 4%;
  --bulma-text-10-l: 9%;
  --bulma-text-15-l: 14%;
  --bulma-text-20-l: 19%;
  --bulma-text-25-l: 24%;
  --bulma-text-30-l: 29%;
  --bulma-text-35-l: 34%;
  --bulma-text-40-l: 39%;
  --bulma-text-45-l: 44%;
  --bulma-text-50-l: 49%;
  --bulma-text-55-l: 54%;
  --bulma-text-60-l: 59%;
  --bulma-text-65-l: 64%;
  --bulma-text-70-l: 69%;
  --bulma-text-75-l: 74%;
  --bulma-text-80-l: 79%;
  --bulma-text-85-l: 84%;
  --bulma-text-90-l: 89%;
  --bulma-text-95-l: 94%;
  --bulma-text-100-l: 99%;
  --bulma-text-00: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-00-l), 1);
  --bulma-text-00-invert-l: var(--bulma-text-60-l);
  --bulma-text-00-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-00-invert-l), 1);
  --bulma-text-05: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-05-l), 1);
  --bulma-text-05-invert-l: var(--bulma-text-60-l);
  --bulma-text-05-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-05-invert-l), 1);
  --bulma-text-10: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-10-l), 1);
  --bulma-text-10-invert-l: var(--bulma-text-70-l);
  --bulma-text-10-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-10-invert-l), 1);
  --bulma-text-15: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-15-l), 1);
  --bulma-text-15-invert-l: var(--bulma-text-75-l);
  --bulma-text-15-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-15-invert-l), 1);
  --bulma-text-20: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-20-l), 1);
  --bulma-text-20-invert-l: var(--bulma-text-85-l);
  --bulma-text-20-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-20-invert-l), 1);
  --bulma-text-25: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-25-l), 1);
  --bulma-text-25-invert-l: var(--bulma-text-95-l);
  --bulma-text-25-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-25-invert-l), 1);
  --bulma-text-30: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-30-l), 1);
  --bulma-text-30-invert-l: var(--bulma-text-100-l);
  --bulma-text-30-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-30-invert-l), 1);
  --bulma-text-35: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-35-l), 1);
  --bulma-text-35-invert-l: var(--bulma-text-100-l);
  --bulma-text-35-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-35-invert-l), 1);
  --bulma-text-40: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-40-l), 1);
  --bulma-text-40-invert-l: var(--bulma-text-100-l);
  --bulma-text-40-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-40-invert-l), 1);
  --bulma-text-45: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-45-l), 1);
  --bulma-text-45-invert-l: var(--bulma-text-100-l);
  --bulma-text-45-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-45-invert-l), 1);
  --bulma-text-50: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-50-l), 1);
  --bulma-text-50-invert-l: var(--bulma-text-100-l);
  --bulma-text-50-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-50-invert-l), 1);
  --bulma-text-55: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-55-l), 1);
  --bulma-text-55-invert-l: var(--bulma-text-100-l);
  --bulma-text-55-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-55-invert-l), 1);
  --bulma-text-60: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-60-l), 1);
  --bulma-text-60-invert-l: var(--bulma-text-05-l);
  --bulma-text-60-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-60-invert-l), 1);
  --bulma-text-65: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-65-l), 1);
  --bulma-text-65-invert-l: var(--bulma-text-05-l);
  --bulma-text-65-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-65-invert-l), 1);
  --bulma-text-70: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-70-l), 1);
  --bulma-text-70-invert-l: var(--bulma-text-10-l);
  --bulma-text-70-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-70-invert-l), 1);
  --bulma-text-75: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-75-l), 1);
  --bulma-text-75-invert-l: var(--bulma-text-15-l);
  --bulma-text-75-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-75-invert-l), 1);
  --bulma-text-80: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-80-l), 1);
  --bulma-text-80-invert-l: var(--bulma-text-15-l);
  --bulma-text-80-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-80-invert-l), 1);
  --bulma-text-85: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-85-l), 1);
  --bulma-text-85-invert-l: var(--bulma-text-20-l);
  --bulma-text-85-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-85-invert-l), 1);
  --bulma-text-90: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-90-l), 1);
  --bulma-text-90-invert-l: var(--bulma-text-20-l);
  --bulma-text-90-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-90-invert-l), 1);
  --bulma-text-95: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-95-l), 1);
  --bulma-text-95-invert-l: var(--bulma-text-25-l);
  --bulma-text-95-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-95-invert-l), 1);
  --bulma-text-100: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-100-l), 1);
  --bulma-text-100-invert-l: var(--bulma-text-25-l);
  --bulma-text-100-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-100-invert-l), 1);
  --bulma-text-invert-l: var(--bulma-text-100-l);
  --bulma-text-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-invert-l), 1);
  --bulma-text-light-l: var(--bulma-text-90-l);
  --bulma-text-light: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-light-l), 1);
  --bulma-text-light-invert-l: var(--bulma-text-20-l);
  --bulma-text-light-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-light-invert-l), 1);
  --bulma-text-dark-l: var(--bulma-text-10-l);
  --bulma-text-dark: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-dark-l), 1);
  --bulma-text-dark-invert-l: var(--bulma-text-70-l);
  --bulma-text-dark-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-dark-invert-l), 1);
  --bulma-text-soft: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-soft-l), 1);
  --bulma-text-bold: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-bold-l), 1);
  --bulma-text-soft-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-soft-invert-l), 1);
  --bulma-text-bold-invert: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-bold-invert-l), 1);
  --bulma-text-on-scheme-l: 29%;
  --bulma-text-on-scheme: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-on-scheme-l), 1);
  --bulma-primary: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-l), 1);
  --bulma-primary-base: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-l), 1);
  --bulma-primary-rgb: 0, 209.1, 177.735;
  --bulma-primary-h: 171deg;
  --bulma-primary-s: 100%;
  --bulma-primary-l: 41%;
  --bulma-primary-00-l: 1%;
  --bulma-primary-05-l: 6%;
  --bulma-primary-10-l: 11%;
  --bulma-primary-15-l: 16%;
  --bulma-primary-20-l: 21%;
  --bulma-primary-25-l: 26%;
  --bulma-primary-30-l: 31%;
  --bulma-primary-35-l: 36%;
  --bulma-primary-40-l: 41%;
  --bulma-primary-45-l: 46%;
  --bulma-primary-50-l: 51%;
  --bulma-primary-55-l: 56%;
  --bulma-primary-60-l: 61%;
  --bulma-primary-65-l: 66%;
  --bulma-primary-70-l: 71%;
  --bulma-primary-75-l: 76%;
  --bulma-primary-80-l: 81%;
  --bulma-primary-85-l: 86%;
  --bulma-primary-90-l: 91%;
  --bulma-primary-95-l: 96%;
  --bulma-primary-100-l: 100%;
  --bulma-primary-00: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-00-l), 1);
  --bulma-primary-00-invert-l: var(--bulma-primary-30-l);
  --bulma-primary-00-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-00-invert-l), 1);
  --bulma-primary-05: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-05-l), 1);
  --bulma-primary-05-invert-l: var(--bulma-primary-40-l);
  --bulma-primary-05-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-05-invert-l), 1);
  --bulma-primary-10: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-10-l), 1);
  --bulma-primary-10-invert-l: var(--bulma-primary-50-l);
  --bulma-primary-10-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-10-invert-l), 1);
  --bulma-primary-15: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-15-l), 1);
  --bulma-primary-15-invert-l: var(--bulma-primary-100-l);
  --bulma-primary-15-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-15-invert-l), 1);
  --bulma-primary-20: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-20-l), 1);
  --bulma-primary-20-invert-l: var(--bulma-primary-100-l);
  --bulma-primary-20-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-20-invert-l), 1);
  --bulma-primary-25: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-25-l), 1);
  --bulma-primary-25-invert-l: var(--bulma-primary-100-l);
  --bulma-primary-25-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-25-invert-l), 1);
  --bulma-primary-30: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-30-l), 1);
  --bulma-primary-30-invert-l: var(--bulma-primary-00-l);
  --bulma-primary-30-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-30-invert-l), 1);
  --bulma-primary-35: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-35-l), 1);
  --bulma-primary-35-invert-l: var(--bulma-primary-00-l);
  --bulma-primary-35-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-35-invert-l), 1);
  --bulma-primary-40: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-40-l), 1);
  --bulma-primary-40-invert-l: var(--bulma-primary-05-l);
  --bulma-primary-40-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-40-invert-l), 1);
  --bulma-primary-45: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-45-l), 1);
  --bulma-primary-45-invert-l: var(--bulma-primary-05-l);
  --bulma-primary-45-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-45-invert-l), 1);
  --bulma-primary-50: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-50-l), 1);
  --bulma-primary-50-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-50-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-50-invert-l), 1);
  --bulma-primary-55: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-55-l), 1);
  --bulma-primary-55-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-55-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-55-invert-l), 1);
  --bulma-primary-60: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-60-l), 1);
  --bulma-primary-60-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-60-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-60-invert-l), 1);
  --bulma-primary-65: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-65-l), 1);
  --bulma-primary-65-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-65-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-65-invert-l), 1);
  --bulma-primary-70: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-70-l), 1);
  --bulma-primary-70-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-70-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-70-invert-l), 1);
  --bulma-primary-75: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-75-l), 1);
  --bulma-primary-75-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-75-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-75-invert-l), 1);
  --bulma-primary-80: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-80-l), 1);
  --bulma-primary-80-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-80-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-80-invert-l), 1);
  --bulma-primary-85: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-85-l), 1);
  --bulma-primary-85-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-85-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-85-invert-l), 1);
  --bulma-primary-90: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-90-l), 1);
  --bulma-primary-90-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-90-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-90-invert-l), 1);
  --bulma-primary-95: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-95-l), 1);
  --bulma-primary-95-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-95-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-95-invert-l), 1);
  --bulma-primary-100: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-100-l), 1);
  --bulma-primary-100-invert-l: var(--bulma-primary-15-l);
  --bulma-primary-100-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-100-invert-l), 1);
  --bulma-primary-invert-l: var(--bulma-primary-05-l);
  --bulma-primary-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-invert-l), 1);
  --bulma-primary-light-l: var(--bulma-primary-90-l);
  --bulma-primary-light: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-light-l), 1);
  --bulma-primary-light-invert-l: var(--bulma-primary-10-l);
  --bulma-primary-light-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-light-invert-l), 1);
  --bulma-primary-dark-l: var(--bulma-primary-10-l);
  --bulma-primary-dark: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-dark-l), 1);
  --bulma-primary-dark-invert-l: var(--bulma-primary-50-l);
  --bulma-primary-dark-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-dark-invert-l), 1);
  --bulma-primary-soft: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-soft-l), 1);
  --bulma-primary-bold: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-bold-l), 1);
  --bulma-primary-soft-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-soft-invert-l), 1);
  --bulma-primary-bold-invert: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-bold-invert-l), 1);
  --bulma-primary-on-scheme-l: 21%;
  --bulma-primary-on-scheme: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-on-scheme-l), 1);
  --bulma-link: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-l));
  --bulma-link-base: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-l), 1);
  --bulma-link-rgb: 66.3, 88.315, 255;
  --bulma-link-h: 233deg;
  --bulma-link-s: 100%;
  --bulma-link-l: 63%;
  --bulma-link-00-l: 0%;
  --bulma-link-05-l: 3%;
  --bulma-link-10-l: 8%;
  --bulma-link-15-l: 13%;
  --bulma-link-20-l: 18%;
  --bulma-link-25-l: 23%;
  --bulma-link-30-l: 28%;
  --bulma-link-35-l: 33%;
  --bulma-link-40-l: 38%;
  --bulma-link-45-l: 43%;
  --bulma-link-50-l: 48%;
  --bulma-link-55-l: 53%;
  --bulma-link-60-l: 58%;
  --bulma-link-65-l: 63%;
  --bulma-link-70-l: 68%;
  --bulma-link-75-l: 73%;
  --bulma-link-80-l: 78%;
  --bulma-link-85-l: 83%;
  --bulma-link-90-l: 88%;
  --bulma-link-95-l: 93%;
  --bulma-link-100-l: 98%;
  --bulma-link-00: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-00-l), 1);
  --bulma-link-00-invert-l: var(--bulma-link-75-l);
  --bulma-link-00-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-00-invert-l), 1);
  --bulma-link-05: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-05-l), 1);
  --bulma-link-05-invert-l: var(--bulma-link-75-l);
  --bulma-link-05-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-05-invert-l), 1);
  --bulma-link-10: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-10-l), 1);
  --bulma-link-10-invert-l: var(--bulma-link-75-l);
  --bulma-link-10-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-10-invert-l), 1);
  --bulma-link-15: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-15-l), 1);
  --bulma-link-15-invert-l: var(--bulma-link-80-l);
  --bulma-link-15-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-15-invert-l), 1);
  --bulma-link-20: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-20-l), 1);
  --bulma-link-20-invert-l: var(--bulma-link-80-l);
  --bulma-link-20-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-20-invert-l), 1);
  --bulma-link-25: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-25-l), 1);
  --bulma-link-25-invert-l: var(--bulma-link-85-l);
  --bulma-link-25-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-25-invert-l), 1);
  --bulma-link-30: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-30-l), 1);
  --bulma-link-30-invert-l: var(--bulma-link-90-l);
  --bulma-link-30-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-30-invert-l), 1);
  --bulma-link-35: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-35-l), 1);
  --bulma-link-35-invert-l: var(--bulma-link-90-l);
  --bulma-link-35-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-35-invert-l), 1);
  --bulma-link-40: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-40-l), 1);
  --bulma-link-40-invert-l: var(--bulma-link-95-l);
  --bulma-link-40-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-40-invert-l), 1);
  --bulma-link-45: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-45-l), 1);
  --bulma-link-45-invert-l: var(--bulma-link-95-l);
  --bulma-link-45-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-45-invert-l), 1);
  --bulma-link-50: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-50-l), 1);
  --bulma-link-50-invert-l: var(--bulma-link-100-l);
  --bulma-link-50-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-50-invert-l), 1);
  --bulma-link-55: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-55-l), 1);
  --bulma-link-55-invert-l: var(--bulma-link-100-l);
  --bulma-link-55-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-55-invert-l), 1);
  --bulma-link-60: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-60-l), 1);
  --bulma-link-60-invert-l: var(--bulma-link-100-l);
  --bulma-link-60-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-60-invert-l), 1);
  --bulma-link-65: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-65-l), 1);
  --bulma-link-65-invert-l: var(--bulma-link-100-l);
  --bulma-link-65-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-65-invert-l), 1);
  --bulma-link-70: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-70-l), 1);
  --bulma-link-70-invert-l: var(--bulma-link-100-l);
  --bulma-link-70-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-70-invert-l), 1);
  --bulma-link-75: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-75-l), 1);
  --bulma-link-75-invert-l: var(--bulma-link-10-l);
  --bulma-link-75-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-75-invert-l), 1);
  --bulma-link-80: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-80-l), 1);
  --bulma-link-80-invert-l: var(--bulma-link-20-l);
  --bulma-link-80-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-80-invert-l), 1);
  --bulma-link-85: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-85-l), 1);
  --bulma-link-85-invert-l: var(--bulma-link-25-l);
  --bulma-link-85-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-85-invert-l), 1);
  --bulma-link-90: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-90-l), 1);
  --bulma-link-90-invert-l: var(--bulma-link-35-l);
  --bulma-link-90-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-90-invert-l), 1);
  --bulma-link-95: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-95-l), 1);
  --bulma-link-95-invert-l: var(--bulma-link-45-l);
  --bulma-link-95-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-95-invert-l), 1);
  --bulma-link-100: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-100-l), 1);
  --bulma-link-100-invert-l: var(--bulma-link-50-l);
  --bulma-link-100-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-100-invert-l), 1);
  --bulma-link-invert-l: var(--bulma-link-100-l);
  --bulma-link-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-invert-l), 1);
  --bulma-link-light-l: var(--bulma-link-90-l);
  --bulma-link-light: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-light-l), 1);
  --bulma-link-light-invert-l: var(--bulma-link-35-l);
  --bulma-link-light-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-light-invert-l), 1);
  --bulma-link-dark-l: var(--bulma-link-10-l);
  --bulma-link-dark: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-dark-l), 1);
  --bulma-link-dark-invert-l: var(--bulma-link-75-l);
  --bulma-link-dark-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-dark-invert-l), 1);
  --bulma-link-soft: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-soft-l), 1);
  --bulma-link-bold: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-bold-l), 1);
  --bulma-link-soft-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-soft-invert-l), 1);
  --bulma-link-bold-invert: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-bold-invert-l), 1);
  --bulma-link-on-scheme-l: 58%;
  --bulma-link-on-scheme: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-on-scheme-l), 1);
  --bulma-info: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-l), 1);
  --bulma-info-base: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-l), 1);
  --bulma-info-rgb: 102, 209.1, 255;
  --bulma-info-h: 198deg;
  --bulma-info-s: 100%;
  --bulma-info-l: 70%;
  --bulma-info-00-l: 0%;
  --bulma-info-05-l: 5%;
  --bulma-info-10-l: 10%;
  --bulma-info-15-l: 15%;
  --bulma-info-20-l: 20%;
  --bulma-info-25-l: 25%;
  --bulma-info-30-l: 30%;
  --bulma-info-35-l: 35%;
  --bulma-info-40-l: 40%;
  --bulma-info-45-l: 45%;
  --bulma-info-50-l: 50%;
  --bulma-info-55-l: 55%;
  --bulma-info-60-l: 60%;
  --bulma-info-65-l: 65%;
  --bulma-info-70-l: 70%;
  --bulma-info-75-l: 75%;
  --bulma-info-80-l: 80%;
  --bulma-info-85-l: 85%;
  --bulma-info-90-l: 90%;
  --bulma-info-95-l: 95%;
  --bulma-info-100-l: 100%;
  --bulma-info-00: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-00-l), 1);
  --bulma-info-00-invert-l: var(--bulma-info-45-l);
  --bulma-info-00-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-00-invert-l), 1);
  --bulma-info-05: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-05-l), 1);
  --bulma-info-05-invert-l: var(--bulma-info-50-l);
  --bulma-info-05-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-05-invert-l), 1);
  --bulma-info-10: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-10-l), 1);
  --bulma-info-10-invert-l: var(--bulma-info-60-l);
  --bulma-info-10-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-10-invert-l), 1);
  --bulma-info-15: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-15-l), 1);
  --bulma-info-15-invert-l: var(--bulma-info-80-l);
  --bulma-info-15-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-15-invert-l), 1);
  --bulma-info-20: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-20-l), 1);
  --bulma-info-20-invert-l: var(--bulma-info-95-l);
  --bulma-info-20-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-20-invert-l), 1);
  --bulma-info-25: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-25-l), 1);
  --bulma-info-25-invert-l: var(--bulma-info-100-l);
  --bulma-info-25-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-25-invert-l), 1);
  --bulma-info-30: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-30-l), 1);
  --bulma-info-30-invert-l: var(--bulma-info-100-l);
  --bulma-info-30-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-30-invert-l), 1);
  --bulma-info-35: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-35-l), 1);
  --bulma-info-35-invert-l: var(--bulma-info-100-l);
  --bulma-info-35-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-35-invert-l), 1);
  --bulma-info-40: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-40-l), 1);
  --bulma-info-40-invert-l: var(--bulma-info-100-l);
  --bulma-info-40-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-40-invert-l), 1);
  --bulma-info-45: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-45-l), 1);
  --bulma-info-45-invert-l: var(--bulma-info-00-l);
  --bulma-info-45-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-45-invert-l), 1);
  --bulma-info-50: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-50-l), 1);
  --bulma-info-50-invert-l: var(--bulma-info-05-l);
  --bulma-info-50-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-50-invert-l), 1);
  --bulma-info-55: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-55-l), 1);
  --bulma-info-55-invert-l: var(--bulma-info-05-l);
  --bulma-info-55-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-55-invert-l), 1);
  --bulma-info-60: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-60-l), 1);
  --bulma-info-60-invert-l: var(--bulma-info-10-l);
  --bulma-info-60-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-60-invert-l), 1);
  --bulma-info-65: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-65-l), 1);
  --bulma-info-65-invert-l: var(--bulma-info-10-l);
  --bulma-info-65-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-65-invert-l), 1);
  --bulma-info-70: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-70-l), 1);
  --bulma-info-70-invert-l: var(--bulma-info-10-l);
  --bulma-info-70-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-70-invert-l), 1);
  --bulma-info-75: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-75-l), 1);
  --bulma-info-75-invert-l: var(--bulma-info-10-l);
  --bulma-info-75-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-75-invert-l), 1);
  --bulma-info-80: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-80-l), 1);
  --bulma-info-80-invert-l: var(--bulma-info-15-l);
  --bulma-info-80-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-80-invert-l), 1);
  --bulma-info-85: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-85-l), 1);
  --bulma-info-85-invert-l: var(--bulma-info-15-l);
  --bulma-info-85-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-85-invert-l), 1);
  --bulma-info-90: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-90-l), 1);
  --bulma-info-90-invert-l: var(--bulma-info-15-l);
  --bulma-info-90-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-90-invert-l), 1);
  --bulma-info-95: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-95-l), 1);
  --bulma-info-95-invert-l: var(--bulma-info-20-l);
  --bulma-info-95-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-95-invert-l), 1);
  --bulma-info-100: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-100-l), 1);
  --bulma-info-100-invert-l: var(--bulma-info-20-l);
  --bulma-info-100-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-100-invert-l), 1);
  --bulma-info-invert-l: var(--bulma-info-10-l);
  --bulma-info-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-invert-l), 1);
  --bulma-info-light-l: var(--bulma-info-90-l);
  --bulma-info-light: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-light-l), 1);
  --bulma-info-light-invert-l: var(--bulma-info-15-l);
  --bulma-info-light-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-light-invert-l), 1);
  --bulma-info-dark-l: var(--bulma-info-10-l);
  --bulma-info-dark: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-dark-l), 1);
  --bulma-info-dark-invert-l: var(--bulma-info-60-l);
  --bulma-info-dark-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-dark-invert-l), 1);
  --bulma-info-soft: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-soft-l), 1);
  --bulma-info-bold: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-bold-l), 1);
  --bulma-info-soft-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-soft-invert-l), 1);
  --bulma-info-bold-invert: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-bold-invert-l), 1);
  --bulma-info-on-scheme-l: 25%;
  --bulma-info-on-scheme: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-on-scheme-l), 1);
  --bulma-success: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-l), 1);
  --bulma-success-base: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-l), 1);
  --bulma-success-rgb: 71.6295, 198.671, 141.502;
  --bulma-success-h: 153deg;
  --bulma-success-s: 53%;
  --bulma-success-l: 53%;
  --bulma-success-00-l: 0%;
  --bulma-success-05-l: 3%;
  --bulma-success-10-l: 8%;
  --bulma-success-15-l: 13%;
  --bulma-success-20-l: 18%;
  --bulma-success-25-l: 23%;
  --bulma-success-30-l: 28%;
  --bulma-success-35-l: 33%;
  --bulma-success-40-l: 38%;
  --bulma-success-45-l: 43%;
  --bulma-success-50-l: 48%;
  --bulma-success-55-l: 53%;
  --bulma-success-60-l: 58%;
  --bulma-success-65-l: 63%;
  --bulma-success-70-l: 68%;
  --bulma-success-75-l: 73%;
  --bulma-success-80-l: 78%;
  --bulma-success-85-l: 83%;
  --bulma-success-90-l: 88%;
  --bulma-success-95-l: 93%;
  --bulma-success-100-l: 98%;
  --bulma-success-00: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-00-l), 1);
  --bulma-success-00-invert-l: var(--bulma-success-45-l);
  --bulma-success-00-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-00-invert-l), 1);
  --bulma-success-05: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-05-l), 1);
  --bulma-success-05-invert-l: var(--bulma-success-45-l);
  --bulma-success-05-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-05-invert-l), 1);
  --bulma-success-10: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-10-l), 1);
  --bulma-success-10-invert-l: var(--bulma-success-55-l);
  --bulma-success-10-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-10-invert-l), 1);
  --bulma-success-15: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-15-l), 1);
  --bulma-success-15-invert-l: var(--bulma-success-75-l);
  --bulma-success-15-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-15-invert-l), 1);
  --bulma-success-20: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-20-l), 1);
  --bulma-success-20-invert-l: var(--bulma-success-95-l);
  --bulma-success-20-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-20-invert-l), 1);
  --bulma-success-25: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-25-l), 1);
  --bulma-success-25-invert-l: var(--bulma-success-100-l);
  --bulma-success-25-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-25-invert-l), 1);
  --bulma-success-30: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-30-l), 1);
  --bulma-success-30-invert-l: var(--bulma-success-100-l);
  --bulma-success-30-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-30-invert-l), 1);
  --bulma-success-35: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-35-l), 1);
  --bulma-success-35-invert-l: var(--bulma-success-100-l);
  --bulma-success-35-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-35-invert-l), 1);
  --bulma-success-40: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-40-l), 1);
  --bulma-success-40-invert-l: var(--bulma-success-100-l);
  --bulma-success-40-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-40-invert-l), 1);
  --bulma-success-45: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-45-l), 1);
  --bulma-success-45-invert-l: var(--bulma-success-05-l);
  --bulma-success-45-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-45-invert-l), 1);
  --bulma-success-50: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-50-l), 1);
  --bulma-success-50-invert-l: var(--bulma-success-05-l);
  --bulma-success-50-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-50-invert-l), 1);
  --bulma-success-55: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-55-l), 1);
  --bulma-success-55-invert-l: var(--bulma-success-10-l);
  --bulma-success-55-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-55-invert-l), 1);
  --bulma-success-60: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-60-l), 1);
  --bulma-success-60-invert-l: var(--bulma-success-10-l);
  --bulma-success-60-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-60-invert-l), 1);
  --bulma-success-65: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-65-l), 1);
  --bulma-success-65-invert-l: var(--bulma-success-10-l);
  --bulma-success-65-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-65-invert-l), 1);
  --bulma-success-70: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-70-l), 1);
  --bulma-success-70-invert-l: var(--bulma-success-10-l);
  --bulma-success-70-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-70-invert-l), 1);
  --bulma-success-75: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-75-l), 1);
  --bulma-success-75-invert-l: var(--bulma-success-15-l);
  --bulma-success-75-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-75-invert-l), 1);
  --bulma-success-80: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-80-l), 1);
  --bulma-success-80-invert-l: var(--bulma-success-15-l);
  --bulma-success-80-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-80-invert-l), 1);
  --bulma-success-85: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-85-l), 1);
  --bulma-success-85-invert-l: var(--bulma-success-15-l);
  --bulma-success-85-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-85-invert-l), 1);
  --bulma-success-90: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-90-l), 1);
  --bulma-success-90-invert-l: var(--bulma-success-15-l);
  --bulma-success-90-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-90-invert-l), 1);
  --bulma-success-95: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-95-l), 1);
  --bulma-success-95-invert-l: var(--bulma-success-20-l);
  --bulma-success-95-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-95-invert-l), 1);
  --bulma-success-100: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-100-l), 1);
  --bulma-success-100-invert-l: var(--bulma-success-20-l);
  --bulma-success-100-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-100-invert-l), 1);
  --bulma-success-invert-l: var(--bulma-success-10-l);
  --bulma-success-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-invert-l), 1);
  --bulma-success-light-l: var(--bulma-success-90-l);
  --bulma-success-light: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-light-l), 1);
  --bulma-success-light-invert-l: var(--bulma-success-15-l);
  --bulma-success-light-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-light-invert-l), 1);
  --bulma-success-dark-l: var(--bulma-success-10-l);
  --bulma-success-dark: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-dark-l), 1);
  --bulma-success-dark-invert-l: var(--bulma-success-55-l);
  --bulma-success-dark-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-dark-invert-l), 1);
  --bulma-success-soft: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-soft-l), 1);
  --bulma-success-bold: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-bold-l), 1);
  --bulma-success-soft-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-soft-invert-l), 1);
  --bulma-success-bold-invert: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-bold-invert-l), 1);
  --bulma-success-on-scheme-l: 23%;
  --bulma-success-on-scheme: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-on-scheme-l), 1);
  --bulma-warning: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-l), 1);
  --bulma-warning-base: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-l), 1);
  --bulma-warning-rgb: 255, 183.09, 15.3;
  --bulma-warning-h: 42deg;
  --bulma-warning-s: 100%;
  --bulma-warning-l: 53%;
  --bulma-warning-00-l: 0%;
  --bulma-warning-05-l: 3%;
  --bulma-warning-10-l: 8%;
  --bulma-warning-15-l: 13%;
  --bulma-warning-20-l: 18%;
  --bulma-warning-25-l: 23%;
  --bulma-warning-30-l: 28%;
  --bulma-warning-35-l: 33%;
  --bulma-warning-40-l: 38%;
  --bulma-warning-45-l: 43%;
  --bulma-warning-50-l: 48%;
  --bulma-warning-55-l: 53%;
  --bulma-warning-60-l: 58%;
  --bulma-warning-65-l: 63%;
  --bulma-warning-70-l: 68%;
  --bulma-warning-75-l: 73%;
  --bulma-warning-80-l: 78%;
  --bulma-warning-85-l: 83%;
  --bulma-warning-90-l: 88%;
  --bulma-warning-95-l: 93%;
  --bulma-warning-100-l: 98%;
  --bulma-warning-00: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-00-l), 1);
  --bulma-warning-00-invert-l: var(--bulma-warning-40-l);
  --bulma-warning-00-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-00-invert-l), 1);
  --bulma-warning-05: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-05-l), 1);
  --bulma-warning-05-invert-l: var(--bulma-warning-45-l);
  --bulma-warning-05-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-05-invert-l), 1);
  --bulma-warning-10: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-10-l), 1);
  --bulma-warning-10-invert-l: var(--bulma-warning-50-l);
  --bulma-warning-10-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-10-invert-l), 1);
  --bulma-warning-15: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-15-l), 1);
  --bulma-warning-15-invert-l: var(--bulma-warning-70-l);
  --bulma-warning-15-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-15-invert-l), 1);
  --bulma-warning-20: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-20-l), 1);
  --bulma-warning-20-invert-l: var(--bulma-warning-100-l);
  --bulma-warning-20-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-20-invert-l), 1);
  --bulma-warning-25: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-25-l), 1);
  --bulma-warning-25-invert-l: var(--bulma-warning-100-l);
  --bulma-warning-25-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-25-invert-l), 1);
  --bulma-warning-30: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-30-l), 1);
  --bulma-warning-30-invert-l: var(--bulma-warning-100-l);
  --bulma-warning-30-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-30-invert-l), 1);
  --bulma-warning-35: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-35-l), 1);
  --bulma-warning-35-invert-l: var(--bulma-warning-100-l);
  --bulma-warning-35-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-35-invert-l), 1);
  --bulma-warning-40: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-40-l), 1);
  --bulma-warning-40-invert-l: var(--bulma-warning-00-l);
  --bulma-warning-40-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-40-invert-l), 1);
  --bulma-warning-45: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-45-l), 1);
  --bulma-warning-45-invert-l: var(--bulma-warning-05-l);
  --bulma-warning-45-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-45-invert-l), 1);
  --bulma-warning-50: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-50-l), 1);
  --bulma-warning-50-invert-l: var(--bulma-warning-10-l);
  --bulma-warning-50-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-50-invert-l), 1);
  --bulma-warning-55: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-55-l), 1);
  --bulma-warning-55-invert-l: var(--bulma-warning-10-l);
  --bulma-warning-55-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-55-invert-l), 1);
  --bulma-warning-60: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-60-l), 1);
  --bulma-warning-60-invert-l: var(--bulma-warning-10-l);
  --bulma-warning-60-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-60-invert-l), 1);
  --bulma-warning-65: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-65-l), 1);
  --bulma-warning-65-invert-l: var(--bulma-warning-10-l);
  --bulma-warning-65-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-65-invert-l), 1);
  --bulma-warning-70: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-70-l), 1);
  --bulma-warning-70-invert-l: var(--bulma-warning-15-l);
  --bulma-warning-70-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-70-invert-l), 1);
  --bulma-warning-75: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-75-l), 1);
  --bulma-warning-75-invert-l: var(--bulma-warning-15-l);
  --bulma-warning-75-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-75-invert-l), 1);
  --bulma-warning-80: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-80-l), 1);
  --bulma-warning-80-invert-l: var(--bulma-warning-15-l);
  --bulma-warning-80-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-80-invert-l), 1);
  --bulma-warning-85: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-85-l), 1);
  --bulma-warning-85-invert-l: var(--bulma-warning-15-l);
  --bulma-warning-85-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-85-invert-l), 1);
  --bulma-warning-90: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-90-l), 1);
  --bulma-warning-90-invert-l: var(--bulma-warning-15-l);
  --bulma-warning-90-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-90-invert-l), 1);
  --bulma-warning-95: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-95-l), 1);
  --bulma-warning-95-invert-l: var(--bulma-warning-15-l);
  --bulma-warning-95-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-95-invert-l), 1);
  --bulma-warning-100: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-100-l), 1);
  --bulma-warning-100-invert-l: var(--bulma-warning-20-l);
  --bulma-warning-100-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-100-invert-l), 1);
  --bulma-warning-invert-l: var(--bulma-warning-10-l);
  --bulma-warning-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-invert-l), 1);
  --bulma-warning-light-l: var(--bulma-warning-90-l);
  --bulma-warning-light: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-light-l), 1);
  --bulma-warning-light-invert-l: var(--bulma-warning-15-l);
  --bulma-warning-light-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-light-invert-l), 1);
  --bulma-warning-dark-l: var(--bulma-warning-10-l);
  --bulma-warning-dark: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-dark-l), 1);
  --bulma-warning-dark-invert-l: var(--bulma-warning-50-l);
  --bulma-warning-dark-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-dark-invert-l), 1);
  --bulma-warning-soft: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-soft-l), 1);
  --bulma-warning-bold: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-bold-l), 1);
  --bulma-warning-soft-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-soft-invert-l), 1);
  --bulma-warning-bold-invert: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-bold-invert-l), 1);
  --bulma-warning-on-scheme-l: 23%;
  --bulma-warning-on-scheme: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-on-scheme-l), 1);
  --bulma-danger: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-l), 1);
  --bulma-danger-base: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-l), 1);
  --bulma-danger-rgb: 255, 102, 132.6;
  --bulma-danger-h: 348deg;
  --bulma-danger-s: 100%;
  --bulma-danger-l: 70%;
  --bulma-danger-00-l: 0%;
  --bulma-danger-05-l: 5%;
  --bulma-danger-10-l: 10%;
  --bulma-danger-15-l: 15%;
  --bulma-danger-20-l: 20%;
  --bulma-danger-25-l: 25%;
  --bulma-danger-30-l: 30%;
  --bulma-danger-35-l: 35%;
  --bulma-danger-40-l: 40%;
  --bulma-danger-45-l: 45%;
  --bulma-danger-50-l: 50%;
  --bulma-danger-55-l: 55%;
  --bulma-danger-60-l: 60%;
  --bulma-danger-65-l: 65%;
  --bulma-danger-70-l: 70%;
  --bulma-danger-75-l: 75%;
  --bulma-danger-80-l: 80%;
  --bulma-danger-85-l: 85%;
  --bulma-danger-90-l: 90%;
  --bulma-danger-95-l: 95%;
  --bulma-danger-100-l: 100%;
  --bulma-danger-00: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-00-l), 1);
  --bulma-danger-00-invert-l: var(--bulma-danger-65-l);
  --bulma-danger-00-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-00-invert-l), 1);
  --bulma-danger-05: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-05-l), 1);
  --bulma-danger-05-invert-l: var(--bulma-danger-70-l);
  --bulma-danger-05-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-05-invert-l), 1);
  --bulma-danger-10: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-10-l), 1);
  --bulma-danger-10-invert-l: var(--bulma-danger-75-l);
  --bulma-danger-10-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-10-invert-l), 1);
  --bulma-danger-15: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-15-l), 1);
  --bulma-danger-15-invert-l: var(--bulma-danger-80-l);
  --bulma-danger-15-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-15-invert-l), 1);
  --bulma-danger-20: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-20-l), 1);
  --bulma-danger-20-invert-l: var(--bulma-danger-85-l);
  --bulma-danger-20-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-20-invert-l), 1);
  --bulma-danger-25: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-25-l), 1);
  --bulma-danger-25-invert-l: var(--bulma-danger-90-l);
  --bulma-danger-25-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-25-invert-l), 1);
  --bulma-danger-30: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-30-l), 1);
  --bulma-danger-30-invert-l: var(--bulma-danger-100-l);
  --bulma-danger-30-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-30-invert-l), 1);
  --bulma-danger-35: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-35-l), 1);
  --bulma-danger-35-invert-l: var(--bulma-danger-100-l);
  --bulma-danger-35-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-35-invert-l), 1);
  --bulma-danger-40: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-40-l), 1);
  --bulma-danger-40-invert-l: var(--bulma-danger-100-l);
  --bulma-danger-40-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-40-invert-l), 1);
  --bulma-danger-45: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-45-l), 1);
  --bulma-danger-45-invert-l: var(--bulma-danger-100-l);
  --bulma-danger-45-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-45-invert-l), 1);
  --bulma-danger-50: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-50-l), 1);
  --bulma-danger-50-invert-l: var(--bulma-danger-100-l);
  --bulma-danger-50-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-50-invert-l), 1);
  --bulma-danger-55: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-55-l), 1);
  --bulma-danger-55-invert-l: var(--bulma-danger-100-l);
  --bulma-danger-55-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-55-invert-l), 1);
  --bulma-danger-60: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-60-l), 1);
  --bulma-danger-60-invert-l: var(--bulma-danger-100-l);
  --bulma-danger-60-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-60-invert-l), 1);
  --bulma-danger-65: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-65-l), 1);
  --bulma-danger-65-invert-l: var(--bulma-danger-00-l);
  --bulma-danger-65-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-65-invert-l), 1);
  --bulma-danger-70: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-70-l), 1);
  --bulma-danger-70-invert-l: var(--bulma-danger-05-l);
  --bulma-danger-70-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-70-invert-l), 1);
  --bulma-danger-75: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-75-l), 1);
  --bulma-danger-75-invert-l: var(--bulma-danger-10-l);
  --bulma-danger-75-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-75-invert-l), 1);
  --bulma-danger-80: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-80-l), 1);
  --bulma-danger-80-invert-l: var(--bulma-danger-15-l);
  --bulma-danger-80-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-80-invert-l), 1);
  --bulma-danger-85: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-85-l), 1);
  --bulma-danger-85-invert-l: var(--bulma-danger-20-l);
  --bulma-danger-85-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-85-invert-l), 1);
  --bulma-danger-90: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-90-l), 1);
  --bulma-danger-90-invert-l: var(--bulma-danger-25-l);
  --bulma-danger-90-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-90-invert-l), 1);
  --bulma-danger-95: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-95-l), 1);
  --bulma-danger-95-invert-l: var(--bulma-danger-25-l);
  --bulma-danger-95-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-95-invert-l), 1);
  --bulma-danger-100: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-100-l), 1);
  --bulma-danger-100-invert-l: var(--bulma-danger-30-l);
  --bulma-danger-100-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-100-invert-l), 1);
  --bulma-danger-invert-l: var(--bulma-danger-05-l);
  --bulma-danger-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-invert-l), 1);
  --bulma-danger-light-l: var(--bulma-danger-90-l);
  --bulma-danger-light: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-light-l), 1);
  --bulma-danger-light-invert-l: var(--bulma-danger-25-l);
  --bulma-danger-light-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-light-invert-l), 1);
  --bulma-danger-dark-l: var(--bulma-danger-10-l);
  --bulma-danger-dark: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-dark-l), 1);
  --bulma-danger-dark-invert-l: var(--bulma-danger-75-l);
  --bulma-danger-dark-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-dark-invert-l), 1);
  --bulma-danger-soft: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-soft-l), 1);
  --bulma-danger-bold: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-bold-l), 1);
  --bulma-danger-soft-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-soft-invert-l), 1);
  --bulma-danger-bold-invert: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-bold-invert-l), 1);
  --bulma-danger-on-scheme-l: 40%;
  --bulma-danger-on-scheme: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-on-scheme-l), 1);
  --bulma-black-bis: #14161a;
  --bulma-black-ter: #1f2229;
  --bulma-grey-darker: #2e333d;
  --bulma-grey-dark: #404654;
  --bulma-grey: #69748c;
  --bulma-grey-light: #abb1bf;
  --bulma-grey-lighter: #d6d9e0;
  --bulma-white-ter: #f3f4f6;
  --bulma-white-bis: #f9fafb;
  --bulma-shadow-h: 221deg;
  --bulma-shadow-s: 14%;
  --bulma-shadow-l: 4%;
  --bulma-size-1: 3rem;
  --bulma-size-2: 2.5rem;
  --bulma-size-3: 2rem;
  --bulma-size-4: 1.5rem;
  --bulma-size-5: 1.25rem;
  --bulma-size-6: 1rem;
  --bulma-size-7: .75rem;
  --bulma-scheme-main: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-main-l));
  --bulma-scheme-main-bis: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-main-bis-l));
  --bulma-scheme-main-ter: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-main-ter-l));
  --bulma-background: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-background-l));
  --bulma-background-hover: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), calc(var(--bulma-background-l)  + var(--bulma-hover-background-l-delta)));
  --bulma-background-active: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), calc(var(--bulma-background-l)  + var(--bulma-active-background-l-delta)));
  --bulma-border-weak: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-border-weak-l));
  --bulma-border: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-border-l));
  --bulma-border-hover: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), calc(var(--bulma-border-l)  + var(--bulma-hover-border-l-delta)));
  --bulma-border-active: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), calc(var(--bulma-border-l)  + var(--bulma-active-border-l-delta)));
  --bulma-text-weak: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-weak-l));
  --bulma-text-strong: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-strong-l));
  --bulma-scheme-invert-ter: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-ter-l));
  --bulma-scheme-invert-bis: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-bis-l));
  --bulma-scheme-invert: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l));
  --bulma-link-text: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-on-scheme-l));
  --bulma-link-text-hover: hsl(var(--bulma-link-h), var(--bulma-link-s), calc(var(--bulma-link-on-scheme-l)  + var(--bulma-hover-color-l-delta)));
  --bulma-link-text-active: hsl(var(--bulma-link-h), var(--bulma-link-s), calc(var(--bulma-link-on-scheme-l)  + var(--bulma-active-color-l-delta)));
  --bulma-focus-h: var(--bulma-link-h);
  --bulma-focus-s: var(--bulma-link-s);
  --bulma-focus-l: var(--bulma-link-l);
  --bulma-focus-offset: 1px;
  --bulma-focus-style: solid;
  --bulma-focus-width: 2px;
  --bulma-focus-shadow-size: 0 0 0 .1875em;
  --bulma-focus-shadow-alpha: .25;
  --bulma-code: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-on-scheme-l));
  --bulma-code-background: var(--bulma-background);
  --bulma-pre: var(--bulma-text);
  --bulma-pre-background: var(--bulma-background);
  --bulma-shadow: 0 .5em 1em -.125em hsla(var(--bulma-shadow-h), var(--bulma-shadow-s), var(--bulma-shadow-l), .1), 0 0px 0 1px hsla(var(--bulma-shadow-h), var(--bulma-shadow-s), var(--bulma-shadow-l), .02);
}

[data-theme="dark"], .theme-dark {
  --bulma-white-on-scheme-l: 100%;
  --bulma-white-on-scheme: hsla(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-on-scheme-l), 1);
  --bulma-black-on-scheme-l: -51%;
  --bulma-black-on-scheme: hsla(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-on-scheme-l), 1);
  --bulma-light-on-scheme-l: 96%;
  --bulma-light-on-scheme: hsla(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-on-scheme-l), 1);
  --bulma-dark-on-scheme-l: 56%;
  --bulma-dark-on-scheme: hsla(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-on-scheme-l), 1);
  --bulma-text-on-scheme-l: 54%;
  --bulma-text-on-scheme: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-on-scheme-l), 1);
  --bulma-primary-on-scheme-l: 41%;
  --bulma-primary-on-scheme: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-on-scheme-l), 1);
  --bulma-link-on-scheme-l: 73%;
  --bulma-link-on-scheme: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-on-scheme-l), 1);
  --bulma-info-on-scheme-l: 70%;
  --bulma-info-on-scheme: hsla(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-on-scheme-l), 1);
  --bulma-success-on-scheme-l: 53%;
  --bulma-success-on-scheme: hsla(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-on-scheme-l), 1);
  --bulma-warning-on-scheme-l: 53%;
  --bulma-warning-on-scheme: hsla(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-on-scheme-l), 1);
  --bulma-danger-on-scheme-l: 70%;
  --bulma-danger-on-scheme: hsla(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-on-scheme-l), 1);
  --bulma-scheme-brightness: dark;
  --bulma-scheme-main-l: 9%;
  --bulma-scheme-main-bis-l: 11%;
  --bulma-scheme-main-ter-l: 13%;
  --bulma-soft-l: 20%;
  --bulma-bold-l: 90%;
  --bulma-soft-invert-l: 90%;
  --bulma-bold-invert-l: 20%;
  --bulma-background-l: 14%;
  --bulma-border-weak-l: 21%;
  --bulma-border-l: 24%;
  --bulma-text-weak-l: 53%;
  --bulma-text-l: 71%;
  --bulma-text-strong-l: 93%;
  --bulma-text-title-l: 100%;
  --bulma-hover-background-l-delta: 5%;
  --bulma-active-background-l-delta: 10%;
  --bulma-hover-border-l-delta: 10%;
  --bulma-active-border-l-delta: 20%;
  --bulma-hover-color-l-delta: 5%;
  --bulma-active-color-l-delta: 10%;
  --bulma-shadow-h: 0deg;
  --bulma-shadow-s: 0%;
  --bulma-shadow-l: 100%;
  --bulma-scheme-main: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-main-l));
  --bulma-scheme-main-bis: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-main-bis-l));
  --bulma-scheme-main-ter: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-main-ter-l));
  --bulma-background: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-background-l));
  --bulma-background-hover: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), calc(var(--bulma-background-l)  + var(--bulma-hover-background-l-delta)));
  --bulma-background-active: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), calc(var(--bulma-background-l)  + var(--bulma-active-background-l-delta)));
  --bulma-border-weak: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-border-weak-l));
  --bulma-border: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-border-l));
  --bulma-border-hover: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), calc(var(--bulma-border-l)  + var(--bulma-hover-border-l-delta)));
  --bulma-border-active: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), calc(var(--bulma-border-l)  + var(--bulma-active-border-l-delta)));
  --bulma-text-weak: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-weak-l));
  --bulma-text: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-l));
  --bulma-text-strong: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-strong-l));
  --bulma-scheme-invert-ter: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-ter-l));
  --bulma-scheme-invert-bis: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-bis-l));
  --bulma-scheme-invert: hsl(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l));
  --bulma-link: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-l));
  --bulma-link-text: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-on-scheme-l));
  --bulma-link-text-hover: hsl(var(--bulma-link-h), var(--bulma-link-s), calc(var(--bulma-link-on-scheme-l)  + var(--bulma-hover-color-l-delta)));
  --bulma-link-text-active: hsl(var(--bulma-link-h), var(--bulma-link-s), calc(var(--bulma-link-on-scheme-l)  + var(--bulma-active-color-l-delta)));
  --bulma-focus-h: var(--bulma-link-h);
  --bulma-focus-s: var(--bulma-link-s);
  --bulma-focus-l: var(--bulma-link-l);
  --bulma-focus-offset: 1px;
  --bulma-focus-style: solid;
  --bulma-focus-width: 2px;
  --bulma-focus-shadow-size: 0 0 0 .1875em;
  --bulma-focus-shadow-alpha: .25;
  --bulma-code: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-on-scheme-l));
  --bulma-code-background: var(--bulma-background);
  --bulma-pre: var(--bulma-text);
  --bulma-pre-background: var(--bulma-background);
  --bulma-shadow: 0 .5em 1em -.125em hsla(var(--bulma-shadow-h), var(--bulma-shadow-s), var(--bulma-shadow-l), .1), 0 0px 0 1px hsla(var(--bulma-shadow-h), var(--bulma-shadow-s), var(--bulma-shadow-l), .02);
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, video {
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

td:not([align]), th:not([align]) {
  text-align: inherit;
}

:root {
  --bulma-body-background-color: var(--bulma-scheme-main);
  --bulma-body-size: 1em;
  --bulma-body-min-width: 300px;
  --bulma-body-rendering: optimizeLegibility;
  --bulma-body-family: var(--bulma-family-primary);
  --bulma-body-overflow-x: hidden;
  --bulma-body-overflow-y: scroll;
  --bulma-body-color: var(--bulma-text);
  --bulma-body-font-size: 1em;
  --bulma-body-weight: var(--bulma-weight-normal);
  --bulma-body-line-height: 1.5;
  --bulma-code-family: var(--bulma-family-code);
  --bulma-code-padding: .25em .5em .25em;
  --bulma-code-weight: normal;
  --bulma-code-size: .875em;
  --bulma-small-font-size: .875em;
  --bulma-hr-background-color: var(--bulma-background);
  --bulma-hr-height: 2px;
  --bulma-hr-margin: 1.5rem 0;
  --bulma-strong-color: var(--bulma-text-strong);
  --bulma-strong-weight: var(--bulma-weight-semibold);
  --bulma-pre-font-size: .875em;
  --bulma-pre-padding: 1.25rem 1.5rem;
  --bulma-pre-code-font-size: 1em;
}

html {
  background-color: var(--bulma-body-background-color);
  font-size: var(--bulma-body-size);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: var(--bulma-body-min-width);
  overflow-x: var(--bulma-body-overflow-x);
  overflow-y: var(--bulma-body-overflow-y);
  text-rendering: var(--bulma-body-rendering);
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

body, button, input, optgroup, select, textarea {
  font-family: var(--bulma-body-family);
}

code, pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: var(--bulma-code-family);
}

body {
  color: var(--bulma-body-color);
  font-size: var(--bulma-body-font-size);
  font-weight: var(--bulma-body-weight);
  line-height: var(--bulma-body-line-height);
}

a, button {
  cursor: pointer;
}

a:focus-visible, button:focus-visible {
  outline-color: hsl(var(--bulma-focus-h), var(--bulma-focus-s), var(--bulma-focus-l));
  outline-offset: var(--bulma-focus-offset);
  outline-style: var(--bulma-focus-style);
  outline-width: var(--bulma-focus-width);
}

a:focus-visible:active, button:focus-visible:active, a:active, button:active {
  outline-width: 1px;
}

a {
  color: var(--bulma-link-text);
  cursor: pointer;
  transition-duration: var(--bulma-duration);
  text-decoration: none;
  transition-property: background-color, border-color, color;
}

a strong {
  color: currentColor;
}

button {
  appearance: none;
  color: inherit;
  transition-duration: var(--bulma-duration);
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 1em;
  transition-property: background-color, border-color, color;
}

code {
  background-color: var(--bulma-code-background);
  color: var(--bulma-code);
  font-size: var(--bulma-code-size);
  font-weight: var(--bulma-code-weight);
  padding: var(--bulma-code-padding);
  border-radius: .5em;
}

hr {
  background-color: var(--bulma-hr-background-color);
  height: var(--bulma-hr-height);
  margin: var(--bulma-hr-margin);
  border: none;
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: var(--bulma-small-font-size);
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: var(--bulma-strong-color);
  font-weight: var(--bulma-strong-weight);
}

svg {
  width: auto;
  height: auto;
}

fieldset {
  border: none;
}

pre {
  -webkit-overflow-scrolling: touch;
  background-color: var(--bulma-pre-background);
  color: var(--bulma-pre);
  font-size: var(--bulma-pre-font-size);
  padding: var(--bulma-pre-padding);
  white-space: pre;
  word-wrap: normal;
  overflow-x: auto;
}

pre code {
  color: currentColor;
  font-size: var(--bulma-pre-code-font-size);
  background-color: #0000;
  padding: 0;
}

table td, table th {
  vertical-align: top;
}

table td:not([align]), table th:not([align]) {
  text-align: inherit;
}

table th {
  color: var(--bulma-text-strong);
}

@keyframes spinAround {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes pulsate {
  50% {
    opacity: .5;
  }
}

.navbar-link:not(.is-arrowless):after, .select:not(.is-multiple):not(.is-loading):after {
  border: .125em solid var(--bulma-arrow-color);
  content: " ";
  pointer-events: none;
  transform-origin: center;
  height: .625em;
  transition-duration: var(--bulma-duration);
  border-top: 0;
  border-right: 0;
  width: .625em;
  margin-top: -.4375em;
  transition-property: border-color;
  display: block;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
}

.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .file-cta, .file-name, .select select, .input, .textarea, .button {
  appearance: none;
  border-style: solid;
  border-color: #0000;
  border-width: var(--bulma-control-border-width);
  border-radius: var(--bulma-control-radius);
  box-shadow: none;
  font-size: var(--bulma-control-size);
  height: var(--bulma-control-height);
  line-height: var(--bulma-control-line-height);
  padding-bottom: var(--bulma-control-padding-vertical);
  padding-left: var(--bulma-control-padding-horizontal);
  padding-right: var(--bulma-control-padding-horizontal);
  padding-top: var(--bulma-control-padding-vertical);
  transition-duration: var(--bulma-duration);
  vertical-align: top;
  justify-content: flex-start;
  align-items: center;
  transition-property: background-color, border-color, box-shadow, color;
  display: inline-flex;
  position: relative;
}

.modal-close {
  --bulma-delete-dimensions: 1.25rem;
  --bulma-delete-background-l: 0%;
  --bulma-delete-background-alpha: .5;
  --bulma-delete-color: var(--bulma-white);
  appearance: none;
  background-color: hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-delete-background-l), var(--bulma-delete-background-alpha));
  border-radius: var(--bulma-radius-rounded);
  cursor: pointer;
  pointer-events: auto;
  height: var(--bulma-delete-dimensions);
  max-height: var(--bulma-delete-dimensions);
  max-width: var(--bulma-delete-dimensions);
  min-height: var(--bulma-delete-dimensions);
  min-width: var(--bulma-delete-dimensions);
  vertical-align: top;
  width: var(--bulma-delete-dimensions);
  border: none;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 1em;
  display: inline-flex;
  position: relative;
}

.modal-close:active {
  --bulma-delete-background-alpha: .5;
}

.control.is-loading:after, .select.is-loading:after, .button.is-loading:after {
  border: 2px solid var(--bulma-loading-color);
  border-radius: var(--bulma-radius-rounded);
  content: "";
  border-top-color: #0000;
  border-right-color: #0000;
  width: 1em;
  height: 1em;
  animation: .5s linear infinite spinAround;
  display: block;
  position: relative;
}

.is-overlay, .hero-video, .modal, .modal-background {
  position: absolute;
  inset: 0;
}

.navbar-burger, .menu-list a, .menu-list button, .menu-list .menu-item {
  appearance: none;
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 1em;
}

.is-unselectable, .tabs, .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .breadcrumb, .file, .button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.box {
  --bulma-box-background-color: var(--bulma-scheme-main);
  --bulma-box-color: var(--bulma-text);
  --bulma-box-radius: var(--bulma-radius-large);
  --bulma-box-shadow: var(--bulma-shadow);
  --bulma-box-padding: 1.25rem;
  --bulma-box-link-hover-shadow: 0 .5em 1em -.125em hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), .1), 0 0 0 1px var(--bulma-link);
  --bulma-box-link-active-shadow: inset 0 1px 2px hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), .2), 0 0 0 1px var(--bulma-link);
  background-color: var(--bulma-box-background-color);
  border-radius: var(--bulma-box-radius);
  box-shadow: var(--bulma-box-shadow);
  color: var(--bulma-box-color);
  padding: var(--bulma-box-padding);
  display: block;
}

a.box:hover, a.box:focus {
  box-shadow: var(--bulma-box-link-hover-shadow);
}

a.box:active {
  box-shadow: var(--bulma-box-link-active-shadow);
}

.button {
  --bulma-button-family: false;
  --bulma-button-weight: var(--bulma-weight-medium);
  --bulma-button-border-color: var(--bulma-border);
  --bulma-button-border-style: solid;
  --bulma-button-border-width: var(--bulma-control-border-width);
  --bulma-button-padding-vertical: .5em;
  --bulma-button-padding-horizontal: 1em;
  --bulma-button-focus-border-color: var(--bulma-link-focus-border);
  --bulma-button-focus-box-shadow-size: 0 0 0 .125em;
  --bulma-button-focus-box-shadow-color: hsla(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-on-scheme-l), .25);
  --bulma-button-active-color: var(--bulma-link-active);
  --bulma-button-active-border-color: var(--bulma-link-active-border);
  --bulma-button-text-color: var(--bulma-text);
  --bulma-button-text-decoration: underline;
  --bulma-button-text-hover-background-color: var(--bulma-background);
  --bulma-button-text-hover-color: var(--bulma-text-strong);
  --bulma-button-ghost-background: none;
  --bulma-button-ghost-border-color: transparent;
  --bulma-button-ghost-color: var(--bulma-link-text);
  --bulma-button-ghost-decoration: none;
  --bulma-button-ghost-hover-color: var(--bulma-link);
  --bulma-button-ghost-hover-decoration: underline;
  --bulma-button-disabled-background-color: var(--bulma-scheme-main);
  --bulma-button-disabled-border-color: var(--bulma-border);
  --bulma-button-disabled-shadow: none;
  --bulma-button-disabled-opacity: .5;
  --bulma-button-static-color: var(--bulma-text-weak);
  --bulma-button-static-background-color: var(--bulma-scheme-main-ter);
  --bulma-button-static-border-color: var(--bulma-border);
  --bulma-button-h: var(--bulma-scheme-h);
  --bulma-button-s: var(--bulma-scheme-s);
  --bulma-button-l: var(--bulma-scheme-main-l);
  --bulma-button-background-l: var(--bulma-scheme-main-l);
  --bulma-button-background-l-delta: 0%;
  --bulma-button-hover-background-l-delta: var(--bulma-hover-background-l-delta);
  --bulma-button-active-background-l-delta: var(--bulma-active-background-l-delta);
  --bulma-button-color-l: var(--bulma-text-strong-l);
  --bulma-button-border-l: var(--bulma-border-l);
  --bulma-button-border-l-delta: 0%;
  --bulma-button-hover-border-l-delta: var(--bulma-hover-border-l-delta);
  --bulma-button-active-border-l-delta: var(--bulma-active-border-l-delta);
  --bulma-button-focus-border-l-delta: var(--bulma-focus-border-l-delta);
  --bulma-button-outer-shadow-h: 0;
  --bulma-button-outer-shadow-s: 0%;
  --bulma-button-outer-shadow-l: 20%;
  --bulma-button-outer-shadow-a: .05;
  --bulma-loading-color: hsl(var(--bulma-button-h), var(--bulma-button-s), var(--bulma-button-color-l));
  background-color: hsl(var(--bulma-button-h), var(--bulma-button-s), calc(var(--bulma-button-background-l)  + var(--bulma-button-background-l-delta)));
  border-color: hsl(var(--bulma-button-h), var(--bulma-button-s), calc(var(--bulma-button-border-l)  + var(--bulma-button-border-l-delta)));
  border-style: var(--bulma-button-border-style);
  border-width: var(--bulma-button-border-width);
  box-shadow: 0px .0625em .125em hsla(var(--bulma-button-outer-shadow-h), var(--bulma-button-outer-shadow-s), var(--bulma-button-outer-shadow-l), var(--bulma-button-outer-shadow-a)), 0px .125em .25em hsla(var(--bulma-button-outer-shadow-h), var(--bulma-button-outer-shadow-s), var(--bulma-button-outer-shadow-l), var(--bulma-button-outer-shadow-a));
  color: hsl(var(--bulma-button-h), var(--bulma-button-s), var(--bulma-button-color-l));
  cursor: pointer;
  font-weight: var(--bulma-button-weight);
  height: auto;
  padding-bottom: calc(var(--bulma-button-padding-vertical)  - var(--bulma-button-border-width));
  padding-left: calc(var(--bulma-button-padding-horizontal)  - var(--bulma-button-border-width));
  padding-right: calc(var(--bulma-button-padding-horizontal)  - var(--bulma-button-border-width));
  padding-top: calc(var(--bulma-button-padding-vertical)  - var(--bulma-button-border-width));
  text-align: center;
  white-space: nowrap;
  justify-content: center;
}

.button strong {
  color: inherit;
}

.button .icon, .button .icon.is-small, .button .icon.is-medium, .button .icon.is-large {
  width: 1.5em;
  height: 1.5em;
}

.button .icon:first-child:not(:last-child) {
  margin-inline-start: calc(-.5 * var(--bulma-button-padding-horizontal));
  margin-inline-end: calc(var(--bulma-button-padding-horizontal) * .25);
}

.button .icon:last-child:not(:first-child) {
  margin-inline-start: calc(var(--bulma-button-padding-horizontal) * .25);
  margin-inline-end: calc(-.5 * var(--bulma-button-padding-horizontal));
}

.button .icon:first-child:last-child {
  margin-inline-start: calc(-.5 * var(--bulma-button-padding-horizontal));
  margin-inline-end: calc(-.5 * var(--bulma-button-padding-horizontal));
}

.button:hover, .button.is-hovered {
  --bulma-button-background-l-delta: var(--bulma-button-hover-background-l-delta);
  --bulma-button-border-l-delta: var(--bulma-button-hover-border-l-delta);
}

.button:focus-visible, .button.is-focused {
  --bulma-button-border-width: 1px;
  border-color: hsl(var(--bulma-focus-h), var(--bulma-focus-s), var(--bulma-focus-l));
  box-shadow: var(--bulma-focus-shadow-size) hsla(var(--bulma-focus-h), var(--bulma-focus-s), var(--bulma-focus-l), var(--bulma-focus-shadow-alpha));
}

.button:active, .button.is-active {
  --bulma-button-background-l-delta: var(--bulma-button-active-background-l-delta);
  --bulma-button-border-l-delta: var(--bulma-button-active-border-l-delta);
  --bulma-button-outer-shadow-a: 0;
}

.button[disabled], fieldset[disabled] .button {
  background-color: var(--bulma-button-disabled-background-color);
  border-color: var(--bulma-button-disabled-border-color);
  box-shadow: var(--bulma-button-disabled-shadow);
  opacity: var(--bulma-button-disabled-opacity);
}

.button.is-white {
  --bulma-button-h: var(--bulma-white-h);
  --bulma-button-s: var(--bulma-white-s);
  --bulma-button-l: var(--bulma-white-l);
  --bulma-button-background-l: var(--bulma-white-l);
  --bulma-button-border-l: var(--bulma-white-l);
  --bulma-button-border-width: 0px;
  --bulma-button-color-l: var(--bulma-white-invert-l);
  --bulma-button-outer-shadow-a: 0;
}

.button.is-white:focus-visible, .button.is-white.is-focused {
  --bulma-button-border-width: 1px;
}

.button.is-white.is-soft {
  --bulma-button-background-l: var(--bulma-soft-l);
  --bulma-button-color-l: var(--bulma-soft-invert-l);
}

.button.is-white.is-bold {
  --bulma-button-background-l: var(--bulma-bold-l);
  --bulma-button-color-l: var(--bulma-bold-invert-l);
}

.button.is-white[disabled], fieldset[disabled] .button.is-white {
  background-color: var(--bulma-white);
  border-color: var(--bulma-white);
  box-shadow: none;
}

.button.is-black {
  --bulma-button-h: var(--bulma-black-h);
  --bulma-button-s: var(--bulma-black-s);
  --bulma-button-l: var(--bulma-black-l);
  --bulma-button-background-l: var(--bulma-black-l);
  --bulma-button-border-l: var(--bulma-black-l);
  --bulma-button-border-width: 0px;
  --bulma-button-color-l: var(--bulma-black-invert-l);
  --bulma-button-outer-shadow-a: 0;
}

.button.is-black:focus-visible, .button.is-black.is-focused {
  --bulma-button-border-width: 1px;
}

.button.is-black.is-soft {
  --bulma-button-background-l: var(--bulma-soft-l);
  --bulma-button-color-l: var(--bulma-soft-invert-l);
}

.button.is-black.is-bold {
  --bulma-button-background-l: var(--bulma-bold-l);
  --bulma-button-color-l: var(--bulma-bold-invert-l);
}

.button.is-black[disabled], fieldset[disabled] .button.is-black {
  background-color: var(--bulma-black);
  border-color: var(--bulma-black);
  box-shadow: none;
}

.button.is-light {
  --bulma-button-h: var(--bulma-light-h);
  --bulma-button-s: var(--bulma-light-s);
  --bulma-button-l: var(--bulma-light-l);
  --bulma-button-background-l: var(--bulma-light-l);
  --bulma-button-border-l: var(--bulma-light-l);
  --bulma-button-border-width: 0px;
  --bulma-button-color-l: var(--bulma-light-invert-l);
  --bulma-button-outer-shadow-a: 0;
}

.button.is-light:focus-visible, .button.is-light.is-focused {
  --bulma-button-border-width: 1px;
}

.button.is-light.is-soft {
  --bulma-button-background-l: var(--bulma-soft-l);
  --bulma-button-color-l: var(--bulma-soft-invert-l);
}

.button.is-light.is-bold {
  --bulma-button-background-l: var(--bulma-bold-l);
  --bulma-button-color-l: var(--bulma-bold-invert-l);
}

.button.is-light[disabled], fieldset[disabled] .button.is-light {
  background-color: var(--bulma-light);
  border-color: var(--bulma-light);
  box-shadow: none;
}

.button.is-dark {
  --bulma-button-h: var(--bulma-dark-h);
  --bulma-button-s: var(--bulma-dark-s);
  --bulma-button-l: var(--bulma-dark-l);
  --bulma-button-background-l: var(--bulma-dark-l);
  --bulma-button-border-l: var(--bulma-dark-l);
  --bulma-button-border-width: 0px;
  --bulma-button-color-l: var(--bulma-dark-invert-l);
  --bulma-button-outer-shadow-a: 0;
}

.button.is-dark:focus-visible, .button.is-dark.is-focused {
  --bulma-button-border-width: 1px;
}

.button.is-dark.is-soft {
  --bulma-button-background-l: var(--bulma-soft-l);
  --bulma-button-color-l: var(--bulma-soft-invert-l);
}

.button.is-dark.is-bold {
  --bulma-button-background-l: var(--bulma-bold-l);
  --bulma-button-color-l: var(--bulma-bold-invert-l);
}

.button.is-dark[disabled], fieldset[disabled] .button.is-dark {
  background-color: var(--bulma-dark);
  border-color: var(--bulma-dark);
  box-shadow: none;
}

.button.is-text {
  --bulma-button-h: var(--bulma-text-h);
  --bulma-button-s: var(--bulma-text-s);
  --bulma-button-l: var(--bulma-text-l);
  --bulma-button-background-l: var(--bulma-text-l);
  --bulma-button-border-l: var(--bulma-text-l);
  --bulma-button-border-width: 0px;
  --bulma-button-color-l: var(--bulma-text-invert-l);
  --bulma-button-outer-shadow-a: 0;
}

.button.is-text:focus-visible, .button.is-text.is-focused {
  --bulma-button-border-width: 1px;
}

.button.is-text.is-light {
  --bulma-button-background-l: var(--bulma-light-l);
  --bulma-button-color-l: var(--bulma-text-light-invert-l);
}

.button.is-text.is-dark {
  --bulma-button-background-l: var(--bulma-dark-l);
  --bulma-button-color-l: var(--bulma-text-dark-invert-l);
}

.button.is-text.is-soft {
  --bulma-button-background-l: var(--bulma-soft-l);
  --bulma-button-color-l: var(--bulma-soft-invert-l);
}

.button.is-text.is-bold {
  --bulma-button-background-l: var(--bulma-bold-l);
  --bulma-button-color-l: var(--bulma-bold-invert-l);
}

.button.is-text[disabled], fieldset[disabled] .button.is-text {
  background-color: var(--bulma-text);
  border-color: var(--bulma-text);
  box-shadow: none;
}

.button.is-primary {
  --bulma-button-h: var(--bulma-primary-h);
  --bulma-button-s: var(--bulma-primary-s);
  --bulma-button-l: var(--bulma-primary-l);
  --bulma-button-background-l: var(--bulma-primary-l);
  --bulma-button-border-l: var(--bulma-primary-l);
  --bulma-button-border-width: 0px;
  --bulma-button-color-l: var(--bulma-primary-invert-l);
  --bulma-button-outer-shadow-a: 0;
}

.button.is-primary:focus-visible, .button.is-primary.is-focused {
  --bulma-button-border-width: 1px;
}

.button.is-primary.is-light {
  --bulma-button-background-l: var(--bulma-light-l);
  --bulma-button-color-l: var(--bulma-primary-light-invert-l);
}

.button.is-primary.is-dark {
  --bulma-button-background-l: var(--bulma-dark-l);
  --bulma-button-color-l: var(--bulma-primary-dark-invert-l);
}

.button.is-primary.is-soft {
  --bulma-button-background-l: var(--bulma-soft-l);
  --bulma-button-color-l: var(--bulma-soft-invert-l);
}

.button.is-primary.is-bold {
  --bulma-button-background-l: var(--bulma-bold-l);
  --bulma-button-color-l: var(--bulma-bold-invert-l);
}

.button.is-primary[disabled], fieldset[disabled] .button.is-primary {
  background-color: var(--bulma-primary);
  border-color: var(--bulma-primary);
  box-shadow: none;
}

.button.is-link {
  --bulma-button-h: var(--bulma-link-h);
  --bulma-button-s: var(--bulma-link-s);
  --bulma-button-l: var(--bulma-link-l);
  --bulma-button-background-l: var(--bulma-link-l);
  --bulma-button-border-l: var(--bulma-link-l);
  --bulma-button-border-width: 0px;
  --bulma-button-color-l: var(--bulma-link-invert-l);
  --bulma-button-outer-shadow-a: 0;
}

.button.is-link:focus-visible, .button.is-link.is-focused {
  --bulma-button-border-width: 1px;
}

.button.is-link.is-light {
  --bulma-button-background-l: var(--bulma-light-l);
  --bulma-button-color-l: var(--bulma-link-light-invert-l);
}

.button.is-link.is-dark {
  --bulma-button-background-l: var(--bulma-dark-l);
  --bulma-button-color-l: var(--bulma-link-dark-invert-l);
}

.button.is-link.is-soft {
  --bulma-button-background-l: var(--bulma-soft-l);
  --bulma-button-color-l: var(--bulma-soft-invert-l);
}

.button.is-link.is-bold {
  --bulma-button-background-l: var(--bulma-bold-l);
  --bulma-button-color-l: var(--bulma-bold-invert-l);
}

.button.is-link[disabled], fieldset[disabled] .button.is-link {
  background-color: var(--bulma-link);
  border-color: var(--bulma-link);
  box-shadow: none;
}

.button.is-info {
  --bulma-button-h: var(--bulma-info-h);
  --bulma-button-s: var(--bulma-info-s);
  --bulma-button-l: var(--bulma-info-l);
  --bulma-button-background-l: var(--bulma-info-l);
  --bulma-button-border-l: var(--bulma-info-l);
  --bulma-button-border-width: 0px;
  --bulma-button-color-l: var(--bulma-info-invert-l);
  --bulma-button-outer-shadow-a: 0;
}

.button.is-info:focus-visible, .button.is-info.is-focused {
  --bulma-button-border-width: 1px;
}

.button.is-info.is-light {
  --bulma-button-background-l: var(--bulma-light-l);
  --bulma-button-color-l: var(--bulma-info-light-invert-l);
}

.button.is-info.is-dark {
  --bulma-button-background-l: var(--bulma-dark-l);
  --bulma-button-color-l: var(--bulma-info-dark-invert-l);
}

.button.is-info.is-soft {
  --bulma-button-background-l: var(--bulma-soft-l);
  --bulma-button-color-l: var(--bulma-soft-invert-l);
}

.button.is-info.is-bold {
  --bulma-button-background-l: var(--bulma-bold-l);
  --bulma-button-color-l: var(--bulma-bold-invert-l);
}

.button.is-info[disabled], fieldset[disabled] .button.is-info {
  background-color: var(--bulma-info);
  border-color: var(--bulma-info);
  box-shadow: none;
}

.button.is-success {
  --bulma-button-h: var(--bulma-success-h);
  --bulma-button-s: var(--bulma-success-s);
  --bulma-button-l: var(--bulma-success-l);
  --bulma-button-background-l: var(--bulma-success-l);
  --bulma-button-border-l: var(--bulma-success-l);
  --bulma-button-border-width: 0px;
  --bulma-button-color-l: var(--bulma-success-invert-l);
  --bulma-button-outer-shadow-a: 0;
}

.button.is-success:focus-visible, .button.is-success.is-focused {
  --bulma-button-border-width: 1px;
}

.button.is-success.is-light {
  --bulma-button-background-l: var(--bulma-light-l);
  --bulma-button-color-l: var(--bulma-success-light-invert-l);
}

.button.is-success.is-dark {
  --bulma-button-background-l: var(--bulma-dark-l);
  --bulma-button-color-l: var(--bulma-success-dark-invert-l);
}

.button.is-success.is-soft {
  --bulma-button-background-l: var(--bulma-soft-l);
  --bulma-button-color-l: var(--bulma-soft-invert-l);
}

.button.is-success.is-bold {
  --bulma-button-background-l: var(--bulma-bold-l);
  --bulma-button-color-l: var(--bulma-bold-invert-l);
}

.button.is-success[disabled], fieldset[disabled] .button.is-success {
  background-color: var(--bulma-success);
  border-color: var(--bulma-success);
  box-shadow: none;
}

.button.is-warning {
  --bulma-button-h: var(--bulma-warning-h);
  --bulma-button-s: var(--bulma-warning-s);
  --bulma-button-l: var(--bulma-warning-l);
  --bulma-button-background-l: var(--bulma-warning-l);
  --bulma-button-border-l: var(--bulma-warning-l);
  --bulma-button-border-width: 0px;
  --bulma-button-color-l: var(--bulma-warning-invert-l);
  --bulma-button-outer-shadow-a: 0;
}

.button.is-warning:focus-visible, .button.is-warning.is-focused {
  --bulma-button-border-width: 1px;
}

.button.is-warning.is-light {
  --bulma-button-background-l: var(--bulma-light-l);
  --bulma-button-color-l: var(--bulma-warning-light-invert-l);
}

.button.is-warning.is-dark {
  --bulma-button-background-l: var(--bulma-dark-l);
  --bulma-button-color-l: var(--bulma-warning-dark-invert-l);
}

.button.is-warning.is-soft {
  --bulma-button-background-l: var(--bulma-soft-l);
  --bulma-button-color-l: var(--bulma-soft-invert-l);
}

.button.is-warning.is-bold {
  --bulma-button-background-l: var(--bulma-bold-l);
  --bulma-button-color-l: var(--bulma-bold-invert-l);
}

.button.is-warning[disabled], fieldset[disabled] .button.is-warning {
  background-color: var(--bulma-warning);
  border-color: var(--bulma-warning);
  box-shadow: none;
}

.button.is-danger {
  --bulma-button-h: var(--bulma-danger-h);
  --bulma-button-s: var(--bulma-danger-s);
  --bulma-button-l: var(--bulma-danger-l);
  --bulma-button-background-l: var(--bulma-danger-l);
  --bulma-button-border-l: var(--bulma-danger-l);
  --bulma-button-border-width: 0px;
  --bulma-button-color-l: var(--bulma-danger-invert-l);
  --bulma-button-outer-shadow-a: 0;
}

.button.is-danger:focus-visible, .button.is-danger.is-focused {
  --bulma-button-border-width: 1px;
}

.button.is-danger.is-light {
  --bulma-button-background-l: var(--bulma-light-l);
  --bulma-button-color-l: var(--bulma-danger-light-invert-l);
}

.button.is-danger.is-dark {
  --bulma-button-background-l: var(--bulma-dark-l);
  --bulma-button-color-l: var(--bulma-danger-dark-invert-l);
}

.button.is-danger.is-soft {
  --bulma-button-background-l: var(--bulma-soft-l);
  --bulma-button-color-l: var(--bulma-soft-invert-l);
}

.button.is-danger.is-bold {
  --bulma-button-background-l: var(--bulma-bold-l);
  --bulma-button-color-l: var(--bulma-bold-invert-l);
}

.button.is-danger[disabled], fieldset[disabled] .button.is-danger {
  background-color: var(--bulma-danger);
  border-color: var(--bulma-danger);
  box-shadow: none;
}

.button.is-outlined {
  --bulma-button-border-width: max(1px, .0625em);
  border-color: hsl(var(--bulma-button-h), var(--bulma-button-s), var(--bulma-button-l));
  color: hsl(var(--bulma-button-h), var(--bulma-button-s), var(--bulma-button-l));
  background-color: #0000;
}

.button.is-outlined:hover {
  --bulma-button-border-width: max(2px, .125em);
  --bulma-button-outer-shadow-alpha: 1;
}

.button.is-outlined[disabled], fieldset[disabled] .button.is-outlined {
  box-shadow: none;
  background-color: #0000;
}

.button.is-inverted {
  background-color: hsl(var(--bulma-button-h), var(--bulma-button-s), calc(var(--bulma-button-color-l)  + var(--bulma-button-background-l-delta)));
  color: hsl(var(--bulma-button-h), var(--bulma-button-s), var(--bulma-button-background-l));
}

.button.is-text {
  color: var(--bulma-button-text-color);
  -webkit-text-decoration: var(--bulma-button-text-decoration);
  text-decoration: var(--bulma-button-text-decoration);
  background-color: #0000;
  border-color: #0000;
}

.button.is-text:hover, .button.is-text.is-hovered {
  background-color: var(--bulma-button-text-hover-background-color);
  color: var(--bulma-button-text-hover-color);
}

.button.is-text:active, .button.is-text.is-active {
  color: var(--bulma-button-text-hover-color);
}

.button.is-text[disabled], fieldset[disabled] .button.is-text {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
}

.button.is-ghost {
  background: var(--bulma-button-ghost-background);
  border-color: var(--bulma-button-ghost-border-color);
  box-shadow: none;
  color: var(--bulma-button-ghost-color);
  -webkit-text-decoration: var(--bulma-button-ghost-decoration);
  text-decoration: var(--bulma-button-ghost-decoration);
}

.button.is-ghost:hover, .button.is-ghost.is-hovered {
  color: var(--bulma-button-ghost-hover-color);
  -webkit-text-decoration: var(--bulma-button-ghost-hover-decoration);
  text-decoration: var(--bulma-button-ghost-hover-decoration);
}

.button.is-small {
  --bulma-control-size: var(--bulma-size-small);
  --bulma-control-radius: var(--bulma-radius-small);
}

.button.is-normal {
  --bulma-control-size: var(--bulma-size-normal);
  --bulma-control-radius: var(--bulma-radius);
}

.button.is-medium {
  --bulma-control-size: var(--bulma-size-medium);
  --bulma-control-radius: var(--bulma-radius-medium);
}

.button.is-large {
  --bulma-control-size: var(--bulma-size-large);
  --bulma-control-radius: var(--bulma-radius-medium);
}

.button.is-fullwidth {
  width: 100%;
  display: flex;
}

.button.is-loading {
  box-shadow: none;
  pointer-events: none;
  color: #0000 !important;
}

.button.is-loading:after {
  position: absolute;
  top: calc(50% - .5em);
  left: calc(50% - .5em);
  position: absolute !important;
}

.button.is-static {
  background-color: var(--bulma-button-static-background-color);
  border-color: var(--bulma-button-static-border-color);
  color: var(--bulma-button-static-color);
  box-shadow: none;
  pointer-events: none;
}

.button.is-rounded {
  border-radius: var(--bulma-radius-rounded);
  padding-left: calc(var(--bulma-button-padding-horizontal)  + .25em - var(--bulma-button-border-width));
  padding-right: calc(var(--bulma-button-padding-horizontal)  + .25em - var(--bulma-button-border-width));
}

.buttons {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.buttons.are-small {
  --bulma-control-size: var(--bulma-size-small);
  --bulma-control-radius: var(--bulma-radius-small);
}

.buttons.are-medium {
  --bulma-control-size: var(--bulma-size-medium);
  --bulma-control-radius: var(--bulma-radius-medium);
}

.buttons.are-large {
  --bulma-control-size: var(--bulma-size-large);
  --bulma-control-radius: var(--bulma-radius-large);
}

.buttons.has-addons {
  gap: 0;
}

.buttons.has-addons .button:not(:first-child) {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.buttons.has-addons .button:not(:last-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  margin-inline-end: -1px;
}

.buttons.has-addons .button:hover, .buttons.has-addons .button.is-hovered {
  z-index: 2;
}

.buttons.has-addons .button:focus, .buttons.has-addons .button.is-focused, .buttons.has-addons .button:active, .buttons.has-addons .button.is-active, .buttons.has-addons .button.is-selected {
  z-index: 3;
}

.buttons.has-addons .button:focus:hover, .buttons.has-addons .button.is-focused:hover, .buttons.has-addons .button:active:hover, .buttons.has-addons .button.is-active:hover, .buttons.has-addons .button.is-selected:hover {
  z-index: 4;
}

.buttons.has-addons .button.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.buttons.is-centered {
  justify-content: center;
}

.buttons.is-right {
  justify-content: flex-end;
}

@media screen and (width <= 768px) {
  .button.is-responsive.is-small {
    font-size: calc(var(--bulma-size-small) * .75);
  }

  .button.is-responsive, .button.is-responsive.is-normal {
    font-size: calc(var(--bulma-size-small) * .875);
  }

  .button.is-responsive.is-medium {
    font-size: var(--bulma-size-small);
  }

  .button.is-responsive.is-large {
    font-size: var(--bulma-size-normal);
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .button.is-responsive.is-small {
    font-size: calc(var(--bulma-size-small) * .875);
  }

  .button.is-responsive, .button.is-responsive.is-normal {
    font-size: var(--bulma-size-small);
  }

  .button.is-responsive.is-medium {
    font-size: var(--bulma-size-normal);
  }

  .button.is-responsive.is-large {
    font-size: var(--bulma-size-medium);
  }
}

.content {
  --bulma-content-heading-color: var(--bulma-text-strong);
  --bulma-content-heading-weight: var(--bulma-weight-extrabold);
  --bulma-content-heading-line-height: 1.125;
  --bulma-content-block-margin-bottom: 1em;
  --bulma-content-blockquote-background-color: var(--bulma-background);
  --bulma-content-blockquote-border-left: 5px solid var(--bulma-border);
  --bulma-content-blockquote-padding: 1.25em 1.5em;
  --bulma-content-pre-padding: 1.25em 1.5em;
  --bulma-content-table-cell-border: 1px solid var(--bulma-border);
  --bulma-content-table-cell-border-width: 0 0 1px;
  --bulma-content-table-cell-padding: .5em .75em;
  --bulma-content-table-cell-heading-color: var(--bulma-text-strong);
  --bulma-content-table-head-cell-border-width: 0 0 2px;
  --bulma-content-table-head-cell-color: var(--bulma-text-strong);
  --bulma-content-table-body-last-row-cell-border-bottom-width: 0;
  --bulma-content-table-foot-cell-border-width: 2px 0 0;
  --bulma-content-table-foot-cell-color: var(--bulma-text-strong);
}

.content li + li {
  margin-top: .25em;
}

.content p:not(:last-child), .content dl:not(:last-child), .content ol:not(:last-child), .content ul:not(:last-child), .content blockquote:not(:last-child), .content pre:not(:last-child), .content table:not(:last-child) {
  margin-bottom: var(--bulma-content-block-margin-bottom);
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  color: var(--bulma-content-heading-color);
  font-weight: var(--bulma-content-heading-weight);
  line-height: var(--bulma-content-heading-line-height);
}

.content h1 {
  margin-bottom: .5em;
  font-size: 2em;
}

.content h1:not(:first-child) {
  margin-top: 1em;
}

.content h2 {
  margin-bottom: .5714em;
  font-size: 1.75em;
}

.content h2:not(:first-child) {
  margin-top: 1.1428em;
}

.content h3 {
  margin-bottom: .6666em;
  font-size: 1.5em;
}

.content h3:not(:first-child) {
  margin-top: 1.3333em;
}

.content h4 {
  margin-bottom: .8em;
  font-size: 1.25em;
}

.content h5 {
  margin-bottom: .8888em;
  font-size: 1.125em;
}

.content h6 {
  margin-bottom: 1em;
  font-size: 1em;
}

.content blockquote {
  background-color: var(--bulma-content-blockquote-background-color);
  border-inline-start: var(--bulma-content-blockquote-border-left);
  padding: var(--bulma-content-blockquote-padding);
}

.content ol {
  margin-inline-start: 2em;
  list-style-position: outside;
}

.content ol:not(:first-child) {
  margin-top: 1em;
}

.content ol:not([type]) {
  list-style-type: decimal;
}

.content ol:not([type]).is-lower-alpha {
  list-style-type: lower-alpha;
}

.content ol:not([type]).is-lower-roman {
  list-style-type: lower-roman;
}

.content ol:not([type]).is-upper-alpha {
  list-style-type: upper-alpha;
}

.content ol:not([type]).is-upper-roman {
  list-style-type: upper-roman;
}

.content ul {
  margin-inline-start: 2em;
  list-style: outside;
}

.content ul:not(:first-child) {
  margin-top: 1em;
}

.content ul ul {
  margin-top: .25em;
  margin-bottom: .25em;
  list-style-type: circle;
}

.content ul ul ul {
  list-style-type: square;
}

.content dd {
  margin-inline-start: 2em;
}

.content figure:not([class]) {
  text-align: center;
  margin-left: 2em;
  margin-right: 2em;
}

.content figure:not([class]):not(:first-child) {
  margin-top: 2em;
}

.content figure:not([class]):not(:last-child) {
  margin-bottom: 2em;
}

.content figure:not([class]) img {
  display: inline-block;
}

.content figure:not([class]) figcaption {
  font-style: italic;
}

.content pre {
  -webkit-overflow-scrolling: touch;
  padding: var(--bulma-content-pre-padding);
  white-space: pre;
  word-wrap: normal;
  overflow-x: auto;
}

.content sup, .content sub {
  font-size: 75%;
}

.content table td, .content table th {
  border: var(--bulma-content-table-cell-border);
  border-width: var(--bulma-content-table-cell-border-width);
  padding: var(--bulma-content-table-cell-padding);
  vertical-align: top;
}

.content table th {
  color: var(--bulma-content-table-cell-heading-color);
}

.content table th:not([align]) {
  text-align: inherit;
}

.content table thead td, .content table thead th {
  border-width: var(--bulma-content-table-head-cell-border-width);
  color: var(--bulma-content-table-head-cell-color);
}

.content table tfoot td, .content table tfoot th {
  border-width: var(--bulma-content-table-foot-cell-border-width);
  color: var(--bulma-content-table-foot-cell-color);
}

.content table tbody tr:last-child td, .content table tbody tr:last-child th {
  border-bottom-width: var(--bulma-content-table-body-last-row-cell-border-bottom-width);
}

.content .tabs li + li {
  margin-top: 0;
}

.content.is-small {
  font-size: var(--bulma-size-small);
}

.content.is-normal {
  font-size: var(--bulma-size-normal);
}

.content.is-medium {
  font-size: var(--bulma-size-medium);
}

.content.is-large {
  font-size: var(--bulma-size-large);
}

.delete {
  --bulma-delete-dimensions: 1.25rem;
  --bulma-delete-background-l: 0%;
  --bulma-delete-background-alpha: .5;
  --bulma-delete-color: var(--bulma-white);
  appearance: none;
  background-color: hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-delete-background-l), var(--bulma-delete-background-alpha));
  border-radius: var(--bulma-radius-rounded);
  cursor: pointer;
  pointer-events: auto;
  height: var(--bulma-delete-dimensions);
  max-height: var(--bulma-delete-dimensions);
  max-width: var(--bulma-delete-dimensions);
  min-height: var(--bulma-delete-dimensions);
  min-width: var(--bulma-delete-dimensions);
  vertical-align: top;
  width: var(--bulma-delete-dimensions);
  border: none;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 1em;
  display: inline-flex;
  position: relative;
}

.delete:before, .delete:after {
  background-color: var(--bulma-delete-color);
  content: "";
  transform-origin: center;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.delete:before {
  width: 50%;
  height: 2px;
}

.delete:after {
  width: 2px;
  height: 50%;
}

.delete:hover, .delete:focus {
  --bulma-delete-background-alpha: .4;
}

.delete:active {
  --bulma-delete-background-alpha: .5;
}

.delete.is-small {
  --bulma-delete-dimensions: 1rem;
}

.delete.is-medium {
  --bulma-delete-dimensions: 1.5rem;
}

.delete.is-large {
  --bulma-delete-dimensions: 2rem;
}

.icon, .icon-text {
  --bulma-icon-dimensions: 1.5rem;
  --bulma-icon-dimensions-small: 1rem;
  --bulma-icon-dimensions-medium: 2rem;
  --bulma-icon-dimensions-large: 3rem;
  --bulma-icon-text-spacing: .25em;
}

.icon {
  height: var(--bulma-icon-dimensions);
  transition-duration: var(--bulma-duration);
  width: var(--bulma-icon-dimensions);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  transition-property: color;
  display: inline-flex;
}

.icon.is-small {
  height: var(--bulma-icon-dimensions-small);
  width: var(--bulma-icon-dimensions-small);
}

.icon.is-medium {
  height: var(--bulma-icon-dimensions-medium);
  width: var(--bulma-icon-dimensions-medium);
}

.icon.is-large {
  height: var(--bulma-icon-dimensions-large);
  width: var(--bulma-icon-dimensions-large);
}

.icon-text {
  color: inherit;
  align-items: flex-start;
  gap: var(--bulma-icon-text-spacing);
  line-height: var(--bulma-icon-dimensions);
  vertical-align: top;
  flex-wrap: wrap;
  display: inline-flex;
}

.icon-text .icon {
  flex-grow: 0;
  flex-shrink: 0;
}

div.icon-text {
  display: flex;
}

.image {
  display: block;
  position: relative;
}

.image img {
  width: 100%;
  height: auto;
  display: block;
}

.image img.is-rounded {
  border-radius: var(--bulma-radius-rounded);
}

.image.is-fullwidth {
  width: 100%;
}

.image.is-square img, .image.is-square .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-square, .image.is-1by1 {
  aspect-ratio: 1;
}

.image.is-1by1 img, .image.is-1by1 .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-5by4 {
  aspect-ratio: 5 / 4;
}

.image.is-5by4 img, .image.is-5by4 .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-4by3 {
  aspect-ratio: 4 / 3;
}

.image.is-4by3 img, .image.is-4by3 .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-3by2 {
  aspect-ratio: 3 / 2;
}

.image.is-3by2 img, .image.is-3by2 .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-5by3 {
  aspect-ratio: 5 / 3;
}

.image.is-5by3 img, .image.is-5by3 .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-16by9 {
  aspect-ratio: 16 / 9;
}

.image.is-16by9 img, .image.is-16by9 .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-2by1 {
  aspect-ratio: 2;
}

.image.is-2by1 img, .image.is-2by1 .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-3by1 {
  aspect-ratio: 3;
}

.image.is-3by1 img, .image.is-3by1 .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-4by5 {
  aspect-ratio: 4 / 5;
}

.image.is-4by5 img, .image.is-4by5 .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-3by4 {
  aspect-ratio: 3 / 4;
}

.image.is-3by4 img, .image.is-3by4 .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-2by3 {
  aspect-ratio: 2 / 3;
}

.image.is-2by3 img, .image.is-2by3 .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-3by5 {
  aspect-ratio: 3 / 5;
}

.image.is-3by5 img, .image.is-3by5 .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-9by16 {
  aspect-ratio: 9 / 16;
}

.image.is-9by16 img, .image.is-9by16 .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-1by2 {
  aspect-ratio: 1 / 2;
}

.image.is-1by2 img, .image.is-1by2 .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-1by3 {
  aspect-ratio: 1 / 3;
}

.image.is-1by3 img, .image.is-1by3 .has-ratio {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-16x16 {
  width: 16px;
  height: 16px;
}

.image.is-24x24 {
  width: 24px;
  height: 24px;
}

.image.is-32x32 {
  width: 32px;
  height: 32px;
}

.image.is-48x48 {
  width: 48px;
  height: 48px;
}

.image.is-64x64 {
  width: 64px;
  height: 64px;
}

.image.is-96x96 {
  width: 96px;
  height: 96px;
}

.image.is-128x128 {
  width: 128px;
  height: 128px;
}

.loader {
  border: 2px solid var(--bulma-border);
  border-radius: var(--bulma-radius-rounded);
  content: "";
  border-top-color: #0000;
  border-right-color: #0000;
  width: 1em;
  height: 1em;
  animation: .5s linear infinite spinAround;
  display: block;
  position: relative;
}

.notification {
  --bulma-notification-h: var(--bulma-scheme-h);
  --bulma-notification-s: var(--bulma-scheme-s);
  --bulma-notification-background-l: var(--bulma-background-l);
  --bulma-notification-color-l: var(--bulma-text-strong-l);
  --bulma-notification-code-background-color: var(--bulma-scheme-main);
  --bulma-notification-radius: var(--bulma-radius);
  --bulma-notification-padding: 1.375em 1.5em;
  background-color: hsl(var(--bulma-notification-h), var(--bulma-notification-s), var(--bulma-notification-background-l));
  border-radius: var(--bulma-notification-radius);
  color: hsl(var(--bulma-notification-h), var(--bulma-notification-s), var(--bulma-notification-color-l));
  padding: var(--bulma-notification-padding);
  position: relative;
}

.notification a:not(.button):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.notification strong {
  color: currentColor;
}

.notification code, .notification pre {
  background: var(--bulma-notification-code-background-color);
}

.notification pre code {
  background: none;
}

.notification > .delete {
  inset-inline-end: 1rem;
  position: absolute;
  top: 1rem;
}

.notification .title, .notification .subtitle, .notification .content {
  color: currentColor;
}

.notification.is-white {
  --bulma-notification-h: var(--bulma-white-h);
  --bulma-notification-s: var(--bulma-white-s);
  --bulma-notification-background-l: var(--bulma-white-l);
  --bulma-notification-color-l: var(--bulma-white-invert-l);
}

.notification.is-white.is-light {
  --bulma-notification-background-l: 90%;
  --bulma-notification-color-l: var(--bulma-white-light-invert-l);
}

.notification.is-white.is-dark {
  --bulma-notification-background-l: 20%;
  --bulma-notification-color-l: var(--bulma-white-dark-invert-l);
}

.notification.is-black {
  --bulma-notification-h: var(--bulma-black-h);
  --bulma-notification-s: var(--bulma-black-s);
  --bulma-notification-background-l: var(--bulma-black-l);
  --bulma-notification-color-l: var(--bulma-black-invert-l);
}

.notification.is-black.is-light {
  --bulma-notification-background-l: 90%;
  --bulma-notification-color-l: var(--bulma-black-light-invert-l);
}

.notification.is-black.is-dark {
  --bulma-notification-background-l: 20%;
  --bulma-notification-color-l: var(--bulma-black-dark-invert-l);
}

.notification.is-light {
  --bulma-notification-h: var(--bulma-light-h);
  --bulma-notification-s: var(--bulma-light-s);
  --bulma-notification-background-l: var(--bulma-light-l);
  --bulma-notification-color-l: var(--bulma-light-invert-l);
}

.notification.is-light.is-light {
  --bulma-notification-background-l: 90%;
  --bulma-notification-color-l: var(--bulma-light-light-invert-l);
}

.notification.is-light.is-dark {
  --bulma-notification-background-l: 20%;
  --bulma-notification-color-l: var(--bulma-light-dark-invert-l);
}

.notification.is-dark {
  --bulma-notification-h: var(--bulma-dark-h);
  --bulma-notification-s: var(--bulma-dark-s);
  --bulma-notification-background-l: var(--bulma-dark-l);
  --bulma-notification-color-l: var(--bulma-dark-invert-l);
}

.notification.is-dark.is-light {
  --bulma-notification-background-l: 90%;
  --bulma-notification-color-l: var(--bulma-dark-light-invert-l);
}

.notification.is-dark.is-dark {
  --bulma-notification-background-l: 20%;
  --bulma-notification-color-l: var(--bulma-dark-dark-invert-l);
}

.notification.is-text {
  --bulma-notification-h: var(--bulma-text-h);
  --bulma-notification-s: var(--bulma-text-s);
  --bulma-notification-background-l: var(--bulma-text-l);
  --bulma-notification-color-l: var(--bulma-text-invert-l);
}

.notification.is-text.is-light {
  --bulma-notification-background-l: 90%;
  --bulma-notification-color-l: var(--bulma-text-light-invert-l);
}

.notification.is-text.is-dark {
  --bulma-notification-background-l: 20%;
  --bulma-notification-color-l: var(--bulma-text-dark-invert-l);
}

.notification.is-primary {
  --bulma-notification-h: var(--bulma-primary-h);
  --bulma-notification-s: var(--bulma-primary-s);
  --bulma-notification-background-l: var(--bulma-primary-l);
  --bulma-notification-color-l: var(--bulma-primary-invert-l);
}

.notification.is-primary.is-light {
  --bulma-notification-background-l: 90%;
  --bulma-notification-color-l: var(--bulma-primary-light-invert-l);
}

.notification.is-primary.is-dark {
  --bulma-notification-background-l: 20%;
  --bulma-notification-color-l: var(--bulma-primary-dark-invert-l);
}

.notification.is-link {
  --bulma-notification-h: var(--bulma-link-h);
  --bulma-notification-s: var(--bulma-link-s);
  --bulma-notification-background-l: var(--bulma-link-l);
  --bulma-notification-color-l: var(--bulma-link-invert-l);
}

.notification.is-link.is-light {
  --bulma-notification-background-l: 90%;
  --bulma-notification-color-l: var(--bulma-link-light-invert-l);
}

.notification.is-link.is-dark {
  --bulma-notification-background-l: 20%;
  --bulma-notification-color-l: var(--bulma-link-dark-invert-l);
}

.notification.is-info {
  --bulma-notification-h: var(--bulma-info-h);
  --bulma-notification-s: var(--bulma-info-s);
  --bulma-notification-background-l: var(--bulma-info-l);
  --bulma-notification-color-l: var(--bulma-info-invert-l);
}

.notification.is-info.is-light {
  --bulma-notification-background-l: 90%;
  --bulma-notification-color-l: var(--bulma-info-light-invert-l);
}

.notification.is-info.is-dark {
  --bulma-notification-background-l: 20%;
  --bulma-notification-color-l: var(--bulma-info-dark-invert-l);
}

.notification.is-success {
  --bulma-notification-h: var(--bulma-success-h);
  --bulma-notification-s: var(--bulma-success-s);
  --bulma-notification-background-l: var(--bulma-success-l);
  --bulma-notification-color-l: var(--bulma-success-invert-l);
}

.notification.is-success.is-light {
  --bulma-notification-background-l: 90%;
  --bulma-notification-color-l: var(--bulma-success-light-invert-l);
}

.notification.is-success.is-dark {
  --bulma-notification-background-l: 20%;
  --bulma-notification-color-l: var(--bulma-success-dark-invert-l);
}

.notification.is-warning {
  --bulma-notification-h: var(--bulma-warning-h);
  --bulma-notification-s: var(--bulma-warning-s);
  --bulma-notification-background-l: var(--bulma-warning-l);
  --bulma-notification-color-l: var(--bulma-warning-invert-l);
}

.notification.is-warning.is-light {
  --bulma-notification-background-l: 90%;
  --bulma-notification-color-l: var(--bulma-warning-light-invert-l);
}

.notification.is-warning.is-dark {
  --bulma-notification-background-l: 20%;
  --bulma-notification-color-l: var(--bulma-warning-dark-invert-l);
}

.notification.is-danger {
  --bulma-notification-h: var(--bulma-danger-h);
  --bulma-notification-s: var(--bulma-danger-s);
  --bulma-notification-background-l: var(--bulma-danger-l);
  --bulma-notification-color-l: var(--bulma-danger-invert-l);
}

.notification.is-danger.is-light {
  --bulma-notification-background-l: 90%;
  --bulma-notification-color-l: var(--bulma-danger-light-invert-l);
}

.notification.is-danger.is-dark {
  --bulma-notification-background-l: 20%;
  --bulma-notification-color-l: var(--bulma-danger-dark-invert-l);
}

.progress {
  --bulma-progress-border-radius: var(--bulma-radius-rounded);
  --bulma-progress-bar-background-color: var(--bulma-border-weak);
  --bulma-progress-value-background-color: var(--bulma-text);
  --bulma-progress-indeterminate-duration: 1.5s;
  appearance: none;
  border-radius: var(--bulma-progress-border-radius);
  height: var(--bulma-size-normal);
  border: none;
  width: 100%;
  padding: 0;
  display: block;
  overflow: hidden;
}

.progress::-webkit-progress-bar {
  background-color: var(--bulma-progress-bar-background-color);
}

.progress::-webkit-progress-value {
  background-color: var(--bulma-progress-value-background-color);
}

.progress::-moz-progress-bar {
  background-color: var(--bulma-progress-value-background-color);
}

.progress::-ms-fill {
  background-color: var(--bulma-progress-value-background-color);
  border: none;
}

.progress.is-white {
  --bulma-progress-value-background-color: var(--bulma-white);
}

.progress.is-black {
  --bulma-progress-value-background-color: var(--bulma-black);
}

.progress.is-light {
  --bulma-progress-value-background-color: var(--bulma-light);
}

.progress.is-dark {
  --bulma-progress-value-background-color: var(--bulma-dark);
}

.progress.is-text {
  --bulma-progress-value-background-color: var(--bulma-text);
}

.progress.is-primary {
  --bulma-progress-value-background-color: var(--bulma-primary);
}

.progress.is-link {
  --bulma-progress-value-background-color: var(--bulma-link);
}

.progress.is-info {
  --bulma-progress-value-background-color: var(--bulma-info);
}

.progress.is-success {
  --bulma-progress-value-background-color: var(--bulma-success);
}

.progress.is-warning {
  --bulma-progress-value-background-color: var(--bulma-warning);
}

.progress.is-danger {
  --bulma-progress-value-background-color: var(--bulma-danger);
}

.progress:indeterminate {
  animation-duration: var(--bulma-progress-indeterminate-duration);
  background-color: var(--bulma-progress-bar-background-color);
  background-image: linear-gradient(to right, var(--bulma-progress-value-background-color) 30%, var(--bulma-progress-bar-background-color) 30%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 150% 150%;
  animation-name: moveIndeterminate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.progress:indeterminate::-webkit-progress-bar {
  background-color: #0000;
}

.progress:indeterminate::-moz-progress-bar {
  background-color: #0000;
}

.progress:indeterminate::-ms-fill {
  animation-name: none;
}

.progress.is-small {
  height: var(--bulma-size-small);
}

.progress.is-medium {
  height: var(--bulma-size-medium);
}

.progress.is-large {
  height: var(--bulma-size-large);
}

@keyframes moveIndeterminate {
  from {
    background-position: 200% 0;
  }

  to {
    background-position: -200% 0;
  }
}

.table {
  --bulma-table-color: var(--bulma-text-strong);
  --bulma-table-background-color: var(--bulma-scheme-main);
  --bulma-table-cell-border-color: var(--bulma-border);
  --bulma-table-cell-border-style: solid;
  --bulma-table-cell-border-width: 0 0 1px;
  --bulma-table-cell-padding: .5em .75em;
  --bulma-table-cell-heading-color: var(--bulma-text-strong);
  --bulma-table-cell-text-align: left;
  --bulma-table-head-cell-border-width: 0 0 2px;
  --bulma-table-head-cell-color: var(--bulma-text-strong);
  --bulma-table-foot-cell-border-width: 2px 0 0;
  --bulma-table-foot-cell-color: var(--bulma-text-strong);
  --bulma-table-head-background-color: transparent;
  --bulma-table-body-background-color: transparent;
  --bulma-table-foot-background-color: transparent;
  --bulma-table-row-hover-background-color: var(--bulma-scheme-main-bis);
  --bulma-table-row-active-background-color: var(--bulma-primary);
  --bulma-table-row-active-color: var(--bulma-primary-invert);
  --bulma-table-striped-row-even-background-color: var(--bulma-scheme-main-bis);
  --bulma-table-striped-row-even-hover-background-color: var(--bulma-scheme-main-ter);
  background-color: var(--bulma-table-background-color);
  color: var(--bulma-table-color);
}

.table td, .table th {
  background-color: var(--bulma-table-cell-background-color);
  border-color: var(--bulma-table-cell-border-color);
  border-style: var(--bulma-table-cell-border-style);
  border-width: var(--bulma-table-cell-border-width);
  color: var(--bulma-table-color);
  padding: var(--bulma-table-cell-padding);
  vertical-align: top;
}

.table td.is-white, .table th.is-white {
  --bulma-table-color: var(--bulma-white-invert);
  --bulma-table-cell-heading-color: var(--bulma-white-invert);
  --bulma-table-cell-background-color: var(--bulma-white);
  --bulma-table-cell-border-color: var(--bulma-white);
}

.table td.is-black, .table th.is-black {
  --bulma-table-color: var(--bulma-black-invert);
  --bulma-table-cell-heading-color: var(--bulma-black-invert);
  --bulma-table-cell-background-color: var(--bulma-black);
  --bulma-table-cell-border-color: var(--bulma-black);
}

.table td.is-light, .table th.is-light {
  --bulma-table-color: var(--bulma-light-invert);
  --bulma-table-cell-heading-color: var(--bulma-light-invert);
  --bulma-table-cell-background-color: var(--bulma-light);
  --bulma-table-cell-border-color: var(--bulma-light);
}

.table td.is-dark, .table th.is-dark {
  --bulma-table-color: var(--bulma-dark-invert);
  --bulma-table-cell-heading-color: var(--bulma-dark-invert);
  --bulma-table-cell-background-color: var(--bulma-dark);
  --bulma-table-cell-border-color: var(--bulma-dark);
}

.table td.is-text, .table th.is-text {
  --bulma-table-color: var(--bulma-text-invert);
  --bulma-table-cell-heading-color: var(--bulma-text-invert);
  --bulma-table-cell-background-color: var(--bulma-text);
  --bulma-table-cell-border-color: var(--bulma-text);
}

.table td.is-primary, .table th.is-primary {
  --bulma-table-color: var(--bulma-primary-invert);
  --bulma-table-cell-heading-color: var(--bulma-primary-invert);
  --bulma-table-cell-background-color: var(--bulma-primary);
  --bulma-table-cell-border-color: var(--bulma-primary);
}

.table td.is-link, .table th.is-link {
  --bulma-table-color: var(--bulma-link-invert);
  --bulma-table-cell-heading-color: var(--bulma-link-invert);
  --bulma-table-cell-background-color: var(--bulma-link);
  --bulma-table-cell-border-color: var(--bulma-link);
}

.table td.is-info, .table th.is-info {
  --bulma-table-color: var(--bulma-info-invert);
  --bulma-table-cell-heading-color: var(--bulma-info-invert);
  --bulma-table-cell-background-color: var(--bulma-info);
  --bulma-table-cell-border-color: var(--bulma-info);
}

.table td.is-success, .table th.is-success {
  --bulma-table-color: var(--bulma-success-invert);
  --bulma-table-cell-heading-color: var(--bulma-success-invert);
  --bulma-table-cell-background-color: var(--bulma-success);
  --bulma-table-cell-border-color: var(--bulma-success);
}

.table td.is-warning, .table th.is-warning {
  --bulma-table-color: var(--bulma-warning-invert);
  --bulma-table-cell-heading-color: var(--bulma-warning-invert);
  --bulma-table-cell-background-color: var(--bulma-warning);
  --bulma-table-cell-border-color: var(--bulma-warning);
}

.table td.is-danger, .table th.is-danger {
  --bulma-table-color: var(--bulma-danger-invert);
  --bulma-table-cell-heading-color: var(--bulma-danger-invert);
  --bulma-table-cell-background-color: var(--bulma-danger);
  --bulma-table-cell-border-color: var(--bulma-danger);
}

.table td.is-narrow, .table th.is-narrow {
  white-space: nowrap;
  width: 1%;
}

.table td.is-selected, .table th.is-selected {
  background-color: var(--bulma-table-row-active-background-color);
  color: var(--bulma-table-row-active-color);
}

.table td.is-selected a, .table td.is-selected strong, .table th.is-selected a, .table th.is-selected strong {
  color: currentColor;
}

.table td.is-vcentered, .table th.is-vcentered {
  vertical-align: middle;
}

.table th {
  color: var(--bulma-table-cell-heading-color);
}

.table th:not([align]) {
  text-align: var(--bulma-table-cell-text-align);
}

.table tr.is-selected {
  background-color: var(--bulma-table-row-active-background-color);
  color: var(--bulma-table-row-active-color);
}

.table tr.is-selected a, .table tr.is-selected strong {
  color: currentColor;
}

.table tr.is-selected td, .table tr.is-selected th {
  border-color: var(--bulma-table-row-active-color);
  color: currentColor;
}

.table tr.is-white {
  --bulma-table-color: var(--bulma-white-invert);
  --bulma-table-cell-heading-color: var(--bulma-white-invert);
  --bulma-table-cell-background-color: var(--bulma-white);
  --bulma-table-cell-border-color: var(--bulma-white);
}

.table tr.is-black {
  --bulma-table-color: var(--bulma-black-invert);
  --bulma-table-cell-heading-color: var(--bulma-black-invert);
  --bulma-table-cell-background-color: var(--bulma-black);
  --bulma-table-cell-border-color: var(--bulma-black);
}

.table tr.is-light {
  --bulma-table-color: var(--bulma-light-invert);
  --bulma-table-cell-heading-color: var(--bulma-light-invert);
  --bulma-table-cell-background-color: var(--bulma-light);
  --bulma-table-cell-border-color: var(--bulma-light);
}

.table tr.is-dark {
  --bulma-table-color: var(--bulma-dark-invert);
  --bulma-table-cell-heading-color: var(--bulma-dark-invert);
  --bulma-table-cell-background-color: var(--bulma-dark);
  --bulma-table-cell-border-color: var(--bulma-dark);
}

.table tr.is-text {
  --bulma-table-color: var(--bulma-text-invert);
  --bulma-table-cell-heading-color: var(--bulma-text-invert);
  --bulma-table-cell-background-color: var(--bulma-text);
  --bulma-table-cell-border-color: var(--bulma-text);
}

.table tr.is-primary {
  --bulma-table-color: var(--bulma-primary-invert);
  --bulma-table-cell-heading-color: var(--bulma-primary-invert);
  --bulma-table-cell-background-color: var(--bulma-primary);
  --bulma-table-cell-border-color: var(--bulma-primary);
}

.table tr.is-link {
  --bulma-table-color: var(--bulma-link-invert);
  --bulma-table-cell-heading-color: var(--bulma-link-invert);
  --bulma-table-cell-background-color: var(--bulma-link);
  --bulma-table-cell-border-color: var(--bulma-link);
}

.table tr.is-info {
  --bulma-table-color: var(--bulma-info-invert);
  --bulma-table-cell-heading-color: var(--bulma-info-invert);
  --bulma-table-cell-background-color: var(--bulma-info);
  --bulma-table-cell-border-color: var(--bulma-info);
}

.table tr.is-success {
  --bulma-table-color: var(--bulma-success-invert);
  --bulma-table-cell-heading-color: var(--bulma-success-invert);
  --bulma-table-cell-background-color: var(--bulma-success);
  --bulma-table-cell-border-color: var(--bulma-success);
}

.table tr.is-warning {
  --bulma-table-color: var(--bulma-warning-invert);
  --bulma-table-cell-heading-color: var(--bulma-warning-invert);
  --bulma-table-cell-background-color: var(--bulma-warning);
  --bulma-table-cell-border-color: var(--bulma-warning);
}

.table tr.is-danger {
  --bulma-table-color: var(--bulma-danger-invert);
  --bulma-table-cell-heading-color: var(--bulma-danger-invert);
  --bulma-table-cell-background-color: var(--bulma-danger);
  --bulma-table-cell-border-color: var(--bulma-danger);
}

.table thead {
  background-color: var(--bulma-table-head-background-color);
}

.table thead td, .table thead th {
  border-width: var(--bulma-table-head-cell-border-width);
  color: var(--bulma-table-head-cell-color);
}

.table tfoot {
  background-color: var(--bulma-table-foot-background-color);
}

.table tfoot td, .table tfoot th {
  border-width: var(--bulma-table-foot-cell-border-width);
  color: var(--bulma-table-foot-cell-color);
}

.table tbody {
  background-color: var(--bulma-table-body-background-color);
}

.table tbody tr:last-child td, .table tbody tr:last-child th {
  border-bottom-width: 0;
}

.table.is-bordered td, .table.is-bordered th {
  border-width: 1px;
}

.table.is-bordered tr:last-child td, .table.is-bordered tr:last-child th {
  border-bottom-width: 1px;
}

.table.is-fullwidth {
  width: 100%;
}

.table.is-hoverable tbody tr:not(.is-selected):hover, .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
  background-color: var(--bulma-table-row-hover-background-color);
}

.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(2n) {
  background-color: var(--bulma-table-striped-row-even-hover-background-color);
}

.table.is-narrow td, .table.is-narrow th {
  padding: .25em .5em;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(2n) {
  background-color: var(--bulma-table-striped-row-even-background-color);
}

.table-container {
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  overflow: auto hidden;
}

.tags {
  color: hsl(var(--bulma-tag-h), var(--bulma-tag-s), var(--bulma-tag-color-l));
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.tags.are-medium .tag:not(.is-normal):not(.is-large) {
  font-size: var(--bulma-size-normal);
}

.tags.are-large .tag:not(.is-normal):not(.is-medium) {
  font-size: var(--bulma-size-medium);
}

.tags.is-centered {
  justify-content: center;
  gap: .25rem;
}

.tags.is-right {
  justify-content: flex-end;
}

.tags.has-addons {
  gap: 0;
}

.tags.has-addons .tag:not(:first-child) {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.tags.has-addons .tag:not(:last-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.tag {
  --bulma-tag-h: var(--bulma-scheme-h);
  --bulma-tag-s: var(--bulma-scheme-s);
  --bulma-tag-background-l: var(--bulma-background-l);
  --bulma-tag-background-l-delta: 0%;
  --bulma-tag-hover-background-l-delta: var(--bulma-hover-background-l-delta);
  --bulma-tag-active-background-l-delta: var(--bulma-active-background-l-delta);
  --bulma-tag-color-l: var(--bulma-text-l);
  --bulma-tag-radius: var(--bulma-radius);
  --bulma-tag-delete-margin: 1px;
  background-color: hsl(var(--bulma-tag-h), var(--bulma-tag-s), calc(var(--bulma-tag-background-l)  + var(--bulma-tag-background-l-delta)));
  border-radius: var(--bulma-radius);
  color: hsl(var(--bulma-tag-h), var(--bulma-tag-s), var(--bulma-tag-color-l));
  font-size: var(--bulma-size-small);
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  height: 2em;
  padding-left: .75em;
  padding-right: .75em;
  line-height: 1.5;
  display: inline-flex;
}

.tag .delete {
  margin-inline: .25rem -.375rem;
}

.tag.is-white {
  --bulma-tag-h: var(--bulma-white-h);
  --bulma-tag-s: var(--bulma-white-s);
  --bulma-tag-background-l: var(--bulma-white-l);
  --bulma-tag-color-l: var(--bulma-white-invert-l);
}

.tag.is-white.is-light {
  --bulma-tag-background-l: var(--bulma-light-l);
  --bulma-tag-color-l: var(--bulma-white-light-invert-l);
}

.tag.is-black {
  --bulma-tag-h: var(--bulma-black-h);
  --bulma-tag-s: var(--bulma-black-s);
  --bulma-tag-background-l: var(--bulma-black-l);
  --bulma-tag-color-l: var(--bulma-black-invert-l);
}

.tag.is-black.is-light {
  --bulma-tag-background-l: var(--bulma-light-l);
  --bulma-tag-color-l: var(--bulma-black-light-invert-l);
}

.tag.is-light {
  --bulma-tag-h: var(--bulma-light-h);
  --bulma-tag-s: var(--bulma-light-s);
  --bulma-tag-background-l: var(--bulma-light-l);
  --bulma-tag-color-l: var(--bulma-light-invert-l);
}

.tag.is-light.is-light {
  --bulma-tag-background-l: var(--bulma-light-l);
  --bulma-tag-color-l: var(--bulma-light-light-invert-l);
}

.tag.is-dark {
  --bulma-tag-h: var(--bulma-dark-h);
  --bulma-tag-s: var(--bulma-dark-s);
  --bulma-tag-background-l: var(--bulma-dark-l);
  --bulma-tag-color-l: var(--bulma-dark-invert-l);
}

.tag.is-dark.is-light {
  --bulma-tag-background-l: var(--bulma-light-l);
  --bulma-tag-color-l: var(--bulma-dark-light-invert-l);
}

.tag.is-text {
  --bulma-tag-h: var(--bulma-text-h);
  --bulma-tag-s: var(--bulma-text-s);
  --bulma-tag-background-l: var(--bulma-text-l);
  --bulma-tag-color-l: var(--bulma-text-invert-l);
}

.tag.is-text.is-light {
  --bulma-tag-background-l: var(--bulma-light-l);
  --bulma-tag-color-l: var(--bulma-text-light-invert-l);
}

.tag.is-primary {
  --bulma-tag-h: var(--bulma-primary-h);
  --bulma-tag-s: var(--bulma-primary-s);
  --bulma-tag-background-l: var(--bulma-primary-l);
  --bulma-tag-color-l: var(--bulma-primary-invert-l);
}

.tag.is-primary.is-light {
  --bulma-tag-background-l: var(--bulma-light-l);
  --bulma-tag-color-l: var(--bulma-primary-light-invert-l);
}

.tag.is-link {
  --bulma-tag-h: var(--bulma-link-h);
  --bulma-tag-s: var(--bulma-link-s);
  --bulma-tag-background-l: var(--bulma-link-l);
  --bulma-tag-color-l: var(--bulma-link-invert-l);
}

.tag.is-link.is-light {
  --bulma-tag-background-l: var(--bulma-light-l);
  --bulma-tag-color-l: var(--bulma-link-light-invert-l);
}

.tag.is-info {
  --bulma-tag-h: var(--bulma-info-h);
  --bulma-tag-s: var(--bulma-info-s);
  --bulma-tag-background-l: var(--bulma-info-l);
  --bulma-tag-color-l: var(--bulma-info-invert-l);
}

.tag.is-info.is-light {
  --bulma-tag-background-l: var(--bulma-light-l);
  --bulma-tag-color-l: var(--bulma-info-light-invert-l);
}

.tag.is-success {
  --bulma-tag-h: var(--bulma-success-h);
  --bulma-tag-s: var(--bulma-success-s);
  --bulma-tag-background-l: var(--bulma-success-l);
  --bulma-tag-color-l: var(--bulma-success-invert-l);
}

.tag.is-success.is-light {
  --bulma-tag-background-l: var(--bulma-light-l);
  --bulma-tag-color-l: var(--bulma-success-light-invert-l);
}

.tag.is-warning {
  --bulma-tag-h: var(--bulma-warning-h);
  --bulma-tag-s: var(--bulma-warning-s);
  --bulma-tag-background-l: var(--bulma-warning-l);
  --bulma-tag-color-l: var(--bulma-warning-invert-l);
}

.tag.is-warning.is-light {
  --bulma-tag-background-l: var(--bulma-light-l);
  --bulma-tag-color-l: var(--bulma-warning-light-invert-l);
}

.tag.is-danger {
  --bulma-tag-h: var(--bulma-danger-h);
  --bulma-tag-s: var(--bulma-danger-s);
  --bulma-tag-background-l: var(--bulma-danger-l);
  --bulma-tag-color-l: var(--bulma-danger-invert-l);
}

.tag.is-danger.is-light {
  --bulma-tag-background-l: var(--bulma-light-l);
  --bulma-tag-color-l: var(--bulma-danger-light-invert-l);
}

.tag.is-normal {
  font-size: var(--bulma-size-small);
}

.tag.is-medium {
  font-size: var(--bulma-size-normal);
}

.tag.is-large {
  font-size: var(--bulma-size-medium);
}

.tag .icon:first-child:not(:last-child) {
  margin-inline: -.375em .1875em;
}

.tag .icon:last-child:not(:first-child) {
  margin-inline: .1875em -.375em;
}

.tag .icon:first-child:last-child {
  margin-inline: -.375em;
}

.tag.is-delete {
  width: 2em;
  margin-inline-start: var(--bulma-tag-delete-margin);
  padding: 0;
  position: relative;
}

.tag.is-delete:before, .tag.is-delete:after {
  content: "";
  transform-origin: center;
  background-color: currentColor;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.tag.is-delete:before {
  width: 50%;
  height: 1px;
}

.tag.is-delete:after {
  width: 1px;
  height: 50%;
}

.tag.is-rounded {
  border-radius: var(--bulma-radius-rounded);
}

a.tag, button.tag, .tag.is-hoverable {
  cursor: pointer;
}

a.tag:hover, button.tag:hover, .tag.is-hoverable:hover {
  --bulma-tag-background-l-delta: var(--bulma-tag-hover-background-l-delta);
}

a.tag:active, button.tag:active, .tag.is-hoverable:active {
  --bulma-tag-background-l-delta: var(--bulma-tag-active-background-l-delta);
}

.title, .subtitle {
  --bulma-title-color: var(--bulma-text-strong);
  --bulma-title-family: false;
  --bulma-title-size: var(--bulma-size-3);
  --bulma-title-weight: var(--bulma-weight-extrabold);
  --bulma-title-line-height: 1.125;
  --bulma-title-strong-color: inherit;
  --bulma-title-strong-weight: inherit;
  --bulma-title-sub-size: .75em;
  --bulma-title-sup-size: .75em;
  --bulma-subtitle-color: var(--bulma-text);
  --bulma-subtitle-family: false;
  --bulma-subtitle-size: var(--bulma-size-5);
  --bulma-subtitle-weight: var(--bulma-weight-normal);
  --bulma-subtitle-line-height: 1.25;
  --bulma-subtitle-strong-color: var(--bulma-text-strong);
  --bulma-subtitle-strong-weight: var(--bulma-weight-semibold);
  word-break: break-word;
}

.title em, .title span, .subtitle em, .subtitle span {
  font-weight: inherit;
}

.title sub, .subtitle sub {
  font-size: var(--bulma-title-sub-size);
}

.title sup, .subtitle sup {
  font-size: var(--bulma-title-sup-size);
}

.title .tag, .subtitle .tag {
  vertical-align: middle;
}

.title {
  color: var(--bulma-title-color);
  font-size: var(--bulma-title-size);
  font-weight: var(--bulma-title-weight);
  line-height: var(--bulma-title-line-height);
}

.title strong {
  color: var(--bulma-title-strong-color);
  font-weight: var(--bulma-title-strong-weight);
}

.title:not(.is-spaced):has( + .subtitle) {
  margin-bottom: 0;
}

.title.is-1 {
  font-size: 3rem;
}

.title.is-2 {
  font-size: 2.5rem;
}

.title.is-3 {
  font-size: 2rem;
}

.title.is-4 {
  font-size: 1.5rem;
}

.title.is-5 {
  font-size: 1.25rem;
}

.title.is-6 {
  font-size: 1rem;
}

.title.is-7 {
  font-size: .75rem;
}

.subtitle {
  color: var(--bulma-subtitle-color);
  font-size: var(--bulma-subtitle-size);
  font-weight: var(--bulma-subtitle-weight);
  line-height: var(--bulma-subtitle-line-height);
}

.subtitle strong {
  color: var(--bulma-subtitle-strong-color);
  font-weight: var(--bulma-subtitle-strong-weight);
}

.subtitle:not(.is-spaced):has( + .title) {
  margin-bottom: 0;
}

.subtitle.is-1 {
  font-size: 3rem;
}

.subtitle.is-2 {
  font-size: 2.5rem;
}

.subtitle.is-3 {
  font-size: 2rem;
}

.subtitle.is-4 {
  font-size: 1.5rem;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle.is-6 {
  font-size: 1rem;
}

.subtitle.is-7 {
  font-size: .75rem;
}

.control, .input, .textarea, .select {
  --bulma-input-h: var(--bulma-scheme-h);
  --bulma-input-s: var(--bulma-scheme-s);
  --bulma-input-l: var(--bulma-scheme-main-l);
  --bulma-input-border-style: solid;
  --bulma-input-border-width: var(--bulma-control-border-width);
  --bulma-input-border-l: var(--bulma-border-l);
  --bulma-input-border-l-delta: 0%;
  --bulma-input-border-color: hsl(var(--bulma-input-h), var(--bulma-input-s), calc(var(--bulma-input-border-l)  + var(--bulma-input-border-l-delta)));
  --bulma-input-hover-border-l-delta: var(--bulma-hover-border-l-delta);
  --bulma-input-active-border-l-delta: var(--bulma-active-border-l-delta);
  --bulma-input-focus-h: var(--bulma-focus-h);
  --bulma-input-focus-s: var(--bulma-focus-s);
  --bulma-input-focus-l: var(--bulma-focus-l);
  --bulma-input-focus-shadow-size: var(--bulma-focus-shadow-size);
  --bulma-input-focus-shadow-alpha: var(--bulma-focus-shadow-alpha);
  --bulma-input-color-l: var(--bulma-text-strong-l);
  --bulma-input-background-l: var(--bulma-scheme-main-l);
  --bulma-input-background-l-delta: 0%;
  --bulma-input-height: var(--bulma-control-height);
  --bulma-input-shadow: inset 0 .0625em .125em hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), .05);
  --bulma-input-placeholder-color: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-strong-l), .3);
  --bulma-input-disabled-color: var(--bulma-text-weak);
  --bulma-input-disabled-background-color: var(--bulma-background);
  --bulma-input-disabled-border-color: var(--bulma-background);
  --bulma-input-disabled-placeholder-color: hsla(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-weak-l), .3);
  --bulma-input-arrow: var(--bulma-link);
  --bulma-input-icon-color: var(--bulma-text-light);
  --bulma-input-icon-hover-color: var(--bulma-text-weak);
  --bulma-input-icon-focus-color: var(--bulma-link);
  --bulma-input-radius: var(--bulma-radius);
}

.select select, .input, .textarea {
  background-color: hsl(var(--bulma-input-h), var(--bulma-input-s), calc(var(--bulma-input-background-l)  + var(--bulma-input-background-l-delta)));
  border-color: var(--bulma-input-border-color);
  border-radius: var(--bulma-input-radius);
  color: hsl(var(--bulma-input-h), var(--bulma-input-s), var(--bulma-input-color-l));
}

.textarea, .input {
  box-shadow: inset 0 .0625em .125em hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), .05);
  width: 100%;
  max-width: 100%;
}

[readonly].textarea, [readonly].input {
  box-shadow: none;
}

.is-white.textarea, .is-white.input {
  --bulma-input-h: var(--bulma-white-h);
  --bulma-input-s: var(--bulma-white-s);
  --bulma-input-l: var(--bulma-white-l);
  --bulma-input-focus-h: var(--bulma-white-h);
  --bulma-input-focus-s: var(--bulma-white-s);
  --bulma-input-focus-l: var(--bulma-white-l);
  --bulma-input-border-l: var(--bulma-white-l);
}

.is-black.textarea, .is-black.input {
  --bulma-input-h: var(--bulma-black-h);
  --bulma-input-s: var(--bulma-black-s);
  --bulma-input-l: var(--bulma-black-l);
  --bulma-input-focus-h: var(--bulma-black-h);
  --bulma-input-focus-s: var(--bulma-black-s);
  --bulma-input-focus-l: var(--bulma-black-l);
  --bulma-input-border-l: var(--bulma-black-l);
}

.is-light.textarea, .is-light.input {
  --bulma-input-h: var(--bulma-light-h);
  --bulma-input-s: var(--bulma-light-s);
  --bulma-input-l: var(--bulma-light-l);
  --bulma-input-focus-h: var(--bulma-light-h);
  --bulma-input-focus-s: var(--bulma-light-s);
  --bulma-input-focus-l: var(--bulma-light-l);
  --bulma-input-border-l: var(--bulma-light-l);
}

.is-dark.textarea, .is-dark.input {
  --bulma-input-h: var(--bulma-dark-h);
  --bulma-input-s: var(--bulma-dark-s);
  --bulma-input-l: var(--bulma-dark-l);
  --bulma-input-focus-h: var(--bulma-dark-h);
  --bulma-input-focus-s: var(--bulma-dark-s);
  --bulma-input-focus-l: var(--bulma-dark-l);
  --bulma-input-border-l: var(--bulma-dark-l);
}

.is-text.textarea, .is-text.input {
  --bulma-input-h: var(--bulma-text-h);
  --bulma-input-s: var(--bulma-text-s);
  --bulma-input-l: var(--bulma-text-l);
  --bulma-input-focus-h: var(--bulma-text-h);
  --bulma-input-focus-s: var(--bulma-text-s);
  --bulma-input-focus-l: var(--bulma-text-l);
  --bulma-input-border-l: var(--bulma-text-l);
}

.is-primary.textarea, .is-primary.input {
  --bulma-input-h: var(--bulma-primary-h);
  --bulma-input-s: var(--bulma-primary-s);
  --bulma-input-l: var(--bulma-primary-l);
  --bulma-input-focus-h: var(--bulma-primary-h);
  --bulma-input-focus-s: var(--bulma-primary-s);
  --bulma-input-focus-l: var(--bulma-primary-l);
  --bulma-input-border-l: var(--bulma-primary-l);
}

.is-link.textarea, .is-link.input {
  --bulma-input-h: var(--bulma-link-h);
  --bulma-input-s: var(--bulma-link-s);
  --bulma-input-l: var(--bulma-link-l);
  --bulma-input-focus-h: var(--bulma-link-h);
  --bulma-input-focus-s: var(--bulma-link-s);
  --bulma-input-focus-l: var(--bulma-link-l);
  --bulma-input-border-l: var(--bulma-link-l);
}

.is-info.textarea, .is-info.input {
  --bulma-input-h: var(--bulma-info-h);
  --bulma-input-s: var(--bulma-info-s);
  --bulma-input-l: var(--bulma-info-l);
  --bulma-input-focus-h: var(--bulma-info-h);
  --bulma-input-focus-s: var(--bulma-info-s);
  --bulma-input-focus-l: var(--bulma-info-l);
  --bulma-input-border-l: var(--bulma-info-l);
}

.is-success.textarea, .is-success.input {
  --bulma-input-h: var(--bulma-success-h);
  --bulma-input-s: var(--bulma-success-s);
  --bulma-input-l: var(--bulma-success-l);
  --bulma-input-focus-h: var(--bulma-success-h);
  --bulma-input-focus-s: var(--bulma-success-s);
  --bulma-input-focus-l: var(--bulma-success-l);
  --bulma-input-border-l: var(--bulma-success-l);
}

.is-warning.textarea, .is-warning.input {
  --bulma-input-h: var(--bulma-warning-h);
  --bulma-input-s: var(--bulma-warning-s);
  --bulma-input-l: var(--bulma-warning-l);
  --bulma-input-focus-h: var(--bulma-warning-h);
  --bulma-input-focus-s: var(--bulma-warning-s);
  --bulma-input-focus-l: var(--bulma-warning-l);
  --bulma-input-border-l: var(--bulma-warning-l);
}

.is-danger.textarea, .is-danger.input {
  --bulma-input-h: var(--bulma-danger-h);
  --bulma-input-s: var(--bulma-danger-s);
  --bulma-input-l: var(--bulma-danger-l);
  --bulma-input-focus-h: var(--bulma-danger-h);
  --bulma-input-focus-s: var(--bulma-danger-s);
  --bulma-input-focus-l: var(--bulma-danger-l);
  --bulma-input-border-l: var(--bulma-danger-l);
}

.is-small.textarea, .is-small.input {
  border-radius: var(--bulma-radius-small);
  font-size: var(--bulma-size-small);
}

.is-medium.textarea, .is-medium.input {
  font-size: var(--bulma-size-medium);
}

.is-large.textarea, .is-large.input {
  font-size: var(--bulma-size-large);
}

.is-fullwidth.textarea, .is-fullwidth.input {
  width: 100%;
  display: block;
}

.is-inline.textarea, .is-inline.input {
  width: auto;
  display: inline;
}

.input.is-rounded {
  border-radius: var(--bulma-radius-rounded);
  padding-left: calc(1.125em - 1px);
  padding-right: calc(1.125em - 1px);
}

.input.is-static {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
  padding-left: 0;
  padding-right: 0;
}

.textarea {
  --bulma-textarea-padding: var(--bulma-control-padding-horizontal);
  --bulma-textarea-max-height: 40em;
  --bulma-textarea-min-height: 8em;
  min-width: 100%;
  max-width: 100%;
  padding: var(--bulma-textarea-padding);
  resize: vertical;
  display: block;
}

.textarea:not([rows]) {
  max-height: var(--bulma-textarea-max-height);
  min-height: var(--bulma-textarea-min-height);
}

.textarea[rows] {
  height: initial;
}

.textarea.has-fixed-size {
  resize: none;
}

.radio, .checkbox {
  cursor: pointer;
  line-height: 1.25;
  display: inline-block;
  position: relative;
}

.radio input, .checkbox input {
  cursor: pointer;
}

[disabled].radio, [disabled].checkbox, fieldset[disabled] .radio, fieldset[disabled] .checkbox, .radio input[disabled], .checkbox input[disabled] {
  color: var(--bulma-text-weak);
  cursor: not-allowed;
}

.checkboxes, .radios {
  flex-wrap: wrap;
  gap: .5em 1em;
  display: flex;
}

.select {
  --bulma-input-h: var(--bulma-scheme-h);
  --bulma-input-s: var(--bulma-scheme-s);
  --bulma-input-border-style: solid;
  --bulma-input-border-width: 1px;
  --bulma-input-border-l: var(--bulma-border-l);
  vertical-align: top;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.select:not(.is-multiple) {
  height: var(--bulma-control-height);
}

.select:not(.is-multiple):not(.is-loading):after {
  z-index: 4;
  inset-inline-end: 1.125em;
}

.select.is-rounded select {
  border-radius: var(--bulma-radius-rounded);
  padding-inline-start: 1em;
}

.select select {
  cursor: pointer;
  outline: none;
  max-width: 100%;
  font-size: 1em;
  display: block;
}

.select select::-ms-expand {
  display: none;
}

.select select[disabled]:hover, fieldset[disabled] .select select:hover {
  border-color: var(--bulma-background);
}

.select select:not([multiple]) {
  padding-inline-end: 2.5em;
}

.select select[multiple] {
  height: auto;
  padding: 0;
}

.select select[multiple] option {
  padding: .5em 1em;
}

.select.is-white {
  --bulma-input-h: var(--bulma-white-h);
  --bulma-input-s: var(--bulma-white-s);
  --bulma-input-l: var(--bulma-white-l);
  --bulma-input-focus-h: var(--bulma-white-h);
  --bulma-input-focus-s: var(--bulma-white-s);
  --bulma-input-focus-l: var(--bulma-white-l);
  --bulma-input-border-l: var(--bulma-white-l);
  --bulma-arrow-color: var(--bulma-white);
}

.select.is-black {
  --bulma-input-h: var(--bulma-black-h);
  --bulma-input-s: var(--bulma-black-s);
  --bulma-input-l: var(--bulma-black-l);
  --bulma-input-focus-h: var(--bulma-black-h);
  --bulma-input-focus-s: var(--bulma-black-s);
  --bulma-input-focus-l: var(--bulma-black-l);
  --bulma-input-border-l: var(--bulma-black-l);
  --bulma-arrow-color: var(--bulma-black);
}

.select.is-light {
  --bulma-input-h: var(--bulma-light-h);
  --bulma-input-s: var(--bulma-light-s);
  --bulma-input-l: var(--bulma-light-l);
  --bulma-input-focus-h: var(--bulma-light-h);
  --bulma-input-focus-s: var(--bulma-light-s);
  --bulma-input-focus-l: var(--bulma-light-l);
  --bulma-input-border-l: var(--bulma-light-l);
  --bulma-arrow-color: var(--bulma-light);
}

.select.is-dark {
  --bulma-input-h: var(--bulma-dark-h);
  --bulma-input-s: var(--bulma-dark-s);
  --bulma-input-l: var(--bulma-dark-l);
  --bulma-input-focus-h: var(--bulma-dark-h);
  --bulma-input-focus-s: var(--bulma-dark-s);
  --bulma-input-focus-l: var(--bulma-dark-l);
  --bulma-input-border-l: var(--bulma-dark-l);
  --bulma-arrow-color: var(--bulma-dark);
}

.select.is-text {
  --bulma-input-h: var(--bulma-text-h);
  --bulma-input-s: var(--bulma-text-s);
  --bulma-input-l: var(--bulma-text-l);
  --bulma-input-focus-h: var(--bulma-text-h);
  --bulma-input-focus-s: var(--bulma-text-s);
  --bulma-input-focus-l: var(--bulma-text-l);
  --bulma-input-border-l: var(--bulma-text-l);
  --bulma-arrow-color: var(--bulma-text);
}

.select.is-primary {
  --bulma-input-h: var(--bulma-primary-h);
  --bulma-input-s: var(--bulma-primary-s);
  --bulma-input-l: var(--bulma-primary-l);
  --bulma-input-focus-h: var(--bulma-primary-h);
  --bulma-input-focus-s: var(--bulma-primary-s);
  --bulma-input-focus-l: var(--bulma-primary-l);
  --bulma-input-border-l: var(--bulma-primary-l);
  --bulma-arrow-color: var(--bulma-primary);
}

.select.is-link {
  --bulma-input-h: var(--bulma-link-h);
  --bulma-input-s: var(--bulma-link-s);
  --bulma-input-l: var(--bulma-link-l);
  --bulma-input-focus-h: var(--bulma-link-h);
  --bulma-input-focus-s: var(--bulma-link-s);
  --bulma-input-focus-l: var(--bulma-link-l);
  --bulma-input-border-l: var(--bulma-link-l);
  --bulma-arrow-color: var(--bulma-link);
}

.select.is-info {
  --bulma-input-h: var(--bulma-info-h);
  --bulma-input-s: var(--bulma-info-s);
  --bulma-input-l: var(--bulma-info-l);
  --bulma-input-focus-h: var(--bulma-info-h);
  --bulma-input-focus-s: var(--bulma-info-s);
  --bulma-input-focus-l: var(--bulma-info-l);
  --bulma-input-border-l: var(--bulma-info-l);
  --bulma-arrow-color: var(--bulma-info);
}

.select.is-success {
  --bulma-input-h: var(--bulma-success-h);
  --bulma-input-s: var(--bulma-success-s);
  --bulma-input-l: var(--bulma-success-l);
  --bulma-input-focus-h: var(--bulma-success-h);
  --bulma-input-focus-s: var(--bulma-success-s);
  --bulma-input-focus-l: var(--bulma-success-l);
  --bulma-input-border-l: var(--bulma-success-l);
  --bulma-arrow-color: var(--bulma-success);
}

.select.is-warning {
  --bulma-input-h: var(--bulma-warning-h);
  --bulma-input-s: var(--bulma-warning-s);
  --bulma-input-l: var(--bulma-warning-l);
  --bulma-input-focus-h: var(--bulma-warning-h);
  --bulma-input-focus-s: var(--bulma-warning-s);
  --bulma-input-focus-l: var(--bulma-warning-l);
  --bulma-input-border-l: var(--bulma-warning-l);
  --bulma-arrow-color: var(--bulma-warning);
}

.select.is-danger {
  --bulma-input-h: var(--bulma-danger-h);
  --bulma-input-s: var(--bulma-danger-s);
  --bulma-input-l: var(--bulma-danger-l);
  --bulma-input-focus-h: var(--bulma-danger-h);
  --bulma-input-focus-s: var(--bulma-danger-s);
  --bulma-input-focus-l: var(--bulma-danger-l);
  --bulma-input-border-l: var(--bulma-danger-l);
  --bulma-arrow-color: var(--bulma-danger);
}

.select.is-small {
  border-radius: var(--bulma-radius-small);
  font-size: var(--bulma-size-small);
}

.select.is-medium {
  font-size: var(--bulma-size-medium);
}

.select.is-large {
  font-size: var(--bulma-size-large);
}

.select.is-disabled:after {
  opacity: .5;
  border-color: var(--bulma-text-weak) !important;
}

.select.is-fullwidth, .select.is-fullwidth select {
  width: 100%;
}

.select.is-loading:after {
  inset-inline-end: .625em;
  margin-top: 0;
  position: absolute;
  top: .625em;
  transform: none;
}

.select.is-loading.is-small:after {
  font-size: var(--bulma-size-small);
}

.select.is-loading.is-medium:after {
  font-size: var(--bulma-size-medium);
}

.select.is-loading.is-large:after {
  font-size: var(--bulma-size-large);
}

.file {
  --bulma-file-radius: var(--bulma-radius);
  --bulma-file-name-border-color: var(--bulma-border);
  --bulma-file-name-border-style: solid;
  --bulma-file-name-border-width: 1px 1px 1px 0;
  --bulma-file-name-max-width: 16em;
  --bulma-file-h: var(--bulma-scheme-h);
  --bulma-file-s: var(--bulma-scheme-s);
  --bulma-file-background-l: var(--bulma-scheme-main-ter-l);
  --bulma-file-background-l-delta: 0%;
  --bulma-file-hover-background-l-delta: -5%;
  --bulma-file-active-background-l-delta: -10%;
  --bulma-file-border-l: var(--bulma-border-l);
  --bulma-file-border-l-delta: 0%;
  --bulma-file-hover-border-l-delta: -10%;
  --bulma-file-active-border-l-delta: -20%;
  --bulma-file-cta-color-l: var(--bulma-text-strong-l);
  --bulma-file-name-color-l: var(--bulma-text-strong-l);
  --bulma-file-color-l-delta: 0%;
  --bulma-file-hover-color-l-delta: -5%;
  --bulma-file-active-color-l-delta: -10%;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
}

.file.is-white {
  --bulma-file-h: var(--bulma-white-h);
  --bulma-file-s: var(--bulma-white-s);
  --bulma-file-background-l: var(--bulma-white-l);
  --bulma-file-border-l: var(--bulma-white-l);
  --bulma-file-cta-color-l: var(--bulma-white-invert-l);
  --bulma-file-name-color-l: var(--bulma-white-on-scheme-l);
}

.file.is-black {
  --bulma-file-h: var(--bulma-black-h);
  --bulma-file-s: var(--bulma-black-s);
  --bulma-file-background-l: var(--bulma-black-l);
  --bulma-file-border-l: var(--bulma-black-l);
  --bulma-file-cta-color-l: var(--bulma-black-invert-l);
  --bulma-file-name-color-l: var(--bulma-black-on-scheme-l);
}

.file.is-light {
  --bulma-file-h: var(--bulma-light-h);
  --bulma-file-s: var(--bulma-light-s);
  --bulma-file-background-l: var(--bulma-light-l);
  --bulma-file-border-l: var(--bulma-light-l);
  --bulma-file-cta-color-l: var(--bulma-light-invert-l);
  --bulma-file-name-color-l: var(--bulma-light-on-scheme-l);
}

.file.is-dark {
  --bulma-file-h: var(--bulma-dark-h);
  --bulma-file-s: var(--bulma-dark-s);
  --bulma-file-background-l: var(--bulma-dark-l);
  --bulma-file-border-l: var(--bulma-dark-l);
  --bulma-file-cta-color-l: var(--bulma-dark-invert-l);
  --bulma-file-name-color-l: var(--bulma-dark-on-scheme-l);
}

.file.is-text {
  --bulma-file-h: var(--bulma-text-h);
  --bulma-file-s: var(--bulma-text-s);
  --bulma-file-background-l: var(--bulma-text-l);
  --bulma-file-border-l: var(--bulma-text-l);
  --bulma-file-cta-color-l: var(--bulma-text-invert-l);
  --bulma-file-name-color-l: var(--bulma-text-on-scheme-l);
}

.file.is-primary {
  --bulma-file-h: var(--bulma-primary-h);
  --bulma-file-s: var(--bulma-primary-s);
  --bulma-file-background-l: var(--bulma-primary-l);
  --bulma-file-border-l: var(--bulma-primary-l);
  --bulma-file-cta-color-l: var(--bulma-primary-invert-l);
  --bulma-file-name-color-l: var(--bulma-primary-on-scheme-l);
}

.file.is-link {
  --bulma-file-h: var(--bulma-link-h);
  --bulma-file-s: var(--bulma-link-s);
  --bulma-file-background-l: var(--bulma-link-l);
  --bulma-file-border-l: var(--bulma-link-l);
  --bulma-file-cta-color-l: var(--bulma-link-invert-l);
  --bulma-file-name-color-l: var(--bulma-link-on-scheme-l);
}

.file.is-info {
  --bulma-file-h: var(--bulma-info-h);
  --bulma-file-s: var(--bulma-info-s);
  --bulma-file-background-l: var(--bulma-info-l);
  --bulma-file-border-l: var(--bulma-info-l);
  --bulma-file-cta-color-l: var(--bulma-info-invert-l);
  --bulma-file-name-color-l: var(--bulma-info-on-scheme-l);
}

.file.is-success {
  --bulma-file-h: var(--bulma-success-h);
  --bulma-file-s: var(--bulma-success-s);
  --bulma-file-background-l: var(--bulma-success-l);
  --bulma-file-border-l: var(--bulma-success-l);
  --bulma-file-cta-color-l: var(--bulma-success-invert-l);
  --bulma-file-name-color-l: var(--bulma-success-on-scheme-l);
}

.file.is-warning {
  --bulma-file-h: var(--bulma-warning-h);
  --bulma-file-s: var(--bulma-warning-s);
  --bulma-file-background-l: var(--bulma-warning-l);
  --bulma-file-border-l: var(--bulma-warning-l);
  --bulma-file-cta-color-l: var(--bulma-warning-invert-l);
  --bulma-file-name-color-l: var(--bulma-warning-on-scheme-l);
}

.file.is-danger {
  --bulma-file-h: var(--bulma-danger-h);
  --bulma-file-s: var(--bulma-danger-s);
  --bulma-file-background-l: var(--bulma-danger-l);
  --bulma-file-border-l: var(--bulma-danger-l);
  --bulma-file-cta-color-l: var(--bulma-danger-invert-l);
  --bulma-file-name-color-l: var(--bulma-danger-on-scheme-l);
}

.file.is-small {
  font-size: var(--bulma-size-small);
}

.file.is-normal {
  font-size: var(--bulma-size-normal);
}

.file.is-medium {
  font-size: var(--bulma-size-medium);
}

.file.is-medium .file-icon .fa {
  font-size: 1.5rem;
}

.file.is-large {
  font-size: var(--bulma-size-large);
}

.file.is-large .file-icon .fa {
  font-size: 2rem;
}

.file.has-name .file-cta {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.file.has-name .file-name {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.file.has-name.is-empty .file-cta {
  border-radius: var(--bulma-file-radius);
}

.file.has-name.is-empty .file-name {
  display: none;
}

.file.is-boxed .file-label {
  flex-direction: column;
}

.file.is-boxed .file-cta {
  flex-direction: column;
  height: auto;
  padding: 1em 3em;
}

.file.is-boxed .file-name {
  border-width: 0 1px 1px;
}

.file.is-boxed .file-icon {
  width: 1.5em;
  height: 1.5em;
}

.file.is-boxed .file-icon .fa {
  font-size: 1.5rem;
}

.file.is-boxed.is-small .file-icon .fa {
  font-size: 1rem;
}

.file.is-boxed.is-medium .file-icon .fa {
  font-size: 2rem;
}

.file.is-boxed.is-large .file-icon .fa {
  font-size: 2.5rem;
}

.file.is-boxed.has-name .file-cta {
  border-start-start-radius: var(--bulma-file-radius);
  border-start-end-radius: var(--bulma-file-radius);
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.file.is-boxed.has-name .file-name {
  border-width: 0 1px 1px;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: var(--bulma-file-radius);
  border-end-start-radius: var(--bulma-file-radius);
}

.file.is-centered {
  justify-content: center;
}

.file.is-fullwidth .file-label {
  width: 100%;
}

.file.is-fullwidth .file-name {
  flex-grow: 1;
  max-width: none;
}

.file.is-right {
  justify-content: flex-end;
}

.file.is-right .file-cta {
  border-radius: 0 var(--bulma-file-radius) var(--bulma-file-radius) 0;
}

.file.is-right .file-name {
  border-radius: var(--bulma-file-radius) 0 0 var(--bulma-file-radius);
  border-width: 1px 0 1px 1px;
  order: -1;
}

.file-label {
  cursor: pointer;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
}

.file-label:hover {
  --bulma-file-background-l-delta: var(--bulma-file-hover-background-l-delta);
  --bulma-file-border-l-delta: var(--bulma-file-hover-border-l-delta);
  --bulma-file-color-l-delta: var(--bulma-file-hover-color-l-delta);
}

.file-label:active {
  --bulma-file-background-l-delta: var(--bulma-file-active-background-l-delta);
  --bulma-file-border-l-delta: var(--bulma-file-active-border-l-delta);
  --bulma-file-color-l-delta: var(--bulma-file-active-color-l-delta);
}

.file-input {
  opacity: 0;
  outline: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.file-cta, .file-name {
  border-color: hsl(var(--bulma-file-h), var(--bulma-file-s), calc(var(--bulma-file-border-l)  + var(--bulma-file-border-l-delta)));
  border-radius: var(--bulma-file-radius);
  white-space: nowrap;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1em;
}

.file-cta {
  background-color: hsl(var(--bulma-file-h), var(--bulma-file-s), calc(var(--bulma-file-background-l)  + var(--bulma-file-background-l-delta)));
  color: hsl(var(--bulma-file-h), var(--bulma-file-s), calc(var(--bulma-file-cta-color-l)  + var(--bulma-file-color-l-delta)));
}

.file-name {
  border-color: hsl(var(--bulma-file-h), var(--bulma-file-s), calc(var(--bulma-file-border-l)  + var(--bulma-file-color-l-delta)));
  border-style: var(--bulma-file-name-border-style);
  border-width: var(--bulma-file-name-border-width);
  color: hsl(var(--bulma-file-h), var(--bulma-file-s), calc(var(--bulma-file-name-color-l)  + var(--bulma-file-color-l-delta)));
  max-width: var(--bulma-file-name-max-width);
  text-align: inherit;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.file-icon {
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin-inline-end: .5em;
  display: flex;
}

.file-icon .fa {
  font-size: 1rem;
}

:root {
  --bulma-label-color: var(--bulma-text-strong);
  --bulma-label-spacing: .5em;
  --bulma-label-weight: var(--bulma-weight-semibold);
  --bulma-help-size: var(--bulma-size-small);
  --bulma-field-block-spacing: .75rem;
}

.label {
  color: var(--bulma-label-color);
  font-size: var(--bulma-size-normal);
  font-weight: var(--bulma-weight-semibold);
  display: block;
}

.label:not(:last-child) {
  margin-bottom: var(--bulma-label-spacing);
}

.label.is-small {
  font-size: var(--bulma-size-small);
}

.label.is-medium {
  font-size: var(--bulma-size-medium);
}

.label.is-large {
  font-size: var(--bulma-size-large);
}

.help {
  font-size: var(--bulma-help-size);
  margin-top: .25rem;
  display: block;
}

.help.is-white {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-on-scheme-l));
}

.help.is-black {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-on-scheme-l));
}

.help.is-light {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-on-scheme-l));
}

.help.is-dark {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-on-scheme-l));
}

.help.is-text {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-on-scheme-l));
}

.help.is-primary {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-on-scheme-l));
}

.help.is-link {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-on-scheme-l));
}

.help.is-info {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-on-scheme-l));
}

.help.is-success {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-on-scheme-l));
}

.help.is-warning {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-on-scheme-l));
}

.help.is-danger {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-on-scheme-l));
}

.field {
  --bulma-block-spacing: var(--bulma-field-block-spacing);
}

.field.has-addons {
  justify-content: flex-start;
  display: flex;
}

.field.has-addons .control:not(:last-child) {
  margin-inline-end: -1px;
}

.field.has-addons .control:not(:first-child):not(:last-child) .button, .field.has-addons .control:not(:first-child):not(:last-child) .input, .field.has-addons .control:not(:first-child):not(:last-child) .select select {
  border-radius: 0;
}

.field.has-addons .control:first-child:not(:only-child) .button, .field.has-addons .control:first-child:not(:only-child) .input, .field.has-addons .control:first-child:not(:only-child) .select select {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.field.has-addons .control:last-child:not(:only-child) .button, .field.has-addons .control:last-child:not(:only-child) .input, .field.has-addons .control:last-child:not(:only-child) .select select {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.field.has-addons .control .button:not([disabled]):hover, .field.has-addons .control .button:not([disabled]).is-hovered, .field.has-addons .control .input:not([disabled]):hover, .field.has-addons .control .input:not([disabled]).is-hovered, .field.has-addons .control .select select:not([disabled]):hover, .field.has-addons .control .select select:not([disabled]).is-hovered {
  z-index: 2;
}

.field.has-addons .control .button:not([disabled]):focus, .field.has-addons .control .button:not([disabled]).is-focused, .field.has-addons .control .button:not([disabled]):active, .field.has-addons .control .button:not([disabled]).is-active, .field.has-addons .control .input:not([disabled]):focus, .field.has-addons .control .input:not([disabled]).is-focused, .field.has-addons .control .input:not([disabled]):active, .field.has-addons .control .input:not([disabled]).is-active, .field.has-addons .control .select select:not([disabled]):focus, .field.has-addons .control .select select:not([disabled]).is-focused, .field.has-addons .control .select select:not([disabled]):active, .field.has-addons .control .select select:not([disabled]).is-active {
  z-index: 3;
}

.field.has-addons .control .button:not([disabled]):focus:hover, .field.has-addons .control .button:not([disabled]).is-focused:hover, .field.has-addons .control .button:not([disabled]):active:hover, .field.has-addons .control .button:not([disabled]).is-active:hover, .field.has-addons .control .input:not([disabled]):focus:hover, .field.has-addons .control .input:not([disabled]).is-focused:hover, .field.has-addons .control .input:not([disabled]):active:hover, .field.has-addons .control .input:not([disabled]).is-active:hover, .field.has-addons .control .select select:not([disabled]):focus:hover, .field.has-addons .control .select select:not([disabled]).is-focused:hover, .field.has-addons .control .select select:not([disabled]):active:hover, .field.has-addons .control .select select:not([disabled]).is-active:hover {
  z-index: 4;
}

.field.has-addons .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.has-addons.has-addons-centered {
  justify-content: center;
}

.field.has-addons.has-addons-right {
  justify-content: flex-end;
}

.field.has-addons.has-addons-fullwidth .control {
  flex-grow: 1;
  flex-shrink: 0;
}

.field.is-grouped {
  justify-content: flex-start;
  gap: .75rem;
  display: flex;
}

.field.is-grouped > .control {
  flex-shrink: 0;
}

.field.is-grouped > .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.is-grouped.is-grouped-centered {
  justify-content: center;
}

.field.is-grouped.is-grouped-right {
  justify-content: flex-end;
}

.field.is-grouped.is-grouped-multiline {
  flex-wrap: wrap;
}

@media screen and (width >= 769px), print {
  .field.is-horizontal {
    display: flex;
  }
}

.field-label .label {
  font-size: inherit;
}

@media screen and (width <= 768px) {
  .field-label {
    margin-bottom: .5rem;
  }
}

@media screen and (width >= 769px), print {
  .field-label {
    text-align: right;
    flex: 1 0 0;
    margin-inline-end: 1.5rem;
  }

  .field-label.is-small {
    font-size: var(--bulma-size-small);
    padding-top: .375em;
  }

  .field-label.is-normal {
    padding-top: .375em;
  }

  .field-label.is-medium {
    font-size: var(--bulma-size-medium);
    padding-top: .375em;
  }

  .field-label.is-large {
    font-size: var(--bulma-size-large);
    padding-top: .375em;
  }
}

.field-body .field .field {
  margin-bottom: 0;
}

@media screen and (width >= 769px), print {
  .field-body {
    flex: 5 1 0;
    display: flex;
  }

  .field-body .field {
    margin-bottom: 0;
  }

  .field-body > .field {
    flex-shrink: 1;
  }

  .field-body > .field:not(.is-narrow) {
    flex-grow: 1;
  }

  .field-body > .field:not(:last-child) {
    margin-inline-end: .75rem;
  }
}

.control {
  box-sizing: border-box;
  clear: both;
  font-size: var(--bulma-size-normal);
  text-align: inherit;
  position: relative;
}

.control.has-icons-left .input:hover ~ .icon, .control.has-icons-left .select:hover ~ .icon, .control.has-icons-right .input:hover ~ .icon, .control.has-icons-right .select:hover ~ .icon {
  color: var(--bulma-input-icon-hover-color);
}

.control.has-icons-left .input:focus ~ .icon, .control.has-icons-left .select:focus ~ .icon, .control.has-icons-right .input:focus ~ .icon, .control.has-icons-right .select:focus ~ .icon {
  color: var(--bulma-input-icon-focus-color);
}

.control.has-icons-left .input.is-small ~ .icon, .control.has-icons-left .select.is-small ~ .icon, .control.has-icons-right .input.is-small ~ .icon, .control.has-icons-right .select.is-small ~ .icon {
  font-size: var(--bulma-size-small);
}

.control.has-icons-left .input.is-medium ~ .icon, .control.has-icons-left .select.is-medium ~ .icon, .control.has-icons-right .input.is-medium ~ .icon, .control.has-icons-right .select.is-medium ~ .icon {
  font-size: var(--bulma-size-medium);
}

.control.has-icons-left .input.is-large ~ .icon, .control.has-icons-left .select.is-large ~ .icon, .control.has-icons-right .input.is-large ~ .icon, .control.has-icons-right .select.is-large ~ .icon {
  font-size: var(--bulma-size-large);
}

.control.has-icons-left .icon, .control.has-icons-right .icon {
  color: var(--bulma-input-icon-color);
  height: var(--bulma-input-height);
  pointer-events: none;
  width: var(--bulma-input-height);
  z-index: 4;
  position: absolute;
  top: 0;
}

.control.has-icons-left .input, .control.has-icons-left .select select {
  padding-left: var(--bulma-input-height);
}

.control.has-icons-left .icon.is-left {
  left: 0;
}

.control.has-icons-right .input, .control.has-icons-right .select select {
  padding-right: var(--bulma-input-height);
}

.control.has-icons-right .icon.is-right {
  right: 0;
}

.control.is-loading:after {
  z-index: 4;
  inset-inline-end: .75em;
  top: .75em;
  position: absolute !important;
}

.control.is-loading.is-small:after {
  font-size: var(--bulma-size-small);
}

.control.is-loading.is-medium:after {
  font-size: var(--bulma-size-medium);
}

.control.is-loading.is-large:after {
  font-size: var(--bulma-size-large);
}

.breadcrumb {
  --bulma-breadcrumb-item-color: var(--bulma-link-text);
  --bulma-breadcrumb-item-hover-color: var(--bulma-link-text-hover);
  --bulma-breadcrumb-item-active-color: var(--bulma-link-text-active);
  --bulma-breadcrumb-item-padding-vertical: 0;
  --bulma-breadcrumb-item-padding-horizontal: .75em;
  --bulma-breadcrumb-item-separator-color: var(--bulma-border);
  font-size: var(--bulma-size-normal);
  white-space: nowrap;
}

.breadcrumb a {
  color: var(--bulma-breadcrumb-item-color);
  padding: var(--bulma-breadcrumb-item-padding-vertical) var(--bulma-breadcrumb-item-padding-horizontal);
  justify-content: center;
  align-items: center;
  display: flex;
}

.breadcrumb a:hover {
  color: var(--bulma-breadcrumb-item-hover-color);
}

.breadcrumb li {
  align-items: center;
  display: flex;
}

.breadcrumb li:first-child a {
  padding-inline-start: 0;
}

.breadcrumb li.is-active a {
  color: var(--bulma-breadcrumb-item-active-color);
  cursor: default;
  pointer-events: none;
}

.breadcrumb li + li:before {
  color: var(--bulma-breadcrumb-item-separator-color);
  content: "/";
}

.breadcrumb ul, .breadcrumb ol {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.breadcrumb .icon:first-child {
  margin-inline-end: .5em;
}

.breadcrumb .icon:last-child {
  margin-inline-start: .5em;
}

.breadcrumb.is-centered ol, .breadcrumb.is-centered ul {
  justify-content: center;
}

.breadcrumb.is-right ol, .breadcrumb.is-right ul {
  justify-content: flex-end;
}

.breadcrumb.is-small {
  font-size: var(--bulma-size-small);
}

.breadcrumb.is-medium {
  font-size: var(--bulma-size-medium);
}

.breadcrumb.is-large {
  font-size: var(--bulma-size-large);
}

.breadcrumb.has-arrow-separator li + li:before {
  content: "→";
}

.breadcrumb.has-bullet-separator li + li:before {
  content: "•";
}

.breadcrumb.has-dot-separator li + li:before {
  content: "·";
}

.breadcrumb.has-succeeds-separator li + li:before {
  content: "≻";
}

.card {
  --bulma-card-color: var(--bulma-text);
  --bulma-card-background-color: var(--bulma-scheme-main);
  --bulma-card-shadow: var(--bulma-shadow);
  --bulma-card-radius: .75rem;
  --bulma-card-header-background-color: transparent;
  --bulma-card-header-color: var(--bulma-text-strong);
  --bulma-card-header-padding: .75rem 1rem;
  --bulma-card-header-shadow: 0 .125em .25em hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), .1);
  --bulma-card-header-weight: var(--bulma-weight-bold);
  --bulma-card-content-background-color: transparent;
  --bulma-card-content-padding: 1.5rem;
  --bulma-card-footer-background-color: transparent;
  --bulma-card-footer-border-top: 1px solid var(--bulma-border-weak);
  --bulma-card-footer-padding: .75rem;
  --bulma-card-media-margin: var(--bulma-block-spacing);
  background-color: var(--bulma-card-background-color);
  border-radius: var(--bulma-card-radius);
  box-shadow: var(--bulma-card-shadow);
  color: var(--bulma-card-color);
  max-width: 100%;
  position: relative;
}

.card-footer:first-child, .card-content:first-child, .card-header:first-child {
  border-start-start-radius: var(--bulma-card-radius);
  border-start-end-radius: var(--bulma-card-radius);
}

.card-footer:last-child, .card-content:last-child, .card-header:last-child {
  border-end-end-radius: var(--bulma-card-radius);
  border-end-start-radius: var(--bulma-card-radius);
}

.card-header {
  background-color: var(--bulma-card-header-background-color);
  box-shadow: var(--bulma-card-header-shadow);
  align-items: stretch;
  display: flex;
}

.card-header-title {
  color: var(--bulma-card-header-color);
  font-weight: var(--bulma-card-header-weight);
  padding: var(--bulma-card-header-padding);
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.card-header-title.is-centered {
  justify-content: center;
}

.card-header-icon {
  appearance: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
  padding: var(--bulma-card-header-padding);
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-family: inherit;
  font-size: 1em;
  display: flex;
}

.card-image {
  display: block;
  position: relative;
}

.card-image:first-child img {
  border-start-start-radius: var(--bulma-card-radius);
  border-start-end-radius: var(--bulma-card-radius);
}

.card-image:last-child img {
  border-end-end-radius: var(--bulma-card-radius);
  border-end-start-radius: var(--bulma-card-radius);
}

.card-content {
  background-color: var(--bulma-card-content-background-color);
  padding: var(--bulma-card-content-padding);
}

.card-footer {
  background-color: var(--bulma-card-footer-background-color);
  border-top: var(--bulma-card-footer-border-top);
  align-items: stretch;
  display: flex;
}

.card-footer-item {
  padding: var(--bulma-card-footer-padding);
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card-footer-item:not(:last-child) {
  border-inline-end: var(--bulma-card-footer-border-top);
}

.card .media:not(:last-child) {
  margin-bottom: var(--bulma-card-media-margin);
}

.dropdown {
  --bulma-dropdown-menu-min-width: 12rem;
  --bulma-dropdown-content-background-color: var(--bulma-scheme-main);
  --bulma-dropdown-content-offset: .25rem;
  --bulma-dropdown-content-padding-bottom: .5rem;
  --bulma-dropdown-content-padding-top: .5rem;
  --bulma-dropdown-content-radius: var(--bulma-radius);
  --bulma-dropdown-content-shadow: var(--bulma-shadow);
  --bulma-dropdown-content-z: 20;
  --bulma-dropdown-item-h: var(--bulma-scheme-h);
  --bulma-dropdown-item-s: var(--bulma-scheme-s);
  --bulma-dropdown-item-l: var(--bulma-scheme-main-l);
  --bulma-dropdown-item-background-l: var(--bulma-scheme-main-l);
  --bulma-dropdown-item-background-l-delta: 0%;
  --bulma-dropdown-item-hover-background-l-delta: var(--bulma-hover-background-l-delta);
  --bulma-dropdown-item-active-background-l-delta: var(--bulma-active-background-l-delta);
  --bulma-dropdown-item-color-l: var(--bulma-text-strong-l);
  --bulma-dropdown-item-selected-h: var(--bulma-link-h);
  --bulma-dropdown-item-selected-s: var(--bulma-link-s);
  --bulma-dropdown-item-selected-l: var(--bulma-link-l);
  --bulma-dropdown-item-selected-background-l: var(--bulma-link-l);
  --bulma-dropdown-item-selected-color-l: var(--bulma-link-invert-l);
  --bulma-dropdown-divider-background-color: var(--bulma-border-weak);
  vertical-align: top;
  display: inline-flex;
  position: relative;
}

.dropdown.is-active .dropdown-menu, .dropdown.is-hoverable:hover .dropdown-menu {
  display: block;
}

.dropdown.is-right .dropdown-menu {
  left: auto;
  right: 0;
}

.dropdown.is-up .dropdown-menu {
  padding-bottom: var(--bulma-dropdown-content-offset);
  padding-top: initial;
  top: auto;
  bottom: 100%;
}

.dropdown-menu {
  min-width: var(--bulma-dropdown-menu-min-width);
  padding-top: var(--bulma-dropdown-content-offset);
  z-index: var(--bulma-dropdown-content-z);
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-content {
  background-color: var(--bulma-dropdown-content-background-color);
  border-radius: var(--bulma-dropdown-content-radius);
  box-shadow: var(--bulma-dropdown-content-shadow);
  padding-bottom: var(--bulma-dropdown-content-padding-bottom);
  padding-top: var(--bulma-dropdown-content-padding-top);
}

.dropdown-item {
  color: hsl(var(--bulma-dropdown-item-h), var(--bulma-dropdown-item-s), var(--bulma-dropdown-item-color-l));
  padding: .375rem 1rem;
  font-size: .875rem;
  line-height: 1.5;
  display: block;
}

a.dropdown-item, button.dropdown-item {
  background-color: hsl(var(--bulma-dropdown-item-h), var(--bulma-dropdown-item-s), calc(var(--bulma-dropdown-item-background-l)  + var(--bulma-dropdown-item-background-l-delta)));
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
  padding-inline-end: 3rem;
}

a.dropdown-item:hover, button.dropdown-item:hover {
  --bulma-dropdown-item-background-l-delta: var(--bulma-dropdown-item-hover-background-l-delta);
  --bulma-dropdown-item-border-l-delta: var(--bulma-dropdown-item-hover-border-l-delta);
}

a.dropdown-item:active, button.dropdown-item:active {
  --bulma-dropdown-item-background-l-delta: var(--bulma-dropdown-item-active-background-l-delta);
  --bulma-dropdown-item-border-l-delta: var(--bulma-dropdown-item-active-border-l-delta);
}

a.dropdown-item.is-active, a.dropdown-item.is-selected, button.dropdown-item.is-active, button.dropdown-item.is-selected {
  --bulma-dropdown-item-h: var(--bulma-dropdown-item-selected-h);
  --bulma-dropdown-item-s: var(--bulma-dropdown-item-selected-s);
  --bulma-dropdown-item-l: var(--bulma-dropdown-item-selected-l);
  --bulma-dropdown-item-background-l: var(--bulma-dropdown-item-selected-background-l);
  --bulma-dropdown-item-color-l: var(--bulma-dropdown-item-selected-color-l);
}

.dropdown-divider {
  background-color: var(--bulma-dropdown-divider-background-color);
  border: none;
  height: 1px;
  margin: .5rem 0;
  display: block;
}

.menu {
  --bulma-menu-item-h: var(--bulma-scheme-h);
  --bulma-menu-item-s: var(--bulma-scheme-s);
  --bulma-menu-item-l: var(--bulma-scheme-main-l);
  --bulma-menu-item-background-l: var(--bulma-scheme-main-l);
  --bulma-menu-item-background-l-delta: 0%;
  --bulma-menu-item-hover-background-l-delta: var(--bulma-hover-background-l-delta);
  --bulma-menu-item-active-background-l-delta: var(--bulma-active-background-l-delta);
  --bulma-menu-item-color-l: var(--bulma-text-l);
  --bulma-menu-item-radius: var(--bulma-radius-small);
  --bulma-menu-item-selected-h: var(--bulma-link-h);
  --bulma-menu-item-selected-s: var(--bulma-link-s);
  --bulma-menu-item-selected-l: var(--bulma-link-l);
  --bulma-menu-item-selected-background-l: var(--bulma-link-l);
  --bulma-menu-item-selected-color-l: var(--bulma-link-invert-l);
  --bulma-menu-list-border-left: 1px solid var(--bulma-border);
  --bulma-menu-list-line-height: 1.25;
  --bulma-menu-list-link-padding: .5em .75em;
  --bulma-menu-nested-list-margin: .75em;
  --bulma-menu-nested-list-padding-left: .75em;
  --bulma-menu-label-color: var(--bulma-text-weak);
  --bulma-menu-label-font-size: .75em;
  --bulma-menu-label-letter-spacing: .1em;
  --bulma-menu-label-spacing: 1em;
  font-size: var(--bulma-size-normal);
}

.menu.is-small {
  font-size: var(--bulma-size-small);
}

.menu.is-medium {
  font-size: var(--bulma-size-medium);
}

.menu.is-large {
  font-size: var(--bulma-size-large);
}

.menu-list {
  line-height: var(--bulma-menu-list-line-height);
}

.menu-list a, .menu-list button, .menu-list .menu-item {
  background-color: hsl(var(--bulma-menu-item-h), var(--bulma-menu-item-s), calc(var(--bulma-menu-item-background-l)  + var(--bulma-menu-item-background-l-delta)));
  border-radius: var(--bulma-menu-item-radius);
  color: hsl(var(--bulma-menu-item-h), var(--bulma-menu-item-s), var(--bulma-menu-item-color-l));
  padding: var(--bulma-menu-list-link-padding);
  text-align: left;
  width: 100%;
  display: block;
}

.menu-list a:hover, .menu-list button:hover, .menu-list .menu-item:hover {
  --bulma-menu-item-background-l-delta: var(--bulma-menu-item-hover-background-l-delta);
}

.menu-list a:active, .menu-list button:active, .menu-list .menu-item:active {
  --bulma-menu-item-background-l-delta: var(--bulma-menu-item-active-background-l-delta);
}

.menu-list a.is-active, .menu-list a.is-selected, .menu-list button.is-active, .menu-list button.is-selected, .menu-list .menu-item.is-active, .menu-list .menu-item.is-selected {
  --bulma-menu-item-h: var(--bulma-menu-item-selected-h);
  --bulma-menu-item-s: var(--bulma-menu-item-selected-s);
  --bulma-menu-item-l: var(--bulma-menu-item-selected-l);
  --bulma-menu-item-background-l: var(--bulma-menu-item-selected-background-l);
  --bulma-menu-item-color-l: var(--bulma-menu-item-selected-color-l);
}

.menu-list li ul {
  border-inline-start: var(--bulma-menu-list-border-left);
  margin: var(--bulma-menu-nested-list-margin);
  padding-inline-start: var(--bulma-menu-nested-list-padding-left);
}

.menu-label {
  color: var(--bulma-menu-label-color);
  font-size: var(--bulma-menu-label-font-size);
  letter-spacing: var(--bulma-menu-label-letter-spacing);
  text-transform: uppercase;
}

.menu-label:not(:first-child) {
  margin-top: var(--bulma-menu-label-spacing);
}

.menu-label:not(:last-child) {
  margin-bottom: var(--bulma-menu-label-spacing);
}

.message {
  --bulma-message-border-l-delta: -20%;
  --bulma-message-radius: var(--bulma-radius);
  --bulma-message-header-weight: var(--bulma-weight-semibold);
  --bulma-message-header-padding: 1em 1.25em;
  --bulma-message-header-radius: var(--bulma-radius);
  --bulma-message-body-border-width: 0 0 0 4px;
  --bulma-message-body-color: var(--bulma-text);
  --bulma-message-body-padding: 1.25em 1.5em;
  --bulma-message-body-radius: var(--bulma-radius-small);
  --bulma-message-body-pre-code-background-color: transparent;
  --bulma-message-header-body-border-width: 0;
  --bulma-message-h: var(--bulma-scheme-h);
  --bulma-message-s: var(--bulma-scheme-s);
  --bulma-message-background-l: var(--bulma-background-l);
  --bulma-message-border-l: var(--bulma-border-l);
  --bulma-message-border-style: solid;
  --bulma-message-border-width: .25em;
  --bulma-message-color-l: var(--bulma-text-l);
  --bulma-message-header-background-l: var(--bulma-dark-l);
  --bulma-message-header-color-l: var(--bulma-text-dark-invert-l);
  border-radius: var(--bulma-message-radius);
  color: hsl(var(--bulma-message-h), var(--bulma-message-s), var(--bulma-message-color-l));
  font-size: var(--bulma-size-normal);
}

.message strong {
  color: currentColor;
}

.message a:not(.button):not(.tag):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.message.is-small {
  font-size: var(--bulma-size-small);
}

.message.is-medium {
  font-size: var(--bulma-size-medium);
}

.message.is-large {
  font-size: var(--bulma-size-large);
}

.message.is-white {
  --bulma-message-h: var(--bulma-white-h);
  --bulma-message-s: var(--bulma-white-s);
  --bulma-message-border-l: calc(var(--bulma-white-l)  + var(--bulma-message-border-l-delta));
  --bulma-message-color-l: var(--bulma-white-on-scheme-l);
  --bulma-message-header-background-l: var(--bulma-white-l);
  --bulma-message-header-color-l: var(--bulma-white-invert-l);
}

.message.is-black {
  --bulma-message-h: var(--bulma-black-h);
  --bulma-message-s: var(--bulma-black-s);
  --bulma-message-border-l: calc(var(--bulma-black-l)  + var(--bulma-message-border-l-delta));
  --bulma-message-color-l: var(--bulma-black-on-scheme-l);
  --bulma-message-header-background-l: var(--bulma-black-l);
  --bulma-message-header-color-l: var(--bulma-black-invert-l);
}

.message.is-light {
  --bulma-message-h: var(--bulma-light-h);
  --bulma-message-s: var(--bulma-light-s);
  --bulma-message-border-l: calc(var(--bulma-light-l)  + var(--bulma-message-border-l-delta));
  --bulma-message-color-l: var(--bulma-light-on-scheme-l);
  --bulma-message-header-background-l: var(--bulma-light-l);
  --bulma-message-header-color-l: var(--bulma-light-invert-l);
}

.message.is-dark {
  --bulma-message-h: var(--bulma-dark-h);
  --bulma-message-s: var(--bulma-dark-s);
  --bulma-message-border-l: calc(var(--bulma-dark-l)  + var(--bulma-message-border-l-delta));
  --bulma-message-color-l: var(--bulma-dark-on-scheme-l);
  --bulma-message-header-background-l: var(--bulma-dark-l);
  --bulma-message-header-color-l: var(--bulma-dark-invert-l);
}

.message.is-text {
  --bulma-message-h: var(--bulma-text-h);
  --bulma-message-s: var(--bulma-text-s);
  --bulma-message-border-l: calc(var(--bulma-text-l)  + var(--bulma-message-border-l-delta));
  --bulma-message-color-l: var(--bulma-text-on-scheme-l);
  --bulma-message-header-background-l: var(--bulma-text-l);
  --bulma-message-header-color-l: var(--bulma-text-invert-l);
}

.message.is-primary {
  --bulma-message-h: var(--bulma-primary-h);
  --bulma-message-s: var(--bulma-primary-s);
  --bulma-message-border-l: calc(var(--bulma-primary-l)  + var(--bulma-message-border-l-delta));
  --bulma-message-color-l: var(--bulma-primary-on-scheme-l);
  --bulma-message-header-background-l: var(--bulma-primary-l);
  --bulma-message-header-color-l: var(--bulma-primary-invert-l);
}

.message.is-link {
  --bulma-message-h: var(--bulma-link-h);
  --bulma-message-s: var(--bulma-link-s);
  --bulma-message-border-l: calc(var(--bulma-link-l)  + var(--bulma-message-border-l-delta));
  --bulma-message-color-l: var(--bulma-link-on-scheme-l);
  --bulma-message-header-background-l: var(--bulma-link-l);
  --bulma-message-header-color-l: var(--bulma-link-invert-l);
}

.message.is-info {
  --bulma-message-h: var(--bulma-info-h);
  --bulma-message-s: var(--bulma-info-s);
  --bulma-message-border-l: calc(var(--bulma-info-l)  + var(--bulma-message-border-l-delta));
  --bulma-message-color-l: var(--bulma-info-on-scheme-l);
  --bulma-message-header-background-l: var(--bulma-info-l);
  --bulma-message-header-color-l: var(--bulma-info-invert-l);
}

.message.is-success {
  --bulma-message-h: var(--bulma-success-h);
  --bulma-message-s: var(--bulma-success-s);
  --bulma-message-border-l: calc(var(--bulma-success-l)  + var(--bulma-message-border-l-delta));
  --bulma-message-color-l: var(--bulma-success-on-scheme-l);
  --bulma-message-header-background-l: var(--bulma-success-l);
  --bulma-message-header-color-l: var(--bulma-success-invert-l);
}

.message.is-warning {
  --bulma-message-h: var(--bulma-warning-h);
  --bulma-message-s: var(--bulma-warning-s);
  --bulma-message-border-l: calc(var(--bulma-warning-l)  + var(--bulma-message-border-l-delta));
  --bulma-message-color-l: var(--bulma-warning-on-scheme-l);
  --bulma-message-header-background-l: var(--bulma-warning-l);
  --bulma-message-header-color-l: var(--bulma-warning-invert-l);
}

.message.is-danger {
  --bulma-message-h: var(--bulma-danger-h);
  --bulma-message-s: var(--bulma-danger-s);
  --bulma-message-border-l: calc(var(--bulma-danger-l)  + var(--bulma-message-border-l-delta));
  --bulma-message-color-l: var(--bulma-danger-on-scheme-l);
  --bulma-message-header-background-l: var(--bulma-danger-l);
  --bulma-message-header-color-l: var(--bulma-danger-invert-l);
}

.message-header {
  background-color: hsl(var(--bulma-message-h), var(--bulma-message-s), var(--bulma-message-header-background-l));
  color: hsl(var(--bulma-message-h), var(--bulma-message-s), var(--bulma-message-header-color-l));
  font-weight: var(--bulma-message-header-weight);
  padding: var(--bulma-message-header-padding);
  border-start-start-radius: var(--bulma-message-header-radius);
  border-start-end-radius: var(--bulma-message-header-radius);
  justify-content: space-between;
  align-items: center;
  line-height: 1.25;
  display: flex;
  position: relative;
}

.message-header .delete {
  flex-grow: 0;
  flex-shrink: 0;
  margin-inline-start: .75em;
}

.message-header + .message-body {
  border-width: var(--bulma-message-header-body-border-width);
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.message-body {
  background-color: hsl(var(--bulma-message-h), var(--bulma-message-s), var(--bulma-message-background-l));
  border-inline-start-color: hsl(var(--bulma-message-h), var(--bulma-message-s), var(--bulma-message-border-l));
  border-inline-start-style: var(--bulma-message-border-style);
  border-inline-start-width: var(--bulma-message-border-width);
  border-radius: var(--bulma-message-body-radius);
  padding: var(--bulma-message-body-padding);
}

.message-body code, .message-body pre {
  background-color: hsl(var(--bulma-message-h), var(--bulma-message-s), var(--bulma-message-header-color-l));
  color: hsl(var(--bulma-message-h), var(--bulma-message-s), var(--bulma-message-header-background-l));
}

.message-body pre code {
  background-color: var(--bulma-message-body-pre-code-background-color);
}

.modal {
  --bulma-modal-z: 40;
  --bulma-modal-background-background-color: hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), .86);
  --bulma-modal-content-width: 40rem;
  --bulma-modal-content-margin-mobile: 1.25rem;
  --bulma-modal-content-spacing-mobile: 10rem;
  --bulma-modal-content-spacing-tablet: 2.5rem;
  --bulma-modal-close-dimensions: 2.5rem;
  --bulma-modal-close-right: 1.25rem;
  --bulma-modal-close-top: 1.25rem;
  --bulma-modal-card-spacing: 2.5rem;
  --bulma-modal-card-head-background-color: var(--bulma-scheme-main);
  --bulma-modal-card-head-padding: 2rem;
  --bulma-modal-card-head-radius: var(--bulma-radius-large);
  --bulma-modal-card-title-color: var(--bulma-text-strong);
  --bulma-modal-card-title-line-height: 1;
  --bulma-modal-card-title-size: var(--bulma-size-4);
  --bulma-modal-card-foot-background-color: var(--bulma-scheme-main-bis);
  --bulma-modal-card-foot-radius: var(--bulma-radius-large);
  --bulma-modal-card-body-background-color: var(--bulma-scheme-main);
  --bulma-modal-card-body-padding: 2rem;
  z-index: var(--bulma-modal-z);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  overflow: hidden;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: var(--bulma-modal-background-background-color);
}

.modal-content, .modal-card {
  margin: 0 var(--bulma-modal-content-margin-mobile);
  max-height: calc(100vh - var(--bulma-modal-content-spacing-mobile));
  width: 100%;
  position: relative;
  overflow: auto;
}

@media screen and (width >= 769px) {
  .modal-content, .modal-card {
    max-height: calc(100vh - var(--bulma-modal-content-spacing-tablet));
    width: var(--bulma-modal-content-width);
    margin: 0 auto;
  }
}

.modal-close {
  height: var(--bulma-modal-close-dimensions);
  inset-inline-end: var(--bulma-modal-close-right);
  top: var(--bulma-modal-close-top);
  width: var(--bulma-modal-close-dimensions);
  background: none;
  position: fixed;
}

.modal-card {
  max-height: calc(100vh - var(--bulma-modal-card-spacing));
  flex-direction: column;
  display: flex;
  overflow: hidden visible;
}

.modal-card-head, .modal-card-foot {
  padding: var(--bulma-modal-card-head-padding);
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
}

.modal-card-head {
  background-color: var(--bulma-modal-card-head-background-color);
  box-shadow: var(--bulma-shadow);
  border-start-start-radius: var(--bulma-modal-card-head-radius);
  border-start-end-radius: var(--bulma-modal-card-head-radius);
}

.modal-card-title {
  color: var(--bulma-modal-card-title-color);
  font-size: var(--bulma-modal-card-title-size);
  line-height: var(--bulma-modal-card-title-line-height);
  flex-grow: 1;
  flex-shrink: 0;
}

.modal-card-foot {
  background-color: var(--bulma-modal-card-foot-background-color);
  border-end-end-radius: var(--bulma-modal-card-foot-radius);
  border-end-start-radius: var(--bulma-modal-card-foot-radius);
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: var(--bulma-modal-card-body-background-color);
  padding: var(--bulma-modal-card-body-padding);
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
}

:root {
  --bulma-navbar-height: 3.25rem;
}

.navbar {
  --bulma-navbar-h: var(--bulma-scheme-h);
  --bulma-navbar-s: var(--bulma-scheme-s);
  --bulma-navbar-l: var(--bulma-scheme-main-l);
  --bulma-navbar-background-color: var(--bulma-scheme-main);
  --bulma-navbar-box-shadow-size: 0 .125em 0 0;
  --bulma-navbar-box-shadow-color: var(--bulma-background);
  --bulma-navbar-padding-vertical: 1rem;
  --bulma-navbar-padding-horizontal: 2rem;
  --bulma-navbar-z: 30;
  --bulma-navbar-fixed-z: 30;
  --bulma-navbar-item-background-a: 0;
  --bulma-navbar-item-background-l: var(--bulma-scheme-main-l);
  --bulma-navbar-item-background-l-delta: 0%;
  --bulma-navbar-item-hover-background-l-delta: var(--bulma-hover-background-l-delta);
  --bulma-navbar-item-active-background-l-delta: var(--bulma-active-background-l-delta);
  --bulma-navbar-item-color-l: var(--bulma-text-l);
  --bulma-navbar-item-color: hsl(var(--bulma-navbar-h), var(--bulma-navbar-s), var(--bulma-navbar-item-color-l));
  --bulma-navbar-item-selected-h: var(--bulma-link-h);
  --bulma-navbar-item-selected-s: var(--bulma-link-s);
  --bulma-navbar-item-selected-l: var(--bulma-link-l);
  --bulma-navbar-item-selected-background-l: var(--bulma-link-l);
  --bulma-navbar-item-selected-color-l: var(--bulma-link-invert-l);
  --bulma-navbar-item-img-max-height: 1.75rem;
  --bulma-navbar-burger-color: var(--bulma-link);
  --bulma-navbar-tab-hover-background-color: transparent;
  --bulma-navbar-tab-hover-border-bottom-color: var(--bulma-link);
  --bulma-navbar-tab-active-color: var(--bulma-link);
  --bulma-navbar-tab-active-background-color: transparent;
  --bulma-navbar-tab-active-border-bottom-color: var(--bulma-link);
  --bulma-navbar-tab-active-border-bottom-style: solid;
  --bulma-navbar-tab-active-border-bottom-width: .1875em;
  --bulma-navbar-dropdown-background-color: var(--bulma-scheme-main);
  --bulma-navbar-dropdown-border-l: var(--bulma-border-l);
  --bulma-navbar-dropdown-border-color: hsl(var(--bulma-navbar-h), var(--bulma-navbar-s), var(--bulma-navbar-dropdown-border-l));
  --bulma-navbar-dropdown-border-style: solid;
  --bulma-navbar-dropdown-border-width: .125em;
  --bulma-navbar-dropdown-offset: -.25em;
  --bulma-navbar-dropdown-arrow: var(--bulma-link);
  --bulma-navbar-dropdown-radius: var(--bulma-radius-large);
  --bulma-navbar-dropdown-z: 20;
  --bulma-navbar-dropdown-boxed-radius: var(--bulma-radius-large);
  --bulma-navbar-dropdown-boxed-shadow: 0 .5em .5em hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), .1), 0 0 0 1px hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), .1);
  --bulma-navbar-dropdown-item-h: var(--bulma-scheme-h);
  --bulma-navbar-dropdown-item-s: var(--bulma-scheme-s);
  --bulma-navbar-dropdown-item-l: var(--bulma-scheme-main-l);
  --bulma-navbar-dropdown-item-background-l: var(--bulma-scheme-main-l);
  --bulma-navbar-dropdown-item-color-l: var(--bulma-text-l);
  --bulma-navbar-divider-background-l: var(--bulma-background-l);
  --bulma-navbar-divider-height: .125em;
  --bulma-navbar-bottom-box-shadow-size: 0 -.125em 0 0;
  background-color: var(--bulma-navbar-background-color);
  min-height: var(--bulma-navbar-height);
  z-index: var(--bulma-navbar-z);
  position: relative;
}

.navbar.is-white {
  --bulma-navbar-h: var(--bulma-white-h);
  --bulma-navbar-s: var(--bulma-white-s);
  --bulma-navbar-l: var(--bulma-white-l);
  --bulma-burger-h: var(--bulma-white-h);
  --bulma-burger-s: var(--bulma-white-s);
  --bulma-burger-l: var(--bulma-white-invert-l);
  --bulma-navbar-background-color: var(--bulma-white);
  --bulma-navbar-item-background-l: var(--bulma-white-l);
  --bulma-navbar-item-color-l: var(--bulma-white-invert-l);
  --bulma-navbar-item-selected-h: var(--bulma-white-h);
  --bulma-navbar-item-selected-s: var(--bulma-white-s);
  --bulma-navbar-item-selected-l: var(--bulma-white-l);
  --bulma-navbar-item-selected-background-l: var(--bulma-white-l);
  --bulma-navbar-item-selected-color-l: var(--bulma-white-invert-l);
  --bulma-navbar-dropdown-arrow: var(--bulma-white-invert-l);
  --bulma-navbar-dropdown-background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-navbar-dropdown-item-background-l));
  --bulma-navbar-dropdown-item-h: var(--bulma-white-h);
  --bulma-navbar-dropdown-item-s: var(--bulma-white-s);
}

.navbar.is-black {
  --bulma-navbar-h: var(--bulma-black-h);
  --bulma-navbar-s: var(--bulma-black-s);
  --bulma-navbar-l: var(--bulma-black-l);
  --bulma-burger-h: var(--bulma-black-h);
  --bulma-burger-s: var(--bulma-black-s);
  --bulma-burger-l: var(--bulma-black-invert-l);
  --bulma-navbar-background-color: var(--bulma-black);
  --bulma-navbar-item-background-l: var(--bulma-black-l);
  --bulma-navbar-item-color-l: var(--bulma-black-invert-l);
  --bulma-navbar-item-selected-h: var(--bulma-black-h);
  --bulma-navbar-item-selected-s: var(--bulma-black-s);
  --bulma-navbar-item-selected-l: var(--bulma-black-l);
  --bulma-navbar-item-selected-background-l: var(--bulma-black-l);
  --bulma-navbar-item-selected-color-l: var(--bulma-black-invert-l);
  --bulma-navbar-dropdown-arrow: var(--bulma-black-invert-l);
  --bulma-navbar-dropdown-background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-navbar-dropdown-item-background-l));
  --bulma-navbar-dropdown-item-h: var(--bulma-black-h);
  --bulma-navbar-dropdown-item-s: var(--bulma-black-s);
}

.navbar.is-light {
  --bulma-navbar-h: var(--bulma-light-h);
  --bulma-navbar-s: var(--bulma-light-s);
  --bulma-navbar-l: var(--bulma-light-l);
  --bulma-burger-h: var(--bulma-light-h);
  --bulma-burger-s: var(--bulma-light-s);
  --bulma-burger-l: var(--bulma-light-invert-l);
  --bulma-navbar-background-color: var(--bulma-light);
  --bulma-navbar-item-background-l: var(--bulma-light-l);
  --bulma-navbar-item-color-l: var(--bulma-light-invert-l);
  --bulma-navbar-item-selected-h: var(--bulma-light-h);
  --bulma-navbar-item-selected-s: var(--bulma-light-s);
  --bulma-navbar-item-selected-l: var(--bulma-light-l);
  --bulma-navbar-item-selected-background-l: var(--bulma-light-l);
  --bulma-navbar-item-selected-color-l: var(--bulma-light-invert-l);
  --bulma-navbar-dropdown-arrow: var(--bulma-light-invert-l);
  --bulma-navbar-dropdown-background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-navbar-dropdown-item-background-l));
  --bulma-navbar-dropdown-item-h: var(--bulma-light-h);
  --bulma-navbar-dropdown-item-s: var(--bulma-light-s);
}

.navbar.is-dark {
  --bulma-navbar-h: var(--bulma-dark-h);
  --bulma-navbar-s: var(--bulma-dark-s);
  --bulma-navbar-l: var(--bulma-dark-l);
  --bulma-burger-h: var(--bulma-dark-h);
  --bulma-burger-s: var(--bulma-dark-s);
  --bulma-burger-l: var(--bulma-dark-invert-l);
  --bulma-navbar-background-color: var(--bulma-dark);
  --bulma-navbar-item-background-l: var(--bulma-dark-l);
  --bulma-navbar-item-color-l: var(--bulma-dark-invert-l);
  --bulma-navbar-item-selected-h: var(--bulma-dark-h);
  --bulma-navbar-item-selected-s: var(--bulma-dark-s);
  --bulma-navbar-item-selected-l: var(--bulma-dark-l);
  --bulma-navbar-item-selected-background-l: var(--bulma-dark-l);
  --bulma-navbar-item-selected-color-l: var(--bulma-dark-invert-l);
  --bulma-navbar-dropdown-arrow: var(--bulma-dark-invert-l);
  --bulma-navbar-dropdown-background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-navbar-dropdown-item-background-l));
  --bulma-navbar-dropdown-item-h: var(--bulma-dark-h);
  --bulma-navbar-dropdown-item-s: var(--bulma-dark-s);
}

.navbar.is-text {
  --bulma-navbar-h: var(--bulma-text-h);
  --bulma-navbar-s: var(--bulma-text-s);
  --bulma-navbar-l: var(--bulma-text-l);
  --bulma-burger-h: var(--bulma-text-h);
  --bulma-burger-s: var(--bulma-text-s);
  --bulma-burger-l: var(--bulma-text-invert-l);
  --bulma-navbar-background-color: var(--bulma-text);
  --bulma-navbar-item-background-l: var(--bulma-text-l);
  --bulma-navbar-item-color-l: var(--bulma-text-invert-l);
  --bulma-navbar-item-selected-h: var(--bulma-text-h);
  --bulma-navbar-item-selected-s: var(--bulma-text-s);
  --bulma-navbar-item-selected-l: var(--bulma-text-l);
  --bulma-navbar-item-selected-background-l: var(--bulma-text-l);
  --bulma-navbar-item-selected-color-l: var(--bulma-text-invert-l);
  --bulma-navbar-dropdown-arrow: var(--bulma-text-invert-l);
  --bulma-navbar-dropdown-background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-navbar-dropdown-item-background-l));
  --bulma-navbar-dropdown-item-h: var(--bulma-text-h);
  --bulma-navbar-dropdown-item-s: var(--bulma-text-s);
}

.navbar.is-primary {
  --bulma-navbar-h: var(--bulma-primary-h);
  --bulma-navbar-s: var(--bulma-primary-s);
  --bulma-navbar-l: var(--bulma-primary-l);
  --bulma-burger-h: var(--bulma-primary-h);
  --bulma-burger-s: var(--bulma-primary-s);
  --bulma-burger-l: var(--bulma-primary-invert-l);
  --bulma-navbar-background-color: var(--bulma-primary);
  --bulma-navbar-item-background-l: var(--bulma-primary-l);
  --bulma-navbar-item-color-l: var(--bulma-primary-invert-l);
  --bulma-navbar-item-selected-h: var(--bulma-primary-h);
  --bulma-navbar-item-selected-s: var(--bulma-primary-s);
  --bulma-navbar-item-selected-l: var(--bulma-primary-l);
  --bulma-navbar-item-selected-background-l: var(--bulma-primary-l);
  --bulma-navbar-item-selected-color-l: var(--bulma-primary-invert-l);
  --bulma-navbar-dropdown-arrow: var(--bulma-primary-invert-l);
  --bulma-navbar-dropdown-background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-navbar-dropdown-item-background-l));
  --bulma-navbar-dropdown-item-h: var(--bulma-primary-h);
  --bulma-navbar-dropdown-item-s: var(--bulma-primary-s);
}

.navbar.is-link {
  --bulma-navbar-h: var(--bulma-link-h);
  --bulma-navbar-s: var(--bulma-link-s);
  --bulma-navbar-l: var(--bulma-link-l);
  --bulma-burger-h: var(--bulma-link-h);
  --bulma-burger-s: var(--bulma-link-s);
  --bulma-burger-l: var(--bulma-link-invert-l);
  --bulma-navbar-background-color: var(--bulma-link);
  --bulma-navbar-item-background-l: var(--bulma-link-l);
  --bulma-navbar-item-color-l: var(--bulma-link-invert-l);
  --bulma-navbar-item-selected-h: var(--bulma-link-h);
  --bulma-navbar-item-selected-s: var(--bulma-link-s);
  --bulma-navbar-item-selected-l: var(--bulma-link-l);
  --bulma-navbar-item-selected-background-l: var(--bulma-link-l);
  --bulma-navbar-item-selected-color-l: var(--bulma-link-invert-l);
  --bulma-navbar-dropdown-arrow: var(--bulma-link-invert-l);
  --bulma-navbar-dropdown-background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-navbar-dropdown-item-background-l));
  --bulma-navbar-dropdown-item-h: var(--bulma-link-h);
  --bulma-navbar-dropdown-item-s: var(--bulma-link-s);
}

.navbar.is-info {
  --bulma-navbar-h: var(--bulma-info-h);
  --bulma-navbar-s: var(--bulma-info-s);
  --bulma-navbar-l: var(--bulma-info-l);
  --bulma-burger-h: var(--bulma-info-h);
  --bulma-burger-s: var(--bulma-info-s);
  --bulma-burger-l: var(--bulma-info-invert-l);
  --bulma-navbar-background-color: var(--bulma-info);
  --bulma-navbar-item-background-l: var(--bulma-info-l);
  --bulma-navbar-item-color-l: var(--bulma-info-invert-l);
  --bulma-navbar-item-selected-h: var(--bulma-info-h);
  --bulma-navbar-item-selected-s: var(--bulma-info-s);
  --bulma-navbar-item-selected-l: var(--bulma-info-l);
  --bulma-navbar-item-selected-background-l: var(--bulma-info-l);
  --bulma-navbar-item-selected-color-l: var(--bulma-info-invert-l);
  --bulma-navbar-dropdown-arrow: var(--bulma-info-invert-l);
  --bulma-navbar-dropdown-background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-navbar-dropdown-item-background-l));
  --bulma-navbar-dropdown-item-h: var(--bulma-info-h);
  --bulma-navbar-dropdown-item-s: var(--bulma-info-s);
}

.navbar.is-success {
  --bulma-navbar-h: var(--bulma-success-h);
  --bulma-navbar-s: var(--bulma-success-s);
  --bulma-navbar-l: var(--bulma-success-l);
  --bulma-burger-h: var(--bulma-success-h);
  --bulma-burger-s: var(--bulma-success-s);
  --bulma-burger-l: var(--bulma-success-invert-l);
  --bulma-navbar-background-color: var(--bulma-success);
  --bulma-navbar-item-background-l: var(--bulma-success-l);
  --bulma-navbar-item-color-l: var(--bulma-success-invert-l);
  --bulma-navbar-item-selected-h: var(--bulma-success-h);
  --bulma-navbar-item-selected-s: var(--bulma-success-s);
  --bulma-navbar-item-selected-l: var(--bulma-success-l);
  --bulma-navbar-item-selected-background-l: var(--bulma-success-l);
  --bulma-navbar-item-selected-color-l: var(--bulma-success-invert-l);
  --bulma-navbar-dropdown-arrow: var(--bulma-success-invert-l);
  --bulma-navbar-dropdown-background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-navbar-dropdown-item-background-l));
  --bulma-navbar-dropdown-item-h: var(--bulma-success-h);
  --bulma-navbar-dropdown-item-s: var(--bulma-success-s);
}

.navbar.is-warning {
  --bulma-navbar-h: var(--bulma-warning-h);
  --bulma-navbar-s: var(--bulma-warning-s);
  --bulma-navbar-l: var(--bulma-warning-l);
  --bulma-burger-h: var(--bulma-warning-h);
  --bulma-burger-s: var(--bulma-warning-s);
  --bulma-burger-l: var(--bulma-warning-invert-l);
  --bulma-navbar-background-color: var(--bulma-warning);
  --bulma-navbar-item-background-l: var(--bulma-warning-l);
  --bulma-navbar-item-color-l: var(--bulma-warning-invert-l);
  --bulma-navbar-item-selected-h: var(--bulma-warning-h);
  --bulma-navbar-item-selected-s: var(--bulma-warning-s);
  --bulma-navbar-item-selected-l: var(--bulma-warning-l);
  --bulma-navbar-item-selected-background-l: var(--bulma-warning-l);
  --bulma-navbar-item-selected-color-l: var(--bulma-warning-invert-l);
  --bulma-navbar-dropdown-arrow: var(--bulma-warning-invert-l);
  --bulma-navbar-dropdown-background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-navbar-dropdown-item-background-l));
  --bulma-navbar-dropdown-item-h: var(--bulma-warning-h);
  --bulma-navbar-dropdown-item-s: var(--bulma-warning-s);
}

.navbar.is-danger {
  --bulma-navbar-h: var(--bulma-danger-h);
  --bulma-navbar-s: var(--bulma-danger-s);
  --bulma-navbar-l: var(--bulma-danger-l);
  --bulma-burger-h: var(--bulma-danger-h);
  --bulma-burger-s: var(--bulma-danger-s);
  --bulma-burger-l: var(--bulma-danger-invert-l);
  --bulma-navbar-background-color: var(--bulma-danger);
  --bulma-navbar-item-background-l: var(--bulma-danger-l);
  --bulma-navbar-item-color-l: var(--bulma-danger-invert-l);
  --bulma-navbar-item-selected-h: var(--bulma-danger-h);
  --bulma-navbar-item-selected-s: var(--bulma-danger-s);
  --bulma-navbar-item-selected-l: var(--bulma-danger-l);
  --bulma-navbar-item-selected-background-l: var(--bulma-danger-l);
  --bulma-navbar-item-selected-color-l: var(--bulma-danger-invert-l);
  --bulma-navbar-dropdown-arrow: var(--bulma-danger-invert-l);
  --bulma-navbar-dropdown-background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-navbar-dropdown-item-background-l));
  --bulma-navbar-dropdown-item-h: var(--bulma-danger-h);
  --bulma-navbar-dropdown-item-s: var(--bulma-danger-s);
}

.navbar > .container {
  min-height: var(--bulma-navbar-height);
  align-items: stretch;
  width: 100%;
  display: flex;
}

.navbar.has-shadow {
  box-shadow: var(--bulma-navbar-box-shadow-size) var(--bulma-navbar-box-shadow-color);
}

.navbar.is-fixed-bottom, .navbar.is-fixed-top {
  z-index: var(--bulma-navbar-fixed-z);
  position: fixed;
  left: 0;
  right: 0;
}

.navbar.is-fixed-bottom {
  bottom: 0;
}

.navbar.is-fixed-bottom.has-shadow {
  box-shadow: var(--bulma-navbar-bottom-box-shadow-size) var(--bulma-navbar-box-shadow-color);
}

.navbar.is-fixed-top {
  top: 0;
}

html.has-navbar-fixed-top, body.has-navbar-fixed-top {
  padding-top: var(--bulma-navbar-height);
}

html.has-navbar-fixed-bottom, body.has-navbar-fixed-bottom {
  padding-bottom: var(--bulma-navbar-height);
}

.navbar-brand, .navbar-tabs {
  min-height: var(--bulma-navbar-height);
  flex-shrink: 0;
  align-items: stretch;
  display: flex;
}

.navbar-tabs {
  -webkit-overflow-scrolling: touch;
  max-width: 100vw;
  overflow: auto hidden;
}

.navbar-burger {
  appearance: none;
  border-radius: var(--bulma-burger-border-radius);
  color: hsl(var(--bulma-burger-h), var(--bulma-burger-s), var(--bulma-burger-l));
  cursor: pointer;
  vertical-align: top;
  background: none;
  border: none;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  display: inline-flex;
  position: relative;
}

.navbar-burger span {
  height: var(--bulma-burger-item-height);
  left: calc(50% - (var(--bulma-burger-item-width)) / 2);
  transform-origin: center;
  transition-duration: var(--bulma-duration);
  transition-property: background-color, color, opacity, transform;
  transition-timing-function: var(--bulma-easing);
  width: var(--bulma-burger-item-width);
  background-color: currentColor;
  display: block;
  position: absolute;
}

.navbar-burger span:first-child, .navbar-burger span:nth-child(2) {
  top: calc(50% - (var(--bulma-burger-item-height)) / 2);
}

.navbar-burger span:nth-child(3) {
  bottom: calc(50% + var(--bulma-burger-gap));
}

.navbar-burger span:nth-child(4) {
  top: calc(50% + var(--bulma-burger-gap));
}

.navbar-burger:hover {
  background-color: hsla(var(--bulma-burger-h), var(--bulma-burger-s), var(--bulma-burger-l), .1);
}

.navbar-burger:active {
  background-color: hsla(var(--bulma-burger-h), var(--bulma-burger-s), var(--bulma-burger-l), .2);
}

.navbar-burger.is-active span:first-child {
  transform: rotate(-45deg);
}

.navbar-burger.is-active span:nth-child(2) {
  transform: rotate(45deg);
}

.navbar-burger.is-active span:nth-child(3), .navbar-burger.is-active span:nth-child(4) {
  opacity: 0;
}

.navbar-burger {
  color: var(--bulma-navbar-burger-color);
  align-self: center;
  margin-inline: auto .375rem;
}

.navbar-menu {
  display: none;
}

.navbar-item, .navbar-link {
  color: var(--bulma-navbar-item-color);
  gap: .75rem;
  padding: .5rem .75rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

.navbar-item .icon:only-child, .navbar-link .icon:only-child {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

a.navbar-item, .navbar-link {
  background-color: hsla(var(--bulma-navbar-h), var(--bulma-navbar-s), calc(var(--bulma-navbar-item-background-l)  + var(--bulma-navbar-item-background-l-delta)), var(--bulma-navbar-item-background-a));
  cursor: pointer;
}

a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover {
  --bulma-navbar-item-background-l-delta: var(--bulma-navbar-item-hover-background-l-delta);
  --bulma-navbar-item-background-a: 1;
}

a.navbar-item:active, .navbar-link:active {
  --bulma-navbar-item-background-l-delta: var(--bulma-navbar-item-active-background-l-delta);
  --bulma-navbar-item-background-a: 1;
}

a.navbar-item.is-active, a.navbar-item.is-selected, .navbar-link.is-active, .navbar-link.is-selected {
  --bulma-navbar-h: var(--bulma-navbar-item-selected-h);
  --bulma-navbar-s: var(--bulma-navbar-item-selected-s);
  --bulma-navbar-l: var(--bulma-navbar-item-selected-l);
  --bulma-navbar-item-background-l: var(--bulma-navbar-item-selected-background-l);
  --bulma-navbar-item-background-a: 1;
  --bulma-navbar-item-color-l: var(--bulma-navbar-item-selected-color-l);
}

.navbar-item {
  flex-grow: 0;
  flex-shrink: 0;
}

.navbar-item img, .navbar-item svg {
  max-height: var(--bulma-navbar-item-img-max-height);
}

.navbar-item.has-dropdown {
  padding: 0;
}

.navbar-item.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-item.is-tab {
  min-height: var(--bulma-navbar-height);
  border-bottom: 1px solid #0000;
  padding-bottom: calc(.5rem - 1px);
}

.navbar-item.is-tab:focus, .navbar-item.is-tab:hover {
  background-color: var(--bulma-navbar-tab-hover-background-color);
  border-bottom-color: var(--bulma-navbar-tab-hover-border-bottom-color);
}

.navbar-item.is-tab.is-active {
  background-color: var(--bulma-navbar-tab-active-background-color);
  border-bottom-color: var(--bulma-navbar-tab-active-border-bottom-color);
  border-bottom-style: var(--bulma-navbar-tab-active-border-bottom-style);
  border-bottom-width: var(--bulma-navbar-tab-active-border-bottom-width);
  color: var(--bulma-navbar-tab-active-color);
  padding-bottom: calc(.5rem - var(--bulma-navbar-tab-active-border-bottom-width));
}

.navbar-content {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-link:not(.is-arrowless) {
  padding-inline-end: 2.5em;
}

.navbar-link:not(.is-arrowless):after {
  border-color: var(--bulma-navbar-dropdown-arrow);
  margin-top: -.375em;
  inset-inline-end: 1.125em;
}

.navbar-dropdown {
  padding-top: .5rem;
  padding-bottom: .75rem;
  font-size: .875rem;
}

.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-dropdown .navbar-item:not(.is-active, .is-selected) {
  background-color: hsl(var(--bulma-navbar-dropdown-item-h), var(--bulma-navbar-dropdown-item-s), calc(var(--bulma-navbar-dropdown-item-background-l)  + var(--bulma-navbar-item-background-l-delta)));
  color: hsl(var(--bulma-navbar-dropdown-item-h), var(--bulma-navbar-dropdown-item-s), var(--bulma-navbar-dropdown-item-color-l));
}

.navbar-divider {
  background-color: hsl(var(--bulma-navbar-h), var(--bulma-navbar-s), var(--bulma-navbar-divider-background-l));
  height: var(--bulma-navbar-divider-height);
  border: none;
  margin: .5rem 0;
  display: none;
}

@media screen and (width <= 1023px) {
  .navbar > .container {
    display: block;
  }

  .navbar-brand .navbar-item, .navbar-tabs .navbar-item {
    align-items: center;
    display: flex;
  }

  .navbar-link:after {
    display: none;
  }

  .navbar-menu {
    background-color: var(--bulma-navbar-background-color);
    box-shadow: 0 .5em 1em hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), .1);
    padding: .5rem 0;
  }

  .navbar-menu.is-active {
    display: block;
  }

  .navbar.is-fixed-bottom-touch, .navbar.is-fixed-top-touch {
    z-index: var(--bulma-navbar-fixed-z);
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-touch {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-touch.has-shadow {
    box-shadow: 0 -.125em .1875em hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), .1);
  }

  .navbar.is-fixed-top-touch {
    top: 0;
  }

  .navbar.is-fixed-top .navbar-menu, .navbar.is-fixed-top-touch .navbar-menu {
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - var(--bulma-navbar-height));
    overflow: auto;
  }

  html.has-navbar-fixed-top-touch, body.has-navbar-fixed-top-touch {
    padding-top: var(--bulma-navbar-height);
  }

  html.has-navbar-fixed-bottom-touch, body.has-navbar-fixed-bottom-touch {
    padding-bottom: var(--bulma-navbar-height);
  }
}

@media screen and (width >= 1024px) {
  .navbar, .navbar-menu, .navbar-start, .navbar-end {
    align-items: stretch;
    display: flex;
  }

  .navbar {
    min-height: var(--bulma-navbar-height);
  }

  .navbar.is-spaced {
    padding: var(--bulma-navbar-padding-vertical) var(--bulma-navbar-padding-horizontal);
  }

  .navbar.is-spaced .navbar-start, .navbar.is-spaced .navbar-end {
    align-items: center;
  }

  .navbar.is-spaced a.navbar-item, .navbar.is-spaced .navbar-link {
    border-radius: var(--bulma-radius);
  }

  .navbar.is-transparent {
    --bulma-navbar-item-background-a: 0;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item {
    background-color: hsl(var(--bulma-navbar-h), var(--bulma-navbar-s), calc(var(--bulma-navbar-item-background-l)  + var(--bulma-navbar-item-background-l-delta)));
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item.is-active, .navbar.is-transparent .navbar-dropdown a.navbar-item.is-selected {
    --bulma-navbar-h: var(--bulma-navbar-item-selected-h);
    --bulma-navbar-s: var(--bulma-navbar-item-selected-s);
    --bulma-navbar-l: var(--bulma-navbar-item-selected-l);
    --bulma-navbar-item-background-l: var(--bulma-navbar-item-selected-background-l);
    --bulma-navbar-item-color-l: var(--bulma-navbar-item-selected-color-l);
  }

  .navbar-burger {
    display: none;
  }

  .navbar-item, .navbar-link {
    align-items: center;
    display: flex;
  }

  .navbar-item.has-dropdown {
    align-items: stretch;
  }

  .navbar-item.has-dropdown-up .navbar-link:after {
    transform: rotate(135deg)translate(.25em, -.25em);
  }

  .navbar-item.has-dropdown-up .navbar-dropdown {
    border-bottom-color: var(--bulma-navbar-dropdown-border-color);
    border-bottom-style: var(--bulma-navbar-dropdown-border-style);
    border-bottom-width: var(--bulma-navbar-dropdown-border-width);
    border-radius: var(--bulma-navbar-dropdown-radius) var(--bulma-navbar-dropdown-radius) 0 0;
    box-shadow: 0 -.5em .5em hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), .1);
    border-top: none;
    top: auto;
    bottom: 100%;
  }

  .navbar-item.is-active .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block;
  }

  .navbar.is-spaced .navbar-item.is-active .navbar-dropdown, .navbar-item.is-active .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:hover .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }

  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .navbar-start {
    justify-content: flex-start;
    margin-inline-end: auto;
  }

  .navbar-end {
    justify-content: flex-end;
    margin-inline-start: auto;
  }

  .navbar-dropdown {
    background-color: var(--bulma-navbar-dropdown-background-color);
    border-top-color: var(--bulma-navbar-dropdown-border-color);
    border-top-style: var(--bulma-navbar-dropdown-border-style);
    border-top-width: var(--bulma-navbar-dropdown-border-width);
    box-shadow: 0 .5em .5em hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), .1);
    min-width: 100%;
    z-index: var(--bulma-navbar-dropdown-z);
    inset-inline-start: 0;
    border-end-end-radius: var(--bulma-navbar-dropdown-radius);
    border-end-start-radius: var(--bulma-navbar-dropdown-radius);
    font-size: .875rem;
    display: none;
    position: absolute;
    top: 100%;
  }

  .navbar-dropdown .navbar-item {
    white-space: nowrap;
    padding: .375rem 1rem;
  }

  .navbar-dropdown a.navbar-item {
    padding-inline-end: 3rem;
  }

  .navbar-dropdown a.navbar-item:not(.is-active, .is-selected) {
    background-color: hsl(var(--bulma-navbar-dropdown-item-h), var(--bulma-navbar-dropdown-item-s), calc(var(--bulma-navbar-dropdown-item-background-l)  + var(--bulma-navbar-item-background-l-delta)));
    color: hsl(var(--bulma-navbar-dropdown-item-h), var(--bulma-navbar-dropdown-item-s), var(--bulma-navbar-dropdown-item-color-l));
  }

  .navbar.is-spaced .navbar-dropdown, .navbar-dropdown.is-boxed {
    border-radius: var(--bulma-navbar-dropdown-boxed-radius);
    box-shadow: var(--bulma-navbar-dropdown-boxed-shadow);
    opacity: 0;
    pointer-events: none;
    top: calc(100% + (var(--bulma-navbar-dropdown-offset)));
    transition-duration: var(--bulma-duration);
    border-top: none;
    transition-property: opacity, transform;
    display: block;
    transform: translateY(-5px);
  }

  .navbar-dropdown.is-right {
    left: auto;
    right: 0;
  }

  .navbar-divider {
    display: block;
  }

  .navbar > .container .navbar-brand, .container > .navbar .navbar-brand {
    margin-inline-start: -.75rem;
  }

  .navbar > .container .navbar-menu, .container > .navbar .navbar-menu {
    margin-inline-end: -.75rem;
  }

  .navbar.is-fixed-bottom-desktop, .navbar.is-fixed-top-desktop {
    z-index: var(--bulma-navbar-fixed-z);
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-desktop {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-desktop.has-shadow {
    box-shadow: 0 -.125em .1875em hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), .1);
  }

  .navbar.is-fixed-top-desktop {
    top: 0;
  }

  html.has-navbar-fixed-top-desktop, body.has-navbar-fixed-top-desktop {
    padding-top: var(--bulma-navbar-height);
  }

  html.has-navbar-fixed-bottom-desktop, body.has-navbar-fixed-bottom-desktop {
    padding-bottom: var(--bulma-navbar-height);
  }

  html.has-spaced-navbar-fixed-top, body.has-spaced-navbar-fixed-top {
    padding-top: calc(var(--bulma-navbar-height)  + var(--bulma-navbar-padding-vertical) * 2);
  }

  html.has-spaced-navbar-fixed-bottom, body.has-spaced-navbar-fixed-bottom {
    padding-bottom: calc(var(--bulma-navbar-height)  + var(--bulma-navbar-padding-vertical) * 2);
  }
}

.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - var(--bulma-navbar-height));
}

.pagination {
  --bulma-pagination-margin: -.25rem;
  --bulma-pagination-min-width: var(--bulma-control-height);
  --bulma-pagination-item-h: var(--bulma-scheme-h);
  --bulma-pagination-item-s: var(--bulma-scheme-s);
  --bulma-pagination-item-l: var(--bulma-scheme-main-l);
  --bulma-pagination-item-background-l-delta: 0%;
  --bulma-pagination-item-hover-background-l-delta: var(--bulma-hover-background-l-delta);
  --bulma-pagination-item-active-background-l-delta: var(--bulma-active-background-l-delta);
  --bulma-pagination-item-border-style: solid;
  --bulma-pagination-item-border-width: var(--bulma-control-border-width);
  --bulma-pagination-item-border-l: var(--bulma-border-l);
  --bulma-pagination-item-border-l-delta: 0%;
  --bulma-pagination-item-hover-border-l-delta: var(--bulma-hover-border-l-delta);
  --bulma-pagination-item-active-border-l-delta: var(--bulma-active-border-l-delta);
  --bulma-pagination-item-focus-border-l-delta: var(--bulma-focus-border-l-delta);
  --bulma-pagination-item-color-l: var(--bulma-text-strong-l);
  --bulma-pagination-item-font-size: 1em;
  --bulma-pagination-item-margin: .25rem;
  --bulma-pagination-item-padding-left: .5em;
  --bulma-pagination-item-padding-right: .5em;
  --bulma-pagination-item-outer-shadow-h: 0;
  --bulma-pagination-item-outer-shadow-s: 0%;
  --bulma-pagination-item-outer-shadow-l: 20%;
  --bulma-pagination-item-outer-shadow-a: .05;
  --bulma-pagination-nav-padding-left: .75em;
  --bulma-pagination-nav-padding-right: .75em;
  --bulma-pagination-disabled-color: var(--bulma-text-weak);
  --bulma-pagination-disabled-background-color: var(--bulma-border);
  --bulma-pagination-disabled-border-color: var(--bulma-border);
  --bulma-pagination-current-color: var(--bulma-link-invert);
  --bulma-pagination-current-background-color: var(--bulma-link);
  --bulma-pagination-current-border-color: var(--bulma-link);
  --bulma-pagination-ellipsis-color: var(--bulma-text-weak);
  --bulma-pagination-shadow-inset: inset 0 .0625em .125em hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-scheme-invert-l), .2);
  --bulma-pagination-selected-item-h: var(--bulma-link-h);
  --bulma-pagination-selected-item-s: var(--bulma-link-s);
  --bulma-pagination-selected-item-l: var(--bulma-link-l);
  --bulma-pagination-selected-item-background-l: var(--bulma-link-l);
  --bulma-pagination-selected-item-border-l: var(--bulma-link-l);
  --bulma-pagination-selected-item-color-l: var(--bulma-link-invert-l);
  font-size: var(--bulma-size-normal);
  margin: var(--bulma-pagination-margin);
}

.pagination.is-small {
  font-size: var(--bulma-size-small);
}

.pagination.is-medium {
  font-size: var(--bulma-size-medium);
}

.pagination.is-large {
  font-size: var(--bulma-size-large);
}

.pagination.is-rounded .pagination-previous, .pagination.is-rounded .pagination-next {
  border-radius: var(--bulma-radius-rounded);
  padding-left: 1em;
  padding-right: 1em;
}

.pagination.is-rounded .pagination-link {
  border-radius: var(--bulma-radius-rounded);
}

.pagination, .pagination-list {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
  color: hsl(var(--bulma-pagination-item-h), var(--bulma-pagination-item-s), var(--bulma-pagination-item-color-l));
  font-size: var(--bulma-pagination-item-font-size);
  margin: var(--bulma-pagination-item-margin);
  padding-left: var(--bulma-pagination-item-padding-left);
  padding-right: var(--bulma-pagination-item-padding-right);
  text-align: center;
  justify-content: center;
}

.pagination-previous, .pagination-next, .pagination-link {
  background-color: hsl(var(--bulma-pagination-item-h), var(--bulma-pagination-item-s), calc(var(--bulma-pagination-item-background-l)  + var(--bulma-pagination-item-background-l-delta)));
  border-color: hsl(var(--bulma-pagination-item-h), var(--bulma-pagination-item-s), calc(var(--bulma-pagination-item-border-l)  + var(--bulma-pagination-item-border-l-delta)));
  border-style: var(--bulma-pagination-item-border-style);
  border-width: var(--bulma-pagination-item-border-width);
  box-shadow: 0px .0625em .125em hsla(var(--bulma-pagination-item-outer-shadow-h), var(--bulma-pagination-item-outer-shadow-s), var(--bulma-pagination-item-outer-shadow-l), var(--bulma-pagination-item-outer-shadow-a)), 0px .125em .25em hsla(var(--bulma-pagination-item-outer-shadow-h), var(--bulma-pagination-item-outer-shadow-s), var(--bulma-pagination-item-outer-shadow-l), var(--bulma-pagination-item-outer-shadow-a));
  color: hsl(var(--bulma-pagination-item-h), var(--bulma-pagination-item-s), var(--bulma-pagination-item-color-l));
  min-width: var(--bulma-pagination-min-width);
  transition-duration: var(--bulma-duration);
  transition-property: background-color, border-color, box-shadow, color;
}

.pagination-previous:hover, .pagination-next:hover, .pagination-link:hover, .pagination-previous:focus, .pagination-next:focus, .pagination-link:focus {
  --bulma-pagination-item-background-l-delta: var(--bulma-pagination-item-hover-background-l-delta);
  --bulma-pagination-item-border-l-delta: var(--bulma-pagination-item-hover-border-l-delta);
}

.pagination-previous:active, .pagination-next:active, .pagination-link:active {
  box-shadow: var(--bulma-pagination-shadow-inset);
}

.pagination-previous[disabled], .pagination-previous.is-disabled, .pagination-next[disabled], .pagination-next.is-disabled, .pagination-link[disabled], .pagination-link.is-disabled {
  background-color: var(--bulma-pagination-disabled-background-color);
  border-color: var(--bulma-pagination-disabled-border-color);
  box-shadow: none;
  color: var(--bulma-pagination-disabled-color);
  opacity: .5;
}

.pagination-previous, .pagination-next {
  padding-left: var(--bulma-pagination-nav-padding-left);
  padding-right: var(--bulma-pagination-nav-padding-right);
  white-space: nowrap;
}

.pagination-link.is-current, .pagination-link.is-selected {
  --bulma-pagination-item-h: var(--bulma-pagination-selected-item-h);
  --bulma-pagination-item-s: var(--bulma-pagination-selected-item-s);
  --bulma-pagination-item-l: var(--bulma-pagination-selected-item-l);
  --bulma-pagination-item-background-l: var(--bulma-pagination-selected-item-background-l);
  --bulma-pagination-item-border-l: var(--bulma-pagination-selected-item-border-l);
  --bulma-pagination-item-color-l: var(--bulma-pagination-selected-item-color-l);
}

.pagination-ellipsis {
  color: var(--bulma-pagination-ellipsis-color);
  pointer-events: none;
}

.pagination-list {
  flex-wrap: wrap;
}

.pagination-list li {
  list-style: none;
}

@media screen and (width <= 768px) {
  .pagination {
    flex-wrap: wrap;
  }

  .pagination-previous, .pagination-next, .pagination-list li {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@media screen and (width >= 769px), print {
  .pagination-list {
    flex-grow: 1;
    flex-shrink: 1;
    order: 1;
    justify-content: flex-start;
  }

  .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
    margin-top: 0;
    margin-bottom: 0;
  }

  .pagination-previous {
    order: 2;
  }

  .pagination-next {
    order: 3;
  }

  .pagination {
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
  }

  .pagination.is-centered .pagination-previous {
    order: 1;
  }

  .pagination.is-centered .pagination-list {
    order: 2;
    justify-content: center;
  }

  .pagination.is-centered .pagination-next {
    order: 3;
  }

  .pagination.is-right .pagination-previous {
    order: 1;
  }

  .pagination.is-right .pagination-next {
    order: 2;
  }

  .pagination.is-right .pagination-list {
    order: 3;
    justify-content: flex-end;
  }
}

.panel {
  --bulma-panel-margin: var(--bulma-block-spacing);
  --bulma-panel-item-border: 1px solid var(--bulma-border-weak);
  --bulma-panel-radius: var(--bulma-radius-large);
  --bulma-panel-shadow: var(--bulma-shadow);
  --bulma-panel-heading-line-height: 1.25;
  --bulma-panel-heading-padding: 1em 1.25em;
  --bulma-panel-heading-radius: var(--bulma-radius);
  --bulma-panel-heading-size: 1.25em;
  --bulma-panel-heading-weight: var(--bulma-weight-bold);
  --bulma-panel-tabs-font-size: 1em;
  --bulma-panel-tab-border-bottom-color: var(--bulma-border);
  --bulma-panel-tab-border-bottom-style: solid;
  --bulma-panel-tab-border-bottom-width: 1px;
  --bulma-panel-tab-active-color: var(--bulma-link-active);
  --bulma-panel-list-item-color: var(--bulma-text);
  --bulma-panel-list-item-hover-color: var(--bulma-link);
  --bulma-panel-block-color: var(--bulma-text-strong);
  --bulma-panel-block-hover-background-color: var(--bulma-background);
  --bulma-panel-block-active-border-left-color: var(--bulma-link);
  --bulma-panel-block-active-color: var(--bulma-link-active);
  --bulma-panel-block-active-icon-color: var(--bulma-link);
  --bulma-panel-icon-color: var(--bulma-text-weak);
  --bulma-panel-h: var(--bulma-scheme-h);
  --bulma-panel-s: var(--bulma-scheme-s);
  --bulma-panel-color-l: var(--bulma-text-l);
  --bulma-panel-heading-background-l: var(--bulma-text-l);
  --bulma-panel-heading-color-l: var(--bulma-text-invert-l);
  border-radius: var(--bulma-panel-radius);
  box-shadow: var(--bulma-panel-shadow);
  font-size: var(--bulma-size-normal);
}

.panel:not(:last-child) {
  margin-bottom: var(--bulma-panel-margin);
}

.panel.is-white {
  --bulma-panel-h: var(--bulma-white-h);
  --bulma-panel-s: var(--bulma-white-s);
  --bulma-panel-color-l: var(--bulma-white-l);
  --bulma-panel-heading-background-l: var(--bulma-white-l);
  --bulma-panel-heading-color-l: var(--bulma-white-invert-l);
}

.panel.is-black {
  --bulma-panel-h: var(--bulma-black-h);
  --bulma-panel-s: var(--bulma-black-s);
  --bulma-panel-color-l: var(--bulma-black-l);
  --bulma-panel-heading-background-l: var(--bulma-black-l);
  --bulma-panel-heading-color-l: var(--bulma-black-invert-l);
}

.panel.is-light {
  --bulma-panel-h: var(--bulma-light-h);
  --bulma-panel-s: var(--bulma-light-s);
  --bulma-panel-color-l: var(--bulma-light-l);
  --bulma-panel-heading-background-l: var(--bulma-light-l);
  --bulma-panel-heading-color-l: var(--bulma-light-invert-l);
}

.panel.is-dark {
  --bulma-panel-h: var(--bulma-dark-h);
  --bulma-panel-s: var(--bulma-dark-s);
  --bulma-panel-color-l: var(--bulma-dark-l);
  --bulma-panel-heading-background-l: var(--bulma-dark-l);
  --bulma-panel-heading-color-l: var(--bulma-dark-invert-l);
}

.panel.is-text {
  --bulma-panel-h: var(--bulma-text-h);
  --bulma-panel-s: var(--bulma-text-s);
  --bulma-panel-color-l: var(--bulma-text-l);
  --bulma-panel-heading-background-l: var(--bulma-text-l);
  --bulma-panel-heading-color-l: var(--bulma-text-invert-l);
}

.panel.is-primary {
  --bulma-panel-h: var(--bulma-primary-h);
  --bulma-panel-s: var(--bulma-primary-s);
  --bulma-panel-color-l: var(--bulma-primary-l);
  --bulma-panel-heading-background-l: var(--bulma-primary-l);
  --bulma-panel-heading-color-l: var(--bulma-primary-invert-l);
}

.panel.is-link {
  --bulma-panel-h: var(--bulma-link-h);
  --bulma-panel-s: var(--bulma-link-s);
  --bulma-panel-color-l: var(--bulma-link-l);
  --bulma-panel-heading-background-l: var(--bulma-link-l);
  --bulma-panel-heading-color-l: var(--bulma-link-invert-l);
}

.panel.is-info {
  --bulma-panel-h: var(--bulma-info-h);
  --bulma-panel-s: var(--bulma-info-s);
  --bulma-panel-color-l: var(--bulma-info-l);
  --bulma-panel-heading-background-l: var(--bulma-info-l);
  --bulma-panel-heading-color-l: var(--bulma-info-invert-l);
}

.panel.is-success {
  --bulma-panel-h: var(--bulma-success-h);
  --bulma-panel-s: var(--bulma-success-s);
  --bulma-panel-color-l: var(--bulma-success-l);
  --bulma-panel-heading-background-l: var(--bulma-success-l);
  --bulma-panel-heading-color-l: var(--bulma-success-invert-l);
}

.panel.is-warning {
  --bulma-panel-h: var(--bulma-warning-h);
  --bulma-panel-s: var(--bulma-warning-s);
  --bulma-panel-color-l: var(--bulma-warning-l);
  --bulma-panel-heading-background-l: var(--bulma-warning-l);
  --bulma-panel-heading-color-l: var(--bulma-warning-invert-l);
}

.panel.is-danger {
  --bulma-panel-h: var(--bulma-danger-h);
  --bulma-panel-s: var(--bulma-danger-s);
  --bulma-panel-color-l: var(--bulma-danger-l);
  --bulma-panel-heading-background-l: var(--bulma-danger-l);
  --bulma-panel-heading-color-l: var(--bulma-danger-invert-l);
}

.panel-tabs:not(:last-child), .panel-block:not(:last-child) {
  border-bottom: var(--bulma-panel-item-border);
}

.panel-heading {
  background-color: hsl(var(--bulma-panel-h), var(--bulma-panel-s), var(--bulma-panel-heading-background-l));
  border-radius: var(--bulma-panel-radius) var(--bulma-panel-radius) 0 0;
  color: hsl(var(--bulma-panel-h), var(--bulma-panel-s), var(--bulma-panel-heading-color-l));
  font-size: var(--bulma-panel-heading-size);
  font-weight: var(--bulma-panel-heading-weight);
  line-height: var(--bulma-panel-heading-line-height);
  padding: var(--bulma-panel-heading-padding);
}

.panel-tabs {
  font-size: var(--bulma-panel-tabs-font-size);
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.panel-tabs a {
  border-bottom-color: var(--bulma-panel-tab-border-bottom-color);
  border-bottom-style: var(--bulma-panel-tab-border-bottom-style);
  border-bottom-width: var(--bulma-panel-tab-border-bottom-width);
  margin-bottom: -1px;
  padding: .75em;
}

.panel-tabs a.is-active {
  border-bottom-color: hsl(var(--bulma-panel-h), var(--bulma-panel-s), var(--bulma-panel-color-l));
  color: var(--bulma-panel-tab-active-color);
}

.panel-list a {
  color: var(--bulma-panel-list-item-color);
}

.panel-list a:hover {
  color: var(--bulma-panel-list-item-hover-color);
}

.panel-block {
  color: var(--bulma-panel-block-color);
  justify-content: flex-start;
  align-items: center;
  padding: .75em 1em;
  display: flex;
}

.panel-block input[type="checkbox"] {
  margin-inline-end: .75em;
}

.panel-block > .control {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
}

.panel-block.is-wrapped {
  flex-wrap: wrap;
}

.panel-block.is-active {
  border-left-color: var(--bulma-panel-block-active-border-left-color);
  color: var(--bulma-panel-block-active-color);
}

.panel-block.is-active .panel-icon {
  color: hsl(var(--bulma-panel-h), var(--bulma-panel-s), var(--bulma-panel-color-l));
}

.panel-block:last-child {
  border-end-end-radius: var(--bulma-panel-radius);
  border-end-start-radius: var(--bulma-panel-radius);
}

a.panel-block, label.panel-block {
  cursor: pointer;
}

a.panel-block:hover, label.panel-block:hover {
  background-color: var(--bulma-panel-block-hover-background-color);
}

.panel-icon {
  text-align: center;
  vertical-align: top;
  width: 1em;
  height: 1em;
  color: var(--bulma-panel-icon-color);
  margin-inline-end: .75em;
  font-size: 1em;
  line-height: 1em;
  display: inline-block;
}

.panel-icon .fa {
  font-size: inherit;
  line-height: inherit;
}

.tabs {
  --bulma-tabs-border-bottom-color: var(--bulma-border);
  --bulma-tabs-border-bottom-style: solid;
  --bulma-tabs-border-bottom-width: 1px;
  --bulma-tabs-link-color: var(--bulma-text);
  --bulma-tabs-link-hover-border-bottom-color: var(--bulma-text-strong);
  --bulma-tabs-link-hover-color: var(--bulma-text-strong);
  --bulma-tabs-link-active-border-bottom-color: var(--bulma-link-text);
  --bulma-tabs-link-active-color: var(--bulma-link-text);
  --bulma-tabs-link-padding: .5em 1em;
  --bulma-tabs-boxed-link-radius: var(--bulma-radius);
  --bulma-tabs-boxed-link-hover-background-color: var(--bulma-background);
  --bulma-tabs-boxed-link-hover-border-bottom-color: var(--bulma-border);
  --bulma-tabs-boxed-link-active-background-color: var(--bulma-scheme-main);
  --bulma-tabs-boxed-link-active-border-color: var(--bulma-border);
  --bulma-tabs-boxed-link-active-border-bottom-color: transparent;
  --bulma-tabs-toggle-link-border-color: var(--bulma-border);
  --bulma-tabs-toggle-link-border-style: solid;
  --bulma-tabs-toggle-link-border-width: 1px;
  --bulma-tabs-toggle-link-hover-background-color: var(--bulma-background);
  --bulma-tabs-toggle-link-hover-border-color: var(--bulma-border-hover);
  --bulma-tabs-toggle-link-radius: var(--bulma-radius);
  --bulma-tabs-toggle-link-active-background-color: var(--bulma-link);
  --bulma-tabs-toggle-link-active-border-color: var(--bulma-link);
  --bulma-tabs-toggle-link-active-color: var(--bulma-link-invert);
  -webkit-overflow-scrolling: touch;
  font-size: var(--bulma-size-normal);
  white-space: nowrap;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  overflow: auto hidden;
}

.tabs a {
  border-bottom-color: var(--bulma-tabs-border-bottom-color);
  border-bottom-style: var(--bulma-tabs-border-bottom-style);
  border-bottom-width: var(--bulma-tabs-border-bottom-width);
  color: var(--bulma-tabs-link-color);
  margin-bottom: calc(-1 * var(--bulma-tabs-border-bottom-width));
  padding: var(--bulma-tabs-link-padding);
  transition-duration: var(--bulma-duration);
  vertical-align: top;
  justify-content: center;
  align-items: center;
  transition-property: background-color, border-color, color;
  display: flex;
}

.tabs a:hover {
  border-bottom-color: var(--bulma-tabs-link-hover-border-bottom-color);
  color: var(--bulma-tabs-link-hover-color);
}

.tabs li {
  display: block;
}

.tabs li.is-active a {
  border-bottom-color: var(--bulma-tabs-link-active-border-bottom-color);
  color: var(--bulma-tabs-link-active-color);
}

.tabs ul {
  border-bottom-color: var(--bulma-tabs-border-bottom-color);
  border-bottom-style: var(--bulma-tabs-border-bottom-style);
  border-bottom-width: var(--bulma-tabs-border-bottom-width);
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tabs ul.is-left {
  padding-right: .75em;
}

.tabs ul.is-center {
  flex: none;
  justify-content: center;
  padding-left: .75em;
  padding-right: .75em;
}

.tabs ul.is-right {
  justify-content: flex-end;
  padding-left: .75em;
}

.tabs .icon:first-child {
  margin-inline-end: .5em;
}

.tabs .icon:last-child {
  margin-inline-start: .5em;
}

.tabs.is-centered ul {
  justify-content: center;
}

.tabs.is-right ul {
  justify-content: flex-end;
}

.tabs.is-boxed a {
  border: 1px solid #0000;
  border-start-start-radius: var(--bulma-tabs-boxed-link-radius);
  border-start-end-radius: var(--bulma-tabs-boxed-link-radius);
}

.tabs.is-boxed a:hover {
  background-color: var(--bulma-tabs-boxed-link-hover-background-color);
  border-bottom-color: var(--bulma-tabs-boxed-link-hover-border-bottom-color);
}

.tabs.is-boxed li.is-active a {
  background-color: var(--bulma-tabs-boxed-link-active-background-color);
  border-color: var(--bulma-tabs-boxed-link-active-border-color);
  border-bottom-color: var(--bulma-tabs-boxed-link-active-border-bottom-color) !important;
}

.tabs.is-fullwidth li {
  flex-grow: 1;
  flex-shrink: 0;
}

.tabs.is-toggle a {
  border-color: var(--bulma-tabs-toggle-link-border-color);
  border-style: var(--bulma-tabs-toggle-link-border-style);
  border-width: var(--bulma-tabs-toggle-link-border-width);
  margin-bottom: 0;
  position: relative;
}

.tabs.is-toggle a:hover {
  background-color: var(--bulma-tabs-toggle-link-hover-background-color);
  border-color: var(--bulma-tabs-toggle-link-hover-border-color);
  z-index: 2;
}

.tabs.is-toggle li + li {
  margin-inline-start: calc(-1 * var(--bulma-tabs-toggle-link-border-width));
}

.tabs.is-toggle li:first-child a {
  border-start-start-radius: var(--bulma-tabs-toggle-link-radius);
  border-end-start-radius: var(--bulma-tabs-toggle-link-radius);
}

.tabs.is-toggle li:last-child a {
  border-start-end-radius: var(--bulma-tabs-toggle-link-radius);
  border-end-end-radius: var(--bulma-tabs-toggle-link-radius);
}

.tabs.is-toggle li.is-active a {
  background-color: var(--bulma-tabs-toggle-link-active-background-color);
  border-color: var(--bulma-tabs-toggle-link-active-border-color);
  color: var(--bulma-tabs-toggle-link-active-color);
  z-index: 1;
}

.tabs.is-toggle ul {
  border-bottom: none;
}

.tabs.is-toggle.is-toggle-rounded li:first-child a {
  border-start-start-radius: var(--bulma-radius-rounded);
  border-end-start-radius: var(--bulma-radius-rounded);
  padding-inline-start: 1.25em;
}

.tabs.is-toggle.is-toggle-rounded li:last-child a {
  border-start-end-radius: var(--bulma-radius-rounded);
  border-end-end-radius: var(--bulma-radius-rounded);
  padding-inline-end: 1.25em;
}

.tabs.is-small {
  font-size: var(--bulma-size-small);
}

.tabs.is-medium {
  font-size: var(--bulma-size-medium);
}

.tabs.is-large {
  font-size: var(--bulma-size-large);
}

:root {
  --bulma-column-gap: .75rem;
}

.column {
  padding: var(--bulma-column-gap);
  flex: 1 1 0;
  display: block;
}

.columns.is-mobile > .column.is-narrow {
  width: unset;
  flex: none;
}

.columns.is-mobile > .column.is-full {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-three-quarters {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-two-thirds {
  flex: none;
  width: 66.6666%;
}

.columns.is-mobile > .column.is-half {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-one-third {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-one-quarter {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-one-fifth {
  flex: none;
  width: 20%;
}

.columns.is-mobile > .column.is-two-fifths {
  flex: none;
  width: 40%;
}

.columns.is-mobile > .column.is-three-fifths {
  flex: none;
  width: 60%;
}

.columns.is-mobile > .column.is-four-fifths {
  flex: none;
  width: 80%;
}

.columns.is-mobile > .column.is-offset-three-quarters {
  margin-inline-start: 75%;
}

.columns.is-mobile > .column.is-offset-two-thirds {
  margin-inline-start: 66.6666%;
}

.columns.is-mobile > .column.is-offset-half {
  margin-inline-start: 50%;
}

.columns.is-mobile > .column.is-offset-one-third {
  margin-inline-start: .3333%;
}

.columns.is-mobile > .column.is-offset-one-quarter {
  margin-inline-start: 25%;
}

.columns.is-mobile > .column.is-offset-one-fifth {
  margin-inline-start: 20%;
}

.columns.is-mobile > .column.is-offset-two-fifths {
  margin-inline-start: 40%;
}

.columns.is-mobile > .column.is-offset-three-fifths {
  margin-inline-start: 60%;
}

.columns.is-mobile > .column.is-offset-four-fifths {
  margin-inline-start: 80%;
}

.columns.is-mobile > .column.is-0 {
  flex: none;
  width: 0%;
}

.columns.is-mobile > .column.is-offset-0 {
  margin-inline-start: 0%;
}

.columns.is-mobile > .column.is-1 {
  flex: none;
  width: 8.33333%;
}

.columns.is-mobile > .column.is-offset-1 {
  margin-inline-start: 8.33333%;
}

.columns.is-mobile > .column.is-2 {
  flex: none;
  width: 16.6667%;
}

.columns.is-mobile > .column.is-offset-2 {
  margin-inline-start: 16.6667%;
}

.columns.is-mobile > .column.is-3 {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-offset-3 {
  margin-inline-start: 25%;
}

.columns.is-mobile > .column.is-4 {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-offset-4 {
  margin-inline-start: 33.3333%;
}

.columns.is-mobile > .column.is-5 {
  flex: none;
  width: 41.6667%;
}

.columns.is-mobile > .column.is-offset-5 {
  margin-inline-start: 41.6667%;
}

.columns.is-mobile > .column.is-6 {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-offset-6 {
  margin-inline-start: 50%;
}

.columns.is-mobile > .column.is-7 {
  flex: none;
  width: 58.3333%;
}

.columns.is-mobile > .column.is-offset-7 {
  margin-inline-start: 58.3333%;
}

.columns.is-mobile > .column.is-8 {
  flex: none;
  width: 66.6667%;
}

.columns.is-mobile > .column.is-offset-8 {
  margin-inline-start: 66.6667%;
}

.columns.is-mobile > .column.is-9 {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-offset-9 {
  margin-inline-start: 75%;
}

.columns.is-mobile > .column.is-10 {
  flex: none;
  width: 83.3333%;
}

.columns.is-mobile > .column.is-offset-10 {
  margin-inline-start: 83.3333%;
}

.columns.is-mobile > .column.is-11 {
  flex: none;
  width: 91.6667%;
}

.columns.is-mobile > .column.is-offset-11 {
  margin-inline-start: 91.6667%;
}

.columns.is-mobile > .column.is-12 {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-offset-12 {
  margin-inline-start: 100%;
}

@media screen and (width <= 768px) {
  .column.is-narrow-mobile {
    width: unset;
    flex: none;
  }

  .column.is-full-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-mobile {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-mobile {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-mobile {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-mobile {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-mobile {
    margin-inline-start: 75%;
  }

  .column.is-offset-two-thirds-mobile {
    margin-inline-start: 66.6666%;
  }

  .column.is-offset-half-mobile {
    margin-inline-start: 50%;
  }

  .column.is-offset-one-third-mobile {
    margin-inline-start: .3333%;
  }

  .column.is-offset-one-quarter-mobile {
    margin-inline-start: 25%;
  }

  .column.is-offset-one-fifth-mobile {
    margin-inline-start: 20%;
  }

  .column.is-offset-two-fifths-mobile {
    margin-inline-start: 40%;
  }

  .column.is-offset-three-fifths-mobile {
    margin-inline-start: 60%;
  }

  .column.is-offset-four-fifths-mobile {
    margin-inline-start: 80%;
  }

  .column.is-0-mobile {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-mobile {
    margin-inline-start: 0%;
  }

  .column.is-1-mobile {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-mobile {
    margin-inline-start: 8.33333%;
  }

  .column.is-2-mobile {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-mobile {
    margin-inline-start: 16.6667%;
  }

  .column.is-3-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-mobile {
    margin-inline-start: 25%;
  }

  .column.is-4-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-mobile {
    margin-inline-start: 33.3333%;
  }

  .column.is-5-mobile {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-mobile {
    margin-inline-start: 41.6667%;
  }

  .column.is-6-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-mobile {
    margin-inline-start: 50%;
  }

  .column.is-7-mobile {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-mobile {
    margin-inline-start: 58.3333%;
  }

  .column.is-8-mobile {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-mobile {
    margin-inline-start: 66.6667%;
  }

  .column.is-9-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-mobile {
    margin-inline-start: 75%;
  }

  .column.is-10-mobile {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-mobile {
    margin-inline-start: 83.3333%;
  }

  .column.is-11-mobile {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-mobile {
    margin-inline-start: 91.6667%;
  }

  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-mobile {
    margin-inline-start: 100%;
  }
}

@media screen and (width >= 769px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    width: unset;
    flex: none;
  }

  .column.is-full, .column.is-full-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters, .column.is-three-quarters-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds, .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half, .column.is-half-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-one-third, .column.is-one-third-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter, .column.is-one-quarter-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth, .column.is-one-fifth-tablet {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths, .column.is-two-fifths-tablet {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths, .column.is-three-fifths-tablet {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths, .column.is-four-fifths-tablet {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-inline-start: 75%;
  }

  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-inline-start: 66.6666%;
  }

  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-inline-start: 50%;
  }

  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-inline-start: .3333%;
  }

  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-inline-start: 25%;
  }

  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-inline-start: 20%;
  }

  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-inline-start: 40%;
  }

  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-inline-start: 60%;
  }

  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-inline-start: 80%;
  }

  .column.is-0, .column.is-0-tablet {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0, .column.is-offset-0-tablet {
    margin-inline-start: 0%;
  }

  .column.is-1, .column.is-1-tablet {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-inline-start: 8.33333%;
  }

  .column.is-2, .column.is-2-tablet {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-inline-start: 16.6667%;
  }

  .column.is-3, .column.is-3-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-inline-start: 25%;
  }

  .column.is-4, .column.is-4-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-inline-start: 33.3333%;
  }

  .column.is-5, .column.is-5-tablet {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-inline-start: 41.6667%;
  }

  .column.is-6, .column.is-6-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-inline-start: 50%;
  }

  .column.is-7, .column.is-7-tablet {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-inline-start: 58.3333%;
  }

  .column.is-8, .column.is-8-tablet {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-inline-start: 66.6667%;
  }

  .column.is-9, .column.is-9-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-inline-start: 75%;
  }

  .column.is-10, .column.is-10-tablet {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-inline-start: 83.3333%;
  }

  .column.is-11, .column.is-11-tablet {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-inline-start: 91.6667%;
  }

  .column.is-12, .column.is-12-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-inline-start: 100%;
  }
}

@media screen and (width <= 1023px) {
  .column.is-narrow-touch {
    width: unset;
    flex: none;
  }

  .column.is-full-touch {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-touch {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-touch {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-touch {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-touch {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-touch {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-touch {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-touch {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-touch {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-touch {
    margin-inline-start: 75%;
  }

  .column.is-offset-two-thirds-touch {
    margin-inline-start: 66.6666%;
  }

  .column.is-offset-half-touch {
    margin-inline-start: 50%;
  }

  .column.is-offset-one-third-touch {
    margin-inline-start: .3333%;
  }

  .column.is-offset-one-quarter-touch {
    margin-inline-start: 25%;
  }

  .column.is-offset-one-fifth-touch {
    margin-inline-start: 20%;
  }

  .column.is-offset-two-fifths-touch {
    margin-inline-start: 40%;
  }

  .column.is-offset-three-fifths-touch {
    margin-inline-start: 60%;
  }

  .column.is-offset-four-fifths-touch {
    margin-inline-start: 80%;
  }

  .column.is-0-touch {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-touch {
    margin-inline-start: 0%;
  }

  .column.is-1-touch {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-touch {
    margin-inline-start: 8.33333%;
  }

  .column.is-2-touch {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-touch {
    margin-inline-start: 16.6667%;
  }

  .column.is-3-touch {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-touch {
    margin-inline-start: 25%;
  }

  .column.is-4-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-touch {
    margin-inline-start: 33.3333%;
  }

  .column.is-5-touch {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-touch {
    margin-inline-start: 41.6667%;
  }

  .column.is-6-touch {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-touch {
    margin-inline-start: 50%;
  }

  .column.is-7-touch {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-touch {
    margin-inline-start: 58.3333%;
  }

  .column.is-8-touch {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-touch {
    margin-inline-start: 66.6667%;
  }

  .column.is-9-touch {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-touch {
    margin-inline-start: 75%;
  }

  .column.is-10-touch {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-touch {
    margin-inline-start: 83.3333%;
  }

  .column.is-11-touch {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-touch {
    margin-inline-start: 91.6667%;
  }

  .column.is-12-touch {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-touch {
    margin-inline-start: 100%;
  }
}

@media screen and (width >= 1024px) {
  .column.is-narrow-desktop {
    width: unset;
    flex: none;
  }

  .column.is-full-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-desktop {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-desktop {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-desktop {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-desktop {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-desktop {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-desktop {
    margin-inline-start: 75%;
  }

  .column.is-offset-two-thirds-desktop {
    margin-inline-start: 66.6666%;
  }

  .column.is-offset-half-desktop {
    margin-inline-start: 50%;
  }

  .column.is-offset-one-third-desktop {
    margin-inline-start: .3333%;
  }

  .column.is-offset-one-quarter-desktop {
    margin-inline-start: 25%;
  }

  .column.is-offset-one-fifth-desktop {
    margin-inline-start: 20%;
  }

  .column.is-offset-two-fifths-desktop {
    margin-inline-start: 40%;
  }

  .column.is-offset-three-fifths-desktop {
    margin-inline-start: 60%;
  }

  .column.is-offset-four-fifths-desktop {
    margin-inline-start: 80%;
  }

  .column.is-0-desktop {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-desktop {
    margin-inline-start: 0%;
  }

  .column.is-1-desktop {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-desktop {
    margin-inline-start: 8.33333%;
  }

  .column.is-2-desktop {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-desktop {
    margin-inline-start: 16.6667%;
  }

  .column.is-3-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-desktop {
    margin-inline-start: 25%;
  }

  .column.is-4-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-desktop {
    margin-inline-start: 33.3333%;
  }

  .column.is-5-desktop {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-desktop {
    margin-inline-start: 41.6667%;
  }

  .column.is-6-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-desktop {
    margin-inline-start: 50%;
  }

  .column.is-7-desktop {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-desktop {
    margin-inline-start: 58.3333%;
  }

  .column.is-8-desktop {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-desktop {
    margin-inline-start: 66.6667%;
  }

  .column.is-9-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-desktop {
    margin-inline-start: 75%;
  }

  .column.is-10-desktop {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-desktop {
    margin-inline-start: 83.3333%;
  }

  .column.is-11-desktop {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-desktop {
    margin-inline-start: 91.6667%;
  }

  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-desktop {
    margin-inline-start: 100%;
  }
}

@media screen and (width >= 1216px) {
  .column.is-narrow-widescreen {
    width: unset;
    flex: none;
  }

  .column.is-full-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-widescreen {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-widescreen {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-widescreen {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-widescreen {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-widescreen {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-widescreen {
    margin-inline-start: 75%;
  }

  .column.is-offset-two-thirds-widescreen {
    margin-inline-start: 66.6666%;
  }

  .column.is-offset-half-widescreen {
    margin-inline-start: 50%;
  }

  .column.is-offset-one-third-widescreen {
    margin-inline-start: .3333%;
  }

  .column.is-offset-one-quarter-widescreen {
    margin-inline-start: 25%;
  }

  .column.is-offset-one-fifth-widescreen {
    margin-inline-start: 20%;
  }

  .column.is-offset-two-fifths-widescreen {
    margin-inline-start: 40%;
  }

  .column.is-offset-three-fifths-widescreen {
    margin-inline-start: 60%;
  }

  .column.is-offset-four-fifths-widescreen {
    margin-inline-start: 80%;
  }

  .column.is-0-widescreen {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-widescreen {
    margin-inline-start: 0%;
  }

  .column.is-1-widescreen {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-widescreen {
    margin-inline-start: 8.33333%;
  }

  .column.is-2-widescreen {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-widescreen {
    margin-inline-start: 16.6667%;
  }

  .column.is-3-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-widescreen {
    margin-inline-start: 25%;
  }

  .column.is-4-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-widescreen {
    margin-inline-start: 33.3333%;
  }

  .column.is-5-widescreen {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-widescreen {
    margin-inline-start: 41.6667%;
  }

  .column.is-6-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-widescreen {
    margin-inline-start: 50%;
  }

  .column.is-7-widescreen {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-widescreen {
    margin-inline-start: 58.3333%;
  }

  .column.is-8-widescreen {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-widescreen {
    margin-inline-start: 66.6667%;
  }

  .column.is-9-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-widescreen {
    margin-inline-start: 75%;
  }

  .column.is-10-widescreen {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-widescreen {
    margin-inline-start: 83.3333%;
  }

  .column.is-11-widescreen {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-widescreen {
    margin-inline-start: 91.6667%;
  }

  .column.is-12-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-widescreen {
    margin-inline-start: 100%;
  }
}

@media screen and (width >= 1408px) {
  .column.is-narrow-fullhd {
    width: unset;
    flex: none;
  }

  .column.is-full-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-fullhd {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-fullhd {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-fullhd {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-fullhd {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-fullhd {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-fullhd {
    margin-inline-start: 75%;
  }

  .column.is-offset-two-thirds-fullhd {
    margin-inline-start: 66.6666%;
  }

  .column.is-offset-half-fullhd {
    margin-inline-start: 50%;
  }

  .column.is-offset-one-third-fullhd {
    margin-inline-start: 33.3333%;
  }

  .column.is-offset-one-quarter-fullhd {
    margin-inline-start: 25%;
  }

  .column.is-offset-one-fifth-fullhd {
    margin-inline-start: 20%;
  }

  .column.is-offset-two-fifths-fullhd {
    margin-inline-start: 40%;
  }

  .column.is-offset-three-fifths-fullhd {
    margin-inline-start: 60%;
  }

  .column.is-offset-four-fifths-fullhd {
    margin-inline-start: 80%;
  }

  .column.is-0-fullhd {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-fullhd {
    margin-inline-start: 0%;
  }

  .column.is-1-fullhd {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-fullhd {
    margin-inline-start: 8.33333%;
  }

  .column.is-2-fullhd {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-fullhd {
    margin-inline-start: 16.6667%;
  }

  .column.is-3-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-fullhd {
    margin-inline-start: 25%;
  }

  .column.is-4-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-fullhd {
    margin-inline-start: 33.3333%;
  }

  .column.is-5-fullhd {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-fullhd {
    margin-inline-start: 41.6667%;
  }

  .column.is-6-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-fullhd {
    margin-inline-start: 50%;
  }

  .column.is-7-fullhd {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-fullhd {
    margin-inline-start: 58.3333%;
  }

  .column.is-8-fullhd {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-fullhd {
    margin-inline-start: 66.6667%;
  }

  .column.is-9-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-fullhd {
    margin-inline-start: 75%;
  }

  .column.is-10-fullhd {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-fullhd {
    margin-inline-start: 83.3333%;
  }

  .column.is-11-fullhd {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-fullhd {
    margin-inline-start: 91.6667%;
  }

  .column.is-12-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-fullhd {
    margin-inline-start: 100%;
  }
}

.columns {
  margin-inline-start: calc(-1 * var(--bulma-column-gap));
  margin-inline-end: calc(-1 * var(--bulma-column-gap));
  margin-top: calc(-1 * var(--bulma-column-gap));
}

.columns:last-child {
  margin-bottom: calc(-1 * var(--bulma-column-gap));
}

.columns:not(:last-child) {
  margin-bottom: calc(var(--bulma-block-spacing)  - var(--bulma-column-gap));
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-inline: 0;
  margin-top: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.columns.is-multiline {
  flex-wrap: wrap;
}

.columns.is-vcentered {
  align-items: center;
}

@media screen and (width >= 769px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-0 {
  --bulma-column-gap: 0rem;
}

@media screen and (width <= 768px) {
  .columns.is-0-mobile {
    --bulma-column-gap: 0rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-0-tablet {
    --bulma-column-gap: 0rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-0-tablet-only {
    --bulma-column-gap: 0rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-0-touch {
    --bulma-column-gap: 0rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-0-desktop {
    --bulma-column-gap: 0rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-0-desktop-only {
    --bulma-column-gap: 0rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-0-widescreen {
    --bulma-column-gap: 0rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-0-widescreen-only {
    --bulma-column-gap: 0rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-0-fullhd {
    --bulma-column-gap: 0rem;
  }
}

.columns.is-1 {
  --bulma-column-gap: .25rem;
}

@media screen and (width <= 768px) {
  .columns.is-1-mobile {
    --bulma-column-gap: .25rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-1-tablet {
    --bulma-column-gap: .25rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-1-tablet-only {
    --bulma-column-gap: .25rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-1-touch {
    --bulma-column-gap: .25rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-1-desktop {
    --bulma-column-gap: .25rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-1-desktop-only {
    --bulma-column-gap: .25rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-1-widescreen {
    --bulma-column-gap: .25rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-1-widescreen-only {
    --bulma-column-gap: .25rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-1-fullhd {
    --bulma-column-gap: .25rem;
  }
}

.columns.is-2 {
  --bulma-column-gap: .5rem;
}

@media screen and (width <= 768px) {
  .columns.is-2-mobile {
    --bulma-column-gap: .5rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-2-tablet {
    --bulma-column-gap: .5rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-2-tablet-only {
    --bulma-column-gap: .5rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-2-touch {
    --bulma-column-gap: .5rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-2-desktop {
    --bulma-column-gap: .5rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-2-desktop-only {
    --bulma-column-gap: .5rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-2-widescreen {
    --bulma-column-gap: .5rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-2-widescreen-only {
    --bulma-column-gap: .5rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-2-fullhd {
    --bulma-column-gap: .5rem;
  }
}

.columns.is-3 {
  --bulma-column-gap: .75rem;
}

@media screen and (width <= 768px) {
  .columns.is-3-mobile {
    --bulma-column-gap: .75rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-3-tablet {
    --bulma-column-gap: .75rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-3-tablet-only {
    --bulma-column-gap: .75rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-3-touch {
    --bulma-column-gap: .75rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-3-desktop {
    --bulma-column-gap: .75rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-3-desktop-only {
    --bulma-column-gap: .75rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-3-widescreen {
    --bulma-column-gap: .75rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-3-widescreen-only {
    --bulma-column-gap: .75rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-3-fullhd {
    --bulma-column-gap: .75rem;
  }
}

.columns.is-4 {
  --bulma-column-gap: 1rem;
}

@media screen and (width <= 768px) {
  .columns.is-4-mobile {
    --bulma-column-gap: 1rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-4-tablet {
    --bulma-column-gap: 1rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-4-tablet-only {
    --bulma-column-gap: 1rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-4-touch {
    --bulma-column-gap: 1rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-4-desktop {
    --bulma-column-gap: 1rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-4-desktop-only {
    --bulma-column-gap: 1rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-4-widescreen {
    --bulma-column-gap: 1rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-4-widescreen-only {
    --bulma-column-gap: 1rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-4-fullhd {
    --bulma-column-gap: 1rem;
  }
}

.columns.is-5 {
  --bulma-column-gap: 1.25rem;
}

@media screen and (width <= 768px) {
  .columns.is-5-mobile {
    --bulma-column-gap: 1.25rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-5-tablet {
    --bulma-column-gap: 1.25rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-5-tablet-only {
    --bulma-column-gap: 1.25rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-5-touch {
    --bulma-column-gap: 1.25rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-5-desktop {
    --bulma-column-gap: 1.25rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-5-desktop-only {
    --bulma-column-gap: 1.25rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-5-widescreen {
    --bulma-column-gap: 1.25rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-5-widescreen-only {
    --bulma-column-gap: 1.25rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-5-fullhd {
    --bulma-column-gap: 1.25rem;
  }
}

.columns.is-6 {
  --bulma-column-gap: 1.5rem;
}

@media screen and (width <= 768px) {
  .columns.is-6-mobile {
    --bulma-column-gap: 1.5rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-6-tablet {
    --bulma-column-gap: 1.5rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-6-tablet-only {
    --bulma-column-gap: 1.5rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-6-touch {
    --bulma-column-gap: 1.5rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-6-desktop {
    --bulma-column-gap: 1.5rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-6-desktop-only {
    --bulma-column-gap: 1.5rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-6-widescreen {
    --bulma-column-gap: 1.5rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-6-widescreen-only {
    --bulma-column-gap: 1.5rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-6-fullhd {
    --bulma-column-gap: 1.5rem;
  }
}

.columns.is-7 {
  --bulma-column-gap: 1.75rem;
}

@media screen and (width <= 768px) {
  .columns.is-7-mobile {
    --bulma-column-gap: 1.75rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-7-tablet {
    --bulma-column-gap: 1.75rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-7-tablet-only {
    --bulma-column-gap: 1.75rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-7-touch {
    --bulma-column-gap: 1.75rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-7-desktop {
    --bulma-column-gap: 1.75rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-7-desktop-only {
    --bulma-column-gap: 1.75rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-7-widescreen {
    --bulma-column-gap: 1.75rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-7-widescreen-only {
    --bulma-column-gap: 1.75rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-7-fullhd {
    --bulma-column-gap: 1.75rem;
  }
}

.columns.is-8 {
  --bulma-column-gap: 2rem;
}

@media screen and (width <= 768px) {
  .columns.is-8-mobile {
    --bulma-column-gap: 2rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-8-tablet {
    --bulma-column-gap: 2rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-8-tablet-only {
    --bulma-column-gap: 2rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-8-touch {
    --bulma-column-gap: 2rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-8-desktop {
    --bulma-column-gap: 2rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-8-desktop-only {
    --bulma-column-gap: 2rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-8-widescreen {
    --bulma-column-gap: 2rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-8-widescreen-only {
    --bulma-column-gap: 2rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-8-fullhd {
    --bulma-column-gap: 2rem;
  }
}

.fixed-grid {
  container: bulma-fixed-grid / inline-size;
}

.fixed-grid > .grid {
  --bulma-grid-gap-count: calc(var(--bulma-grid-column-count)  - 1);
  --bulma-grid-column-count: 2;
  grid-template-columns: repeat(var(--bulma-grid-column-count), 1fr);
}

.fixed-grid.has-1-cols > .grid {
  --bulma-grid-column-count: 1;
}

.fixed-grid.has-2-cols > .grid {
  --bulma-grid-column-count: 2;
}

.fixed-grid.has-3-cols > .grid {
  --bulma-grid-column-count: 3;
}

.fixed-grid.has-4-cols > .grid {
  --bulma-grid-column-count: 4;
}

.fixed-grid.has-5-cols > .grid {
  --bulma-grid-column-count: 5;
}

.fixed-grid.has-6-cols > .grid {
  --bulma-grid-column-count: 6;
}

.fixed-grid.has-7-cols > .grid {
  --bulma-grid-column-count: 7;
}

.fixed-grid.has-8-cols > .grid {
  --bulma-grid-column-count: 8;
}

.fixed-grid.has-9-cols > .grid {
  --bulma-grid-column-count: 9;
}

.fixed-grid.has-10-cols > .grid {
  --bulma-grid-column-count: 10;
}

.fixed-grid.has-11-cols > .grid {
  --bulma-grid-column-count: 11;
}

.fixed-grid.has-12-cols > .grid {
  --bulma-grid-column-count: 12;
}

@container bulma-fixed-grid (width <= 768px) {
  .fixed-grid.has-1-cols-mobile > .grid {
    --bulma-grid-column-count: 1;
  }

  .fixed-grid.has-2-cols-mobile > .grid {
    --bulma-grid-column-count: 2;
  }

  .fixed-grid.has-3-cols-mobile > .grid {
    --bulma-grid-column-count: 3;
  }

  .fixed-grid.has-4-cols-mobile > .grid {
    --bulma-grid-column-count: 4;
  }

  .fixed-grid.has-5-cols-mobile > .grid {
    --bulma-grid-column-count: 5;
  }

  .fixed-grid.has-6-cols-mobile > .grid {
    --bulma-grid-column-count: 6;
  }

  .fixed-grid.has-7-cols-mobile > .grid {
    --bulma-grid-column-count: 7;
  }

  .fixed-grid.has-8-cols-mobile > .grid {
    --bulma-grid-column-count: 8;
  }

  .fixed-grid.has-9-cols-mobile > .grid {
    --bulma-grid-column-count: 9;
  }

  .fixed-grid.has-10-cols-mobile > .grid {
    --bulma-grid-column-count: 10;
  }

  .fixed-grid.has-11-cols-mobile > .grid {
    --bulma-grid-column-count: 11;
  }

  .fixed-grid.has-12-cols-mobile > .grid {
    --bulma-grid-column-count: 12;
  }
}

@container bulma-fixed-grid (width >= 769px) {
  .fixed-grid.has-1-cols-tablet > .grid {
    --bulma-grid-column-count: 1;
  }

  .fixed-grid.has-2-cols-tablet > .grid {
    --bulma-grid-column-count: 2;
  }

  .fixed-grid.has-3-cols-tablet > .grid {
    --bulma-grid-column-count: 3;
  }

  .fixed-grid.has-4-cols-tablet > .grid {
    --bulma-grid-column-count: 4;
  }

  .fixed-grid.has-5-cols-tablet > .grid {
    --bulma-grid-column-count: 5;
  }

  .fixed-grid.has-6-cols-tablet > .grid {
    --bulma-grid-column-count: 6;
  }

  .fixed-grid.has-7-cols-tablet > .grid {
    --bulma-grid-column-count: 7;
  }

  .fixed-grid.has-8-cols-tablet > .grid {
    --bulma-grid-column-count: 8;
  }

  .fixed-grid.has-9-cols-tablet > .grid {
    --bulma-grid-column-count: 9;
  }

  .fixed-grid.has-10-cols-tablet > .grid {
    --bulma-grid-column-count: 10;
  }

  .fixed-grid.has-11-cols-tablet > .grid {
    --bulma-grid-column-count: 11;
  }

  .fixed-grid.has-12-cols-tablet > .grid {
    --bulma-grid-column-count: 12;
  }
}

@container bulma-fixed-grid (width >= 1024px) {
  .fixed-grid.has-1-cols-desktop > .grid {
    --bulma-grid-column-count: 1;
  }

  .fixed-grid.has-2-cols-desktop > .grid {
    --bulma-grid-column-count: 2;
  }

  .fixed-grid.has-3-cols-desktop > .grid {
    --bulma-grid-column-count: 3;
  }

  .fixed-grid.has-4-cols-desktop > .grid {
    --bulma-grid-column-count: 4;
  }

  .fixed-grid.has-5-cols-desktop > .grid {
    --bulma-grid-column-count: 5;
  }

  .fixed-grid.has-6-cols-desktop > .grid {
    --bulma-grid-column-count: 6;
  }

  .fixed-grid.has-7-cols-desktop > .grid {
    --bulma-grid-column-count: 7;
  }

  .fixed-grid.has-8-cols-desktop > .grid {
    --bulma-grid-column-count: 8;
  }

  .fixed-grid.has-9-cols-desktop > .grid {
    --bulma-grid-column-count: 9;
  }

  .fixed-grid.has-10-cols-desktop > .grid {
    --bulma-grid-column-count: 10;
  }

  .fixed-grid.has-11-cols-desktop > .grid {
    --bulma-grid-column-count: 11;
  }

  .fixed-grid.has-12-cols-desktop > .grid {
    --bulma-grid-column-count: 12;
  }
}

@container bulma-fixed-grid (width >= 1216px) {
  .fixed-grid.has-1-cols-widescreen > .grid {
    --bulma-grid-column-count: 1;
  }

  .fixed-grid.has-2-cols-widescreen > .grid {
    --bulma-grid-column-count: 2;
  }

  .fixed-grid.has-3-cols-widescreen > .grid {
    --bulma-grid-column-count: 3;
  }

  .fixed-grid.has-4-cols-widescreen > .grid {
    --bulma-grid-column-count: 4;
  }

  .fixed-grid.has-5-cols-widescreen > .grid {
    --bulma-grid-column-count: 5;
  }

  .fixed-grid.has-6-cols-widescreen > .grid {
    --bulma-grid-column-count: 6;
  }

  .fixed-grid.has-7-cols-widescreen > .grid {
    --bulma-grid-column-count: 7;
  }

  .fixed-grid.has-8-cols-widescreen > .grid {
    --bulma-grid-column-count: 8;
  }

  .fixed-grid.has-9-cols-widescreen > .grid {
    --bulma-grid-column-count: 9;
  }

  .fixed-grid.has-10-cols-widescreen > .grid {
    --bulma-grid-column-count: 10;
  }

  .fixed-grid.has-11-cols-widescreen > .grid {
    --bulma-grid-column-count: 11;
  }

  .fixed-grid.has-12-cols-widescreen > .grid {
    --bulma-grid-column-count: 12;
  }
}

@container bulma-fixed-grid (width >= 1408px) {
  .fixed-grid.has-1-cols-fullhd > .grid {
    --bulma-grid-column-count: 1;
  }

  .fixed-grid.has-2-cols-fullhd > .grid {
    --bulma-grid-column-count: 2;
  }

  .fixed-grid.has-3-cols-fullhd > .grid {
    --bulma-grid-column-count: 3;
  }

  .fixed-grid.has-4-cols-fullhd > .grid {
    --bulma-grid-column-count: 4;
  }

  .fixed-grid.has-5-cols-fullhd > .grid {
    --bulma-grid-column-count: 5;
  }

  .fixed-grid.has-6-cols-fullhd > .grid {
    --bulma-grid-column-count: 6;
  }

  .fixed-grid.has-7-cols-fullhd > .grid {
    --bulma-grid-column-count: 7;
  }

  .fixed-grid.has-8-cols-fullhd > .grid {
    --bulma-grid-column-count: 8;
  }

  .fixed-grid.has-9-cols-fullhd > .grid {
    --bulma-grid-column-count: 9;
  }

  .fixed-grid.has-10-cols-fullhd > .grid {
    --bulma-grid-column-count: 10;
  }

  .fixed-grid.has-11-cols-fullhd > .grid {
    --bulma-grid-column-count: 11;
  }

  .fixed-grid.has-12-cols-fullhd > .grid {
    --bulma-grid-column-count: 12;
  }
}

@container bulma-fixed-grid (width <= 768px) {
  .fixed-grid.has-auto-count .grid {
    --bulma-grid-column-count: 2;
  }
}

@container bulma-fixed-grid (width >= 769px) {
  .fixed-grid.has-auto-count .grid {
    --bulma-grid-column-count: 4;
  }
}

@container bulma-fixed-grid (width >= 1024px) {
  .fixed-grid.has-auto-count .grid {
    --bulma-grid-column-count: 8;
  }
}

@container bulma-fixed-grid (width >= 1216px) {
  .fixed-grid.has-auto-count .grid {
    --bulma-grid-column-count: 12;
  }
}

@container bulma-fixed-grid (width >= 1408px) {
  .fixed-grid.has-auto-count .grid {
    --bulma-grid-column-count: 16;
  }
}

.grid {
  --bulma-grid-gap: .75rem;
  --bulma-grid-column-min: 9rem;
  --bulma-grid-cell-column-span: 1;
  --bulma-grid-cell-row-span: 1;
  gap: var(--bulma-grid-gap);
  column-gap: var(--bulma-grid-column-gap, var(--bulma-grid-gap));
  row-gap: var(--bulma-grid-row-gap, var(--bulma-grid-gap));
  grid-template-columns: repeat(auto-fit, minmax(var(--bulma-grid-column-min), 1fr));
  grid-template-rows: auto;
  display: grid;
}

.grid.is-auto-fill {
  grid-template-columns: repeat(auto-fill, minmax(var(--bulma-grid-column-min), 1fr));
}

.grid.is-col-min-1 {
  --bulma-grid-column-min: 1.5rem;
}

.grid.is-col-min-2 {
  --bulma-grid-column-min: 3rem;
}

.grid.is-col-min-3 {
  --bulma-grid-column-min: 4.5rem;
}

.grid.is-col-min-4 {
  --bulma-grid-column-min: 6rem;
}

.grid.is-col-min-5 {
  --bulma-grid-column-min: 7.5rem;
}

.grid.is-col-min-6 {
  --bulma-grid-column-min: 9rem;
}

.grid.is-col-min-7 {
  --bulma-grid-column-min: 10.5rem;
}

.grid.is-col-min-8 {
  --bulma-grid-column-min: 12rem;
}

.grid.is-col-min-9 {
  --bulma-grid-column-min: 13.5rem;
}

.grid.is-col-min-10 {
  --bulma-grid-column-min: 15rem;
}

.grid.is-col-min-11 {
  --bulma-grid-column-min: 16.5rem;
}

.grid.is-col-min-12 {
  --bulma-grid-column-min: 18rem;
}

.grid.is-col-min-13 {
  --bulma-grid-column-min: 19.5rem;
}

.grid.is-col-min-14 {
  --bulma-grid-column-min: 21rem;
}

.grid.is-col-min-15 {
  --bulma-grid-column-min: 22.5rem;
}

.grid.is-col-min-16 {
  --bulma-grid-column-min: 24rem;
}

.grid.is-col-min-17 {
  --bulma-grid-column-min: 25.5rem;
}

.grid.is-col-min-18 {
  --bulma-grid-column-min: 27rem;
}

.grid.is-col-min-19 {
  --bulma-grid-column-min: 28.5rem;
}

.grid.is-col-min-20 {
  --bulma-grid-column-min: 30rem;
}

.grid.is-col-min-21 {
  --bulma-grid-column-min: 31.5rem;
}

.grid.is-col-min-22 {
  --bulma-grid-column-min: 33rem;
}

.grid.is-col-min-23 {
  --bulma-grid-column-min: 34.5rem;
}

.grid.is-col-min-24 {
  --bulma-grid-column-min: 36rem;
}

.grid.is-col-min-25 {
  --bulma-grid-column-min: 37.5rem;
}

.grid.is-col-min-26 {
  --bulma-grid-column-min: 39rem;
}

.grid.is-col-min-27 {
  --bulma-grid-column-min: 40.5rem;
}

.grid.is-col-min-28 {
  --bulma-grid-column-min: 42rem;
}

.grid.is-col-min-29 {
  --bulma-grid-column-min: 43.5rem;
}

.grid.is-col-min-30 {
  --bulma-grid-column-min: 45rem;
}

.grid.is-col-min-31 {
  --bulma-grid-column-min: 46.5rem;
}

.grid.is-col-min-32 {
  --bulma-grid-column-min: 48rem;
}

.cell {
  grid-column-end: span var(--bulma-grid-cell-column-span);
  grid-column-start: var(--bulma-grid-cell-column-start);
  grid-row-end: span var(--bulma-grid-cell-row-span);
  grid-row-start: var(--bulma-grid-cell-row-start);
}

.cell.is-col-start-end {
  --bulma-grid-cell-column-start: -1;
}

.cell.is-row-start-end {
  --bulma-grid-cell-row-start: -1;
}

.cell.is-col-start-1 {
  --bulma-grid-cell-column-start: 1;
}

.cell.is-col-end-1 {
  --bulma-grid-cell-column-end: 1;
}

.cell.is-col-from-end-1 {
  --bulma-grid-cell-column-start: -1;
}

.cell.is-col-span-1 {
  --bulma-grid-cell-column-span: 1;
}

.cell.is-row-start-1 {
  --bulma-grid-cell-row-start: 1;
}

.cell.is-row-end-1 {
  --bulma-grid-cell-row-end: 1;
}

.cell.is-row-from-end-1 {
  --bulma-grid-cell-row-start: -1;
}

.cell.is-row-span-1 {
  --bulma-grid-cell-row-span: 1;
}

.cell.is-col-start-2 {
  --bulma-grid-cell-column-start: 2;
}

.cell.is-col-end-2 {
  --bulma-grid-cell-column-end: 2;
}

.cell.is-col-from-end-2 {
  --bulma-grid-cell-column-start: -2;
}

.cell.is-col-span-2 {
  --bulma-grid-cell-column-span: 2;
}

.cell.is-row-start-2 {
  --bulma-grid-cell-row-start: 2;
}

.cell.is-row-end-2 {
  --bulma-grid-cell-row-end: 2;
}

.cell.is-row-from-end-2 {
  --bulma-grid-cell-row-start: -2;
}

.cell.is-row-span-2 {
  --bulma-grid-cell-row-span: 2;
}

.cell.is-col-start-3 {
  --bulma-grid-cell-column-start: 3;
}

.cell.is-col-end-3 {
  --bulma-grid-cell-column-end: 3;
}

.cell.is-col-from-end-3 {
  --bulma-grid-cell-column-start: -3;
}

.cell.is-col-span-3 {
  --bulma-grid-cell-column-span: 3;
}

.cell.is-row-start-3 {
  --bulma-grid-cell-row-start: 3;
}

.cell.is-row-end-3 {
  --bulma-grid-cell-row-end: 3;
}

.cell.is-row-from-end-3 {
  --bulma-grid-cell-row-start: -3;
}

.cell.is-row-span-3 {
  --bulma-grid-cell-row-span: 3;
}

.cell.is-col-start-4 {
  --bulma-grid-cell-column-start: 4;
}

.cell.is-col-end-4 {
  --bulma-grid-cell-column-end: 4;
}

.cell.is-col-from-end-4 {
  --bulma-grid-cell-column-start: -4;
}

.cell.is-col-span-4 {
  --bulma-grid-cell-column-span: 4;
}

.cell.is-row-start-4 {
  --bulma-grid-cell-row-start: 4;
}

.cell.is-row-end-4 {
  --bulma-grid-cell-row-end: 4;
}

.cell.is-row-from-end-4 {
  --bulma-grid-cell-row-start: -4;
}

.cell.is-row-span-4 {
  --bulma-grid-cell-row-span: 4;
}

.cell.is-col-start-5 {
  --bulma-grid-cell-column-start: 5;
}

.cell.is-col-end-5 {
  --bulma-grid-cell-column-end: 5;
}

.cell.is-col-from-end-5 {
  --bulma-grid-cell-column-start: -5;
}

.cell.is-col-span-5 {
  --bulma-grid-cell-column-span: 5;
}

.cell.is-row-start-5 {
  --bulma-grid-cell-row-start: 5;
}

.cell.is-row-end-5 {
  --bulma-grid-cell-row-end: 5;
}

.cell.is-row-from-end-5 {
  --bulma-grid-cell-row-start: -5;
}

.cell.is-row-span-5 {
  --bulma-grid-cell-row-span: 5;
}

.cell.is-col-start-6 {
  --bulma-grid-cell-column-start: 6;
}

.cell.is-col-end-6 {
  --bulma-grid-cell-column-end: 6;
}

.cell.is-col-from-end-6 {
  --bulma-grid-cell-column-start: -6;
}

.cell.is-col-span-6 {
  --bulma-grid-cell-column-span: 6;
}

.cell.is-row-start-6 {
  --bulma-grid-cell-row-start: 6;
}

.cell.is-row-end-6 {
  --bulma-grid-cell-row-end: 6;
}

.cell.is-row-from-end-6 {
  --bulma-grid-cell-row-start: -6;
}

.cell.is-row-span-6 {
  --bulma-grid-cell-row-span: 6;
}

.cell.is-col-start-7 {
  --bulma-grid-cell-column-start: 7;
}

.cell.is-col-end-7 {
  --bulma-grid-cell-column-end: 7;
}

.cell.is-col-from-end-7 {
  --bulma-grid-cell-column-start: -7;
}

.cell.is-col-span-7 {
  --bulma-grid-cell-column-span: 7;
}

.cell.is-row-start-7 {
  --bulma-grid-cell-row-start: 7;
}

.cell.is-row-end-7 {
  --bulma-grid-cell-row-end: 7;
}

.cell.is-row-from-end-7 {
  --bulma-grid-cell-row-start: -7;
}

.cell.is-row-span-7 {
  --bulma-grid-cell-row-span: 7;
}

.cell.is-col-start-8 {
  --bulma-grid-cell-column-start: 8;
}

.cell.is-col-end-8 {
  --bulma-grid-cell-column-end: 8;
}

.cell.is-col-from-end-8 {
  --bulma-grid-cell-column-start: -8;
}

.cell.is-col-span-8 {
  --bulma-grid-cell-column-span: 8;
}

.cell.is-row-start-8 {
  --bulma-grid-cell-row-start: 8;
}

.cell.is-row-end-8 {
  --bulma-grid-cell-row-end: 8;
}

.cell.is-row-from-end-8 {
  --bulma-grid-cell-row-start: -8;
}

.cell.is-row-span-8 {
  --bulma-grid-cell-row-span: 8;
}

.cell.is-col-start-9 {
  --bulma-grid-cell-column-start: 9;
}

.cell.is-col-end-9 {
  --bulma-grid-cell-column-end: 9;
}

.cell.is-col-from-end-9 {
  --bulma-grid-cell-column-start: -9;
}

.cell.is-col-span-9 {
  --bulma-grid-cell-column-span: 9;
}

.cell.is-row-start-9 {
  --bulma-grid-cell-row-start: 9;
}

.cell.is-row-end-9 {
  --bulma-grid-cell-row-end: 9;
}

.cell.is-row-from-end-9 {
  --bulma-grid-cell-row-start: -9;
}

.cell.is-row-span-9 {
  --bulma-grid-cell-row-span: 9;
}

.cell.is-col-start-10 {
  --bulma-grid-cell-column-start: 10;
}

.cell.is-col-end-10 {
  --bulma-grid-cell-column-end: 10;
}

.cell.is-col-from-end-10 {
  --bulma-grid-cell-column-start: -10;
}

.cell.is-col-span-10 {
  --bulma-grid-cell-column-span: 10;
}

.cell.is-row-start-10 {
  --bulma-grid-cell-row-start: 10;
}

.cell.is-row-end-10 {
  --bulma-grid-cell-row-end: 10;
}

.cell.is-row-from-end-10 {
  --bulma-grid-cell-row-start: -10;
}

.cell.is-row-span-10 {
  --bulma-grid-cell-row-span: 10;
}

.cell.is-col-start-11 {
  --bulma-grid-cell-column-start: 11;
}

.cell.is-col-end-11 {
  --bulma-grid-cell-column-end: 11;
}

.cell.is-col-from-end-11 {
  --bulma-grid-cell-column-start: -11;
}

.cell.is-col-span-11 {
  --bulma-grid-cell-column-span: 11;
}

.cell.is-row-start-11 {
  --bulma-grid-cell-row-start: 11;
}

.cell.is-row-end-11 {
  --bulma-grid-cell-row-end: 11;
}

.cell.is-row-from-end-11 {
  --bulma-grid-cell-row-start: -11;
}

.cell.is-row-span-11 {
  --bulma-grid-cell-row-span: 11;
}

.cell.is-col-start-12 {
  --bulma-grid-cell-column-start: 12;
}

.cell.is-col-end-12 {
  --bulma-grid-cell-column-end: 12;
}

.cell.is-col-from-end-12 {
  --bulma-grid-cell-column-start: -12;
}

.cell.is-col-span-12 {
  --bulma-grid-cell-column-span: 12;
}

.cell.is-row-start-12 {
  --bulma-grid-cell-row-start: 12;
}

.cell.is-row-end-12 {
  --bulma-grid-cell-row-end: 12;
}

.cell.is-row-from-end-12 {
  --bulma-grid-cell-row-start: -12;
}

.cell.is-row-span-12 {
  --bulma-grid-cell-row-span: 12;
}

@media screen and (width <= 768px) {
  .cell.is-col-start-1-mobile {
    --bulma-grid-cell-column-start: 1;
  }

  .cell.is-col-end-1-mobile {
    --bulma-grid-cell-column-end: 1;
  }

  .cell.is-col-from-end-1-mobile {
    --bulma-grid-cell-column-start: -1;
  }

  .cell.is-col-span-1-mobile {
    --bulma-grid-cell-column-span: 1;
  }

  .cell.is-row-start-1-mobile {
    --bulma-grid-cell-row-start: 1;
  }

  .cell.is-row-end-1-mobile {
    --bulma-grid-cell-row-end: 1;
  }

  .cell.is-row-from-end-1-mobile {
    --bulma-grid-cell-row-start: -1;
  }

  .cell.is-row-span-1-mobile {
    --bulma-grid-cell-row-span: 1;
  }

  .cell.is-col-start-2-mobile {
    --bulma-grid-cell-column-start: 2;
  }

  .cell.is-col-end-2-mobile {
    --bulma-grid-cell-column-end: 2;
  }

  .cell.is-col-from-end-2-mobile {
    --bulma-grid-cell-column-start: -2;
  }

  .cell.is-col-span-2-mobile {
    --bulma-grid-cell-column-span: 2;
  }

  .cell.is-row-start-2-mobile {
    --bulma-grid-cell-row-start: 2;
  }

  .cell.is-row-end-2-mobile {
    --bulma-grid-cell-row-end: 2;
  }

  .cell.is-row-from-end-2-mobile {
    --bulma-grid-cell-row-start: -2;
  }

  .cell.is-row-span-2-mobile {
    --bulma-grid-cell-row-span: 2;
  }

  .cell.is-col-start-3-mobile {
    --bulma-grid-cell-column-start: 3;
  }

  .cell.is-col-end-3-mobile {
    --bulma-grid-cell-column-end: 3;
  }

  .cell.is-col-from-end-3-mobile {
    --bulma-grid-cell-column-start: -3;
  }

  .cell.is-col-span-3-mobile {
    --bulma-grid-cell-column-span: 3;
  }

  .cell.is-row-start-3-mobile {
    --bulma-grid-cell-row-start: 3;
  }

  .cell.is-row-end-3-mobile {
    --bulma-grid-cell-row-end: 3;
  }

  .cell.is-row-from-end-3-mobile {
    --bulma-grid-cell-row-start: -3;
  }

  .cell.is-row-span-3-mobile {
    --bulma-grid-cell-row-span: 3;
  }

  .cell.is-col-start-4-mobile {
    --bulma-grid-cell-column-start: 4;
  }

  .cell.is-col-end-4-mobile {
    --bulma-grid-cell-column-end: 4;
  }

  .cell.is-col-from-end-4-mobile {
    --bulma-grid-cell-column-start: -4;
  }

  .cell.is-col-span-4-mobile {
    --bulma-grid-cell-column-span: 4;
  }

  .cell.is-row-start-4-mobile {
    --bulma-grid-cell-row-start: 4;
  }

  .cell.is-row-end-4-mobile {
    --bulma-grid-cell-row-end: 4;
  }

  .cell.is-row-from-end-4-mobile {
    --bulma-grid-cell-row-start: -4;
  }

  .cell.is-row-span-4-mobile {
    --bulma-grid-cell-row-span: 4;
  }

  .cell.is-col-start-5-mobile {
    --bulma-grid-cell-column-start: 5;
  }

  .cell.is-col-end-5-mobile {
    --bulma-grid-cell-column-end: 5;
  }

  .cell.is-col-from-end-5-mobile {
    --bulma-grid-cell-column-start: -5;
  }

  .cell.is-col-span-5-mobile {
    --bulma-grid-cell-column-span: 5;
  }

  .cell.is-row-start-5-mobile {
    --bulma-grid-cell-row-start: 5;
  }

  .cell.is-row-end-5-mobile {
    --bulma-grid-cell-row-end: 5;
  }

  .cell.is-row-from-end-5-mobile {
    --bulma-grid-cell-row-start: -5;
  }

  .cell.is-row-span-5-mobile {
    --bulma-grid-cell-row-span: 5;
  }

  .cell.is-col-start-6-mobile {
    --bulma-grid-cell-column-start: 6;
  }

  .cell.is-col-end-6-mobile {
    --bulma-grid-cell-column-end: 6;
  }

  .cell.is-col-from-end-6-mobile {
    --bulma-grid-cell-column-start: -6;
  }

  .cell.is-col-span-6-mobile {
    --bulma-grid-cell-column-span: 6;
  }

  .cell.is-row-start-6-mobile {
    --bulma-grid-cell-row-start: 6;
  }

  .cell.is-row-end-6-mobile {
    --bulma-grid-cell-row-end: 6;
  }

  .cell.is-row-from-end-6-mobile {
    --bulma-grid-cell-row-start: -6;
  }

  .cell.is-row-span-6-mobile {
    --bulma-grid-cell-row-span: 6;
  }

  .cell.is-col-start-7-mobile {
    --bulma-grid-cell-column-start: 7;
  }

  .cell.is-col-end-7-mobile {
    --bulma-grid-cell-column-end: 7;
  }

  .cell.is-col-from-end-7-mobile {
    --bulma-grid-cell-column-start: -7;
  }

  .cell.is-col-span-7-mobile {
    --bulma-grid-cell-column-span: 7;
  }

  .cell.is-row-start-7-mobile {
    --bulma-grid-cell-row-start: 7;
  }

  .cell.is-row-end-7-mobile {
    --bulma-grid-cell-row-end: 7;
  }

  .cell.is-row-from-end-7-mobile {
    --bulma-grid-cell-row-start: -7;
  }

  .cell.is-row-span-7-mobile {
    --bulma-grid-cell-row-span: 7;
  }

  .cell.is-col-start-8-mobile {
    --bulma-grid-cell-column-start: 8;
  }

  .cell.is-col-end-8-mobile {
    --bulma-grid-cell-column-end: 8;
  }

  .cell.is-col-from-end-8-mobile {
    --bulma-grid-cell-column-start: -8;
  }

  .cell.is-col-span-8-mobile {
    --bulma-grid-cell-column-span: 8;
  }

  .cell.is-row-start-8-mobile {
    --bulma-grid-cell-row-start: 8;
  }

  .cell.is-row-end-8-mobile {
    --bulma-grid-cell-row-end: 8;
  }

  .cell.is-row-from-end-8-mobile {
    --bulma-grid-cell-row-start: -8;
  }

  .cell.is-row-span-8-mobile {
    --bulma-grid-cell-row-span: 8;
  }

  .cell.is-col-start-9-mobile {
    --bulma-grid-cell-column-start: 9;
  }

  .cell.is-col-end-9-mobile {
    --bulma-grid-cell-column-end: 9;
  }

  .cell.is-col-from-end-9-mobile {
    --bulma-grid-cell-column-start: -9;
  }

  .cell.is-col-span-9-mobile {
    --bulma-grid-cell-column-span: 9;
  }

  .cell.is-row-start-9-mobile {
    --bulma-grid-cell-row-start: 9;
  }

  .cell.is-row-end-9-mobile {
    --bulma-grid-cell-row-end: 9;
  }

  .cell.is-row-from-end-9-mobile {
    --bulma-grid-cell-row-start: -9;
  }

  .cell.is-row-span-9-mobile {
    --bulma-grid-cell-row-span: 9;
  }

  .cell.is-col-start-10-mobile {
    --bulma-grid-cell-column-start: 10;
  }

  .cell.is-col-end-10-mobile {
    --bulma-grid-cell-column-end: 10;
  }

  .cell.is-col-from-end-10-mobile {
    --bulma-grid-cell-column-start: -10;
  }

  .cell.is-col-span-10-mobile {
    --bulma-grid-cell-column-span: 10;
  }

  .cell.is-row-start-10-mobile {
    --bulma-grid-cell-row-start: 10;
  }

  .cell.is-row-end-10-mobile {
    --bulma-grid-cell-row-end: 10;
  }

  .cell.is-row-from-end-10-mobile {
    --bulma-grid-cell-row-start: -10;
  }

  .cell.is-row-span-10-mobile {
    --bulma-grid-cell-row-span: 10;
  }

  .cell.is-col-start-11-mobile {
    --bulma-grid-cell-column-start: 11;
  }

  .cell.is-col-end-11-mobile {
    --bulma-grid-cell-column-end: 11;
  }

  .cell.is-col-from-end-11-mobile {
    --bulma-grid-cell-column-start: -11;
  }

  .cell.is-col-span-11-mobile {
    --bulma-grid-cell-column-span: 11;
  }

  .cell.is-row-start-11-mobile {
    --bulma-grid-cell-row-start: 11;
  }

  .cell.is-row-end-11-mobile {
    --bulma-grid-cell-row-end: 11;
  }

  .cell.is-row-from-end-11-mobile {
    --bulma-grid-cell-row-start: -11;
  }

  .cell.is-row-span-11-mobile {
    --bulma-grid-cell-row-span: 11;
  }

  .cell.is-col-start-12-mobile {
    --bulma-grid-cell-column-start: 12;
  }

  .cell.is-col-end-12-mobile {
    --bulma-grid-cell-column-end: 12;
  }

  .cell.is-col-from-end-12-mobile {
    --bulma-grid-cell-column-start: -12;
  }

  .cell.is-col-span-12-mobile {
    --bulma-grid-cell-column-span: 12;
  }

  .cell.is-row-start-12-mobile {
    --bulma-grid-cell-row-start: 12;
  }

  .cell.is-row-end-12-mobile {
    --bulma-grid-cell-row-end: 12;
  }

  .cell.is-row-from-end-12-mobile {
    --bulma-grid-cell-row-start: -12;
  }

  .cell.is-row-span-12-mobile {
    --bulma-grid-cell-row-span: 12;
  }
}

@media screen and (width >= 769px), print {
  .cell.is-col-start-1-tablet {
    --bulma-grid-cell-column-start: 1;
  }

  .cell.is-col-end-1-tablet {
    --bulma-grid-cell-column-end: 1;
  }

  .cell.is-col-from-end-1-tablet {
    --bulma-grid-cell-column-start: -1;
  }

  .cell.is-col-span-1-tablet {
    --bulma-grid-cell-column-span: 1;
  }

  .cell.is-row-start-1-tablet {
    --bulma-grid-cell-row-start: 1;
  }

  .cell.is-row-end-1-tablet {
    --bulma-grid-cell-row-end: 1;
  }

  .cell.is-row-from-end-1-tablet {
    --bulma-grid-cell-row-start: -1;
  }

  .cell.is-row-span-1-tablet {
    --bulma-grid-cell-row-span: 1;
  }

  .cell.is-col-start-2-tablet {
    --bulma-grid-cell-column-start: 2;
  }

  .cell.is-col-end-2-tablet {
    --bulma-grid-cell-column-end: 2;
  }

  .cell.is-col-from-end-2-tablet {
    --bulma-grid-cell-column-start: -2;
  }

  .cell.is-col-span-2-tablet {
    --bulma-grid-cell-column-span: 2;
  }

  .cell.is-row-start-2-tablet {
    --bulma-grid-cell-row-start: 2;
  }

  .cell.is-row-end-2-tablet {
    --bulma-grid-cell-row-end: 2;
  }

  .cell.is-row-from-end-2-tablet {
    --bulma-grid-cell-row-start: -2;
  }

  .cell.is-row-span-2-tablet {
    --bulma-grid-cell-row-span: 2;
  }

  .cell.is-col-start-3-tablet {
    --bulma-grid-cell-column-start: 3;
  }

  .cell.is-col-end-3-tablet {
    --bulma-grid-cell-column-end: 3;
  }

  .cell.is-col-from-end-3-tablet {
    --bulma-grid-cell-column-start: -3;
  }

  .cell.is-col-span-3-tablet {
    --bulma-grid-cell-column-span: 3;
  }

  .cell.is-row-start-3-tablet {
    --bulma-grid-cell-row-start: 3;
  }

  .cell.is-row-end-3-tablet {
    --bulma-grid-cell-row-end: 3;
  }

  .cell.is-row-from-end-3-tablet {
    --bulma-grid-cell-row-start: -3;
  }

  .cell.is-row-span-3-tablet {
    --bulma-grid-cell-row-span: 3;
  }

  .cell.is-col-start-4-tablet {
    --bulma-grid-cell-column-start: 4;
  }

  .cell.is-col-end-4-tablet {
    --bulma-grid-cell-column-end: 4;
  }

  .cell.is-col-from-end-4-tablet {
    --bulma-grid-cell-column-start: -4;
  }

  .cell.is-col-span-4-tablet {
    --bulma-grid-cell-column-span: 4;
  }

  .cell.is-row-start-4-tablet {
    --bulma-grid-cell-row-start: 4;
  }

  .cell.is-row-end-4-tablet {
    --bulma-grid-cell-row-end: 4;
  }

  .cell.is-row-from-end-4-tablet {
    --bulma-grid-cell-row-start: -4;
  }

  .cell.is-row-span-4-tablet {
    --bulma-grid-cell-row-span: 4;
  }

  .cell.is-col-start-5-tablet {
    --bulma-grid-cell-column-start: 5;
  }

  .cell.is-col-end-5-tablet {
    --bulma-grid-cell-column-end: 5;
  }

  .cell.is-col-from-end-5-tablet {
    --bulma-grid-cell-column-start: -5;
  }

  .cell.is-col-span-5-tablet {
    --bulma-grid-cell-column-span: 5;
  }

  .cell.is-row-start-5-tablet {
    --bulma-grid-cell-row-start: 5;
  }

  .cell.is-row-end-5-tablet {
    --bulma-grid-cell-row-end: 5;
  }

  .cell.is-row-from-end-5-tablet {
    --bulma-grid-cell-row-start: -5;
  }

  .cell.is-row-span-5-tablet {
    --bulma-grid-cell-row-span: 5;
  }

  .cell.is-col-start-6-tablet {
    --bulma-grid-cell-column-start: 6;
  }

  .cell.is-col-end-6-tablet {
    --bulma-grid-cell-column-end: 6;
  }

  .cell.is-col-from-end-6-tablet {
    --bulma-grid-cell-column-start: -6;
  }

  .cell.is-col-span-6-tablet {
    --bulma-grid-cell-column-span: 6;
  }

  .cell.is-row-start-6-tablet {
    --bulma-grid-cell-row-start: 6;
  }

  .cell.is-row-end-6-tablet {
    --bulma-grid-cell-row-end: 6;
  }

  .cell.is-row-from-end-6-tablet {
    --bulma-grid-cell-row-start: -6;
  }

  .cell.is-row-span-6-tablet {
    --bulma-grid-cell-row-span: 6;
  }

  .cell.is-col-start-7-tablet {
    --bulma-grid-cell-column-start: 7;
  }

  .cell.is-col-end-7-tablet {
    --bulma-grid-cell-column-end: 7;
  }

  .cell.is-col-from-end-7-tablet {
    --bulma-grid-cell-column-start: -7;
  }

  .cell.is-col-span-7-tablet {
    --bulma-grid-cell-column-span: 7;
  }

  .cell.is-row-start-7-tablet {
    --bulma-grid-cell-row-start: 7;
  }

  .cell.is-row-end-7-tablet {
    --bulma-grid-cell-row-end: 7;
  }

  .cell.is-row-from-end-7-tablet {
    --bulma-grid-cell-row-start: -7;
  }

  .cell.is-row-span-7-tablet {
    --bulma-grid-cell-row-span: 7;
  }

  .cell.is-col-start-8-tablet {
    --bulma-grid-cell-column-start: 8;
  }

  .cell.is-col-end-8-tablet {
    --bulma-grid-cell-column-end: 8;
  }

  .cell.is-col-from-end-8-tablet {
    --bulma-grid-cell-column-start: -8;
  }

  .cell.is-col-span-8-tablet {
    --bulma-grid-cell-column-span: 8;
  }

  .cell.is-row-start-8-tablet {
    --bulma-grid-cell-row-start: 8;
  }

  .cell.is-row-end-8-tablet {
    --bulma-grid-cell-row-end: 8;
  }

  .cell.is-row-from-end-8-tablet {
    --bulma-grid-cell-row-start: -8;
  }

  .cell.is-row-span-8-tablet {
    --bulma-grid-cell-row-span: 8;
  }

  .cell.is-col-start-9-tablet {
    --bulma-grid-cell-column-start: 9;
  }

  .cell.is-col-end-9-tablet {
    --bulma-grid-cell-column-end: 9;
  }

  .cell.is-col-from-end-9-tablet {
    --bulma-grid-cell-column-start: -9;
  }

  .cell.is-col-span-9-tablet {
    --bulma-grid-cell-column-span: 9;
  }

  .cell.is-row-start-9-tablet {
    --bulma-grid-cell-row-start: 9;
  }

  .cell.is-row-end-9-tablet {
    --bulma-grid-cell-row-end: 9;
  }

  .cell.is-row-from-end-9-tablet {
    --bulma-grid-cell-row-start: -9;
  }

  .cell.is-row-span-9-tablet {
    --bulma-grid-cell-row-span: 9;
  }

  .cell.is-col-start-10-tablet {
    --bulma-grid-cell-column-start: 10;
  }

  .cell.is-col-end-10-tablet {
    --bulma-grid-cell-column-end: 10;
  }

  .cell.is-col-from-end-10-tablet {
    --bulma-grid-cell-column-start: -10;
  }

  .cell.is-col-span-10-tablet {
    --bulma-grid-cell-column-span: 10;
  }

  .cell.is-row-start-10-tablet {
    --bulma-grid-cell-row-start: 10;
  }

  .cell.is-row-end-10-tablet {
    --bulma-grid-cell-row-end: 10;
  }

  .cell.is-row-from-end-10-tablet {
    --bulma-grid-cell-row-start: -10;
  }

  .cell.is-row-span-10-tablet {
    --bulma-grid-cell-row-span: 10;
  }

  .cell.is-col-start-11-tablet {
    --bulma-grid-cell-column-start: 11;
  }

  .cell.is-col-end-11-tablet {
    --bulma-grid-cell-column-end: 11;
  }

  .cell.is-col-from-end-11-tablet {
    --bulma-grid-cell-column-start: -11;
  }

  .cell.is-col-span-11-tablet {
    --bulma-grid-cell-column-span: 11;
  }

  .cell.is-row-start-11-tablet {
    --bulma-grid-cell-row-start: 11;
  }

  .cell.is-row-end-11-tablet {
    --bulma-grid-cell-row-end: 11;
  }

  .cell.is-row-from-end-11-tablet {
    --bulma-grid-cell-row-start: -11;
  }

  .cell.is-row-span-11-tablet {
    --bulma-grid-cell-row-span: 11;
  }

  .cell.is-col-start-12-tablet {
    --bulma-grid-cell-column-start: 12;
  }

  .cell.is-col-end-12-tablet {
    --bulma-grid-cell-column-end: 12;
  }

  .cell.is-col-from-end-12-tablet {
    --bulma-grid-cell-column-start: -12;
  }

  .cell.is-col-span-12-tablet {
    --bulma-grid-cell-column-span: 12;
  }

  .cell.is-row-start-12-tablet {
    --bulma-grid-cell-row-start: 12;
  }

  .cell.is-row-end-12-tablet {
    --bulma-grid-cell-row-end: 12;
  }

  .cell.is-row-from-end-12-tablet {
    --bulma-grid-cell-row-start: -12;
  }

  .cell.is-row-span-12-tablet {
    --bulma-grid-cell-row-span: 12;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .cell.is-col-start-1-tablet-only {
    --bulma-grid-cell-column-start: 1;
  }

  .cell.is-col-end-1-tablet-only {
    --bulma-grid-cell-column-end: 1;
  }

  .cell.is-col-from-end-1-tablet-only {
    --bulma-grid-cell-column-start: -1;
  }

  .cell.is-col-span-1-tablet-only {
    --bulma-grid-cell-column-span: 1;
  }

  .cell.is-row-start-1-tablet-only {
    --bulma-grid-cell-row-start: 1;
  }

  .cell.is-row-end-1-tablet-only {
    --bulma-grid-cell-row-end: 1;
  }

  .cell.is-row-from-end-1-tablet-only {
    --bulma-grid-cell-row-start: -1;
  }

  .cell.is-row-span-1-tablet-only {
    --bulma-grid-cell-row-span: 1;
  }

  .cell.is-col-start-2-tablet-only {
    --bulma-grid-cell-column-start: 2;
  }

  .cell.is-col-end-2-tablet-only {
    --bulma-grid-cell-column-end: 2;
  }

  .cell.is-col-from-end-2-tablet-only {
    --bulma-grid-cell-column-start: -2;
  }

  .cell.is-col-span-2-tablet-only {
    --bulma-grid-cell-column-span: 2;
  }

  .cell.is-row-start-2-tablet-only {
    --bulma-grid-cell-row-start: 2;
  }

  .cell.is-row-end-2-tablet-only {
    --bulma-grid-cell-row-end: 2;
  }

  .cell.is-row-from-end-2-tablet-only {
    --bulma-grid-cell-row-start: -2;
  }

  .cell.is-row-span-2-tablet-only {
    --bulma-grid-cell-row-span: 2;
  }

  .cell.is-col-start-3-tablet-only {
    --bulma-grid-cell-column-start: 3;
  }

  .cell.is-col-end-3-tablet-only {
    --bulma-grid-cell-column-end: 3;
  }

  .cell.is-col-from-end-3-tablet-only {
    --bulma-grid-cell-column-start: -3;
  }

  .cell.is-col-span-3-tablet-only {
    --bulma-grid-cell-column-span: 3;
  }

  .cell.is-row-start-3-tablet-only {
    --bulma-grid-cell-row-start: 3;
  }

  .cell.is-row-end-3-tablet-only {
    --bulma-grid-cell-row-end: 3;
  }

  .cell.is-row-from-end-3-tablet-only {
    --bulma-grid-cell-row-start: -3;
  }

  .cell.is-row-span-3-tablet-only {
    --bulma-grid-cell-row-span: 3;
  }

  .cell.is-col-start-4-tablet-only {
    --bulma-grid-cell-column-start: 4;
  }

  .cell.is-col-end-4-tablet-only {
    --bulma-grid-cell-column-end: 4;
  }

  .cell.is-col-from-end-4-tablet-only {
    --bulma-grid-cell-column-start: -4;
  }

  .cell.is-col-span-4-tablet-only {
    --bulma-grid-cell-column-span: 4;
  }

  .cell.is-row-start-4-tablet-only {
    --bulma-grid-cell-row-start: 4;
  }

  .cell.is-row-end-4-tablet-only {
    --bulma-grid-cell-row-end: 4;
  }

  .cell.is-row-from-end-4-tablet-only {
    --bulma-grid-cell-row-start: -4;
  }

  .cell.is-row-span-4-tablet-only {
    --bulma-grid-cell-row-span: 4;
  }

  .cell.is-col-start-5-tablet-only {
    --bulma-grid-cell-column-start: 5;
  }

  .cell.is-col-end-5-tablet-only {
    --bulma-grid-cell-column-end: 5;
  }

  .cell.is-col-from-end-5-tablet-only {
    --bulma-grid-cell-column-start: -5;
  }

  .cell.is-col-span-5-tablet-only {
    --bulma-grid-cell-column-span: 5;
  }

  .cell.is-row-start-5-tablet-only {
    --bulma-grid-cell-row-start: 5;
  }

  .cell.is-row-end-5-tablet-only {
    --bulma-grid-cell-row-end: 5;
  }

  .cell.is-row-from-end-5-tablet-only {
    --bulma-grid-cell-row-start: -5;
  }

  .cell.is-row-span-5-tablet-only {
    --bulma-grid-cell-row-span: 5;
  }

  .cell.is-col-start-6-tablet-only {
    --bulma-grid-cell-column-start: 6;
  }

  .cell.is-col-end-6-tablet-only {
    --bulma-grid-cell-column-end: 6;
  }

  .cell.is-col-from-end-6-tablet-only {
    --bulma-grid-cell-column-start: -6;
  }

  .cell.is-col-span-6-tablet-only {
    --bulma-grid-cell-column-span: 6;
  }

  .cell.is-row-start-6-tablet-only {
    --bulma-grid-cell-row-start: 6;
  }

  .cell.is-row-end-6-tablet-only {
    --bulma-grid-cell-row-end: 6;
  }

  .cell.is-row-from-end-6-tablet-only {
    --bulma-grid-cell-row-start: -6;
  }

  .cell.is-row-span-6-tablet-only {
    --bulma-grid-cell-row-span: 6;
  }

  .cell.is-col-start-7-tablet-only {
    --bulma-grid-cell-column-start: 7;
  }

  .cell.is-col-end-7-tablet-only {
    --bulma-grid-cell-column-end: 7;
  }

  .cell.is-col-from-end-7-tablet-only {
    --bulma-grid-cell-column-start: -7;
  }

  .cell.is-col-span-7-tablet-only {
    --bulma-grid-cell-column-span: 7;
  }

  .cell.is-row-start-7-tablet-only {
    --bulma-grid-cell-row-start: 7;
  }

  .cell.is-row-end-7-tablet-only {
    --bulma-grid-cell-row-end: 7;
  }

  .cell.is-row-from-end-7-tablet-only {
    --bulma-grid-cell-row-start: -7;
  }

  .cell.is-row-span-7-tablet-only {
    --bulma-grid-cell-row-span: 7;
  }

  .cell.is-col-start-8-tablet-only {
    --bulma-grid-cell-column-start: 8;
  }

  .cell.is-col-end-8-tablet-only {
    --bulma-grid-cell-column-end: 8;
  }

  .cell.is-col-from-end-8-tablet-only {
    --bulma-grid-cell-column-start: -8;
  }

  .cell.is-col-span-8-tablet-only {
    --bulma-grid-cell-column-span: 8;
  }

  .cell.is-row-start-8-tablet-only {
    --bulma-grid-cell-row-start: 8;
  }

  .cell.is-row-end-8-tablet-only {
    --bulma-grid-cell-row-end: 8;
  }

  .cell.is-row-from-end-8-tablet-only {
    --bulma-grid-cell-row-start: -8;
  }

  .cell.is-row-span-8-tablet-only {
    --bulma-grid-cell-row-span: 8;
  }

  .cell.is-col-start-9-tablet-only {
    --bulma-grid-cell-column-start: 9;
  }

  .cell.is-col-end-9-tablet-only {
    --bulma-grid-cell-column-end: 9;
  }

  .cell.is-col-from-end-9-tablet-only {
    --bulma-grid-cell-column-start: -9;
  }

  .cell.is-col-span-9-tablet-only {
    --bulma-grid-cell-column-span: 9;
  }

  .cell.is-row-start-9-tablet-only {
    --bulma-grid-cell-row-start: 9;
  }

  .cell.is-row-end-9-tablet-only {
    --bulma-grid-cell-row-end: 9;
  }

  .cell.is-row-from-end-9-tablet-only {
    --bulma-grid-cell-row-start: -9;
  }

  .cell.is-row-span-9-tablet-only {
    --bulma-grid-cell-row-span: 9;
  }

  .cell.is-col-start-10-tablet-only {
    --bulma-grid-cell-column-start: 10;
  }

  .cell.is-col-end-10-tablet-only {
    --bulma-grid-cell-column-end: 10;
  }

  .cell.is-col-from-end-10-tablet-only {
    --bulma-grid-cell-column-start: -10;
  }

  .cell.is-col-span-10-tablet-only {
    --bulma-grid-cell-column-span: 10;
  }

  .cell.is-row-start-10-tablet-only {
    --bulma-grid-cell-row-start: 10;
  }

  .cell.is-row-end-10-tablet-only {
    --bulma-grid-cell-row-end: 10;
  }

  .cell.is-row-from-end-10-tablet-only {
    --bulma-grid-cell-row-start: -10;
  }

  .cell.is-row-span-10-tablet-only {
    --bulma-grid-cell-row-span: 10;
  }

  .cell.is-col-start-11-tablet-only {
    --bulma-grid-cell-column-start: 11;
  }

  .cell.is-col-end-11-tablet-only {
    --bulma-grid-cell-column-end: 11;
  }

  .cell.is-col-from-end-11-tablet-only {
    --bulma-grid-cell-column-start: -11;
  }

  .cell.is-col-span-11-tablet-only {
    --bulma-grid-cell-column-span: 11;
  }

  .cell.is-row-start-11-tablet-only {
    --bulma-grid-cell-row-start: 11;
  }

  .cell.is-row-end-11-tablet-only {
    --bulma-grid-cell-row-end: 11;
  }

  .cell.is-row-from-end-11-tablet-only {
    --bulma-grid-cell-row-start: -11;
  }

  .cell.is-row-span-11-tablet-only {
    --bulma-grid-cell-row-span: 11;
  }

  .cell.is-col-start-12-tablet-only {
    --bulma-grid-cell-column-start: 12;
  }

  .cell.is-col-end-12-tablet-only {
    --bulma-grid-cell-column-end: 12;
  }

  .cell.is-col-from-end-12-tablet-only {
    --bulma-grid-cell-column-start: -12;
  }

  .cell.is-col-span-12-tablet-only {
    --bulma-grid-cell-column-span: 12;
  }

  .cell.is-row-start-12-tablet-only {
    --bulma-grid-cell-row-start: 12;
  }

  .cell.is-row-end-12-tablet-only {
    --bulma-grid-cell-row-end: 12;
  }

  .cell.is-row-from-end-12-tablet-only {
    --bulma-grid-cell-row-start: -12;
  }

  .cell.is-row-span-12-tablet-only {
    --bulma-grid-cell-row-span: 12;
  }
}

@media screen and (width >= 1024px) {
  .cell.is-col-start-1-desktop {
    --bulma-grid-cell-column-start: 1;
  }

  .cell.is-col-end-1-desktop {
    --bulma-grid-cell-column-end: 1;
  }

  .cell.is-col-from-end-1-desktop {
    --bulma-grid-cell-column-start: -1;
  }

  .cell.is-col-span-1-desktop {
    --bulma-grid-cell-column-span: 1;
  }

  .cell.is-row-start-1-desktop {
    --bulma-grid-cell-row-start: 1;
  }

  .cell.is-row-end-1-desktop {
    --bulma-grid-cell-row-end: 1;
  }

  .cell.is-row-from-end-1-desktop {
    --bulma-grid-cell-row-start: -1;
  }

  .cell.is-row-span-1-desktop {
    --bulma-grid-cell-row-span: 1;
  }

  .cell.is-col-start-2-desktop {
    --bulma-grid-cell-column-start: 2;
  }

  .cell.is-col-end-2-desktop {
    --bulma-grid-cell-column-end: 2;
  }

  .cell.is-col-from-end-2-desktop {
    --bulma-grid-cell-column-start: -2;
  }

  .cell.is-col-span-2-desktop {
    --bulma-grid-cell-column-span: 2;
  }

  .cell.is-row-start-2-desktop {
    --bulma-grid-cell-row-start: 2;
  }

  .cell.is-row-end-2-desktop {
    --bulma-grid-cell-row-end: 2;
  }

  .cell.is-row-from-end-2-desktop {
    --bulma-grid-cell-row-start: -2;
  }

  .cell.is-row-span-2-desktop {
    --bulma-grid-cell-row-span: 2;
  }

  .cell.is-col-start-3-desktop {
    --bulma-grid-cell-column-start: 3;
  }

  .cell.is-col-end-3-desktop {
    --bulma-grid-cell-column-end: 3;
  }

  .cell.is-col-from-end-3-desktop {
    --bulma-grid-cell-column-start: -3;
  }

  .cell.is-col-span-3-desktop {
    --bulma-grid-cell-column-span: 3;
  }

  .cell.is-row-start-3-desktop {
    --bulma-grid-cell-row-start: 3;
  }

  .cell.is-row-end-3-desktop {
    --bulma-grid-cell-row-end: 3;
  }

  .cell.is-row-from-end-3-desktop {
    --bulma-grid-cell-row-start: -3;
  }

  .cell.is-row-span-3-desktop {
    --bulma-grid-cell-row-span: 3;
  }

  .cell.is-col-start-4-desktop {
    --bulma-grid-cell-column-start: 4;
  }

  .cell.is-col-end-4-desktop {
    --bulma-grid-cell-column-end: 4;
  }

  .cell.is-col-from-end-4-desktop {
    --bulma-grid-cell-column-start: -4;
  }

  .cell.is-col-span-4-desktop {
    --bulma-grid-cell-column-span: 4;
  }

  .cell.is-row-start-4-desktop {
    --bulma-grid-cell-row-start: 4;
  }

  .cell.is-row-end-4-desktop {
    --bulma-grid-cell-row-end: 4;
  }

  .cell.is-row-from-end-4-desktop {
    --bulma-grid-cell-row-start: -4;
  }

  .cell.is-row-span-4-desktop {
    --bulma-grid-cell-row-span: 4;
  }

  .cell.is-col-start-5-desktop {
    --bulma-grid-cell-column-start: 5;
  }

  .cell.is-col-end-5-desktop {
    --bulma-grid-cell-column-end: 5;
  }

  .cell.is-col-from-end-5-desktop {
    --bulma-grid-cell-column-start: -5;
  }

  .cell.is-col-span-5-desktop {
    --bulma-grid-cell-column-span: 5;
  }

  .cell.is-row-start-5-desktop {
    --bulma-grid-cell-row-start: 5;
  }

  .cell.is-row-end-5-desktop {
    --bulma-grid-cell-row-end: 5;
  }

  .cell.is-row-from-end-5-desktop {
    --bulma-grid-cell-row-start: -5;
  }

  .cell.is-row-span-5-desktop {
    --bulma-grid-cell-row-span: 5;
  }

  .cell.is-col-start-6-desktop {
    --bulma-grid-cell-column-start: 6;
  }

  .cell.is-col-end-6-desktop {
    --bulma-grid-cell-column-end: 6;
  }

  .cell.is-col-from-end-6-desktop {
    --bulma-grid-cell-column-start: -6;
  }

  .cell.is-col-span-6-desktop {
    --bulma-grid-cell-column-span: 6;
  }

  .cell.is-row-start-6-desktop {
    --bulma-grid-cell-row-start: 6;
  }

  .cell.is-row-end-6-desktop {
    --bulma-grid-cell-row-end: 6;
  }

  .cell.is-row-from-end-6-desktop {
    --bulma-grid-cell-row-start: -6;
  }

  .cell.is-row-span-6-desktop {
    --bulma-grid-cell-row-span: 6;
  }

  .cell.is-col-start-7-desktop {
    --bulma-grid-cell-column-start: 7;
  }

  .cell.is-col-end-7-desktop {
    --bulma-grid-cell-column-end: 7;
  }

  .cell.is-col-from-end-7-desktop {
    --bulma-grid-cell-column-start: -7;
  }

  .cell.is-col-span-7-desktop {
    --bulma-grid-cell-column-span: 7;
  }

  .cell.is-row-start-7-desktop {
    --bulma-grid-cell-row-start: 7;
  }

  .cell.is-row-end-7-desktop {
    --bulma-grid-cell-row-end: 7;
  }

  .cell.is-row-from-end-7-desktop {
    --bulma-grid-cell-row-start: -7;
  }

  .cell.is-row-span-7-desktop {
    --bulma-grid-cell-row-span: 7;
  }

  .cell.is-col-start-8-desktop {
    --bulma-grid-cell-column-start: 8;
  }

  .cell.is-col-end-8-desktop {
    --bulma-grid-cell-column-end: 8;
  }

  .cell.is-col-from-end-8-desktop {
    --bulma-grid-cell-column-start: -8;
  }

  .cell.is-col-span-8-desktop {
    --bulma-grid-cell-column-span: 8;
  }

  .cell.is-row-start-8-desktop {
    --bulma-grid-cell-row-start: 8;
  }

  .cell.is-row-end-8-desktop {
    --bulma-grid-cell-row-end: 8;
  }

  .cell.is-row-from-end-8-desktop {
    --bulma-grid-cell-row-start: -8;
  }

  .cell.is-row-span-8-desktop {
    --bulma-grid-cell-row-span: 8;
  }

  .cell.is-col-start-9-desktop {
    --bulma-grid-cell-column-start: 9;
  }

  .cell.is-col-end-9-desktop {
    --bulma-grid-cell-column-end: 9;
  }

  .cell.is-col-from-end-9-desktop {
    --bulma-grid-cell-column-start: -9;
  }

  .cell.is-col-span-9-desktop {
    --bulma-grid-cell-column-span: 9;
  }

  .cell.is-row-start-9-desktop {
    --bulma-grid-cell-row-start: 9;
  }

  .cell.is-row-end-9-desktop {
    --bulma-grid-cell-row-end: 9;
  }

  .cell.is-row-from-end-9-desktop {
    --bulma-grid-cell-row-start: -9;
  }

  .cell.is-row-span-9-desktop {
    --bulma-grid-cell-row-span: 9;
  }

  .cell.is-col-start-10-desktop {
    --bulma-grid-cell-column-start: 10;
  }

  .cell.is-col-end-10-desktop {
    --bulma-grid-cell-column-end: 10;
  }

  .cell.is-col-from-end-10-desktop {
    --bulma-grid-cell-column-start: -10;
  }

  .cell.is-col-span-10-desktop {
    --bulma-grid-cell-column-span: 10;
  }

  .cell.is-row-start-10-desktop {
    --bulma-grid-cell-row-start: 10;
  }

  .cell.is-row-end-10-desktop {
    --bulma-grid-cell-row-end: 10;
  }

  .cell.is-row-from-end-10-desktop {
    --bulma-grid-cell-row-start: -10;
  }

  .cell.is-row-span-10-desktop {
    --bulma-grid-cell-row-span: 10;
  }

  .cell.is-col-start-11-desktop {
    --bulma-grid-cell-column-start: 11;
  }

  .cell.is-col-end-11-desktop {
    --bulma-grid-cell-column-end: 11;
  }

  .cell.is-col-from-end-11-desktop {
    --bulma-grid-cell-column-start: -11;
  }

  .cell.is-col-span-11-desktop {
    --bulma-grid-cell-column-span: 11;
  }

  .cell.is-row-start-11-desktop {
    --bulma-grid-cell-row-start: 11;
  }

  .cell.is-row-end-11-desktop {
    --bulma-grid-cell-row-end: 11;
  }

  .cell.is-row-from-end-11-desktop {
    --bulma-grid-cell-row-start: -11;
  }

  .cell.is-row-span-11-desktop {
    --bulma-grid-cell-row-span: 11;
  }

  .cell.is-col-start-12-desktop {
    --bulma-grid-cell-column-start: 12;
  }

  .cell.is-col-end-12-desktop {
    --bulma-grid-cell-column-end: 12;
  }

  .cell.is-col-from-end-12-desktop {
    --bulma-grid-cell-column-start: -12;
  }

  .cell.is-col-span-12-desktop {
    --bulma-grid-cell-column-span: 12;
  }

  .cell.is-row-start-12-desktop {
    --bulma-grid-cell-row-start: 12;
  }

  .cell.is-row-end-12-desktop {
    --bulma-grid-cell-row-end: 12;
  }

  .cell.is-row-from-end-12-desktop {
    --bulma-grid-cell-row-start: -12;
  }

  .cell.is-row-span-12-desktop {
    --bulma-grid-cell-row-span: 12;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .cell.is-col-start-1-desktop-only {
    --bulma-grid-cell-column-start: 1;
  }

  .cell.is-col-end-1-desktop-only {
    --bulma-grid-cell-column-end: 1;
  }

  .cell.is-col-from-end-1-desktop-only {
    --bulma-grid-cell-column-start: -1;
  }

  .cell.is-col-span-1-desktop-only {
    --bulma-grid-cell-column-span: 1;
  }

  .cell.is-row-start-1-desktop-only {
    --bulma-grid-cell-row-start: 1;
  }

  .cell.is-row-end-1-desktop-only {
    --bulma-grid-cell-row-end: 1;
  }

  .cell.is-row-from-end-1-desktop-only {
    --bulma-grid-cell-row-start: -1;
  }

  .cell.is-row-span-1-desktop-only {
    --bulma-grid-cell-row-span: 1;
  }

  .cell.is-col-start-2-desktop-only {
    --bulma-grid-cell-column-start: 2;
  }

  .cell.is-col-end-2-desktop-only {
    --bulma-grid-cell-column-end: 2;
  }

  .cell.is-col-from-end-2-desktop-only {
    --bulma-grid-cell-column-start: -2;
  }

  .cell.is-col-span-2-desktop-only {
    --bulma-grid-cell-column-span: 2;
  }

  .cell.is-row-start-2-desktop-only {
    --bulma-grid-cell-row-start: 2;
  }

  .cell.is-row-end-2-desktop-only {
    --bulma-grid-cell-row-end: 2;
  }

  .cell.is-row-from-end-2-desktop-only {
    --bulma-grid-cell-row-start: -2;
  }

  .cell.is-row-span-2-desktop-only {
    --bulma-grid-cell-row-span: 2;
  }

  .cell.is-col-start-3-desktop-only {
    --bulma-grid-cell-column-start: 3;
  }

  .cell.is-col-end-3-desktop-only {
    --bulma-grid-cell-column-end: 3;
  }

  .cell.is-col-from-end-3-desktop-only {
    --bulma-grid-cell-column-start: -3;
  }

  .cell.is-col-span-3-desktop-only {
    --bulma-grid-cell-column-span: 3;
  }

  .cell.is-row-start-3-desktop-only {
    --bulma-grid-cell-row-start: 3;
  }

  .cell.is-row-end-3-desktop-only {
    --bulma-grid-cell-row-end: 3;
  }

  .cell.is-row-from-end-3-desktop-only {
    --bulma-grid-cell-row-start: -3;
  }

  .cell.is-row-span-3-desktop-only {
    --bulma-grid-cell-row-span: 3;
  }

  .cell.is-col-start-4-desktop-only {
    --bulma-grid-cell-column-start: 4;
  }

  .cell.is-col-end-4-desktop-only {
    --bulma-grid-cell-column-end: 4;
  }

  .cell.is-col-from-end-4-desktop-only {
    --bulma-grid-cell-column-start: -4;
  }

  .cell.is-col-span-4-desktop-only {
    --bulma-grid-cell-column-span: 4;
  }

  .cell.is-row-start-4-desktop-only {
    --bulma-grid-cell-row-start: 4;
  }

  .cell.is-row-end-4-desktop-only {
    --bulma-grid-cell-row-end: 4;
  }

  .cell.is-row-from-end-4-desktop-only {
    --bulma-grid-cell-row-start: -4;
  }

  .cell.is-row-span-4-desktop-only {
    --bulma-grid-cell-row-span: 4;
  }

  .cell.is-col-start-5-desktop-only {
    --bulma-grid-cell-column-start: 5;
  }

  .cell.is-col-end-5-desktop-only {
    --bulma-grid-cell-column-end: 5;
  }

  .cell.is-col-from-end-5-desktop-only {
    --bulma-grid-cell-column-start: -5;
  }

  .cell.is-col-span-5-desktop-only {
    --bulma-grid-cell-column-span: 5;
  }

  .cell.is-row-start-5-desktop-only {
    --bulma-grid-cell-row-start: 5;
  }

  .cell.is-row-end-5-desktop-only {
    --bulma-grid-cell-row-end: 5;
  }

  .cell.is-row-from-end-5-desktop-only {
    --bulma-grid-cell-row-start: -5;
  }

  .cell.is-row-span-5-desktop-only {
    --bulma-grid-cell-row-span: 5;
  }

  .cell.is-col-start-6-desktop-only {
    --bulma-grid-cell-column-start: 6;
  }

  .cell.is-col-end-6-desktop-only {
    --bulma-grid-cell-column-end: 6;
  }

  .cell.is-col-from-end-6-desktop-only {
    --bulma-grid-cell-column-start: -6;
  }

  .cell.is-col-span-6-desktop-only {
    --bulma-grid-cell-column-span: 6;
  }

  .cell.is-row-start-6-desktop-only {
    --bulma-grid-cell-row-start: 6;
  }

  .cell.is-row-end-6-desktop-only {
    --bulma-grid-cell-row-end: 6;
  }

  .cell.is-row-from-end-6-desktop-only {
    --bulma-grid-cell-row-start: -6;
  }

  .cell.is-row-span-6-desktop-only {
    --bulma-grid-cell-row-span: 6;
  }

  .cell.is-col-start-7-desktop-only {
    --bulma-grid-cell-column-start: 7;
  }

  .cell.is-col-end-7-desktop-only {
    --bulma-grid-cell-column-end: 7;
  }

  .cell.is-col-from-end-7-desktop-only {
    --bulma-grid-cell-column-start: -7;
  }

  .cell.is-col-span-7-desktop-only {
    --bulma-grid-cell-column-span: 7;
  }

  .cell.is-row-start-7-desktop-only {
    --bulma-grid-cell-row-start: 7;
  }

  .cell.is-row-end-7-desktop-only {
    --bulma-grid-cell-row-end: 7;
  }

  .cell.is-row-from-end-7-desktop-only {
    --bulma-grid-cell-row-start: -7;
  }

  .cell.is-row-span-7-desktop-only {
    --bulma-grid-cell-row-span: 7;
  }

  .cell.is-col-start-8-desktop-only {
    --bulma-grid-cell-column-start: 8;
  }

  .cell.is-col-end-8-desktop-only {
    --bulma-grid-cell-column-end: 8;
  }

  .cell.is-col-from-end-8-desktop-only {
    --bulma-grid-cell-column-start: -8;
  }

  .cell.is-col-span-8-desktop-only {
    --bulma-grid-cell-column-span: 8;
  }

  .cell.is-row-start-8-desktop-only {
    --bulma-grid-cell-row-start: 8;
  }

  .cell.is-row-end-8-desktop-only {
    --bulma-grid-cell-row-end: 8;
  }

  .cell.is-row-from-end-8-desktop-only {
    --bulma-grid-cell-row-start: -8;
  }

  .cell.is-row-span-8-desktop-only {
    --bulma-grid-cell-row-span: 8;
  }

  .cell.is-col-start-9-desktop-only {
    --bulma-grid-cell-column-start: 9;
  }

  .cell.is-col-end-9-desktop-only {
    --bulma-grid-cell-column-end: 9;
  }

  .cell.is-col-from-end-9-desktop-only {
    --bulma-grid-cell-column-start: -9;
  }

  .cell.is-col-span-9-desktop-only {
    --bulma-grid-cell-column-span: 9;
  }

  .cell.is-row-start-9-desktop-only {
    --bulma-grid-cell-row-start: 9;
  }

  .cell.is-row-end-9-desktop-only {
    --bulma-grid-cell-row-end: 9;
  }

  .cell.is-row-from-end-9-desktop-only {
    --bulma-grid-cell-row-start: -9;
  }

  .cell.is-row-span-9-desktop-only {
    --bulma-grid-cell-row-span: 9;
  }

  .cell.is-col-start-10-desktop-only {
    --bulma-grid-cell-column-start: 10;
  }

  .cell.is-col-end-10-desktop-only {
    --bulma-grid-cell-column-end: 10;
  }

  .cell.is-col-from-end-10-desktop-only {
    --bulma-grid-cell-column-start: -10;
  }

  .cell.is-col-span-10-desktop-only {
    --bulma-grid-cell-column-span: 10;
  }

  .cell.is-row-start-10-desktop-only {
    --bulma-grid-cell-row-start: 10;
  }

  .cell.is-row-end-10-desktop-only {
    --bulma-grid-cell-row-end: 10;
  }

  .cell.is-row-from-end-10-desktop-only {
    --bulma-grid-cell-row-start: -10;
  }

  .cell.is-row-span-10-desktop-only {
    --bulma-grid-cell-row-span: 10;
  }

  .cell.is-col-start-11-desktop-only {
    --bulma-grid-cell-column-start: 11;
  }

  .cell.is-col-end-11-desktop-only {
    --bulma-grid-cell-column-end: 11;
  }

  .cell.is-col-from-end-11-desktop-only {
    --bulma-grid-cell-column-start: -11;
  }

  .cell.is-col-span-11-desktop-only {
    --bulma-grid-cell-column-span: 11;
  }

  .cell.is-row-start-11-desktop-only {
    --bulma-grid-cell-row-start: 11;
  }

  .cell.is-row-end-11-desktop-only {
    --bulma-grid-cell-row-end: 11;
  }

  .cell.is-row-from-end-11-desktop-only {
    --bulma-grid-cell-row-start: -11;
  }

  .cell.is-row-span-11-desktop-only {
    --bulma-grid-cell-row-span: 11;
  }

  .cell.is-col-start-12-desktop-only {
    --bulma-grid-cell-column-start: 12;
  }

  .cell.is-col-end-12-desktop-only {
    --bulma-grid-cell-column-end: 12;
  }

  .cell.is-col-from-end-12-desktop-only {
    --bulma-grid-cell-column-start: -12;
  }

  .cell.is-col-span-12-desktop-only {
    --bulma-grid-cell-column-span: 12;
  }

  .cell.is-row-start-12-desktop-only {
    --bulma-grid-cell-row-start: 12;
  }

  .cell.is-row-end-12-desktop-only {
    --bulma-grid-cell-row-end: 12;
  }

  .cell.is-row-from-end-12-desktop-only {
    --bulma-grid-cell-row-start: -12;
  }

  .cell.is-row-span-12-desktop-only {
    --bulma-grid-cell-row-span: 12;
  }
}

@media screen and (width >= 1216px) {
  .cell.is-col-start-1-widescreen {
    --bulma-grid-cell-column-start: 1;
  }

  .cell.is-col-end-1-widescreen {
    --bulma-grid-cell-column-end: 1;
  }

  .cell.is-col-from-end-1-widescreen {
    --bulma-grid-cell-column-start: -1;
  }

  .cell.is-col-span-1-widescreen {
    --bulma-grid-cell-column-span: 1;
  }

  .cell.is-row-start-1-widescreen {
    --bulma-grid-cell-row-start: 1;
  }

  .cell.is-row-end-1-widescreen {
    --bulma-grid-cell-row-end: 1;
  }

  .cell.is-row-from-end-1-widescreen {
    --bulma-grid-cell-row-start: -1;
  }

  .cell.is-row-span-1-widescreen {
    --bulma-grid-cell-row-span: 1;
  }

  .cell.is-col-start-2-widescreen {
    --bulma-grid-cell-column-start: 2;
  }

  .cell.is-col-end-2-widescreen {
    --bulma-grid-cell-column-end: 2;
  }

  .cell.is-col-from-end-2-widescreen {
    --bulma-grid-cell-column-start: -2;
  }

  .cell.is-col-span-2-widescreen {
    --bulma-grid-cell-column-span: 2;
  }

  .cell.is-row-start-2-widescreen {
    --bulma-grid-cell-row-start: 2;
  }

  .cell.is-row-end-2-widescreen {
    --bulma-grid-cell-row-end: 2;
  }

  .cell.is-row-from-end-2-widescreen {
    --bulma-grid-cell-row-start: -2;
  }

  .cell.is-row-span-2-widescreen {
    --bulma-grid-cell-row-span: 2;
  }

  .cell.is-col-start-3-widescreen {
    --bulma-grid-cell-column-start: 3;
  }

  .cell.is-col-end-3-widescreen {
    --bulma-grid-cell-column-end: 3;
  }

  .cell.is-col-from-end-3-widescreen {
    --bulma-grid-cell-column-start: -3;
  }

  .cell.is-col-span-3-widescreen {
    --bulma-grid-cell-column-span: 3;
  }

  .cell.is-row-start-3-widescreen {
    --bulma-grid-cell-row-start: 3;
  }

  .cell.is-row-end-3-widescreen {
    --bulma-grid-cell-row-end: 3;
  }

  .cell.is-row-from-end-3-widescreen {
    --bulma-grid-cell-row-start: -3;
  }

  .cell.is-row-span-3-widescreen {
    --bulma-grid-cell-row-span: 3;
  }

  .cell.is-col-start-4-widescreen {
    --bulma-grid-cell-column-start: 4;
  }

  .cell.is-col-end-4-widescreen {
    --bulma-grid-cell-column-end: 4;
  }

  .cell.is-col-from-end-4-widescreen {
    --bulma-grid-cell-column-start: -4;
  }

  .cell.is-col-span-4-widescreen {
    --bulma-grid-cell-column-span: 4;
  }

  .cell.is-row-start-4-widescreen {
    --bulma-grid-cell-row-start: 4;
  }

  .cell.is-row-end-4-widescreen {
    --bulma-grid-cell-row-end: 4;
  }

  .cell.is-row-from-end-4-widescreen {
    --bulma-grid-cell-row-start: -4;
  }

  .cell.is-row-span-4-widescreen {
    --bulma-grid-cell-row-span: 4;
  }

  .cell.is-col-start-5-widescreen {
    --bulma-grid-cell-column-start: 5;
  }

  .cell.is-col-end-5-widescreen {
    --bulma-grid-cell-column-end: 5;
  }

  .cell.is-col-from-end-5-widescreen {
    --bulma-grid-cell-column-start: -5;
  }

  .cell.is-col-span-5-widescreen {
    --bulma-grid-cell-column-span: 5;
  }

  .cell.is-row-start-5-widescreen {
    --bulma-grid-cell-row-start: 5;
  }

  .cell.is-row-end-5-widescreen {
    --bulma-grid-cell-row-end: 5;
  }

  .cell.is-row-from-end-5-widescreen {
    --bulma-grid-cell-row-start: -5;
  }

  .cell.is-row-span-5-widescreen {
    --bulma-grid-cell-row-span: 5;
  }

  .cell.is-col-start-6-widescreen {
    --bulma-grid-cell-column-start: 6;
  }

  .cell.is-col-end-6-widescreen {
    --bulma-grid-cell-column-end: 6;
  }

  .cell.is-col-from-end-6-widescreen {
    --bulma-grid-cell-column-start: -6;
  }

  .cell.is-col-span-6-widescreen {
    --bulma-grid-cell-column-span: 6;
  }

  .cell.is-row-start-6-widescreen {
    --bulma-grid-cell-row-start: 6;
  }

  .cell.is-row-end-6-widescreen {
    --bulma-grid-cell-row-end: 6;
  }

  .cell.is-row-from-end-6-widescreen {
    --bulma-grid-cell-row-start: -6;
  }

  .cell.is-row-span-6-widescreen {
    --bulma-grid-cell-row-span: 6;
  }

  .cell.is-col-start-7-widescreen {
    --bulma-grid-cell-column-start: 7;
  }

  .cell.is-col-end-7-widescreen {
    --bulma-grid-cell-column-end: 7;
  }

  .cell.is-col-from-end-7-widescreen {
    --bulma-grid-cell-column-start: -7;
  }

  .cell.is-col-span-7-widescreen {
    --bulma-grid-cell-column-span: 7;
  }

  .cell.is-row-start-7-widescreen {
    --bulma-grid-cell-row-start: 7;
  }

  .cell.is-row-end-7-widescreen {
    --bulma-grid-cell-row-end: 7;
  }

  .cell.is-row-from-end-7-widescreen {
    --bulma-grid-cell-row-start: -7;
  }

  .cell.is-row-span-7-widescreen {
    --bulma-grid-cell-row-span: 7;
  }

  .cell.is-col-start-8-widescreen {
    --bulma-grid-cell-column-start: 8;
  }

  .cell.is-col-end-8-widescreen {
    --bulma-grid-cell-column-end: 8;
  }

  .cell.is-col-from-end-8-widescreen {
    --bulma-grid-cell-column-start: -8;
  }

  .cell.is-col-span-8-widescreen {
    --bulma-grid-cell-column-span: 8;
  }

  .cell.is-row-start-8-widescreen {
    --bulma-grid-cell-row-start: 8;
  }

  .cell.is-row-end-8-widescreen {
    --bulma-grid-cell-row-end: 8;
  }

  .cell.is-row-from-end-8-widescreen {
    --bulma-grid-cell-row-start: -8;
  }

  .cell.is-row-span-8-widescreen {
    --bulma-grid-cell-row-span: 8;
  }

  .cell.is-col-start-9-widescreen {
    --bulma-grid-cell-column-start: 9;
  }

  .cell.is-col-end-9-widescreen {
    --bulma-grid-cell-column-end: 9;
  }

  .cell.is-col-from-end-9-widescreen {
    --bulma-grid-cell-column-start: -9;
  }

  .cell.is-col-span-9-widescreen {
    --bulma-grid-cell-column-span: 9;
  }

  .cell.is-row-start-9-widescreen {
    --bulma-grid-cell-row-start: 9;
  }

  .cell.is-row-end-9-widescreen {
    --bulma-grid-cell-row-end: 9;
  }

  .cell.is-row-from-end-9-widescreen {
    --bulma-grid-cell-row-start: -9;
  }

  .cell.is-row-span-9-widescreen {
    --bulma-grid-cell-row-span: 9;
  }

  .cell.is-col-start-10-widescreen {
    --bulma-grid-cell-column-start: 10;
  }

  .cell.is-col-end-10-widescreen {
    --bulma-grid-cell-column-end: 10;
  }

  .cell.is-col-from-end-10-widescreen {
    --bulma-grid-cell-column-start: -10;
  }

  .cell.is-col-span-10-widescreen {
    --bulma-grid-cell-column-span: 10;
  }

  .cell.is-row-start-10-widescreen {
    --bulma-grid-cell-row-start: 10;
  }

  .cell.is-row-end-10-widescreen {
    --bulma-grid-cell-row-end: 10;
  }

  .cell.is-row-from-end-10-widescreen {
    --bulma-grid-cell-row-start: -10;
  }

  .cell.is-row-span-10-widescreen {
    --bulma-grid-cell-row-span: 10;
  }

  .cell.is-col-start-11-widescreen {
    --bulma-grid-cell-column-start: 11;
  }

  .cell.is-col-end-11-widescreen {
    --bulma-grid-cell-column-end: 11;
  }

  .cell.is-col-from-end-11-widescreen {
    --bulma-grid-cell-column-start: -11;
  }

  .cell.is-col-span-11-widescreen {
    --bulma-grid-cell-column-span: 11;
  }

  .cell.is-row-start-11-widescreen {
    --bulma-grid-cell-row-start: 11;
  }

  .cell.is-row-end-11-widescreen {
    --bulma-grid-cell-row-end: 11;
  }

  .cell.is-row-from-end-11-widescreen {
    --bulma-grid-cell-row-start: -11;
  }

  .cell.is-row-span-11-widescreen {
    --bulma-grid-cell-row-span: 11;
  }

  .cell.is-col-start-12-widescreen {
    --bulma-grid-cell-column-start: 12;
  }

  .cell.is-col-end-12-widescreen {
    --bulma-grid-cell-column-end: 12;
  }

  .cell.is-col-from-end-12-widescreen {
    --bulma-grid-cell-column-start: -12;
  }

  .cell.is-col-span-12-widescreen {
    --bulma-grid-cell-column-span: 12;
  }

  .cell.is-row-start-12-widescreen {
    --bulma-grid-cell-row-start: 12;
  }

  .cell.is-row-end-12-widescreen {
    --bulma-grid-cell-row-end: 12;
  }

  .cell.is-row-from-end-12-widescreen {
    --bulma-grid-cell-row-start: -12;
  }

  .cell.is-row-span-12-widescreen {
    --bulma-grid-cell-row-span: 12;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .cell.is-col-start-1-widescreen-only {
    --bulma-grid-cell-column-start: 1;
  }

  .cell.is-col-end-1-widescreen-only {
    --bulma-grid-cell-column-end: 1;
  }

  .cell.is-col-from-end-1-widescreen-only {
    --bulma-grid-cell-column-start: -1;
  }

  .cell.is-col-span-1-widescreen-only {
    --bulma-grid-cell-column-span: 1;
  }

  .cell.is-row-start-1-widescreen-only {
    --bulma-grid-cell-row-start: 1;
  }

  .cell.is-row-end-1-widescreen-only {
    --bulma-grid-cell-row-end: 1;
  }

  .cell.is-row-from-end-1-widescreen-only {
    --bulma-grid-cell-row-start: -1;
  }

  .cell.is-row-span-1-widescreen-only {
    --bulma-grid-cell-row-span: 1;
  }

  .cell.is-col-start-2-widescreen-only {
    --bulma-grid-cell-column-start: 2;
  }

  .cell.is-col-end-2-widescreen-only {
    --bulma-grid-cell-column-end: 2;
  }

  .cell.is-col-from-end-2-widescreen-only {
    --bulma-grid-cell-column-start: -2;
  }

  .cell.is-col-span-2-widescreen-only {
    --bulma-grid-cell-column-span: 2;
  }

  .cell.is-row-start-2-widescreen-only {
    --bulma-grid-cell-row-start: 2;
  }

  .cell.is-row-end-2-widescreen-only {
    --bulma-grid-cell-row-end: 2;
  }

  .cell.is-row-from-end-2-widescreen-only {
    --bulma-grid-cell-row-start: -2;
  }

  .cell.is-row-span-2-widescreen-only {
    --bulma-grid-cell-row-span: 2;
  }

  .cell.is-col-start-3-widescreen-only {
    --bulma-grid-cell-column-start: 3;
  }

  .cell.is-col-end-3-widescreen-only {
    --bulma-grid-cell-column-end: 3;
  }

  .cell.is-col-from-end-3-widescreen-only {
    --bulma-grid-cell-column-start: -3;
  }

  .cell.is-col-span-3-widescreen-only {
    --bulma-grid-cell-column-span: 3;
  }

  .cell.is-row-start-3-widescreen-only {
    --bulma-grid-cell-row-start: 3;
  }

  .cell.is-row-end-3-widescreen-only {
    --bulma-grid-cell-row-end: 3;
  }

  .cell.is-row-from-end-3-widescreen-only {
    --bulma-grid-cell-row-start: -3;
  }

  .cell.is-row-span-3-widescreen-only {
    --bulma-grid-cell-row-span: 3;
  }

  .cell.is-col-start-4-widescreen-only {
    --bulma-grid-cell-column-start: 4;
  }

  .cell.is-col-end-4-widescreen-only {
    --bulma-grid-cell-column-end: 4;
  }

  .cell.is-col-from-end-4-widescreen-only {
    --bulma-grid-cell-column-start: -4;
  }

  .cell.is-col-span-4-widescreen-only {
    --bulma-grid-cell-column-span: 4;
  }

  .cell.is-row-start-4-widescreen-only {
    --bulma-grid-cell-row-start: 4;
  }

  .cell.is-row-end-4-widescreen-only {
    --bulma-grid-cell-row-end: 4;
  }

  .cell.is-row-from-end-4-widescreen-only {
    --bulma-grid-cell-row-start: -4;
  }

  .cell.is-row-span-4-widescreen-only {
    --bulma-grid-cell-row-span: 4;
  }

  .cell.is-col-start-5-widescreen-only {
    --bulma-grid-cell-column-start: 5;
  }

  .cell.is-col-end-5-widescreen-only {
    --bulma-grid-cell-column-end: 5;
  }

  .cell.is-col-from-end-5-widescreen-only {
    --bulma-grid-cell-column-start: -5;
  }

  .cell.is-col-span-5-widescreen-only {
    --bulma-grid-cell-column-span: 5;
  }

  .cell.is-row-start-5-widescreen-only {
    --bulma-grid-cell-row-start: 5;
  }

  .cell.is-row-end-5-widescreen-only {
    --bulma-grid-cell-row-end: 5;
  }

  .cell.is-row-from-end-5-widescreen-only {
    --bulma-grid-cell-row-start: -5;
  }

  .cell.is-row-span-5-widescreen-only {
    --bulma-grid-cell-row-span: 5;
  }

  .cell.is-col-start-6-widescreen-only {
    --bulma-grid-cell-column-start: 6;
  }

  .cell.is-col-end-6-widescreen-only {
    --bulma-grid-cell-column-end: 6;
  }

  .cell.is-col-from-end-6-widescreen-only {
    --bulma-grid-cell-column-start: -6;
  }

  .cell.is-col-span-6-widescreen-only {
    --bulma-grid-cell-column-span: 6;
  }

  .cell.is-row-start-6-widescreen-only {
    --bulma-grid-cell-row-start: 6;
  }

  .cell.is-row-end-6-widescreen-only {
    --bulma-grid-cell-row-end: 6;
  }

  .cell.is-row-from-end-6-widescreen-only {
    --bulma-grid-cell-row-start: -6;
  }

  .cell.is-row-span-6-widescreen-only {
    --bulma-grid-cell-row-span: 6;
  }

  .cell.is-col-start-7-widescreen-only {
    --bulma-grid-cell-column-start: 7;
  }

  .cell.is-col-end-7-widescreen-only {
    --bulma-grid-cell-column-end: 7;
  }

  .cell.is-col-from-end-7-widescreen-only {
    --bulma-grid-cell-column-start: -7;
  }

  .cell.is-col-span-7-widescreen-only {
    --bulma-grid-cell-column-span: 7;
  }

  .cell.is-row-start-7-widescreen-only {
    --bulma-grid-cell-row-start: 7;
  }

  .cell.is-row-end-7-widescreen-only {
    --bulma-grid-cell-row-end: 7;
  }

  .cell.is-row-from-end-7-widescreen-only {
    --bulma-grid-cell-row-start: -7;
  }

  .cell.is-row-span-7-widescreen-only {
    --bulma-grid-cell-row-span: 7;
  }

  .cell.is-col-start-8-widescreen-only {
    --bulma-grid-cell-column-start: 8;
  }

  .cell.is-col-end-8-widescreen-only {
    --bulma-grid-cell-column-end: 8;
  }

  .cell.is-col-from-end-8-widescreen-only {
    --bulma-grid-cell-column-start: -8;
  }

  .cell.is-col-span-8-widescreen-only {
    --bulma-grid-cell-column-span: 8;
  }

  .cell.is-row-start-8-widescreen-only {
    --bulma-grid-cell-row-start: 8;
  }

  .cell.is-row-end-8-widescreen-only {
    --bulma-grid-cell-row-end: 8;
  }

  .cell.is-row-from-end-8-widescreen-only {
    --bulma-grid-cell-row-start: -8;
  }

  .cell.is-row-span-8-widescreen-only {
    --bulma-grid-cell-row-span: 8;
  }

  .cell.is-col-start-9-widescreen-only {
    --bulma-grid-cell-column-start: 9;
  }

  .cell.is-col-end-9-widescreen-only {
    --bulma-grid-cell-column-end: 9;
  }

  .cell.is-col-from-end-9-widescreen-only {
    --bulma-grid-cell-column-start: -9;
  }

  .cell.is-col-span-9-widescreen-only {
    --bulma-grid-cell-column-span: 9;
  }

  .cell.is-row-start-9-widescreen-only {
    --bulma-grid-cell-row-start: 9;
  }

  .cell.is-row-end-9-widescreen-only {
    --bulma-grid-cell-row-end: 9;
  }

  .cell.is-row-from-end-9-widescreen-only {
    --bulma-grid-cell-row-start: -9;
  }

  .cell.is-row-span-9-widescreen-only {
    --bulma-grid-cell-row-span: 9;
  }

  .cell.is-col-start-10-widescreen-only {
    --bulma-grid-cell-column-start: 10;
  }

  .cell.is-col-end-10-widescreen-only {
    --bulma-grid-cell-column-end: 10;
  }

  .cell.is-col-from-end-10-widescreen-only {
    --bulma-grid-cell-column-start: -10;
  }

  .cell.is-col-span-10-widescreen-only {
    --bulma-grid-cell-column-span: 10;
  }

  .cell.is-row-start-10-widescreen-only {
    --bulma-grid-cell-row-start: 10;
  }

  .cell.is-row-end-10-widescreen-only {
    --bulma-grid-cell-row-end: 10;
  }

  .cell.is-row-from-end-10-widescreen-only {
    --bulma-grid-cell-row-start: -10;
  }

  .cell.is-row-span-10-widescreen-only {
    --bulma-grid-cell-row-span: 10;
  }

  .cell.is-col-start-11-widescreen-only {
    --bulma-grid-cell-column-start: 11;
  }

  .cell.is-col-end-11-widescreen-only {
    --bulma-grid-cell-column-end: 11;
  }

  .cell.is-col-from-end-11-widescreen-only {
    --bulma-grid-cell-column-start: -11;
  }

  .cell.is-col-span-11-widescreen-only {
    --bulma-grid-cell-column-span: 11;
  }

  .cell.is-row-start-11-widescreen-only {
    --bulma-grid-cell-row-start: 11;
  }

  .cell.is-row-end-11-widescreen-only {
    --bulma-grid-cell-row-end: 11;
  }

  .cell.is-row-from-end-11-widescreen-only {
    --bulma-grid-cell-row-start: -11;
  }

  .cell.is-row-span-11-widescreen-only {
    --bulma-grid-cell-row-span: 11;
  }

  .cell.is-col-start-12-widescreen-only {
    --bulma-grid-cell-column-start: 12;
  }

  .cell.is-col-end-12-widescreen-only {
    --bulma-grid-cell-column-end: 12;
  }

  .cell.is-col-from-end-12-widescreen-only {
    --bulma-grid-cell-column-start: -12;
  }

  .cell.is-col-span-12-widescreen-only {
    --bulma-grid-cell-column-span: 12;
  }

  .cell.is-row-start-12-widescreen-only {
    --bulma-grid-cell-row-start: 12;
  }

  .cell.is-row-end-12-widescreen-only {
    --bulma-grid-cell-row-end: 12;
  }

  .cell.is-row-from-end-12-widescreen-only {
    --bulma-grid-cell-row-start: -12;
  }

  .cell.is-row-span-12-widescreen-only {
    --bulma-grid-cell-row-span: 12;
  }
}

@media screen and (width >= 1408px) {
  .cell.is-col-start-1-fullhd {
    --bulma-grid-cell-column-start: 1;
  }

  .cell.is-col-end-1-fullhd {
    --bulma-grid-cell-column-end: 1;
  }

  .cell.is-col-from-end-1-fullhd {
    --bulma-grid-cell-column-start: -1;
  }

  .cell.is-col-span-1-fullhd {
    --bulma-grid-cell-column-span: 1;
  }

  .cell.is-row-start-1-fullhd {
    --bulma-grid-cell-row-start: 1;
  }

  .cell.is-row-end-1-fullhd {
    --bulma-grid-cell-row-end: 1;
  }

  .cell.is-row-from-end-1-fullhd {
    --bulma-grid-cell-row-start: -1;
  }

  .cell.is-row-span-1-fullhd {
    --bulma-grid-cell-row-span: 1;
  }

  .cell.is-col-start-2-fullhd {
    --bulma-grid-cell-column-start: 2;
  }

  .cell.is-col-end-2-fullhd {
    --bulma-grid-cell-column-end: 2;
  }

  .cell.is-col-from-end-2-fullhd {
    --bulma-grid-cell-column-start: -2;
  }

  .cell.is-col-span-2-fullhd {
    --bulma-grid-cell-column-span: 2;
  }

  .cell.is-row-start-2-fullhd {
    --bulma-grid-cell-row-start: 2;
  }

  .cell.is-row-end-2-fullhd {
    --bulma-grid-cell-row-end: 2;
  }

  .cell.is-row-from-end-2-fullhd {
    --bulma-grid-cell-row-start: -2;
  }

  .cell.is-row-span-2-fullhd {
    --bulma-grid-cell-row-span: 2;
  }

  .cell.is-col-start-3-fullhd {
    --bulma-grid-cell-column-start: 3;
  }

  .cell.is-col-end-3-fullhd {
    --bulma-grid-cell-column-end: 3;
  }

  .cell.is-col-from-end-3-fullhd {
    --bulma-grid-cell-column-start: -3;
  }

  .cell.is-col-span-3-fullhd {
    --bulma-grid-cell-column-span: 3;
  }

  .cell.is-row-start-3-fullhd {
    --bulma-grid-cell-row-start: 3;
  }

  .cell.is-row-end-3-fullhd {
    --bulma-grid-cell-row-end: 3;
  }

  .cell.is-row-from-end-3-fullhd {
    --bulma-grid-cell-row-start: -3;
  }

  .cell.is-row-span-3-fullhd {
    --bulma-grid-cell-row-span: 3;
  }

  .cell.is-col-start-4-fullhd {
    --bulma-grid-cell-column-start: 4;
  }

  .cell.is-col-end-4-fullhd {
    --bulma-grid-cell-column-end: 4;
  }

  .cell.is-col-from-end-4-fullhd {
    --bulma-grid-cell-column-start: -4;
  }

  .cell.is-col-span-4-fullhd {
    --bulma-grid-cell-column-span: 4;
  }

  .cell.is-row-start-4-fullhd {
    --bulma-grid-cell-row-start: 4;
  }

  .cell.is-row-end-4-fullhd {
    --bulma-grid-cell-row-end: 4;
  }

  .cell.is-row-from-end-4-fullhd {
    --bulma-grid-cell-row-start: -4;
  }

  .cell.is-row-span-4-fullhd {
    --bulma-grid-cell-row-span: 4;
  }

  .cell.is-col-start-5-fullhd {
    --bulma-grid-cell-column-start: 5;
  }

  .cell.is-col-end-5-fullhd {
    --bulma-grid-cell-column-end: 5;
  }

  .cell.is-col-from-end-5-fullhd {
    --bulma-grid-cell-column-start: -5;
  }

  .cell.is-col-span-5-fullhd {
    --bulma-grid-cell-column-span: 5;
  }

  .cell.is-row-start-5-fullhd {
    --bulma-grid-cell-row-start: 5;
  }

  .cell.is-row-end-5-fullhd {
    --bulma-grid-cell-row-end: 5;
  }

  .cell.is-row-from-end-5-fullhd {
    --bulma-grid-cell-row-start: -5;
  }

  .cell.is-row-span-5-fullhd {
    --bulma-grid-cell-row-span: 5;
  }

  .cell.is-col-start-6-fullhd {
    --bulma-grid-cell-column-start: 6;
  }

  .cell.is-col-end-6-fullhd {
    --bulma-grid-cell-column-end: 6;
  }

  .cell.is-col-from-end-6-fullhd {
    --bulma-grid-cell-column-start: -6;
  }

  .cell.is-col-span-6-fullhd {
    --bulma-grid-cell-column-span: 6;
  }

  .cell.is-row-start-6-fullhd {
    --bulma-grid-cell-row-start: 6;
  }

  .cell.is-row-end-6-fullhd {
    --bulma-grid-cell-row-end: 6;
  }

  .cell.is-row-from-end-6-fullhd {
    --bulma-grid-cell-row-start: -6;
  }

  .cell.is-row-span-6-fullhd {
    --bulma-grid-cell-row-span: 6;
  }

  .cell.is-col-start-7-fullhd {
    --bulma-grid-cell-column-start: 7;
  }

  .cell.is-col-end-7-fullhd {
    --bulma-grid-cell-column-end: 7;
  }

  .cell.is-col-from-end-7-fullhd {
    --bulma-grid-cell-column-start: -7;
  }

  .cell.is-col-span-7-fullhd {
    --bulma-grid-cell-column-span: 7;
  }

  .cell.is-row-start-7-fullhd {
    --bulma-grid-cell-row-start: 7;
  }

  .cell.is-row-end-7-fullhd {
    --bulma-grid-cell-row-end: 7;
  }

  .cell.is-row-from-end-7-fullhd {
    --bulma-grid-cell-row-start: -7;
  }

  .cell.is-row-span-7-fullhd {
    --bulma-grid-cell-row-span: 7;
  }

  .cell.is-col-start-8-fullhd {
    --bulma-grid-cell-column-start: 8;
  }

  .cell.is-col-end-8-fullhd {
    --bulma-grid-cell-column-end: 8;
  }

  .cell.is-col-from-end-8-fullhd {
    --bulma-grid-cell-column-start: -8;
  }

  .cell.is-col-span-8-fullhd {
    --bulma-grid-cell-column-span: 8;
  }

  .cell.is-row-start-8-fullhd {
    --bulma-grid-cell-row-start: 8;
  }

  .cell.is-row-end-8-fullhd {
    --bulma-grid-cell-row-end: 8;
  }

  .cell.is-row-from-end-8-fullhd {
    --bulma-grid-cell-row-start: -8;
  }

  .cell.is-row-span-8-fullhd {
    --bulma-grid-cell-row-span: 8;
  }

  .cell.is-col-start-9-fullhd {
    --bulma-grid-cell-column-start: 9;
  }

  .cell.is-col-end-9-fullhd {
    --bulma-grid-cell-column-end: 9;
  }

  .cell.is-col-from-end-9-fullhd {
    --bulma-grid-cell-column-start: -9;
  }

  .cell.is-col-span-9-fullhd {
    --bulma-grid-cell-column-span: 9;
  }

  .cell.is-row-start-9-fullhd {
    --bulma-grid-cell-row-start: 9;
  }

  .cell.is-row-end-9-fullhd {
    --bulma-grid-cell-row-end: 9;
  }

  .cell.is-row-from-end-9-fullhd {
    --bulma-grid-cell-row-start: -9;
  }

  .cell.is-row-span-9-fullhd {
    --bulma-grid-cell-row-span: 9;
  }

  .cell.is-col-start-10-fullhd {
    --bulma-grid-cell-column-start: 10;
  }

  .cell.is-col-end-10-fullhd {
    --bulma-grid-cell-column-end: 10;
  }

  .cell.is-col-from-end-10-fullhd {
    --bulma-grid-cell-column-start: -10;
  }

  .cell.is-col-span-10-fullhd {
    --bulma-grid-cell-column-span: 10;
  }

  .cell.is-row-start-10-fullhd {
    --bulma-grid-cell-row-start: 10;
  }

  .cell.is-row-end-10-fullhd {
    --bulma-grid-cell-row-end: 10;
  }

  .cell.is-row-from-end-10-fullhd {
    --bulma-grid-cell-row-start: -10;
  }

  .cell.is-row-span-10-fullhd {
    --bulma-grid-cell-row-span: 10;
  }

  .cell.is-col-start-11-fullhd {
    --bulma-grid-cell-column-start: 11;
  }

  .cell.is-col-end-11-fullhd {
    --bulma-grid-cell-column-end: 11;
  }

  .cell.is-col-from-end-11-fullhd {
    --bulma-grid-cell-column-start: -11;
  }

  .cell.is-col-span-11-fullhd {
    --bulma-grid-cell-column-span: 11;
  }

  .cell.is-row-start-11-fullhd {
    --bulma-grid-cell-row-start: 11;
  }

  .cell.is-row-end-11-fullhd {
    --bulma-grid-cell-row-end: 11;
  }

  .cell.is-row-from-end-11-fullhd {
    --bulma-grid-cell-row-start: -11;
  }

  .cell.is-row-span-11-fullhd {
    --bulma-grid-cell-row-span: 11;
  }

  .cell.is-col-start-12-fullhd {
    --bulma-grid-cell-column-start: 12;
  }

  .cell.is-col-end-12-fullhd {
    --bulma-grid-cell-column-end: 12;
  }

  .cell.is-col-from-end-12-fullhd {
    --bulma-grid-cell-column-start: -12;
  }

  .cell.is-col-span-12-fullhd {
    --bulma-grid-cell-column-span: 12;
  }

  .cell.is-row-start-12-fullhd {
    --bulma-grid-cell-row-start: 12;
  }

  .cell.is-row-end-12-fullhd {
    --bulma-grid-cell-row-end: 12;
  }

  .cell.is-row-from-end-12-fullhd {
    --bulma-grid-cell-row-start: -12;
  }

  .cell.is-row-span-12-fullhd {
    --bulma-grid-cell-row-span: 12;
  }
}

.container {
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.container.is-fluid {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  max-width: none !important;
}

.container.is-max-tablet {
  max-width: 705px;
}

@media screen and (width >= 1024px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (width <= 1215px) {
  .container.is-widescreen:not(.is-max-tablet):not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (width <= 1407px) {
  .container.is-fullhd:not(.is-max-tablet):not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

@media screen and (width >= 1216px) {
  .container:not(.is-max-tablet):not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (width >= 1408px) {
  .container:not(.is-max-tablet):not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

.footer {
  --bulma-footer-background-color: var(--bulma-scheme-main-bis);
  --bulma-footer-color: false;
  --bulma-footer-padding: 3rem 1.5rem 6rem;
  background-color: var(--bulma-footer-background-color);
  padding: var(--bulma-footer-padding);
}

.hero {
  --bulma-hero-body-padding: 3rem 1.5rem;
  --bulma-hero-body-padding-tablet: 3rem 3rem;
  --bulma-hero-body-padding-small: 1.5rem;
  --bulma-hero-body-padding-medium: 9rem 4.5rem;
  --bulma-hero-body-padding-large: 18rem 6rem;
  --bulma-hero-gradient-h-offset: 5deg;
  --bulma-hero-gradient-s-offset: 10%;
  --bulma-hero-gradient-l-offset: 5%;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.hero .navbar {
  background: none;
}

.hero .tabs ul {
  border-bottom: none;
}

.hero.is-white {
  --bulma-hero-h: var(--bulma-white-h);
  --bulma-hero-s: var(--bulma-white-s);
  --bulma-hero-background-l: var(--bulma-white-l);
  --bulma-hero-color-l: var(--bulma-white-invert-l);
  background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-white .navbar {
  --bulma-navbar-item-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-navbar-item-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-white .tabs {
  --bulma-tabs-link-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-border-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-tabs-link-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-white .subtitle {
  --bulma-subtitle-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-subtitle-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-white .title {
  --bulma-title-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-title-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-white.is-bold {
  background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
}

@media screen and (width <= 768px) {
  .hero.is-white.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
  }
}

.hero.is-black {
  --bulma-hero-h: var(--bulma-black-h);
  --bulma-hero-s: var(--bulma-black-s);
  --bulma-hero-background-l: var(--bulma-black-l);
  --bulma-hero-color-l: var(--bulma-black-invert-l);
  background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-black .navbar {
  --bulma-navbar-item-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-navbar-item-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-black .tabs {
  --bulma-tabs-link-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-border-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-tabs-link-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-black .subtitle {
  --bulma-subtitle-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-subtitle-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-black .title {
  --bulma-title-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-title-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-black.is-bold {
  background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
}

@media screen and (width <= 768px) {
  .hero.is-black.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
  }
}

.hero.is-light {
  --bulma-hero-h: var(--bulma-light-h);
  --bulma-hero-s: var(--bulma-light-s);
  --bulma-hero-background-l: var(--bulma-light-l);
  --bulma-hero-color-l: var(--bulma-light-invert-l);
  background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-light .navbar {
  --bulma-navbar-item-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-navbar-item-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-light .tabs {
  --bulma-tabs-link-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-border-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-tabs-link-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-light .subtitle {
  --bulma-subtitle-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-subtitle-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-light .title {
  --bulma-title-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-title-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-light.is-bold {
  background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
}

@media screen and (width <= 768px) {
  .hero.is-light.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
  }
}

.hero.is-dark {
  --bulma-hero-h: var(--bulma-dark-h);
  --bulma-hero-s: var(--bulma-dark-s);
  --bulma-hero-background-l: var(--bulma-dark-l);
  --bulma-hero-color-l: var(--bulma-dark-invert-l);
  background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-dark .navbar {
  --bulma-navbar-item-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-navbar-item-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-dark .tabs {
  --bulma-tabs-link-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-border-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-tabs-link-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-dark .subtitle {
  --bulma-subtitle-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-subtitle-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-dark .title {
  --bulma-title-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-title-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-dark.is-bold {
  background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
}

@media screen and (width <= 768px) {
  .hero.is-dark.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
  }
}

.hero.is-text {
  --bulma-hero-h: var(--bulma-text-h);
  --bulma-hero-s: var(--bulma-text-s);
  --bulma-hero-background-l: var(--bulma-text-l);
  --bulma-hero-color-l: var(--bulma-text-invert-l);
  background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-text .navbar {
  --bulma-navbar-item-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-navbar-item-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-text .tabs {
  --bulma-tabs-link-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-border-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-tabs-link-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-text .subtitle {
  --bulma-subtitle-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-subtitle-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-text .title {
  --bulma-title-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-title-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-text.is-bold {
  background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
}

@media screen and (width <= 768px) {
  .hero.is-text.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
  }
}

.hero.is-primary {
  --bulma-hero-h: var(--bulma-primary-h);
  --bulma-hero-s: var(--bulma-primary-s);
  --bulma-hero-background-l: var(--bulma-primary-l);
  --bulma-hero-color-l: var(--bulma-primary-invert-l);
  background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-primary .navbar {
  --bulma-navbar-item-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-navbar-item-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-primary .tabs {
  --bulma-tabs-link-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-border-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-tabs-link-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-primary .subtitle {
  --bulma-subtitle-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-subtitle-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-primary .title {
  --bulma-title-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-title-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-primary.is-bold {
  background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
}

@media screen and (width <= 768px) {
  .hero.is-primary.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
  }
}

.hero.is-link {
  --bulma-hero-h: var(--bulma-link-h);
  --bulma-hero-s: var(--bulma-link-s);
  --bulma-hero-background-l: var(--bulma-link-l);
  --bulma-hero-color-l: var(--bulma-link-invert-l);
  background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-link .navbar {
  --bulma-navbar-item-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-navbar-item-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-link .tabs {
  --bulma-tabs-link-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-border-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-tabs-link-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-link .subtitle {
  --bulma-subtitle-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-subtitle-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-link .title {
  --bulma-title-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-title-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-link.is-bold {
  background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
}

@media screen and (width <= 768px) {
  .hero.is-link.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
  }
}

.hero.is-info {
  --bulma-hero-h: var(--bulma-info-h);
  --bulma-hero-s: var(--bulma-info-s);
  --bulma-hero-background-l: var(--bulma-info-l);
  --bulma-hero-color-l: var(--bulma-info-invert-l);
  background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-info .navbar {
  --bulma-navbar-item-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-navbar-item-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-info .tabs {
  --bulma-tabs-link-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-border-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-tabs-link-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-info .subtitle {
  --bulma-subtitle-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-subtitle-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-info .title {
  --bulma-title-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-title-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-info.is-bold {
  background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
}

@media screen and (width <= 768px) {
  .hero.is-info.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
  }
}

.hero.is-success {
  --bulma-hero-h: var(--bulma-success-h);
  --bulma-hero-s: var(--bulma-success-s);
  --bulma-hero-background-l: var(--bulma-success-l);
  --bulma-hero-color-l: var(--bulma-success-invert-l);
  background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-success .navbar {
  --bulma-navbar-item-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-navbar-item-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-success .tabs {
  --bulma-tabs-link-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-border-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-tabs-link-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-success .subtitle {
  --bulma-subtitle-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-subtitle-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-success .title {
  --bulma-title-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-title-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-success.is-bold {
  background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
}

@media screen and (width <= 768px) {
  .hero.is-success.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
  }
}

.hero.is-warning {
  --bulma-hero-h: var(--bulma-warning-h);
  --bulma-hero-s: var(--bulma-warning-s);
  --bulma-hero-background-l: var(--bulma-warning-l);
  --bulma-hero-color-l: var(--bulma-warning-invert-l);
  background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-warning .navbar {
  --bulma-navbar-item-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-navbar-item-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-warning .tabs {
  --bulma-tabs-link-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-border-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-tabs-link-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-warning .subtitle {
  --bulma-subtitle-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-subtitle-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-warning .title {
  --bulma-title-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-title-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-warning.is-bold {
  background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
}

@media screen and (width <= 768px) {
  .hero.is-warning.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
  }
}

.hero.is-danger {
  --bulma-hero-h: var(--bulma-danger-h);
  --bulma-hero-s: var(--bulma-danger-s);
  --bulma-hero-background-l: var(--bulma-danger-l);
  --bulma-hero-color-l: var(--bulma-danger-invert-l);
  background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-danger .navbar {
  --bulma-navbar-item-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-hover-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-navbar-item-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-navbar-item-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-danger .tabs {
  --bulma-tabs-link-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-background-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-tabs-boxed-link-active-border-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
  --bulma-tabs-link-active-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l));
}

.hero.is-danger .subtitle {
  --bulma-subtitle-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-subtitle-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-danger .title {
  --bulma-title-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
  --bulma-title-strong-color: hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l));
}

.hero.is-danger.is-bold {
  background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-background-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
}

@media screen and (width <= 768px) {
  .hero.is-danger.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, hsl(calc(var(--bulma-hero-h)  - 5deg), calc(var(--bulma-hero-s)  + 10%), calc(var(--bulma-hero-background-l)  + 5%)) 0%, hsl(var(--bulma-hero-h), var(--bulma-hero-s), var(--bulma-hero-color-l)) 71%, hsl(calc(var(--bulma-hero-h)  + 5deg), calc(var(--bulma-hero-s)  - 10%), calc(var(--bulma-hero-background-l)  - 5%)) 100%);
  }
}

.hero.is-small .hero-body {
  padding: var(--bulma-hero-body-padding-small);
}

@media screen and (width >= 769px), print {
  .hero.is-medium .hero-body {
    padding: var(--bulma-hero-body-padding-medium);
  }

  .hero.is-large .hero-body {
    padding: var(--bulma-hero-body-padding-large);
  }
}

.hero.is-halfheight .hero-body, .hero.is-fullheight .hero-body, .hero.is-fullheight-with-navbar .hero-body {
  align-items: center;
  display: flex;
}

.hero.is-halfheight .hero-body > .container, .hero.is-fullheight .hero-body > .container, .hero.is-fullheight-with-navbar .hero-body > .container {
  flex-grow: 1;
  flex-shrink: 1;
}

.hero.is-halfheight {
  min-height: 50vh;
}

.hero.is-fullheight {
  min-height: 100vh;
}

.hero-video {
  overflow: hidden;
}

.hero-video video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.hero-video.is-transparent {
  opacity: .3;
}

@media screen and (width <= 768px) {
  .hero-video {
    display: none;
  }
}

.hero-buttons {
  margin-top: 1.5rem;
}

@media screen and (width <= 768px) {
  .hero-buttons .button {
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-bottom: .75rem;
  }
}

@media screen and (width >= 769px), print {
  .hero-buttons {
    justify-content: center;
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-inline-end: 1.5rem;
  }
}

.hero-head, .hero-foot {
  flex-grow: 0;
  flex-shrink: 0;
}

.hero-body {
  padding: var(--bulma-hero-body-padding);
  flex-grow: 1;
  flex-shrink: 0;
}

@media screen and (width >= 769px), print {
  .hero-body {
    padding: var(--bulma-hero-body-padding-tablet);
  }
}

.level {
  --bulma-level-item-spacing: calc(var(--bulma-block-spacing) * .5);
  justify-content: space-between;
  align-items: center;
  gap: var(--bulma-level-item-spacing);
  flex-direction: column;
  display: flex;
}

.level code {
  border-radius: var(--bulma-radius);
}

.level img {
  vertical-align: top;
  display: inline-block;
}

.level.is-mobile {
  flex-direction: row;
  display: flex;
}

.level.is-mobile .level-left, .level.is-mobile .level-right {
  display: flex;
}

.level.is-mobile .level-item:not(.is-narrow) {
  flex-grow: 1;
}

@media screen and (width >= 769px), print {
  .level {
    flex-direction: row;
    display: flex;
  }

  .level > .level-item:not(.is-narrow) {
    flex-grow: 1;
  }
}

.level-item {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.level-item .title, .level-item .subtitle {
  margin-bottom: 0;
}

.level-left, .level-right {
  gap: calc(var(--bulma-block-spacing) * .5);
  flex: none;
}

.level-left .level-item.is-flexible, .level-right .level-item.is-flexible {
  flex-grow: 1;
}

.level-left {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

@media screen and (width >= 769px), print {
  .level-left {
    flex-direction: row;
  }
}

.level-right {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

@media screen and (width >= 769px), print {
  .level-right {
    flex-direction: row;
  }
}

.media {
  --bulma-media-border-color: hsla(var(--bulma-scheme-h), var(--bulma-scheme-s), var(--bulma-border-l), .5);
  --bulma-media-border-size: 1px;
  --bulma-media-spacing: 1rem;
  --bulma-media-spacing-large: 1.5rem;
  --bulma-media-content-spacing: .75rem;
  --bulma-media-level-1-spacing: .75rem;
  --bulma-media-level-1-content-spacing: .5rem;
  --bulma-media-level-2-spacing: .5rem;
  text-align: inherit;
  align-items: flex-start;
  display: flex;
}

.media .content:not(:last-child) {
  margin-bottom: var(--bulma-media-content-spacing);
}

.media .media {
  border-top-color: var(--bulma-media-border-color);
  border-top-style: solid;
  border-top-width: var(--bulma-media-border-size);
  padding-top: var(--bulma-media-level-1-spacing);
  display: flex;
}

.media .media .content:not(:last-child), .media .media .control:not(:last-child) {
  margin-bottom: var(--bulma-media-level-1-content-spacing);
}

.media .media .media {
  padding-top: var(--bulma-media-level-2-spacing);
}

.media .media .media + .media {
  margin-top: var(--bulma-media-level-2-spacing);
}

.media + .media {
  border-top-color: var(--bulma-media-border-color);
  border-top-style: solid;
  border-top-width: var(--bulma-media-border-size);
  margin-top: var(--bulma-media-spacing);
  padding-top: var(--bulma-media-spacing);
}

.media.is-large + .media {
  margin-top: var(--bulma-media-spacing-large);
  padding-top: var(--bulma-media-spacing-large);
}

.media-left, .media-right {
  flex: none;
}

.media-left {
  margin-inline-end: var(--bulma-media-spacing);
}

.media-right {
  margin-inline-start: var(--bulma-media-spacing);
}

.media-content {
  text-align: inherit;
  flex: auto;
}

@media screen and (width <= 768px) {
  .media-content {
    overflow-x: auto;
  }
}

.section {
  --bulma-section-padding: 3rem 1.5rem;
  --bulma-section-padding-desktop: 3rem 3rem;
  --bulma-section-padding-medium: 9rem 4.5rem;
  --bulma-section-padding-large: 18rem 6rem;
  padding: var(--bulma-section-padding);
}

@media screen and (width >= 1024px) {
  .section {
    padding: var(--bulma-section-padding-desktop);
  }

  .section.is-medium {
    padding: var(--bulma-section-padding-medium);
  }

  .section.is-large {
    padding: var(--bulma-section-padding-large);
  }
}

.section.is-fullheight {
  min-height: 100vh;
}

:root {
  --bulma-skeleton-background: var(--bulma-border);
  --bulma-skeleton-radius: var(--bulma-radius-small);
  --bulma-skeleton-block-min-height: 4.5em;
  --bulma-skeleton-lines-gap: .75em;
  --bulma-skeleton-line-height: .75em;
}

.skeleton-lines > div, .skeleton-block, .has-skeleton:after, .is-skeleton {
  background-color: var(--bulma-skeleton-background);
  border-radius: var(--bulma-skeleton-radius);
  box-shadow: none;
  pointer-events: none;
  animation-name: pulsate;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(.4, 0, .6, 1);
  animation-iteration-count: infinite;
}

.is-skeleton {
  color: #0000 !important;
}

.is-skeleton em, .is-skeleton strong {
  color: inherit;
}

.is-skeleton img {
  visibility: hidden;
}

.is-skeleton.checkbox input {
  opacity: 0;
}

.is-skeleton.delete {
  border-radius: var(--bulma-radius-rounded);
}

.is-skeleton.delete:before, .is-skeleton.delete:after {
  display: none;
}

input.is-skeleton, textarea.is-skeleton {
  resize: none;
}

input.is-skeleton::-moz-placeholder {
  color: #0000 !important;
}

textarea.is-skeleton::-moz-placeholder {
  color: #0000 !important;
}

input.is-skeleton::-webkit-input-placeholder {
  color: #0000 !important;
}

textarea.is-skeleton::-webkit-input-placeholder {
  color: #0000 !important;
}

:is(input.is-skeleton:-moz-placeholder, textarea.is-skeleton:-moz-placeholder), :is(input.is-skeleton:-ms-input-placeholder, textarea.is-skeleton:-ms-input-placeholder) {
  color: #0000 !important;
}

.has-skeleton {
  position: relative;
  color: #0000 !important;
}

.has-skeleton:after {
  content: "";
  width: 7em;
  min-width: 10%;
  max-width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.skeleton-block {
  min-height: var(--bulma-skeleton-block-min-height);
  color: #0000 !important;
}

.skeleton-lines {
  gap: var(--bulma-skeleton-lines-gap);
  flex-direction: column;
  display: flex;
  position: relative;
  color: #0000 !important;
}

.skeleton-lines > div {
  height: var(--bulma-skeleton-line-height);
}

.skeleton-lines > div:last-child {
  width: 30%;
  min-width: 4em;
}

.is-aspect-ratio-1by1 {
  aspect-ratio: 1;
}

.is-aspect-ratio-5by4 {
  aspect-ratio: 5 / 4;
}

.is-aspect-ratio-4by3 {
  aspect-ratio: 4 / 3;
}

.is-aspect-ratio-3by2 {
  aspect-ratio: 3 / 2;
}

.is-aspect-ratio-5by3 {
  aspect-ratio: 5 / 3;
}

.is-aspect-ratio-16by9 {
  aspect-ratio: 16 / 9;
}

.is-aspect-ratio-2by1 {
  aspect-ratio: 2;
}

.is-aspect-ratio-3by1 {
  aspect-ratio: 3;
}

.is-aspect-ratio-4by5 {
  aspect-ratio: 4 / 5;
}

.is-aspect-ratio-3by4 {
  aspect-ratio: 3 / 4;
}

.is-aspect-ratio-2by3 {
  aspect-ratio: 2 / 3;
}

.is-aspect-ratio-3by5 {
  aspect-ratio: 3 / 5;
}

.is-aspect-ratio-9by16 {
  aspect-ratio: 9 / 16;
}

.is-aspect-ratio-1by2 {
  aspect-ratio: 1 / 2;
}

.is-aspect-ratio-1by3 {
  aspect-ratio: 1 / 3;
}

.has-radius-small {
  border-radius: var(--bulma-radius-small);
}

.has-radius-normal {
  border-radius: var(--bulma-radius);
}

.has-radius-large {
  border-radius: var(--bulma-radius-large);
}

.has-radius-rounded {
  border-radius: var(--bulma-radius-rounded);
}

.has-background {
  background-color: var(--bulma-background);
}

.has-text-white {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-l)) !important;
}

.has-background-white {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-l)) !important;
}

.has-text-white-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-invert-l)) !important;
}

.has-background-white-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-invert-l)) !important;
}

.has-text-white-on-scheme {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-on-scheme-l)) !important;
}

.has-background-white-on-scheme {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-on-scheme-l)) !important;
}

.has-text-white-light {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-light-l)) !important;
}

.has-background-white-light {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-light-l)) !important;
}

.has-text-white-light-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-light-invert-l)) !important;
}

.has-background-white-light-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-light-invert-l)) !important;
}

.has-text-white-dark {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-dark-l)) !important;
}

.has-background-white-dark {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-dark-l)) !important;
}

.has-text-white-dark-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-dark-invert-l)) !important;
}

.has-background-white-dark-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-dark-invert-l)) !important;
}

.has-text-white-soft {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-soft-l)) !important;
}

.has-background-white-soft {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-soft-l)) !important;
}

.has-text-white-bold {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-bold-l)) !important;
}

.has-background-white-bold {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-bold-l)) !important;
}

.has-text-white-soft-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-soft-invert-l)) !important;
}

.has-background-white-soft-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-soft-invert-l)) !important;
}

.has-text-white-bold-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-bold-invert-l)) !important;
}

.has-background-white-bold-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-bold-invert-l)) !important;
}

.has-text-white-00 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-00-l)) !important;
}

.has-background-white-00 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-00-l)) !important;
}

.has-text-white-00-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-00-invert-l)) !important;
}

.has-background-white-00-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-00-invert-l)) !important;
}

.has-text-white-05 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-05-l)) !important;
}

.has-background-white-05 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-05-l)) !important;
}

.has-text-white-05-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-05-invert-l)) !important;
}

.has-background-white-05-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-05-invert-l)) !important;
}

.has-text-white-10 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-10-l)) !important;
}

.has-background-white-10 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-10-l)) !important;
}

.has-text-white-10-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-10-invert-l)) !important;
}

.has-background-white-10-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-10-invert-l)) !important;
}

.has-text-white-15 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-15-l)) !important;
}

.has-background-white-15 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-15-l)) !important;
}

.has-text-white-15-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-15-invert-l)) !important;
}

.has-background-white-15-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-15-invert-l)) !important;
}

.has-text-white-20 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-20-l)) !important;
}

.has-background-white-20 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-20-l)) !important;
}

.has-text-white-20-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-20-invert-l)) !important;
}

.has-background-white-20-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-20-invert-l)) !important;
}

.has-text-white-25 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-25-l)) !important;
}

.has-background-white-25 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-25-l)) !important;
}

.has-text-white-25-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-25-invert-l)) !important;
}

.has-background-white-25-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-25-invert-l)) !important;
}

.has-text-white-30 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-30-l)) !important;
}

.has-background-white-30 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-30-l)) !important;
}

.has-text-white-30-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-30-invert-l)) !important;
}

.has-background-white-30-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-30-invert-l)) !important;
}

.has-text-white-35 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-35-l)) !important;
}

.has-background-white-35 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-35-l)) !important;
}

.has-text-white-35-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-35-invert-l)) !important;
}

.has-background-white-35-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-35-invert-l)) !important;
}

.has-text-white-40 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-40-l)) !important;
}

.has-background-white-40 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-40-l)) !important;
}

.has-text-white-40-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-40-invert-l)) !important;
}

.has-background-white-40-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-40-invert-l)) !important;
}

.has-text-white-45 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-45-l)) !important;
}

.has-background-white-45 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-45-l)) !important;
}

.has-text-white-45-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-45-invert-l)) !important;
}

.has-background-white-45-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-45-invert-l)) !important;
}

.has-text-white-50 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-50-l)) !important;
}

.has-background-white-50 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-50-l)) !important;
}

.has-text-white-50-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-50-invert-l)) !important;
}

.has-background-white-50-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-50-invert-l)) !important;
}

.has-text-white-55 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-55-l)) !important;
}

.has-background-white-55 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-55-l)) !important;
}

.has-text-white-55-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-55-invert-l)) !important;
}

.has-background-white-55-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-55-invert-l)) !important;
}

.has-text-white-60 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-60-l)) !important;
}

.has-background-white-60 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-60-l)) !important;
}

.has-text-white-60-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-60-invert-l)) !important;
}

.has-background-white-60-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-60-invert-l)) !important;
}

.has-text-white-65 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-65-l)) !important;
}

.has-background-white-65 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-65-l)) !important;
}

.has-text-white-65-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-65-invert-l)) !important;
}

.has-background-white-65-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-65-invert-l)) !important;
}

.has-text-white-70 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-70-l)) !important;
}

.has-background-white-70 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-70-l)) !important;
}

.has-text-white-70-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-70-invert-l)) !important;
}

.has-background-white-70-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-70-invert-l)) !important;
}

.has-text-white-75 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-75-l)) !important;
}

.has-background-white-75 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-75-l)) !important;
}

.has-text-white-75-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-75-invert-l)) !important;
}

.has-background-white-75-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-75-invert-l)) !important;
}

.has-text-white-80 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-80-l)) !important;
}

.has-background-white-80 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-80-l)) !important;
}

.has-text-white-80-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-80-invert-l)) !important;
}

.has-background-white-80-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-80-invert-l)) !important;
}

.has-text-white-85 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-85-l)) !important;
}

.has-background-white-85 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-85-l)) !important;
}

.has-text-white-85-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-85-invert-l)) !important;
}

.has-background-white-85-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-85-invert-l)) !important;
}

.has-text-white-90 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-90-l)) !important;
}

.has-background-white-90 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-90-l)) !important;
}

.has-text-white-90-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-90-invert-l)) !important;
}

.has-background-white-90-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-90-invert-l)) !important;
}

.has-text-white-95 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-95-l)) !important;
}

.has-background-white-95 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-95-l)) !important;
}

.has-text-white-95-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-95-invert-l)) !important;
}

.has-background-white-95-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-95-invert-l)) !important;
}

.has-text-white-100 {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-100-l)) !important;
}

.has-background-white-100 {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-100-l)) !important;
}

.has-text-white-100-invert {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-100-invert-l)) !important;
}

.has-background-white-100-invert {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), var(--bulma-white-100-invert-l)) !important;
}

a.has-text-white:hover, a.has-text-white:focus-visible, button.has-text-white:hover, button.has-text-white:focus-visible, has-text-white.is-hoverable:hover, has-text-white.is-hoverable:focus-visible {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), calc(var(--bulma-white-l)  + var(--bulma-hover-color-l-delta))) !important;
}

a.has-text-white:active, button.has-text-white:active, has-text-white.is-hoverable:active {
  color: hsl(var(--bulma-white-h), var(--bulma-white-s), calc(var(--bulma-white-l)  + var(--bulma-active-color-l-delta))) !important;
}

a.has-background-white:hover, a.has-background-white:focus-visible, button.has-background-white:hover, button.has-background-white:focus-visible, has-background-white.is-hoverable:hover, has-background-white.is-hoverable:focus-visible {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), calc(var(--bulma-white-l)  + var(--bulma-hover-background-l-delta))) !important;
}

a.has-background-white:active, button.has-background-white:active, has-background-white.is-hoverable:active {
  background-color: hsl(var(--bulma-white-h), var(--bulma-white-s), calc(var(--bulma-white-l)  + var(--bulma-active-background-l-delta))) !important;
}

.is-palette-white {
  --h: var(--bulma-white-h);
  --s: var(--bulma-white-s);
  --l: var(--bulma-white-l);
  --color: hsl(var(--h), var(--s), var(--l));
  --00-l: var(--bulma-white-00-l);
  --color-00: hsl(var(--h), var(--s), var(--00-l));
  --05-l: var(--bulma-white-05-l);
  --color-05: hsl(var(--h), var(--s), var(--05-l));
  --10-l: var(--bulma-white-10-l);
  --color-10: hsl(var(--h), var(--s), var(--10-l));
  --15-l: var(--bulma-white-15-l);
  --color-15: hsl(var(--h), var(--s), var(--15-l));
  --20-l: var(--bulma-white-20-l);
  --color-20: hsl(var(--h), var(--s), var(--20-l));
  --25-l: var(--bulma-white-25-l);
  --color-25: hsl(var(--h), var(--s), var(--25-l));
  --30-l: var(--bulma-white-30-l);
  --color-30: hsl(var(--h), var(--s), var(--30-l));
  --35-l: var(--bulma-white-35-l);
  --color-35: hsl(var(--h), var(--s), var(--35-l));
  --40-l: var(--bulma-white-40-l);
  --color-40: hsl(var(--h), var(--s), var(--40-l));
  --45-l: var(--bulma-white-45-l);
  --color-45: hsl(var(--h), var(--s), var(--45-l));
  --50-l: var(--bulma-white-50-l);
  --color-50: hsl(var(--h), var(--s), var(--50-l));
  --55-l: var(--bulma-white-55-l);
  --color-55: hsl(var(--h), var(--s), var(--55-l));
  --60-l: var(--bulma-white-60-l);
  --color-60: hsl(var(--h), var(--s), var(--60-l));
  --65-l: var(--bulma-white-65-l);
  --color-65: hsl(var(--h), var(--s), var(--65-l));
  --70-l: var(--bulma-white-70-l);
  --color-70: hsl(var(--h), var(--s), var(--70-l));
  --75-l: var(--bulma-white-75-l);
  --color-75: hsl(var(--h), var(--s), var(--75-l));
  --80-l: var(--bulma-white-80-l);
  --color-80: hsl(var(--h), var(--s), var(--80-l));
  --85-l: var(--bulma-white-85-l);
  --color-85: hsl(var(--h), var(--s), var(--85-l));
  --90-l: var(--bulma-white-90-l);
  --color-90: hsl(var(--h), var(--s), var(--90-l));
  --95-l: var(--bulma-white-95-l);
  --color-95: hsl(var(--h), var(--s), var(--95-l));
  --100-l: var(--bulma-white-100-l);
  --color-100: hsl(var(--h), var(--s), var(--100-l));
}

.has-text-black {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-l)) !important;
}

.has-background-black {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-l)) !important;
}

.has-text-black-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-invert-l)) !important;
}

.has-background-black-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-invert-l)) !important;
}

.has-text-black-on-scheme {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-on-scheme-l)) !important;
}

.has-background-black-on-scheme {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-on-scheme-l)) !important;
}

.has-text-black-light {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-light-l)) !important;
}

.has-background-black-light {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-light-l)) !important;
}

.has-text-black-light-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-light-invert-l)) !important;
}

.has-background-black-light-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-light-invert-l)) !important;
}

.has-text-black-dark {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-dark-l)) !important;
}

.has-background-black-dark {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-dark-l)) !important;
}

.has-text-black-dark-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-dark-invert-l)) !important;
}

.has-background-black-dark-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-dark-invert-l)) !important;
}

.has-text-black-soft {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-soft-l)) !important;
}

.has-background-black-soft {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-soft-l)) !important;
}

.has-text-black-bold {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-bold-l)) !important;
}

.has-background-black-bold {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-bold-l)) !important;
}

.has-text-black-soft-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-soft-invert-l)) !important;
}

.has-background-black-soft-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-soft-invert-l)) !important;
}

.has-text-black-bold-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-bold-invert-l)) !important;
}

.has-background-black-bold-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-bold-invert-l)) !important;
}

.has-text-black-00 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-00-l)) !important;
}

.has-background-black-00 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-00-l)) !important;
}

.has-text-black-00-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-00-invert-l)) !important;
}

.has-background-black-00-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-00-invert-l)) !important;
}

.has-text-black-05 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-05-l)) !important;
}

.has-background-black-05 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-05-l)) !important;
}

.has-text-black-05-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-05-invert-l)) !important;
}

.has-background-black-05-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-05-invert-l)) !important;
}

.has-text-black-10 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-10-l)) !important;
}

.has-background-black-10 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-10-l)) !important;
}

.has-text-black-10-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-10-invert-l)) !important;
}

.has-background-black-10-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-10-invert-l)) !important;
}

.has-text-black-15 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-15-l)) !important;
}

.has-background-black-15 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-15-l)) !important;
}

.has-text-black-15-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-15-invert-l)) !important;
}

.has-background-black-15-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-15-invert-l)) !important;
}

.has-text-black-20 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-20-l)) !important;
}

.has-background-black-20 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-20-l)) !important;
}

.has-text-black-20-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-20-invert-l)) !important;
}

.has-background-black-20-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-20-invert-l)) !important;
}

.has-text-black-25 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-25-l)) !important;
}

.has-background-black-25 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-25-l)) !important;
}

.has-text-black-25-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-25-invert-l)) !important;
}

.has-background-black-25-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-25-invert-l)) !important;
}

.has-text-black-30 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-30-l)) !important;
}

.has-background-black-30 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-30-l)) !important;
}

.has-text-black-30-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-30-invert-l)) !important;
}

.has-background-black-30-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-30-invert-l)) !important;
}

.has-text-black-35 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-35-l)) !important;
}

.has-background-black-35 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-35-l)) !important;
}

.has-text-black-35-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-35-invert-l)) !important;
}

.has-background-black-35-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-35-invert-l)) !important;
}

.has-text-black-40 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-40-l)) !important;
}

.has-background-black-40 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-40-l)) !important;
}

.has-text-black-40-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-40-invert-l)) !important;
}

.has-background-black-40-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-40-invert-l)) !important;
}

.has-text-black-45 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-45-l)) !important;
}

.has-background-black-45 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-45-l)) !important;
}

.has-text-black-45-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-45-invert-l)) !important;
}

.has-background-black-45-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-45-invert-l)) !important;
}

.has-text-black-50 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-50-l)) !important;
}

.has-background-black-50 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-50-l)) !important;
}

.has-text-black-50-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-50-invert-l)) !important;
}

.has-background-black-50-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-50-invert-l)) !important;
}

.has-text-black-55 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-55-l)) !important;
}

.has-background-black-55 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-55-l)) !important;
}

.has-text-black-55-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-55-invert-l)) !important;
}

.has-background-black-55-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-55-invert-l)) !important;
}

.has-text-black-60 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-60-l)) !important;
}

.has-background-black-60 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-60-l)) !important;
}

.has-text-black-60-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-60-invert-l)) !important;
}

.has-background-black-60-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-60-invert-l)) !important;
}

.has-text-black-65 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-65-l)) !important;
}

.has-background-black-65 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-65-l)) !important;
}

.has-text-black-65-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-65-invert-l)) !important;
}

.has-background-black-65-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-65-invert-l)) !important;
}

.has-text-black-70 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-70-l)) !important;
}

.has-background-black-70 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-70-l)) !important;
}

.has-text-black-70-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-70-invert-l)) !important;
}

.has-background-black-70-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-70-invert-l)) !important;
}

.has-text-black-75 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-75-l)) !important;
}

.has-background-black-75 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-75-l)) !important;
}

.has-text-black-75-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-75-invert-l)) !important;
}

.has-background-black-75-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-75-invert-l)) !important;
}

.has-text-black-80 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-80-l)) !important;
}

.has-background-black-80 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-80-l)) !important;
}

.has-text-black-80-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-80-invert-l)) !important;
}

.has-background-black-80-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-80-invert-l)) !important;
}

.has-text-black-85 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-85-l)) !important;
}

.has-background-black-85 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-85-l)) !important;
}

.has-text-black-85-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-85-invert-l)) !important;
}

.has-background-black-85-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-85-invert-l)) !important;
}

.has-text-black-90 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-90-l)) !important;
}

.has-background-black-90 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-90-l)) !important;
}

.has-text-black-90-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-90-invert-l)) !important;
}

.has-background-black-90-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-90-invert-l)) !important;
}

.has-text-black-95 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-95-l)) !important;
}

.has-background-black-95 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-95-l)) !important;
}

.has-text-black-95-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-95-invert-l)) !important;
}

.has-background-black-95-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-95-invert-l)) !important;
}

.has-text-black-100 {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-100-l)) !important;
}

.has-background-black-100 {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-100-l)) !important;
}

.has-text-black-100-invert {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-100-invert-l)) !important;
}

.has-background-black-100-invert {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), var(--bulma-black-100-invert-l)) !important;
}

a.has-text-black:hover, a.has-text-black:focus-visible, button.has-text-black:hover, button.has-text-black:focus-visible, has-text-black.is-hoverable:hover, has-text-black.is-hoverable:focus-visible {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), calc(var(--bulma-black-l)  + var(--bulma-hover-color-l-delta))) !important;
}

a.has-text-black:active, button.has-text-black:active, has-text-black.is-hoverable:active {
  color: hsl(var(--bulma-black-h), var(--bulma-black-s), calc(var(--bulma-black-l)  + var(--bulma-active-color-l-delta))) !important;
}

a.has-background-black:hover, a.has-background-black:focus-visible, button.has-background-black:hover, button.has-background-black:focus-visible, has-background-black.is-hoverable:hover, has-background-black.is-hoverable:focus-visible {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), calc(var(--bulma-black-l)  + var(--bulma-hover-background-l-delta))) !important;
}

a.has-background-black:active, button.has-background-black:active, has-background-black.is-hoverable:active {
  background-color: hsl(var(--bulma-black-h), var(--bulma-black-s), calc(var(--bulma-black-l)  + var(--bulma-active-background-l-delta))) !important;
}

.is-palette-black {
  --h: var(--bulma-black-h);
  --s: var(--bulma-black-s);
  --l: var(--bulma-black-l);
  --color: hsl(var(--h), var(--s), var(--l));
  --00-l: var(--bulma-black-00-l);
  --color-00: hsl(var(--h), var(--s), var(--00-l));
  --05-l: var(--bulma-black-05-l);
  --color-05: hsl(var(--h), var(--s), var(--05-l));
  --10-l: var(--bulma-black-10-l);
  --color-10: hsl(var(--h), var(--s), var(--10-l));
  --15-l: var(--bulma-black-15-l);
  --color-15: hsl(var(--h), var(--s), var(--15-l));
  --20-l: var(--bulma-black-20-l);
  --color-20: hsl(var(--h), var(--s), var(--20-l));
  --25-l: var(--bulma-black-25-l);
  --color-25: hsl(var(--h), var(--s), var(--25-l));
  --30-l: var(--bulma-black-30-l);
  --color-30: hsl(var(--h), var(--s), var(--30-l));
  --35-l: var(--bulma-black-35-l);
  --color-35: hsl(var(--h), var(--s), var(--35-l));
  --40-l: var(--bulma-black-40-l);
  --color-40: hsl(var(--h), var(--s), var(--40-l));
  --45-l: var(--bulma-black-45-l);
  --color-45: hsl(var(--h), var(--s), var(--45-l));
  --50-l: var(--bulma-black-50-l);
  --color-50: hsl(var(--h), var(--s), var(--50-l));
  --55-l: var(--bulma-black-55-l);
  --color-55: hsl(var(--h), var(--s), var(--55-l));
  --60-l: var(--bulma-black-60-l);
  --color-60: hsl(var(--h), var(--s), var(--60-l));
  --65-l: var(--bulma-black-65-l);
  --color-65: hsl(var(--h), var(--s), var(--65-l));
  --70-l: var(--bulma-black-70-l);
  --color-70: hsl(var(--h), var(--s), var(--70-l));
  --75-l: var(--bulma-black-75-l);
  --color-75: hsl(var(--h), var(--s), var(--75-l));
  --80-l: var(--bulma-black-80-l);
  --color-80: hsl(var(--h), var(--s), var(--80-l));
  --85-l: var(--bulma-black-85-l);
  --color-85: hsl(var(--h), var(--s), var(--85-l));
  --90-l: var(--bulma-black-90-l);
  --color-90: hsl(var(--h), var(--s), var(--90-l));
  --95-l: var(--bulma-black-95-l);
  --color-95: hsl(var(--h), var(--s), var(--95-l));
  --100-l: var(--bulma-black-100-l);
  --color-100: hsl(var(--h), var(--s), var(--100-l));
}

.has-text-light {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-l)) !important;
}

.has-background-light {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-l)) !important;
}

.has-text-light-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-invert-l)) !important;
}

.has-background-light-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-invert-l)) !important;
}

.has-text-light-on-scheme {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-on-scheme-l)) !important;
}

.has-background-light-on-scheme {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-on-scheme-l)) !important;
}

.has-text-light-light {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-light-l)) !important;
}

.has-background-light-light {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-light-l)) !important;
}

.has-text-light-light-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-light-invert-l)) !important;
}

.has-background-light-light-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-light-invert-l)) !important;
}

.has-text-light-dark {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-dark-l)) !important;
}

.has-background-light-dark {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-dark-l)) !important;
}

.has-text-light-dark-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-dark-invert-l)) !important;
}

.has-background-light-dark-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-dark-invert-l)) !important;
}

.has-text-light-soft {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-soft-l)) !important;
}

.has-background-light-soft {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-soft-l)) !important;
}

.has-text-light-bold {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-bold-l)) !important;
}

.has-background-light-bold {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-bold-l)) !important;
}

.has-text-light-soft-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-soft-invert-l)) !important;
}

.has-background-light-soft-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-soft-invert-l)) !important;
}

.has-text-light-bold-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-bold-invert-l)) !important;
}

.has-background-light-bold-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-bold-invert-l)) !important;
}

.has-text-light-00 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-00-l)) !important;
}

.has-background-light-00 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-00-l)) !important;
}

.has-text-light-00-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-00-invert-l)) !important;
}

.has-background-light-00-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-00-invert-l)) !important;
}

.has-text-light-05 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-05-l)) !important;
}

.has-background-light-05 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-05-l)) !important;
}

.has-text-light-05-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-05-invert-l)) !important;
}

.has-background-light-05-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-05-invert-l)) !important;
}

.has-text-light-10 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-10-l)) !important;
}

.has-background-light-10 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-10-l)) !important;
}

.has-text-light-10-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-10-invert-l)) !important;
}

.has-background-light-10-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-10-invert-l)) !important;
}

.has-text-light-15 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-15-l)) !important;
}

.has-background-light-15 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-15-l)) !important;
}

.has-text-light-15-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-15-invert-l)) !important;
}

.has-background-light-15-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-15-invert-l)) !important;
}

.has-text-light-20 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-20-l)) !important;
}

.has-background-light-20 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-20-l)) !important;
}

.has-text-light-20-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-20-invert-l)) !important;
}

.has-background-light-20-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-20-invert-l)) !important;
}

.has-text-light-25 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-25-l)) !important;
}

.has-background-light-25 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-25-l)) !important;
}

.has-text-light-25-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-25-invert-l)) !important;
}

.has-background-light-25-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-25-invert-l)) !important;
}

.has-text-light-30 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-30-l)) !important;
}

.has-background-light-30 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-30-l)) !important;
}

.has-text-light-30-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-30-invert-l)) !important;
}

.has-background-light-30-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-30-invert-l)) !important;
}

.has-text-light-35 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-35-l)) !important;
}

.has-background-light-35 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-35-l)) !important;
}

.has-text-light-35-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-35-invert-l)) !important;
}

.has-background-light-35-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-35-invert-l)) !important;
}

.has-text-light-40 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-40-l)) !important;
}

.has-background-light-40 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-40-l)) !important;
}

.has-text-light-40-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-40-invert-l)) !important;
}

.has-background-light-40-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-40-invert-l)) !important;
}

.has-text-light-45 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-45-l)) !important;
}

.has-background-light-45 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-45-l)) !important;
}

.has-text-light-45-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-45-invert-l)) !important;
}

.has-background-light-45-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-45-invert-l)) !important;
}

.has-text-light-50 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-50-l)) !important;
}

.has-background-light-50 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-50-l)) !important;
}

.has-text-light-50-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-50-invert-l)) !important;
}

.has-background-light-50-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-50-invert-l)) !important;
}

.has-text-light-55 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-55-l)) !important;
}

.has-background-light-55 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-55-l)) !important;
}

.has-text-light-55-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-55-invert-l)) !important;
}

.has-background-light-55-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-55-invert-l)) !important;
}

.has-text-light-60 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-60-l)) !important;
}

.has-background-light-60 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-60-l)) !important;
}

.has-text-light-60-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-60-invert-l)) !important;
}

.has-background-light-60-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-60-invert-l)) !important;
}

.has-text-light-65 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-65-l)) !important;
}

.has-background-light-65 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-65-l)) !important;
}

.has-text-light-65-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-65-invert-l)) !important;
}

.has-background-light-65-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-65-invert-l)) !important;
}

.has-text-light-70 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-70-l)) !important;
}

.has-background-light-70 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-70-l)) !important;
}

.has-text-light-70-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-70-invert-l)) !important;
}

.has-background-light-70-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-70-invert-l)) !important;
}

.has-text-light-75 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-75-l)) !important;
}

.has-background-light-75 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-75-l)) !important;
}

.has-text-light-75-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-75-invert-l)) !important;
}

.has-background-light-75-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-75-invert-l)) !important;
}

.has-text-light-80 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-80-l)) !important;
}

.has-background-light-80 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-80-l)) !important;
}

.has-text-light-80-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-80-invert-l)) !important;
}

.has-background-light-80-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-80-invert-l)) !important;
}

.has-text-light-85 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-85-l)) !important;
}

.has-background-light-85 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-85-l)) !important;
}

.has-text-light-85-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-85-invert-l)) !important;
}

.has-background-light-85-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-85-invert-l)) !important;
}

.has-text-light-90 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-90-l)) !important;
}

.has-background-light-90 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-90-l)) !important;
}

.has-text-light-90-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-90-invert-l)) !important;
}

.has-background-light-90-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-90-invert-l)) !important;
}

.has-text-light-95 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-95-l)) !important;
}

.has-background-light-95 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-95-l)) !important;
}

.has-text-light-95-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-95-invert-l)) !important;
}

.has-background-light-95-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-95-invert-l)) !important;
}

.has-text-light-100 {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-100-l)) !important;
}

.has-background-light-100 {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-100-l)) !important;
}

.has-text-light-100-invert {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-100-invert-l)) !important;
}

.has-background-light-100-invert {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), var(--bulma-light-100-invert-l)) !important;
}

a.has-text-light:hover, a.has-text-light:focus-visible, button.has-text-light:hover, button.has-text-light:focus-visible, has-text-light.is-hoverable:hover, has-text-light.is-hoverable:focus-visible {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), calc(var(--bulma-light-l)  + var(--bulma-hover-color-l-delta))) !important;
}

a.has-text-light:active, button.has-text-light:active, has-text-light.is-hoverable:active {
  color: hsl(var(--bulma-light-h), var(--bulma-light-s), calc(var(--bulma-light-l)  + var(--bulma-active-color-l-delta))) !important;
}

a.has-background-light:hover, a.has-background-light:focus-visible, button.has-background-light:hover, button.has-background-light:focus-visible, has-background-light.is-hoverable:hover, has-background-light.is-hoverable:focus-visible {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), calc(var(--bulma-light-l)  + var(--bulma-hover-background-l-delta))) !important;
}

a.has-background-light:active, button.has-background-light:active, has-background-light.is-hoverable:active {
  background-color: hsl(var(--bulma-light-h), var(--bulma-light-s), calc(var(--bulma-light-l)  + var(--bulma-active-background-l-delta))) !important;
}

.is-palette-light {
  --h: var(--bulma-light-h);
  --s: var(--bulma-light-s);
  --l: var(--bulma-light-l);
  --color: hsl(var(--h), var(--s), var(--l));
  --00-l: var(--bulma-light-00-l);
  --color-00: hsl(var(--h), var(--s), var(--00-l));
  --05-l: var(--bulma-light-05-l);
  --color-05: hsl(var(--h), var(--s), var(--05-l));
  --10-l: var(--bulma-light-10-l);
  --color-10: hsl(var(--h), var(--s), var(--10-l));
  --15-l: var(--bulma-light-15-l);
  --color-15: hsl(var(--h), var(--s), var(--15-l));
  --20-l: var(--bulma-light-20-l);
  --color-20: hsl(var(--h), var(--s), var(--20-l));
  --25-l: var(--bulma-light-25-l);
  --color-25: hsl(var(--h), var(--s), var(--25-l));
  --30-l: var(--bulma-light-30-l);
  --color-30: hsl(var(--h), var(--s), var(--30-l));
  --35-l: var(--bulma-light-35-l);
  --color-35: hsl(var(--h), var(--s), var(--35-l));
  --40-l: var(--bulma-light-40-l);
  --color-40: hsl(var(--h), var(--s), var(--40-l));
  --45-l: var(--bulma-light-45-l);
  --color-45: hsl(var(--h), var(--s), var(--45-l));
  --50-l: var(--bulma-light-50-l);
  --color-50: hsl(var(--h), var(--s), var(--50-l));
  --55-l: var(--bulma-light-55-l);
  --color-55: hsl(var(--h), var(--s), var(--55-l));
  --60-l: var(--bulma-light-60-l);
  --color-60: hsl(var(--h), var(--s), var(--60-l));
  --65-l: var(--bulma-light-65-l);
  --color-65: hsl(var(--h), var(--s), var(--65-l));
  --70-l: var(--bulma-light-70-l);
  --color-70: hsl(var(--h), var(--s), var(--70-l));
  --75-l: var(--bulma-light-75-l);
  --color-75: hsl(var(--h), var(--s), var(--75-l));
  --80-l: var(--bulma-light-80-l);
  --color-80: hsl(var(--h), var(--s), var(--80-l));
  --85-l: var(--bulma-light-85-l);
  --color-85: hsl(var(--h), var(--s), var(--85-l));
  --90-l: var(--bulma-light-90-l);
  --color-90: hsl(var(--h), var(--s), var(--90-l));
  --95-l: var(--bulma-light-95-l);
  --color-95: hsl(var(--h), var(--s), var(--95-l));
  --100-l: var(--bulma-light-100-l);
  --color-100: hsl(var(--h), var(--s), var(--100-l));
}

.has-text-dark {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-l)) !important;
}

.has-background-dark {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-l)) !important;
}

.has-text-dark-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-invert-l)) !important;
}

.has-background-dark-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-invert-l)) !important;
}

.has-text-dark-on-scheme {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-on-scheme-l)) !important;
}

.has-background-dark-on-scheme {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-on-scheme-l)) !important;
}

.has-text-dark-light {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-light-l)) !important;
}

.has-background-dark-light {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-light-l)) !important;
}

.has-text-dark-light-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-light-invert-l)) !important;
}

.has-background-dark-light-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-light-invert-l)) !important;
}

.has-text-dark-dark {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-dark-l)) !important;
}

.has-background-dark-dark {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-dark-l)) !important;
}

.has-text-dark-dark-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-dark-invert-l)) !important;
}

.has-background-dark-dark-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-dark-invert-l)) !important;
}

.has-text-dark-soft {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-soft-l)) !important;
}

.has-background-dark-soft {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-soft-l)) !important;
}

.has-text-dark-bold {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-bold-l)) !important;
}

.has-background-dark-bold {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-bold-l)) !important;
}

.has-text-dark-soft-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-soft-invert-l)) !important;
}

.has-background-dark-soft-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-soft-invert-l)) !important;
}

.has-text-dark-bold-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-bold-invert-l)) !important;
}

.has-background-dark-bold-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-bold-invert-l)) !important;
}

.has-text-dark-00 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-00-l)) !important;
}

.has-background-dark-00 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-00-l)) !important;
}

.has-text-dark-00-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-00-invert-l)) !important;
}

.has-background-dark-00-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-00-invert-l)) !important;
}

.has-text-dark-05 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-05-l)) !important;
}

.has-background-dark-05 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-05-l)) !important;
}

.has-text-dark-05-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-05-invert-l)) !important;
}

.has-background-dark-05-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-05-invert-l)) !important;
}

.has-text-dark-10 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-10-l)) !important;
}

.has-background-dark-10 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-10-l)) !important;
}

.has-text-dark-10-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-10-invert-l)) !important;
}

.has-background-dark-10-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-10-invert-l)) !important;
}

.has-text-dark-15 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-15-l)) !important;
}

.has-background-dark-15 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-15-l)) !important;
}

.has-text-dark-15-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-15-invert-l)) !important;
}

.has-background-dark-15-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-15-invert-l)) !important;
}

.has-text-dark-20 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-20-l)) !important;
}

.has-background-dark-20 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-20-l)) !important;
}

.has-text-dark-20-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-20-invert-l)) !important;
}

.has-background-dark-20-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-20-invert-l)) !important;
}

.has-text-dark-25 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-25-l)) !important;
}

.has-background-dark-25 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-25-l)) !important;
}

.has-text-dark-25-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-25-invert-l)) !important;
}

.has-background-dark-25-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-25-invert-l)) !important;
}

.has-text-dark-30 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-30-l)) !important;
}

.has-background-dark-30 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-30-l)) !important;
}

.has-text-dark-30-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-30-invert-l)) !important;
}

.has-background-dark-30-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-30-invert-l)) !important;
}

.has-text-dark-35 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-35-l)) !important;
}

.has-background-dark-35 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-35-l)) !important;
}

.has-text-dark-35-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-35-invert-l)) !important;
}

.has-background-dark-35-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-35-invert-l)) !important;
}

.has-text-dark-40 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-40-l)) !important;
}

.has-background-dark-40 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-40-l)) !important;
}

.has-text-dark-40-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-40-invert-l)) !important;
}

.has-background-dark-40-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-40-invert-l)) !important;
}

.has-text-dark-45 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-45-l)) !important;
}

.has-background-dark-45 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-45-l)) !important;
}

.has-text-dark-45-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-45-invert-l)) !important;
}

.has-background-dark-45-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-45-invert-l)) !important;
}

.has-text-dark-50 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-50-l)) !important;
}

.has-background-dark-50 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-50-l)) !important;
}

.has-text-dark-50-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-50-invert-l)) !important;
}

.has-background-dark-50-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-50-invert-l)) !important;
}

.has-text-dark-55 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-55-l)) !important;
}

.has-background-dark-55 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-55-l)) !important;
}

.has-text-dark-55-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-55-invert-l)) !important;
}

.has-background-dark-55-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-55-invert-l)) !important;
}

.has-text-dark-60 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-60-l)) !important;
}

.has-background-dark-60 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-60-l)) !important;
}

.has-text-dark-60-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-60-invert-l)) !important;
}

.has-background-dark-60-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-60-invert-l)) !important;
}

.has-text-dark-65 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-65-l)) !important;
}

.has-background-dark-65 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-65-l)) !important;
}

.has-text-dark-65-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-65-invert-l)) !important;
}

.has-background-dark-65-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-65-invert-l)) !important;
}

.has-text-dark-70 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-70-l)) !important;
}

.has-background-dark-70 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-70-l)) !important;
}

.has-text-dark-70-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-70-invert-l)) !important;
}

.has-background-dark-70-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-70-invert-l)) !important;
}

.has-text-dark-75 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-75-l)) !important;
}

.has-background-dark-75 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-75-l)) !important;
}

.has-text-dark-75-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-75-invert-l)) !important;
}

.has-background-dark-75-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-75-invert-l)) !important;
}

.has-text-dark-80 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-80-l)) !important;
}

.has-background-dark-80 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-80-l)) !important;
}

.has-text-dark-80-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-80-invert-l)) !important;
}

.has-background-dark-80-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-80-invert-l)) !important;
}

.has-text-dark-85 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-85-l)) !important;
}

.has-background-dark-85 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-85-l)) !important;
}

.has-text-dark-85-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-85-invert-l)) !important;
}

.has-background-dark-85-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-85-invert-l)) !important;
}

.has-text-dark-90 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-90-l)) !important;
}

.has-background-dark-90 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-90-l)) !important;
}

.has-text-dark-90-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-90-invert-l)) !important;
}

.has-background-dark-90-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-90-invert-l)) !important;
}

.has-text-dark-95 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-95-l)) !important;
}

.has-background-dark-95 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-95-l)) !important;
}

.has-text-dark-95-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-95-invert-l)) !important;
}

.has-background-dark-95-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-95-invert-l)) !important;
}

.has-text-dark-100 {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-100-l)) !important;
}

.has-background-dark-100 {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-100-l)) !important;
}

.has-text-dark-100-invert {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-100-invert-l)) !important;
}

.has-background-dark-100-invert {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), var(--bulma-dark-100-invert-l)) !important;
}

a.has-text-dark:hover, a.has-text-dark:focus-visible, button.has-text-dark:hover, button.has-text-dark:focus-visible, has-text-dark.is-hoverable:hover, has-text-dark.is-hoverable:focus-visible {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), calc(var(--bulma-dark-l)  + var(--bulma-hover-color-l-delta))) !important;
}

a.has-text-dark:active, button.has-text-dark:active, has-text-dark.is-hoverable:active {
  color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), calc(var(--bulma-dark-l)  + var(--bulma-active-color-l-delta))) !important;
}

a.has-background-dark:hover, a.has-background-dark:focus-visible, button.has-background-dark:hover, button.has-background-dark:focus-visible, has-background-dark.is-hoverable:hover, has-background-dark.is-hoverable:focus-visible {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), calc(var(--bulma-dark-l)  + var(--bulma-hover-background-l-delta))) !important;
}

a.has-background-dark:active, button.has-background-dark:active, has-background-dark.is-hoverable:active {
  background-color: hsl(var(--bulma-dark-h), var(--bulma-dark-s), calc(var(--bulma-dark-l)  + var(--bulma-active-background-l-delta))) !important;
}

.is-palette-dark {
  --h: var(--bulma-dark-h);
  --s: var(--bulma-dark-s);
  --l: var(--bulma-dark-l);
  --color: hsl(var(--h), var(--s), var(--l));
  --00-l: var(--bulma-dark-00-l);
  --color-00: hsl(var(--h), var(--s), var(--00-l));
  --05-l: var(--bulma-dark-05-l);
  --color-05: hsl(var(--h), var(--s), var(--05-l));
  --10-l: var(--bulma-dark-10-l);
  --color-10: hsl(var(--h), var(--s), var(--10-l));
  --15-l: var(--bulma-dark-15-l);
  --color-15: hsl(var(--h), var(--s), var(--15-l));
  --20-l: var(--bulma-dark-20-l);
  --color-20: hsl(var(--h), var(--s), var(--20-l));
  --25-l: var(--bulma-dark-25-l);
  --color-25: hsl(var(--h), var(--s), var(--25-l));
  --30-l: var(--bulma-dark-30-l);
  --color-30: hsl(var(--h), var(--s), var(--30-l));
  --35-l: var(--bulma-dark-35-l);
  --color-35: hsl(var(--h), var(--s), var(--35-l));
  --40-l: var(--bulma-dark-40-l);
  --color-40: hsl(var(--h), var(--s), var(--40-l));
  --45-l: var(--bulma-dark-45-l);
  --color-45: hsl(var(--h), var(--s), var(--45-l));
  --50-l: var(--bulma-dark-50-l);
  --color-50: hsl(var(--h), var(--s), var(--50-l));
  --55-l: var(--bulma-dark-55-l);
  --color-55: hsl(var(--h), var(--s), var(--55-l));
  --60-l: var(--bulma-dark-60-l);
  --color-60: hsl(var(--h), var(--s), var(--60-l));
  --65-l: var(--bulma-dark-65-l);
  --color-65: hsl(var(--h), var(--s), var(--65-l));
  --70-l: var(--bulma-dark-70-l);
  --color-70: hsl(var(--h), var(--s), var(--70-l));
  --75-l: var(--bulma-dark-75-l);
  --color-75: hsl(var(--h), var(--s), var(--75-l));
  --80-l: var(--bulma-dark-80-l);
  --color-80: hsl(var(--h), var(--s), var(--80-l));
  --85-l: var(--bulma-dark-85-l);
  --color-85: hsl(var(--h), var(--s), var(--85-l));
  --90-l: var(--bulma-dark-90-l);
  --color-90: hsl(var(--h), var(--s), var(--90-l));
  --95-l: var(--bulma-dark-95-l);
  --color-95: hsl(var(--h), var(--s), var(--95-l));
  --100-l: var(--bulma-dark-100-l);
  --color-100: hsl(var(--h), var(--s), var(--100-l));
}

.has-text-text {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-l)) !important;
}

.has-background-text {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-l)) !important;
}

.has-text-text-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-invert-l)) !important;
}

.has-background-text-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-invert-l)) !important;
}

.has-text-text-on-scheme {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-on-scheme-l)) !important;
}

.has-background-text-on-scheme {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-on-scheme-l)) !important;
}

.has-text-text-light {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-light-l)) !important;
}

.has-background-text-light {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-light-l)) !important;
}

.has-text-text-light-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-light-invert-l)) !important;
}

.has-background-text-light-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-light-invert-l)) !important;
}

.has-text-text-dark {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-dark-l)) !important;
}

.has-background-text-dark {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-dark-l)) !important;
}

.has-text-text-dark-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-dark-invert-l)) !important;
}

.has-background-text-dark-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-dark-invert-l)) !important;
}

.has-text-text-soft {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-soft-l)) !important;
}

.has-background-text-soft {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-soft-l)) !important;
}

.has-text-text-bold {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-bold-l)) !important;
}

.has-background-text-bold {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-bold-l)) !important;
}

.has-text-text-soft-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-soft-invert-l)) !important;
}

.has-background-text-soft-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-soft-invert-l)) !important;
}

.has-text-text-bold-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-bold-invert-l)) !important;
}

.has-background-text-bold-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-bold-invert-l)) !important;
}

.has-text-text-00 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-00-l)) !important;
}

.has-background-text-00 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-00-l)) !important;
}

.has-text-text-00-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-00-invert-l)) !important;
}

.has-background-text-00-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-00-invert-l)) !important;
}

.has-text-text-05 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-05-l)) !important;
}

.has-background-text-05 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-05-l)) !important;
}

.has-text-text-05-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-05-invert-l)) !important;
}

.has-background-text-05-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-05-invert-l)) !important;
}

.has-text-text-10 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-10-l)) !important;
}

.has-background-text-10 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-10-l)) !important;
}

.has-text-text-10-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-10-invert-l)) !important;
}

.has-background-text-10-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-10-invert-l)) !important;
}

.has-text-text-15 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-15-l)) !important;
}

.has-background-text-15 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-15-l)) !important;
}

.has-text-text-15-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-15-invert-l)) !important;
}

.has-background-text-15-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-15-invert-l)) !important;
}

.has-text-text-20 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-20-l)) !important;
}

.has-background-text-20 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-20-l)) !important;
}

.has-text-text-20-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-20-invert-l)) !important;
}

.has-background-text-20-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-20-invert-l)) !important;
}

.has-text-text-25 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-25-l)) !important;
}

.has-background-text-25 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-25-l)) !important;
}

.has-text-text-25-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-25-invert-l)) !important;
}

.has-background-text-25-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-25-invert-l)) !important;
}

.has-text-text-30 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-30-l)) !important;
}

.has-background-text-30 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-30-l)) !important;
}

.has-text-text-30-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-30-invert-l)) !important;
}

.has-background-text-30-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-30-invert-l)) !important;
}

.has-text-text-35 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-35-l)) !important;
}

.has-background-text-35 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-35-l)) !important;
}

.has-text-text-35-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-35-invert-l)) !important;
}

.has-background-text-35-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-35-invert-l)) !important;
}

.has-text-text-40 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-40-l)) !important;
}

.has-background-text-40 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-40-l)) !important;
}

.has-text-text-40-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-40-invert-l)) !important;
}

.has-background-text-40-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-40-invert-l)) !important;
}

.has-text-text-45 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-45-l)) !important;
}

.has-background-text-45 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-45-l)) !important;
}

.has-text-text-45-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-45-invert-l)) !important;
}

.has-background-text-45-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-45-invert-l)) !important;
}

.has-text-text-50 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-50-l)) !important;
}

.has-background-text-50 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-50-l)) !important;
}

.has-text-text-50-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-50-invert-l)) !important;
}

.has-background-text-50-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-50-invert-l)) !important;
}

.has-text-text-55 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-55-l)) !important;
}

.has-background-text-55 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-55-l)) !important;
}

.has-text-text-55-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-55-invert-l)) !important;
}

.has-background-text-55-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-55-invert-l)) !important;
}

.has-text-text-60 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-60-l)) !important;
}

.has-background-text-60 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-60-l)) !important;
}

.has-text-text-60-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-60-invert-l)) !important;
}

.has-background-text-60-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-60-invert-l)) !important;
}

.has-text-text-65 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-65-l)) !important;
}

.has-background-text-65 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-65-l)) !important;
}

.has-text-text-65-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-65-invert-l)) !important;
}

.has-background-text-65-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-65-invert-l)) !important;
}

.has-text-text-70 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-70-l)) !important;
}

.has-background-text-70 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-70-l)) !important;
}

.has-text-text-70-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-70-invert-l)) !important;
}

.has-background-text-70-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-70-invert-l)) !important;
}

.has-text-text-75 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-75-l)) !important;
}

.has-background-text-75 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-75-l)) !important;
}

.has-text-text-75-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-75-invert-l)) !important;
}

.has-background-text-75-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-75-invert-l)) !important;
}

.has-text-text-80 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-80-l)) !important;
}

.has-background-text-80 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-80-l)) !important;
}

.has-text-text-80-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-80-invert-l)) !important;
}

.has-background-text-80-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-80-invert-l)) !important;
}

.has-text-text-85 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-85-l)) !important;
}

.has-background-text-85 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-85-l)) !important;
}

.has-text-text-85-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-85-invert-l)) !important;
}

.has-background-text-85-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-85-invert-l)) !important;
}

.has-text-text-90 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-90-l)) !important;
}

.has-background-text-90 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-90-l)) !important;
}

.has-text-text-90-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-90-invert-l)) !important;
}

.has-background-text-90-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-90-invert-l)) !important;
}

.has-text-text-95 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-95-l)) !important;
}

.has-background-text-95 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-95-l)) !important;
}

.has-text-text-95-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-95-invert-l)) !important;
}

.has-background-text-95-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-95-invert-l)) !important;
}

.has-text-text-100 {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-100-l)) !important;
}

.has-background-text-100 {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-100-l)) !important;
}

.has-text-text-100-invert {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-100-invert-l)) !important;
}

.has-background-text-100-invert {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), var(--bulma-text-100-invert-l)) !important;
}

a.has-text-text:hover, a.has-text-text:focus-visible, button.has-text-text:hover, button.has-text-text:focus-visible, has-text-text.is-hoverable:hover, has-text-text.is-hoverable:focus-visible {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), calc(var(--bulma-text-l)  + var(--bulma-hover-color-l-delta))) !important;
}

a.has-text-text:active, button.has-text-text:active, has-text-text.is-hoverable:active {
  color: hsl(var(--bulma-text-h), var(--bulma-text-s), calc(var(--bulma-text-l)  + var(--bulma-active-color-l-delta))) !important;
}

a.has-background-text:hover, a.has-background-text:focus-visible, button.has-background-text:hover, button.has-background-text:focus-visible, has-background-text.is-hoverable:hover, has-background-text.is-hoverable:focus-visible {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), calc(var(--bulma-text-l)  + var(--bulma-hover-background-l-delta))) !important;
}

a.has-background-text:active, button.has-background-text:active, has-background-text.is-hoverable:active {
  background-color: hsl(var(--bulma-text-h), var(--bulma-text-s), calc(var(--bulma-text-l)  + var(--bulma-active-background-l-delta))) !important;
}

.is-palette-text {
  --h: var(--bulma-text-h);
  --s: var(--bulma-text-s);
  --l: var(--bulma-text-l);
  --color: hsl(var(--h), var(--s), var(--l));
  --00-l: var(--bulma-text-00-l);
  --color-00: hsl(var(--h), var(--s), var(--00-l));
  --05-l: var(--bulma-text-05-l);
  --color-05: hsl(var(--h), var(--s), var(--05-l));
  --10-l: var(--bulma-text-10-l);
  --color-10: hsl(var(--h), var(--s), var(--10-l));
  --15-l: var(--bulma-text-15-l);
  --color-15: hsl(var(--h), var(--s), var(--15-l));
  --20-l: var(--bulma-text-20-l);
  --color-20: hsl(var(--h), var(--s), var(--20-l));
  --25-l: var(--bulma-text-25-l);
  --color-25: hsl(var(--h), var(--s), var(--25-l));
  --30-l: var(--bulma-text-30-l);
  --color-30: hsl(var(--h), var(--s), var(--30-l));
  --35-l: var(--bulma-text-35-l);
  --color-35: hsl(var(--h), var(--s), var(--35-l));
  --40-l: var(--bulma-text-40-l);
  --color-40: hsl(var(--h), var(--s), var(--40-l));
  --45-l: var(--bulma-text-45-l);
  --color-45: hsl(var(--h), var(--s), var(--45-l));
  --50-l: var(--bulma-text-50-l);
  --color-50: hsl(var(--h), var(--s), var(--50-l));
  --55-l: var(--bulma-text-55-l);
  --color-55: hsl(var(--h), var(--s), var(--55-l));
  --60-l: var(--bulma-text-60-l);
  --color-60: hsl(var(--h), var(--s), var(--60-l));
  --65-l: var(--bulma-text-65-l);
  --color-65: hsl(var(--h), var(--s), var(--65-l));
  --70-l: var(--bulma-text-70-l);
  --color-70: hsl(var(--h), var(--s), var(--70-l));
  --75-l: var(--bulma-text-75-l);
  --color-75: hsl(var(--h), var(--s), var(--75-l));
  --80-l: var(--bulma-text-80-l);
  --color-80: hsl(var(--h), var(--s), var(--80-l));
  --85-l: var(--bulma-text-85-l);
  --color-85: hsl(var(--h), var(--s), var(--85-l));
  --90-l: var(--bulma-text-90-l);
  --color-90: hsl(var(--h), var(--s), var(--90-l));
  --95-l: var(--bulma-text-95-l);
  --color-95: hsl(var(--h), var(--s), var(--95-l));
  --100-l: var(--bulma-text-100-l);
  --color-100: hsl(var(--h), var(--s), var(--100-l));
}

.has-text-primary {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-l)) !important;
}

.has-background-primary {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-l)) !important;
}

.has-text-primary-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-invert-l)) !important;
}

.has-background-primary-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-invert-l)) !important;
}

.has-text-primary-on-scheme {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-on-scheme-l)) !important;
}

.has-background-primary-on-scheme {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-on-scheme-l)) !important;
}

.has-text-primary-light {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-light-l)) !important;
}

.has-background-primary-light {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-light-l)) !important;
}

.has-text-primary-light-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-light-invert-l)) !important;
}

.has-background-primary-light-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-light-invert-l)) !important;
}

.has-text-primary-dark {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-dark-l)) !important;
}

.has-background-primary-dark {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-dark-l)) !important;
}

.has-text-primary-dark-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-dark-invert-l)) !important;
}

.has-background-primary-dark-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-dark-invert-l)) !important;
}

.has-text-primary-soft {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-soft-l)) !important;
}

.has-background-primary-soft {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-soft-l)) !important;
}

.has-text-primary-bold {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-bold-l)) !important;
}

.has-background-primary-bold {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-bold-l)) !important;
}

.has-text-primary-soft-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-soft-invert-l)) !important;
}

.has-background-primary-soft-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-soft-invert-l)) !important;
}

.has-text-primary-bold-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-bold-invert-l)) !important;
}

.has-background-primary-bold-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-bold-invert-l)) !important;
}

.has-text-primary-00 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-00-l)) !important;
}

.has-background-primary-00 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-00-l)) !important;
}

.has-text-primary-00-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-00-invert-l)) !important;
}

.has-background-primary-00-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-00-invert-l)) !important;
}

.has-text-primary-05 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-05-l)) !important;
}

.has-background-primary-05 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-05-l)) !important;
}

.has-text-primary-05-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-05-invert-l)) !important;
}

.has-background-primary-05-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-05-invert-l)) !important;
}

.has-text-primary-10 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-10-l)) !important;
}

.has-background-primary-10 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-10-l)) !important;
}

.has-text-primary-10-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-10-invert-l)) !important;
}

.has-background-primary-10-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-10-invert-l)) !important;
}

.has-text-primary-15 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-15-l)) !important;
}

.has-background-primary-15 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-15-l)) !important;
}

.has-text-primary-15-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-15-invert-l)) !important;
}

.has-background-primary-15-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-15-invert-l)) !important;
}

.has-text-primary-20 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-20-l)) !important;
}

.has-background-primary-20 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-20-l)) !important;
}

.has-text-primary-20-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-20-invert-l)) !important;
}

.has-background-primary-20-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-20-invert-l)) !important;
}

.has-text-primary-25 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-25-l)) !important;
}

.has-background-primary-25 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-25-l)) !important;
}

.has-text-primary-25-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-25-invert-l)) !important;
}

.has-background-primary-25-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-25-invert-l)) !important;
}

.has-text-primary-30 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-30-l)) !important;
}

.has-background-primary-30 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-30-l)) !important;
}

.has-text-primary-30-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-30-invert-l)) !important;
}

.has-background-primary-30-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-30-invert-l)) !important;
}

.has-text-primary-35 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-35-l)) !important;
}

.has-background-primary-35 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-35-l)) !important;
}

.has-text-primary-35-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-35-invert-l)) !important;
}

.has-background-primary-35-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-35-invert-l)) !important;
}

.has-text-primary-40 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-40-l)) !important;
}

.has-background-primary-40 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-40-l)) !important;
}

.has-text-primary-40-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-40-invert-l)) !important;
}

.has-background-primary-40-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-40-invert-l)) !important;
}

.has-text-primary-45 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-45-l)) !important;
}

.has-background-primary-45 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-45-l)) !important;
}

.has-text-primary-45-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-45-invert-l)) !important;
}

.has-background-primary-45-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-45-invert-l)) !important;
}

.has-text-primary-50 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-50-l)) !important;
}

.has-background-primary-50 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-50-l)) !important;
}

.has-text-primary-50-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-50-invert-l)) !important;
}

.has-background-primary-50-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-50-invert-l)) !important;
}

.has-text-primary-55 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-55-l)) !important;
}

.has-background-primary-55 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-55-l)) !important;
}

.has-text-primary-55-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-55-invert-l)) !important;
}

.has-background-primary-55-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-55-invert-l)) !important;
}

.has-text-primary-60 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-60-l)) !important;
}

.has-background-primary-60 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-60-l)) !important;
}

.has-text-primary-60-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-60-invert-l)) !important;
}

.has-background-primary-60-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-60-invert-l)) !important;
}

.has-text-primary-65 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-65-l)) !important;
}

.has-background-primary-65 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-65-l)) !important;
}

.has-text-primary-65-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-65-invert-l)) !important;
}

.has-background-primary-65-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-65-invert-l)) !important;
}

.has-text-primary-70 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-70-l)) !important;
}

.has-background-primary-70 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-70-l)) !important;
}

.has-text-primary-70-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-70-invert-l)) !important;
}

.has-background-primary-70-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-70-invert-l)) !important;
}

.has-text-primary-75 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-75-l)) !important;
}

.has-background-primary-75 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-75-l)) !important;
}

.has-text-primary-75-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-75-invert-l)) !important;
}

.has-background-primary-75-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-75-invert-l)) !important;
}

.has-text-primary-80 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-80-l)) !important;
}

.has-background-primary-80 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-80-l)) !important;
}

.has-text-primary-80-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-80-invert-l)) !important;
}

.has-background-primary-80-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-80-invert-l)) !important;
}

.has-text-primary-85 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-85-l)) !important;
}

.has-background-primary-85 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-85-l)) !important;
}

.has-text-primary-85-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-85-invert-l)) !important;
}

.has-background-primary-85-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-85-invert-l)) !important;
}

.has-text-primary-90 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-90-l)) !important;
}

.has-background-primary-90 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-90-l)) !important;
}

.has-text-primary-90-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-90-invert-l)) !important;
}

.has-background-primary-90-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-90-invert-l)) !important;
}

.has-text-primary-95 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-95-l)) !important;
}

.has-background-primary-95 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-95-l)) !important;
}

.has-text-primary-95-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-95-invert-l)) !important;
}

.has-background-primary-95-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-95-invert-l)) !important;
}

.has-text-primary-100 {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-100-l)) !important;
}

.has-background-primary-100 {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-100-l)) !important;
}

.has-text-primary-100-invert {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-100-invert-l)) !important;
}

.has-background-primary-100-invert {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-100-invert-l)) !important;
}

a.has-text-primary:hover, a.has-text-primary:focus-visible, button.has-text-primary:hover, button.has-text-primary:focus-visible, has-text-primary.is-hoverable:hover, has-text-primary.is-hoverable:focus-visible {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), calc(var(--bulma-primary-l)  + var(--bulma-hover-color-l-delta))) !important;
}

a.has-text-primary:active, button.has-text-primary:active, has-text-primary.is-hoverable:active {
  color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), calc(var(--bulma-primary-l)  + var(--bulma-active-color-l-delta))) !important;
}

a.has-background-primary:hover, a.has-background-primary:focus-visible, button.has-background-primary:hover, button.has-background-primary:focus-visible, has-background-primary.is-hoverable:hover, has-background-primary.is-hoverable:focus-visible {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), calc(var(--bulma-primary-l)  + var(--bulma-hover-background-l-delta))) !important;
}

a.has-background-primary:active, button.has-background-primary:active, has-background-primary.is-hoverable:active {
  background-color: hsl(var(--bulma-primary-h), var(--bulma-primary-s), calc(var(--bulma-primary-l)  + var(--bulma-active-background-l-delta))) !important;
}

.is-palette-primary {
  --h: var(--bulma-primary-h);
  --s: var(--bulma-primary-s);
  --l: var(--bulma-primary-l);
  --color: hsl(var(--h), var(--s), var(--l));
  --00-l: var(--bulma-primary-00-l);
  --color-00: hsl(var(--h), var(--s), var(--00-l));
  --05-l: var(--bulma-primary-05-l);
  --color-05: hsl(var(--h), var(--s), var(--05-l));
  --10-l: var(--bulma-primary-10-l);
  --color-10: hsl(var(--h), var(--s), var(--10-l));
  --15-l: var(--bulma-primary-15-l);
  --color-15: hsl(var(--h), var(--s), var(--15-l));
  --20-l: var(--bulma-primary-20-l);
  --color-20: hsl(var(--h), var(--s), var(--20-l));
  --25-l: var(--bulma-primary-25-l);
  --color-25: hsl(var(--h), var(--s), var(--25-l));
  --30-l: var(--bulma-primary-30-l);
  --color-30: hsl(var(--h), var(--s), var(--30-l));
  --35-l: var(--bulma-primary-35-l);
  --color-35: hsl(var(--h), var(--s), var(--35-l));
  --40-l: var(--bulma-primary-40-l);
  --color-40: hsl(var(--h), var(--s), var(--40-l));
  --45-l: var(--bulma-primary-45-l);
  --color-45: hsl(var(--h), var(--s), var(--45-l));
  --50-l: var(--bulma-primary-50-l);
  --color-50: hsl(var(--h), var(--s), var(--50-l));
  --55-l: var(--bulma-primary-55-l);
  --color-55: hsl(var(--h), var(--s), var(--55-l));
  --60-l: var(--bulma-primary-60-l);
  --color-60: hsl(var(--h), var(--s), var(--60-l));
  --65-l: var(--bulma-primary-65-l);
  --color-65: hsl(var(--h), var(--s), var(--65-l));
  --70-l: var(--bulma-primary-70-l);
  --color-70: hsl(var(--h), var(--s), var(--70-l));
  --75-l: var(--bulma-primary-75-l);
  --color-75: hsl(var(--h), var(--s), var(--75-l));
  --80-l: var(--bulma-primary-80-l);
  --color-80: hsl(var(--h), var(--s), var(--80-l));
  --85-l: var(--bulma-primary-85-l);
  --color-85: hsl(var(--h), var(--s), var(--85-l));
  --90-l: var(--bulma-primary-90-l);
  --color-90: hsl(var(--h), var(--s), var(--90-l));
  --95-l: var(--bulma-primary-95-l);
  --color-95: hsl(var(--h), var(--s), var(--95-l));
  --100-l: var(--bulma-primary-100-l);
  --color-100: hsl(var(--h), var(--s), var(--100-l));
}

.has-text-link {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-l)) !important;
}

.has-background-link {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-l)) !important;
}

.has-text-link-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-invert-l)) !important;
}

.has-background-link-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-invert-l)) !important;
}

.has-text-link-on-scheme {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-on-scheme-l)) !important;
}

.has-background-link-on-scheme {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-on-scheme-l)) !important;
}

.has-text-link-light {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-light-l)) !important;
}

.has-background-link-light {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-light-l)) !important;
}

.has-text-link-light-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-light-invert-l)) !important;
}

.has-background-link-light-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-light-invert-l)) !important;
}

.has-text-link-dark {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-dark-l)) !important;
}

.has-background-link-dark {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-dark-l)) !important;
}

.has-text-link-dark-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-dark-invert-l)) !important;
}

.has-background-link-dark-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-dark-invert-l)) !important;
}

.has-text-link-soft {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-soft-l)) !important;
}

.has-background-link-soft {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-soft-l)) !important;
}

.has-text-link-bold {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-bold-l)) !important;
}

.has-background-link-bold {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-bold-l)) !important;
}

.has-text-link-soft-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-soft-invert-l)) !important;
}

.has-background-link-soft-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-soft-invert-l)) !important;
}

.has-text-link-bold-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-bold-invert-l)) !important;
}

.has-background-link-bold-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-bold-invert-l)) !important;
}

.has-text-link-00 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-00-l)) !important;
}

.has-background-link-00 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-00-l)) !important;
}

.has-text-link-00-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-00-invert-l)) !important;
}

.has-background-link-00-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-00-invert-l)) !important;
}

.has-text-link-05 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-05-l)) !important;
}

.has-background-link-05 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-05-l)) !important;
}

.has-text-link-05-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-05-invert-l)) !important;
}

.has-background-link-05-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-05-invert-l)) !important;
}

.has-text-link-10 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-10-l)) !important;
}

.has-background-link-10 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-10-l)) !important;
}

.has-text-link-10-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-10-invert-l)) !important;
}

.has-background-link-10-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-10-invert-l)) !important;
}

.has-text-link-15 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-15-l)) !important;
}

.has-background-link-15 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-15-l)) !important;
}

.has-text-link-15-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-15-invert-l)) !important;
}

.has-background-link-15-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-15-invert-l)) !important;
}

.has-text-link-20 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-20-l)) !important;
}

.has-background-link-20 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-20-l)) !important;
}

.has-text-link-20-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-20-invert-l)) !important;
}

.has-background-link-20-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-20-invert-l)) !important;
}

.has-text-link-25 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-25-l)) !important;
}

.has-background-link-25 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-25-l)) !important;
}

.has-text-link-25-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-25-invert-l)) !important;
}

.has-background-link-25-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-25-invert-l)) !important;
}

.has-text-link-30 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-30-l)) !important;
}

.has-background-link-30 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-30-l)) !important;
}

.has-text-link-30-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-30-invert-l)) !important;
}

.has-background-link-30-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-30-invert-l)) !important;
}

.has-text-link-35 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-35-l)) !important;
}

.has-background-link-35 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-35-l)) !important;
}

.has-text-link-35-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-35-invert-l)) !important;
}

.has-background-link-35-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-35-invert-l)) !important;
}

.has-text-link-40 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-40-l)) !important;
}

.has-background-link-40 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-40-l)) !important;
}

.has-text-link-40-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-40-invert-l)) !important;
}

.has-background-link-40-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-40-invert-l)) !important;
}

.has-text-link-45 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-45-l)) !important;
}

.has-background-link-45 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-45-l)) !important;
}

.has-text-link-45-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-45-invert-l)) !important;
}

.has-background-link-45-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-45-invert-l)) !important;
}

.has-text-link-50 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-50-l)) !important;
}

.has-background-link-50 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-50-l)) !important;
}

.has-text-link-50-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-50-invert-l)) !important;
}

.has-background-link-50-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-50-invert-l)) !important;
}

.has-text-link-55 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-55-l)) !important;
}

.has-background-link-55 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-55-l)) !important;
}

.has-text-link-55-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-55-invert-l)) !important;
}

.has-background-link-55-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-55-invert-l)) !important;
}

.has-text-link-60 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-60-l)) !important;
}

.has-background-link-60 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-60-l)) !important;
}

.has-text-link-60-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-60-invert-l)) !important;
}

.has-background-link-60-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-60-invert-l)) !important;
}

.has-text-link-65 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-65-l)) !important;
}

.has-background-link-65 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-65-l)) !important;
}

.has-text-link-65-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-65-invert-l)) !important;
}

.has-background-link-65-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-65-invert-l)) !important;
}

.has-text-link-70 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-70-l)) !important;
}

.has-background-link-70 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-70-l)) !important;
}

.has-text-link-70-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-70-invert-l)) !important;
}

.has-background-link-70-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-70-invert-l)) !important;
}

.has-text-link-75 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-75-l)) !important;
}

.has-background-link-75 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-75-l)) !important;
}

.has-text-link-75-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-75-invert-l)) !important;
}

.has-background-link-75-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-75-invert-l)) !important;
}

.has-text-link-80 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-80-l)) !important;
}

.has-background-link-80 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-80-l)) !important;
}

.has-text-link-80-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-80-invert-l)) !important;
}

.has-background-link-80-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-80-invert-l)) !important;
}

.has-text-link-85 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-85-l)) !important;
}

.has-background-link-85 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-85-l)) !important;
}

.has-text-link-85-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-85-invert-l)) !important;
}

.has-background-link-85-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-85-invert-l)) !important;
}

.has-text-link-90 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-90-l)) !important;
}

.has-background-link-90 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-90-l)) !important;
}

.has-text-link-90-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-90-invert-l)) !important;
}

.has-background-link-90-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-90-invert-l)) !important;
}

.has-text-link-95 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-95-l)) !important;
}

.has-background-link-95 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-95-l)) !important;
}

.has-text-link-95-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-95-invert-l)) !important;
}

.has-background-link-95-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-95-invert-l)) !important;
}

.has-text-link-100 {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-100-l)) !important;
}

.has-background-link-100 {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-100-l)) !important;
}

.has-text-link-100-invert {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-100-invert-l)) !important;
}

.has-background-link-100-invert {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), var(--bulma-link-100-invert-l)) !important;
}

a.has-text-link:hover, a.has-text-link:focus-visible, button.has-text-link:hover, button.has-text-link:focus-visible, has-text-link.is-hoverable:hover, has-text-link.is-hoverable:focus-visible {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), calc(var(--bulma-link-l)  + var(--bulma-hover-color-l-delta))) !important;
}

a.has-text-link:active, button.has-text-link:active, has-text-link.is-hoverable:active {
  color: hsl(var(--bulma-link-h), var(--bulma-link-s), calc(var(--bulma-link-l)  + var(--bulma-active-color-l-delta))) !important;
}

a.has-background-link:hover, a.has-background-link:focus-visible, button.has-background-link:hover, button.has-background-link:focus-visible, has-background-link.is-hoverable:hover, has-background-link.is-hoverable:focus-visible {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), calc(var(--bulma-link-l)  + var(--bulma-hover-background-l-delta))) !important;
}

a.has-background-link:active, button.has-background-link:active, has-background-link.is-hoverable:active {
  background-color: hsl(var(--bulma-link-h), var(--bulma-link-s), calc(var(--bulma-link-l)  + var(--bulma-active-background-l-delta))) !important;
}

.is-palette-link {
  --h: var(--bulma-link-h);
  --s: var(--bulma-link-s);
  --l: var(--bulma-link-l);
  --color: hsl(var(--h), var(--s), var(--l));
  --00-l: var(--bulma-link-00-l);
  --color-00: hsl(var(--h), var(--s), var(--00-l));
  --05-l: var(--bulma-link-05-l);
  --color-05: hsl(var(--h), var(--s), var(--05-l));
  --10-l: var(--bulma-link-10-l);
  --color-10: hsl(var(--h), var(--s), var(--10-l));
  --15-l: var(--bulma-link-15-l);
  --color-15: hsl(var(--h), var(--s), var(--15-l));
  --20-l: var(--bulma-link-20-l);
  --color-20: hsl(var(--h), var(--s), var(--20-l));
  --25-l: var(--bulma-link-25-l);
  --color-25: hsl(var(--h), var(--s), var(--25-l));
  --30-l: var(--bulma-link-30-l);
  --color-30: hsl(var(--h), var(--s), var(--30-l));
  --35-l: var(--bulma-link-35-l);
  --color-35: hsl(var(--h), var(--s), var(--35-l));
  --40-l: var(--bulma-link-40-l);
  --color-40: hsl(var(--h), var(--s), var(--40-l));
  --45-l: var(--bulma-link-45-l);
  --color-45: hsl(var(--h), var(--s), var(--45-l));
  --50-l: var(--bulma-link-50-l);
  --color-50: hsl(var(--h), var(--s), var(--50-l));
  --55-l: var(--bulma-link-55-l);
  --color-55: hsl(var(--h), var(--s), var(--55-l));
  --60-l: var(--bulma-link-60-l);
  --color-60: hsl(var(--h), var(--s), var(--60-l));
  --65-l: var(--bulma-link-65-l);
  --color-65: hsl(var(--h), var(--s), var(--65-l));
  --70-l: var(--bulma-link-70-l);
  --color-70: hsl(var(--h), var(--s), var(--70-l));
  --75-l: var(--bulma-link-75-l);
  --color-75: hsl(var(--h), var(--s), var(--75-l));
  --80-l: var(--bulma-link-80-l);
  --color-80: hsl(var(--h), var(--s), var(--80-l));
  --85-l: var(--bulma-link-85-l);
  --color-85: hsl(var(--h), var(--s), var(--85-l));
  --90-l: var(--bulma-link-90-l);
  --color-90: hsl(var(--h), var(--s), var(--90-l));
  --95-l: var(--bulma-link-95-l);
  --color-95: hsl(var(--h), var(--s), var(--95-l));
  --100-l: var(--bulma-link-100-l);
  --color-100: hsl(var(--h), var(--s), var(--100-l));
}

.has-text-info {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-l)) !important;
}

.has-background-info {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-l)) !important;
}

.has-text-info-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-invert-l)) !important;
}

.has-background-info-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-invert-l)) !important;
}

.has-text-info-on-scheme {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-on-scheme-l)) !important;
}

.has-background-info-on-scheme {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-on-scheme-l)) !important;
}

.has-text-info-light {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-light-l)) !important;
}

.has-background-info-light {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-light-l)) !important;
}

.has-text-info-light-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-light-invert-l)) !important;
}

.has-background-info-light-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-light-invert-l)) !important;
}

.has-text-info-dark {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-dark-l)) !important;
}

.has-background-info-dark {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-dark-l)) !important;
}

.has-text-info-dark-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-dark-invert-l)) !important;
}

.has-background-info-dark-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-dark-invert-l)) !important;
}

.has-text-info-soft {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-soft-l)) !important;
}

.has-background-info-soft {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-soft-l)) !important;
}

.has-text-info-bold {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-bold-l)) !important;
}

.has-background-info-bold {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-bold-l)) !important;
}

.has-text-info-soft-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-soft-invert-l)) !important;
}

.has-background-info-soft-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-soft-invert-l)) !important;
}

.has-text-info-bold-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-bold-invert-l)) !important;
}

.has-background-info-bold-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-bold-invert-l)) !important;
}

.has-text-info-00 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-00-l)) !important;
}

.has-background-info-00 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-00-l)) !important;
}

.has-text-info-00-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-00-invert-l)) !important;
}

.has-background-info-00-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-00-invert-l)) !important;
}

.has-text-info-05 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-05-l)) !important;
}

.has-background-info-05 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-05-l)) !important;
}

.has-text-info-05-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-05-invert-l)) !important;
}

.has-background-info-05-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-05-invert-l)) !important;
}

.has-text-info-10 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-10-l)) !important;
}

.has-background-info-10 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-10-l)) !important;
}

.has-text-info-10-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-10-invert-l)) !important;
}

.has-background-info-10-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-10-invert-l)) !important;
}

.has-text-info-15 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-15-l)) !important;
}

.has-background-info-15 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-15-l)) !important;
}

.has-text-info-15-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-15-invert-l)) !important;
}

.has-background-info-15-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-15-invert-l)) !important;
}

.has-text-info-20 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-20-l)) !important;
}

.has-background-info-20 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-20-l)) !important;
}

.has-text-info-20-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-20-invert-l)) !important;
}

.has-background-info-20-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-20-invert-l)) !important;
}

.has-text-info-25 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-25-l)) !important;
}

.has-background-info-25 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-25-l)) !important;
}

.has-text-info-25-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-25-invert-l)) !important;
}

.has-background-info-25-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-25-invert-l)) !important;
}

.has-text-info-30 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-30-l)) !important;
}

.has-background-info-30 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-30-l)) !important;
}

.has-text-info-30-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-30-invert-l)) !important;
}

.has-background-info-30-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-30-invert-l)) !important;
}

.has-text-info-35 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-35-l)) !important;
}

.has-background-info-35 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-35-l)) !important;
}

.has-text-info-35-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-35-invert-l)) !important;
}

.has-background-info-35-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-35-invert-l)) !important;
}

.has-text-info-40 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-40-l)) !important;
}

.has-background-info-40 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-40-l)) !important;
}

.has-text-info-40-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-40-invert-l)) !important;
}

.has-background-info-40-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-40-invert-l)) !important;
}

.has-text-info-45 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-45-l)) !important;
}

.has-background-info-45 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-45-l)) !important;
}

.has-text-info-45-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-45-invert-l)) !important;
}

.has-background-info-45-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-45-invert-l)) !important;
}

.has-text-info-50 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-50-l)) !important;
}

.has-background-info-50 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-50-l)) !important;
}

.has-text-info-50-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-50-invert-l)) !important;
}

.has-background-info-50-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-50-invert-l)) !important;
}

.has-text-info-55 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-55-l)) !important;
}

.has-background-info-55 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-55-l)) !important;
}

.has-text-info-55-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-55-invert-l)) !important;
}

.has-background-info-55-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-55-invert-l)) !important;
}

.has-text-info-60 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-60-l)) !important;
}

.has-background-info-60 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-60-l)) !important;
}

.has-text-info-60-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-60-invert-l)) !important;
}

.has-background-info-60-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-60-invert-l)) !important;
}

.has-text-info-65 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-65-l)) !important;
}

.has-background-info-65 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-65-l)) !important;
}

.has-text-info-65-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-65-invert-l)) !important;
}

.has-background-info-65-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-65-invert-l)) !important;
}

.has-text-info-70 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-70-l)) !important;
}

.has-background-info-70 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-70-l)) !important;
}

.has-text-info-70-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-70-invert-l)) !important;
}

.has-background-info-70-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-70-invert-l)) !important;
}

.has-text-info-75 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-75-l)) !important;
}

.has-background-info-75 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-75-l)) !important;
}

.has-text-info-75-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-75-invert-l)) !important;
}

.has-background-info-75-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-75-invert-l)) !important;
}

.has-text-info-80 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-80-l)) !important;
}

.has-background-info-80 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-80-l)) !important;
}

.has-text-info-80-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-80-invert-l)) !important;
}

.has-background-info-80-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-80-invert-l)) !important;
}

.has-text-info-85 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-85-l)) !important;
}

.has-background-info-85 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-85-l)) !important;
}

.has-text-info-85-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-85-invert-l)) !important;
}

.has-background-info-85-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-85-invert-l)) !important;
}

.has-text-info-90 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-90-l)) !important;
}

.has-background-info-90 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-90-l)) !important;
}

.has-text-info-90-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-90-invert-l)) !important;
}

.has-background-info-90-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-90-invert-l)) !important;
}

.has-text-info-95 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-95-l)) !important;
}

.has-background-info-95 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-95-l)) !important;
}

.has-text-info-95-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-95-invert-l)) !important;
}

.has-background-info-95-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-95-invert-l)) !important;
}

.has-text-info-100 {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-100-l)) !important;
}

.has-background-info-100 {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-100-l)) !important;
}

.has-text-info-100-invert {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-100-invert-l)) !important;
}

.has-background-info-100-invert {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), var(--bulma-info-100-invert-l)) !important;
}

a.has-text-info:hover, a.has-text-info:focus-visible, button.has-text-info:hover, button.has-text-info:focus-visible, has-text-info.is-hoverable:hover, has-text-info.is-hoverable:focus-visible {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), calc(var(--bulma-info-l)  + var(--bulma-hover-color-l-delta))) !important;
}

a.has-text-info:active, button.has-text-info:active, has-text-info.is-hoverable:active {
  color: hsl(var(--bulma-info-h), var(--bulma-info-s), calc(var(--bulma-info-l)  + var(--bulma-active-color-l-delta))) !important;
}

a.has-background-info:hover, a.has-background-info:focus-visible, button.has-background-info:hover, button.has-background-info:focus-visible, has-background-info.is-hoverable:hover, has-background-info.is-hoverable:focus-visible {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), calc(var(--bulma-info-l)  + var(--bulma-hover-background-l-delta))) !important;
}

a.has-background-info:active, button.has-background-info:active, has-background-info.is-hoverable:active {
  background-color: hsl(var(--bulma-info-h), var(--bulma-info-s), calc(var(--bulma-info-l)  + var(--bulma-active-background-l-delta))) !important;
}

.is-palette-info {
  --h: var(--bulma-info-h);
  --s: var(--bulma-info-s);
  --l: var(--bulma-info-l);
  --color: hsl(var(--h), var(--s), var(--l));
  --00-l: var(--bulma-info-00-l);
  --color-00: hsl(var(--h), var(--s), var(--00-l));
  --05-l: var(--bulma-info-05-l);
  --color-05: hsl(var(--h), var(--s), var(--05-l));
  --10-l: var(--bulma-info-10-l);
  --color-10: hsl(var(--h), var(--s), var(--10-l));
  --15-l: var(--bulma-info-15-l);
  --color-15: hsl(var(--h), var(--s), var(--15-l));
  --20-l: var(--bulma-info-20-l);
  --color-20: hsl(var(--h), var(--s), var(--20-l));
  --25-l: var(--bulma-info-25-l);
  --color-25: hsl(var(--h), var(--s), var(--25-l));
  --30-l: var(--bulma-info-30-l);
  --color-30: hsl(var(--h), var(--s), var(--30-l));
  --35-l: var(--bulma-info-35-l);
  --color-35: hsl(var(--h), var(--s), var(--35-l));
  --40-l: var(--bulma-info-40-l);
  --color-40: hsl(var(--h), var(--s), var(--40-l));
  --45-l: var(--bulma-info-45-l);
  --color-45: hsl(var(--h), var(--s), var(--45-l));
  --50-l: var(--bulma-info-50-l);
  --color-50: hsl(var(--h), var(--s), var(--50-l));
  --55-l: var(--bulma-info-55-l);
  --color-55: hsl(var(--h), var(--s), var(--55-l));
  --60-l: var(--bulma-info-60-l);
  --color-60: hsl(var(--h), var(--s), var(--60-l));
  --65-l: var(--bulma-info-65-l);
  --color-65: hsl(var(--h), var(--s), var(--65-l));
  --70-l: var(--bulma-info-70-l);
  --color-70: hsl(var(--h), var(--s), var(--70-l));
  --75-l: var(--bulma-info-75-l);
  --color-75: hsl(var(--h), var(--s), var(--75-l));
  --80-l: var(--bulma-info-80-l);
  --color-80: hsl(var(--h), var(--s), var(--80-l));
  --85-l: var(--bulma-info-85-l);
  --color-85: hsl(var(--h), var(--s), var(--85-l));
  --90-l: var(--bulma-info-90-l);
  --color-90: hsl(var(--h), var(--s), var(--90-l));
  --95-l: var(--bulma-info-95-l);
  --color-95: hsl(var(--h), var(--s), var(--95-l));
  --100-l: var(--bulma-info-100-l);
  --color-100: hsl(var(--h), var(--s), var(--100-l));
}

.has-text-success {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-l)) !important;
}

.has-background-success {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-l)) !important;
}

.has-text-success-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-invert-l)) !important;
}

.has-background-success-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-invert-l)) !important;
}

.has-text-success-on-scheme {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-on-scheme-l)) !important;
}

.has-background-success-on-scheme {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-on-scheme-l)) !important;
}

.has-text-success-light {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-light-l)) !important;
}

.has-background-success-light {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-light-l)) !important;
}

.has-text-success-light-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-light-invert-l)) !important;
}

.has-background-success-light-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-light-invert-l)) !important;
}

.has-text-success-dark {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-dark-l)) !important;
}

.has-background-success-dark {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-dark-l)) !important;
}

.has-text-success-dark-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-dark-invert-l)) !important;
}

.has-background-success-dark-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-dark-invert-l)) !important;
}

.has-text-success-soft {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-soft-l)) !important;
}

.has-background-success-soft {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-soft-l)) !important;
}

.has-text-success-bold {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-bold-l)) !important;
}

.has-background-success-bold {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-bold-l)) !important;
}

.has-text-success-soft-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-soft-invert-l)) !important;
}

.has-background-success-soft-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-soft-invert-l)) !important;
}

.has-text-success-bold-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-bold-invert-l)) !important;
}

.has-background-success-bold-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-bold-invert-l)) !important;
}

.has-text-success-00 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-00-l)) !important;
}

.has-background-success-00 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-00-l)) !important;
}

.has-text-success-00-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-00-invert-l)) !important;
}

.has-background-success-00-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-00-invert-l)) !important;
}

.has-text-success-05 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-05-l)) !important;
}

.has-background-success-05 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-05-l)) !important;
}

.has-text-success-05-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-05-invert-l)) !important;
}

.has-background-success-05-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-05-invert-l)) !important;
}

.has-text-success-10 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-10-l)) !important;
}

.has-background-success-10 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-10-l)) !important;
}

.has-text-success-10-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-10-invert-l)) !important;
}

.has-background-success-10-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-10-invert-l)) !important;
}

.has-text-success-15 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-15-l)) !important;
}

.has-background-success-15 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-15-l)) !important;
}

.has-text-success-15-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-15-invert-l)) !important;
}

.has-background-success-15-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-15-invert-l)) !important;
}

.has-text-success-20 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-20-l)) !important;
}

.has-background-success-20 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-20-l)) !important;
}

.has-text-success-20-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-20-invert-l)) !important;
}

.has-background-success-20-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-20-invert-l)) !important;
}

.has-text-success-25 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-25-l)) !important;
}

.has-background-success-25 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-25-l)) !important;
}

.has-text-success-25-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-25-invert-l)) !important;
}

.has-background-success-25-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-25-invert-l)) !important;
}

.has-text-success-30 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-30-l)) !important;
}

.has-background-success-30 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-30-l)) !important;
}

.has-text-success-30-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-30-invert-l)) !important;
}

.has-background-success-30-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-30-invert-l)) !important;
}

.has-text-success-35 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-35-l)) !important;
}

.has-background-success-35 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-35-l)) !important;
}

.has-text-success-35-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-35-invert-l)) !important;
}

.has-background-success-35-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-35-invert-l)) !important;
}

.has-text-success-40 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-40-l)) !important;
}

.has-background-success-40 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-40-l)) !important;
}

.has-text-success-40-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-40-invert-l)) !important;
}

.has-background-success-40-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-40-invert-l)) !important;
}

.has-text-success-45 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-45-l)) !important;
}

.has-background-success-45 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-45-l)) !important;
}

.has-text-success-45-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-45-invert-l)) !important;
}

.has-background-success-45-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-45-invert-l)) !important;
}

.has-text-success-50 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-50-l)) !important;
}

.has-background-success-50 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-50-l)) !important;
}

.has-text-success-50-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-50-invert-l)) !important;
}

.has-background-success-50-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-50-invert-l)) !important;
}

.has-text-success-55 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-55-l)) !important;
}

.has-background-success-55 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-55-l)) !important;
}

.has-text-success-55-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-55-invert-l)) !important;
}

.has-background-success-55-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-55-invert-l)) !important;
}

.has-text-success-60 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-60-l)) !important;
}

.has-background-success-60 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-60-l)) !important;
}

.has-text-success-60-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-60-invert-l)) !important;
}

.has-background-success-60-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-60-invert-l)) !important;
}

.has-text-success-65 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-65-l)) !important;
}

.has-background-success-65 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-65-l)) !important;
}

.has-text-success-65-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-65-invert-l)) !important;
}

.has-background-success-65-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-65-invert-l)) !important;
}

.has-text-success-70 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-70-l)) !important;
}

.has-background-success-70 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-70-l)) !important;
}

.has-text-success-70-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-70-invert-l)) !important;
}

.has-background-success-70-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-70-invert-l)) !important;
}

.has-text-success-75 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-75-l)) !important;
}

.has-background-success-75 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-75-l)) !important;
}

.has-text-success-75-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-75-invert-l)) !important;
}

.has-background-success-75-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-75-invert-l)) !important;
}

.has-text-success-80 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-80-l)) !important;
}

.has-background-success-80 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-80-l)) !important;
}

.has-text-success-80-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-80-invert-l)) !important;
}

.has-background-success-80-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-80-invert-l)) !important;
}

.has-text-success-85 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-85-l)) !important;
}

.has-background-success-85 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-85-l)) !important;
}

.has-text-success-85-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-85-invert-l)) !important;
}

.has-background-success-85-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-85-invert-l)) !important;
}

.has-text-success-90 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-90-l)) !important;
}

.has-background-success-90 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-90-l)) !important;
}

.has-text-success-90-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-90-invert-l)) !important;
}

.has-background-success-90-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-90-invert-l)) !important;
}

.has-text-success-95 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-95-l)) !important;
}

.has-background-success-95 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-95-l)) !important;
}

.has-text-success-95-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-95-invert-l)) !important;
}

.has-background-success-95-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-95-invert-l)) !important;
}

.has-text-success-100 {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-100-l)) !important;
}

.has-background-success-100 {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-100-l)) !important;
}

.has-text-success-100-invert {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-100-invert-l)) !important;
}

.has-background-success-100-invert {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), var(--bulma-success-100-invert-l)) !important;
}

a.has-text-success:hover, a.has-text-success:focus-visible, button.has-text-success:hover, button.has-text-success:focus-visible, has-text-success.is-hoverable:hover, has-text-success.is-hoverable:focus-visible {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), calc(var(--bulma-success-l)  + var(--bulma-hover-color-l-delta))) !important;
}

a.has-text-success:active, button.has-text-success:active, has-text-success.is-hoverable:active {
  color: hsl(var(--bulma-success-h), var(--bulma-success-s), calc(var(--bulma-success-l)  + var(--bulma-active-color-l-delta))) !important;
}

a.has-background-success:hover, a.has-background-success:focus-visible, button.has-background-success:hover, button.has-background-success:focus-visible, has-background-success.is-hoverable:hover, has-background-success.is-hoverable:focus-visible {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), calc(var(--bulma-success-l)  + var(--bulma-hover-background-l-delta))) !important;
}

a.has-background-success:active, button.has-background-success:active, has-background-success.is-hoverable:active {
  background-color: hsl(var(--bulma-success-h), var(--bulma-success-s), calc(var(--bulma-success-l)  + var(--bulma-active-background-l-delta))) !important;
}

.is-palette-success {
  --h: var(--bulma-success-h);
  --s: var(--bulma-success-s);
  --l: var(--bulma-success-l);
  --color: hsl(var(--h), var(--s), var(--l));
  --00-l: var(--bulma-success-00-l);
  --color-00: hsl(var(--h), var(--s), var(--00-l));
  --05-l: var(--bulma-success-05-l);
  --color-05: hsl(var(--h), var(--s), var(--05-l));
  --10-l: var(--bulma-success-10-l);
  --color-10: hsl(var(--h), var(--s), var(--10-l));
  --15-l: var(--bulma-success-15-l);
  --color-15: hsl(var(--h), var(--s), var(--15-l));
  --20-l: var(--bulma-success-20-l);
  --color-20: hsl(var(--h), var(--s), var(--20-l));
  --25-l: var(--bulma-success-25-l);
  --color-25: hsl(var(--h), var(--s), var(--25-l));
  --30-l: var(--bulma-success-30-l);
  --color-30: hsl(var(--h), var(--s), var(--30-l));
  --35-l: var(--bulma-success-35-l);
  --color-35: hsl(var(--h), var(--s), var(--35-l));
  --40-l: var(--bulma-success-40-l);
  --color-40: hsl(var(--h), var(--s), var(--40-l));
  --45-l: var(--bulma-success-45-l);
  --color-45: hsl(var(--h), var(--s), var(--45-l));
  --50-l: var(--bulma-success-50-l);
  --color-50: hsl(var(--h), var(--s), var(--50-l));
  --55-l: var(--bulma-success-55-l);
  --color-55: hsl(var(--h), var(--s), var(--55-l));
  --60-l: var(--bulma-success-60-l);
  --color-60: hsl(var(--h), var(--s), var(--60-l));
  --65-l: var(--bulma-success-65-l);
  --color-65: hsl(var(--h), var(--s), var(--65-l));
  --70-l: var(--bulma-success-70-l);
  --color-70: hsl(var(--h), var(--s), var(--70-l));
  --75-l: var(--bulma-success-75-l);
  --color-75: hsl(var(--h), var(--s), var(--75-l));
  --80-l: var(--bulma-success-80-l);
  --color-80: hsl(var(--h), var(--s), var(--80-l));
  --85-l: var(--bulma-success-85-l);
  --color-85: hsl(var(--h), var(--s), var(--85-l));
  --90-l: var(--bulma-success-90-l);
  --color-90: hsl(var(--h), var(--s), var(--90-l));
  --95-l: var(--bulma-success-95-l);
  --color-95: hsl(var(--h), var(--s), var(--95-l));
  --100-l: var(--bulma-success-100-l);
  --color-100: hsl(var(--h), var(--s), var(--100-l));
}

.has-text-warning {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-l)) !important;
}

.has-background-warning {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-l)) !important;
}

.has-text-warning-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-invert-l)) !important;
}

.has-background-warning-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-invert-l)) !important;
}

.has-text-warning-on-scheme {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-on-scheme-l)) !important;
}

.has-background-warning-on-scheme {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-on-scheme-l)) !important;
}

.has-text-warning-light {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-light-l)) !important;
}

.has-background-warning-light {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-light-l)) !important;
}

.has-text-warning-light-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-light-invert-l)) !important;
}

.has-background-warning-light-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-light-invert-l)) !important;
}

.has-text-warning-dark {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-dark-l)) !important;
}

.has-background-warning-dark {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-dark-l)) !important;
}

.has-text-warning-dark-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-dark-invert-l)) !important;
}

.has-background-warning-dark-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-dark-invert-l)) !important;
}

.has-text-warning-soft {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-soft-l)) !important;
}

.has-background-warning-soft {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-soft-l)) !important;
}

.has-text-warning-bold {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-bold-l)) !important;
}

.has-background-warning-bold {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-bold-l)) !important;
}

.has-text-warning-soft-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-soft-invert-l)) !important;
}

.has-background-warning-soft-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-soft-invert-l)) !important;
}

.has-text-warning-bold-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-bold-invert-l)) !important;
}

.has-background-warning-bold-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-bold-invert-l)) !important;
}

.has-text-warning-00 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-00-l)) !important;
}

.has-background-warning-00 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-00-l)) !important;
}

.has-text-warning-00-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-00-invert-l)) !important;
}

.has-background-warning-00-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-00-invert-l)) !important;
}

.has-text-warning-05 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-05-l)) !important;
}

.has-background-warning-05 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-05-l)) !important;
}

.has-text-warning-05-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-05-invert-l)) !important;
}

.has-background-warning-05-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-05-invert-l)) !important;
}

.has-text-warning-10 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-10-l)) !important;
}

.has-background-warning-10 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-10-l)) !important;
}

.has-text-warning-10-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-10-invert-l)) !important;
}

.has-background-warning-10-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-10-invert-l)) !important;
}

.has-text-warning-15 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-15-l)) !important;
}

.has-background-warning-15 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-15-l)) !important;
}

.has-text-warning-15-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-15-invert-l)) !important;
}

.has-background-warning-15-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-15-invert-l)) !important;
}

.has-text-warning-20 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-20-l)) !important;
}

.has-background-warning-20 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-20-l)) !important;
}

.has-text-warning-20-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-20-invert-l)) !important;
}

.has-background-warning-20-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-20-invert-l)) !important;
}

.has-text-warning-25 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-25-l)) !important;
}

.has-background-warning-25 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-25-l)) !important;
}

.has-text-warning-25-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-25-invert-l)) !important;
}

.has-background-warning-25-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-25-invert-l)) !important;
}

.has-text-warning-30 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-30-l)) !important;
}

.has-background-warning-30 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-30-l)) !important;
}

.has-text-warning-30-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-30-invert-l)) !important;
}

.has-background-warning-30-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-30-invert-l)) !important;
}

.has-text-warning-35 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-35-l)) !important;
}

.has-background-warning-35 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-35-l)) !important;
}

.has-text-warning-35-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-35-invert-l)) !important;
}

.has-background-warning-35-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-35-invert-l)) !important;
}

.has-text-warning-40 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-40-l)) !important;
}

.has-background-warning-40 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-40-l)) !important;
}

.has-text-warning-40-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-40-invert-l)) !important;
}

.has-background-warning-40-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-40-invert-l)) !important;
}

.has-text-warning-45 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-45-l)) !important;
}

.has-background-warning-45 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-45-l)) !important;
}

.has-text-warning-45-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-45-invert-l)) !important;
}

.has-background-warning-45-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-45-invert-l)) !important;
}

.has-text-warning-50 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-50-l)) !important;
}

.has-background-warning-50 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-50-l)) !important;
}

.has-text-warning-50-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-50-invert-l)) !important;
}

.has-background-warning-50-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-50-invert-l)) !important;
}

.has-text-warning-55 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-55-l)) !important;
}

.has-background-warning-55 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-55-l)) !important;
}

.has-text-warning-55-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-55-invert-l)) !important;
}

.has-background-warning-55-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-55-invert-l)) !important;
}

.has-text-warning-60 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-60-l)) !important;
}

.has-background-warning-60 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-60-l)) !important;
}

.has-text-warning-60-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-60-invert-l)) !important;
}

.has-background-warning-60-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-60-invert-l)) !important;
}

.has-text-warning-65 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-65-l)) !important;
}

.has-background-warning-65 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-65-l)) !important;
}

.has-text-warning-65-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-65-invert-l)) !important;
}

.has-background-warning-65-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-65-invert-l)) !important;
}

.has-text-warning-70 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-70-l)) !important;
}

.has-background-warning-70 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-70-l)) !important;
}

.has-text-warning-70-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-70-invert-l)) !important;
}

.has-background-warning-70-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-70-invert-l)) !important;
}

.has-text-warning-75 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-75-l)) !important;
}

.has-background-warning-75 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-75-l)) !important;
}

.has-text-warning-75-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-75-invert-l)) !important;
}

.has-background-warning-75-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-75-invert-l)) !important;
}

.has-text-warning-80 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-80-l)) !important;
}

.has-background-warning-80 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-80-l)) !important;
}

.has-text-warning-80-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-80-invert-l)) !important;
}

.has-background-warning-80-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-80-invert-l)) !important;
}

.has-text-warning-85 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-85-l)) !important;
}

.has-background-warning-85 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-85-l)) !important;
}

.has-text-warning-85-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-85-invert-l)) !important;
}

.has-background-warning-85-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-85-invert-l)) !important;
}

.has-text-warning-90 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-90-l)) !important;
}

.has-background-warning-90 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-90-l)) !important;
}

.has-text-warning-90-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-90-invert-l)) !important;
}

.has-background-warning-90-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-90-invert-l)) !important;
}

.has-text-warning-95 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-95-l)) !important;
}

.has-background-warning-95 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-95-l)) !important;
}

.has-text-warning-95-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-95-invert-l)) !important;
}

.has-background-warning-95-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-95-invert-l)) !important;
}

.has-text-warning-100 {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-100-l)) !important;
}

.has-background-warning-100 {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-100-l)) !important;
}

.has-text-warning-100-invert {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-100-invert-l)) !important;
}

.has-background-warning-100-invert {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), var(--bulma-warning-100-invert-l)) !important;
}

a.has-text-warning:hover, a.has-text-warning:focus-visible, button.has-text-warning:hover, button.has-text-warning:focus-visible, has-text-warning.is-hoverable:hover, has-text-warning.is-hoverable:focus-visible {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), calc(var(--bulma-warning-l)  + var(--bulma-hover-color-l-delta))) !important;
}

a.has-text-warning:active, button.has-text-warning:active, has-text-warning.is-hoverable:active {
  color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), calc(var(--bulma-warning-l)  + var(--bulma-active-color-l-delta))) !important;
}

a.has-background-warning:hover, a.has-background-warning:focus-visible, button.has-background-warning:hover, button.has-background-warning:focus-visible, has-background-warning.is-hoverable:hover, has-background-warning.is-hoverable:focus-visible {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), calc(var(--bulma-warning-l)  + var(--bulma-hover-background-l-delta))) !important;
}

a.has-background-warning:active, button.has-background-warning:active, has-background-warning.is-hoverable:active {
  background-color: hsl(var(--bulma-warning-h), var(--bulma-warning-s), calc(var(--bulma-warning-l)  + var(--bulma-active-background-l-delta))) !important;
}

.is-palette-warning {
  --h: var(--bulma-warning-h);
  --s: var(--bulma-warning-s);
  --l: var(--bulma-warning-l);
  --color: hsl(var(--h), var(--s), var(--l));
  --00-l: var(--bulma-warning-00-l);
  --color-00: hsl(var(--h), var(--s), var(--00-l));
  --05-l: var(--bulma-warning-05-l);
  --color-05: hsl(var(--h), var(--s), var(--05-l));
  --10-l: var(--bulma-warning-10-l);
  --color-10: hsl(var(--h), var(--s), var(--10-l));
  --15-l: var(--bulma-warning-15-l);
  --color-15: hsl(var(--h), var(--s), var(--15-l));
  --20-l: var(--bulma-warning-20-l);
  --color-20: hsl(var(--h), var(--s), var(--20-l));
  --25-l: var(--bulma-warning-25-l);
  --color-25: hsl(var(--h), var(--s), var(--25-l));
  --30-l: var(--bulma-warning-30-l);
  --color-30: hsl(var(--h), var(--s), var(--30-l));
  --35-l: var(--bulma-warning-35-l);
  --color-35: hsl(var(--h), var(--s), var(--35-l));
  --40-l: var(--bulma-warning-40-l);
  --color-40: hsl(var(--h), var(--s), var(--40-l));
  --45-l: var(--bulma-warning-45-l);
  --color-45: hsl(var(--h), var(--s), var(--45-l));
  --50-l: var(--bulma-warning-50-l);
  --color-50: hsl(var(--h), var(--s), var(--50-l));
  --55-l: var(--bulma-warning-55-l);
  --color-55: hsl(var(--h), var(--s), var(--55-l));
  --60-l: var(--bulma-warning-60-l);
  --color-60: hsl(var(--h), var(--s), var(--60-l));
  --65-l: var(--bulma-warning-65-l);
  --color-65: hsl(var(--h), var(--s), var(--65-l));
  --70-l: var(--bulma-warning-70-l);
  --color-70: hsl(var(--h), var(--s), var(--70-l));
  --75-l: var(--bulma-warning-75-l);
  --color-75: hsl(var(--h), var(--s), var(--75-l));
  --80-l: var(--bulma-warning-80-l);
  --color-80: hsl(var(--h), var(--s), var(--80-l));
  --85-l: var(--bulma-warning-85-l);
  --color-85: hsl(var(--h), var(--s), var(--85-l));
  --90-l: var(--bulma-warning-90-l);
  --color-90: hsl(var(--h), var(--s), var(--90-l));
  --95-l: var(--bulma-warning-95-l);
  --color-95: hsl(var(--h), var(--s), var(--95-l));
  --100-l: var(--bulma-warning-100-l);
  --color-100: hsl(var(--h), var(--s), var(--100-l));
}

.has-text-danger {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-l)) !important;
}

.has-background-danger {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-l)) !important;
}

.has-text-danger-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-invert-l)) !important;
}

.has-background-danger-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-invert-l)) !important;
}

.has-text-danger-on-scheme {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-on-scheme-l)) !important;
}

.has-background-danger-on-scheme {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-on-scheme-l)) !important;
}

.has-text-danger-light {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-light-l)) !important;
}

.has-background-danger-light {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-light-l)) !important;
}

.has-text-danger-light-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-light-invert-l)) !important;
}

.has-background-danger-light-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-light-invert-l)) !important;
}

.has-text-danger-dark {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-dark-l)) !important;
}

.has-background-danger-dark {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-dark-l)) !important;
}

.has-text-danger-dark-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-dark-invert-l)) !important;
}

.has-background-danger-dark-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-dark-invert-l)) !important;
}

.has-text-danger-soft {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-soft-l)) !important;
}

.has-background-danger-soft {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-soft-l)) !important;
}

.has-text-danger-bold {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-bold-l)) !important;
}

.has-background-danger-bold {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-bold-l)) !important;
}

.has-text-danger-soft-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-soft-invert-l)) !important;
}

.has-background-danger-soft-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-soft-invert-l)) !important;
}

.has-text-danger-bold-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-bold-invert-l)) !important;
}

.has-background-danger-bold-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-bold-invert-l)) !important;
}

.has-text-danger-00 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-00-l)) !important;
}

.has-background-danger-00 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-00-l)) !important;
}

.has-text-danger-00-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-00-invert-l)) !important;
}

.has-background-danger-00-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-00-invert-l)) !important;
}

.has-text-danger-05 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-05-l)) !important;
}

.has-background-danger-05 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-05-l)) !important;
}

.has-text-danger-05-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-05-invert-l)) !important;
}

.has-background-danger-05-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-05-invert-l)) !important;
}

.has-text-danger-10 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-10-l)) !important;
}

.has-background-danger-10 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-10-l)) !important;
}

.has-text-danger-10-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-10-invert-l)) !important;
}

.has-background-danger-10-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-10-invert-l)) !important;
}

.has-text-danger-15 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-15-l)) !important;
}

.has-background-danger-15 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-15-l)) !important;
}

.has-text-danger-15-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-15-invert-l)) !important;
}

.has-background-danger-15-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-15-invert-l)) !important;
}

.has-text-danger-20 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-20-l)) !important;
}

.has-background-danger-20 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-20-l)) !important;
}

.has-text-danger-20-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-20-invert-l)) !important;
}

.has-background-danger-20-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-20-invert-l)) !important;
}

.has-text-danger-25 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-25-l)) !important;
}

.has-background-danger-25 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-25-l)) !important;
}

.has-text-danger-25-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-25-invert-l)) !important;
}

.has-background-danger-25-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-25-invert-l)) !important;
}

.has-text-danger-30 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-30-l)) !important;
}

.has-background-danger-30 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-30-l)) !important;
}

.has-text-danger-30-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-30-invert-l)) !important;
}

.has-background-danger-30-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-30-invert-l)) !important;
}

.has-text-danger-35 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-35-l)) !important;
}

.has-background-danger-35 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-35-l)) !important;
}

.has-text-danger-35-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-35-invert-l)) !important;
}

.has-background-danger-35-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-35-invert-l)) !important;
}

.has-text-danger-40 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-40-l)) !important;
}

.has-background-danger-40 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-40-l)) !important;
}

.has-text-danger-40-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-40-invert-l)) !important;
}

.has-background-danger-40-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-40-invert-l)) !important;
}

.has-text-danger-45 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-45-l)) !important;
}

.has-background-danger-45 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-45-l)) !important;
}

.has-text-danger-45-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-45-invert-l)) !important;
}

.has-background-danger-45-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-45-invert-l)) !important;
}

.has-text-danger-50 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-50-l)) !important;
}

.has-background-danger-50 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-50-l)) !important;
}

.has-text-danger-50-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-50-invert-l)) !important;
}

.has-background-danger-50-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-50-invert-l)) !important;
}

.has-text-danger-55 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-55-l)) !important;
}

.has-background-danger-55 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-55-l)) !important;
}

.has-text-danger-55-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-55-invert-l)) !important;
}

.has-background-danger-55-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-55-invert-l)) !important;
}

.has-text-danger-60 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-60-l)) !important;
}

.has-background-danger-60 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-60-l)) !important;
}

.has-text-danger-60-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-60-invert-l)) !important;
}

.has-background-danger-60-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-60-invert-l)) !important;
}

.has-text-danger-65 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-65-l)) !important;
}

.has-background-danger-65 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-65-l)) !important;
}

.has-text-danger-65-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-65-invert-l)) !important;
}

.has-background-danger-65-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-65-invert-l)) !important;
}

.has-text-danger-70 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-70-l)) !important;
}

.has-background-danger-70 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-70-l)) !important;
}

.has-text-danger-70-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-70-invert-l)) !important;
}

.has-background-danger-70-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-70-invert-l)) !important;
}

.has-text-danger-75 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-75-l)) !important;
}

.has-background-danger-75 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-75-l)) !important;
}

.has-text-danger-75-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-75-invert-l)) !important;
}

.has-background-danger-75-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-75-invert-l)) !important;
}

.has-text-danger-80 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-80-l)) !important;
}

.has-background-danger-80 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-80-l)) !important;
}

.has-text-danger-80-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-80-invert-l)) !important;
}

.has-background-danger-80-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-80-invert-l)) !important;
}

.has-text-danger-85 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-85-l)) !important;
}

.has-background-danger-85 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-85-l)) !important;
}

.has-text-danger-85-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-85-invert-l)) !important;
}

.has-background-danger-85-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-85-invert-l)) !important;
}

.has-text-danger-90 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-90-l)) !important;
}

.has-background-danger-90 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-90-l)) !important;
}

.has-text-danger-90-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-90-invert-l)) !important;
}

.has-background-danger-90-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-90-invert-l)) !important;
}

.has-text-danger-95 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-95-l)) !important;
}

.has-background-danger-95 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-95-l)) !important;
}

.has-text-danger-95-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-95-invert-l)) !important;
}

.has-background-danger-95-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-95-invert-l)) !important;
}

.has-text-danger-100 {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-100-l)) !important;
}

.has-background-danger-100 {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-100-l)) !important;
}

.has-text-danger-100-invert {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-100-invert-l)) !important;
}

.has-background-danger-100-invert {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), var(--bulma-danger-100-invert-l)) !important;
}

a.has-text-danger:hover, a.has-text-danger:focus-visible, button.has-text-danger:hover, button.has-text-danger:focus-visible, has-text-danger.is-hoverable:hover, has-text-danger.is-hoverable:focus-visible {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), calc(var(--bulma-danger-l)  + var(--bulma-hover-color-l-delta))) !important;
}

a.has-text-danger:active, button.has-text-danger:active, has-text-danger.is-hoverable:active {
  color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), calc(var(--bulma-danger-l)  + var(--bulma-active-color-l-delta))) !important;
}

a.has-background-danger:hover, a.has-background-danger:focus-visible, button.has-background-danger:hover, button.has-background-danger:focus-visible, has-background-danger.is-hoverable:hover, has-background-danger.is-hoverable:focus-visible {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), calc(var(--bulma-danger-l)  + var(--bulma-hover-background-l-delta))) !important;
}

a.has-background-danger:active, button.has-background-danger:active, has-background-danger.is-hoverable:active {
  background-color: hsl(var(--bulma-danger-h), var(--bulma-danger-s), calc(var(--bulma-danger-l)  + var(--bulma-active-background-l-delta))) !important;
}

.is-palette-danger {
  --h: var(--bulma-danger-h);
  --s: var(--bulma-danger-s);
  --l: var(--bulma-danger-l);
  --color: hsl(var(--h), var(--s), var(--l));
  --00-l: var(--bulma-danger-00-l);
  --color-00: hsl(var(--h), var(--s), var(--00-l));
  --05-l: var(--bulma-danger-05-l);
  --color-05: hsl(var(--h), var(--s), var(--05-l));
  --10-l: var(--bulma-danger-10-l);
  --color-10: hsl(var(--h), var(--s), var(--10-l));
  --15-l: var(--bulma-danger-15-l);
  --color-15: hsl(var(--h), var(--s), var(--15-l));
  --20-l: var(--bulma-danger-20-l);
  --color-20: hsl(var(--h), var(--s), var(--20-l));
  --25-l: var(--bulma-danger-25-l);
  --color-25: hsl(var(--h), var(--s), var(--25-l));
  --30-l: var(--bulma-danger-30-l);
  --color-30: hsl(var(--h), var(--s), var(--30-l));
  --35-l: var(--bulma-danger-35-l);
  --color-35: hsl(var(--h), var(--s), var(--35-l));
  --40-l: var(--bulma-danger-40-l);
  --color-40: hsl(var(--h), var(--s), var(--40-l));
  --45-l: var(--bulma-danger-45-l);
  --color-45: hsl(var(--h), var(--s), var(--45-l));
  --50-l: var(--bulma-danger-50-l);
  --color-50: hsl(var(--h), var(--s), var(--50-l));
  --55-l: var(--bulma-danger-55-l);
  --color-55: hsl(var(--h), var(--s), var(--55-l));
  --60-l: var(--bulma-danger-60-l);
  --color-60: hsl(var(--h), var(--s), var(--60-l));
  --65-l: var(--bulma-danger-65-l);
  --color-65: hsl(var(--h), var(--s), var(--65-l));
  --70-l: var(--bulma-danger-70-l);
  --color-70: hsl(var(--h), var(--s), var(--70-l));
  --75-l: var(--bulma-danger-75-l);
  --color-75: hsl(var(--h), var(--s), var(--75-l));
  --80-l: var(--bulma-danger-80-l);
  --color-80: hsl(var(--h), var(--s), var(--80-l));
  --85-l: var(--bulma-danger-85-l);
  --color-85: hsl(var(--h), var(--s), var(--85-l));
  --90-l: var(--bulma-danger-90-l);
  --color-90: hsl(var(--h), var(--s), var(--90-l));
  --95-l: var(--bulma-danger-95-l);
  --color-95: hsl(var(--h), var(--s), var(--95-l));
  --100-l: var(--bulma-danger-100-l);
  --color-100: hsl(var(--h), var(--s), var(--100-l));
}

.has-text-black-bis {
  color: #14161a !important;
}

.has-background-black-bis {
  background-color: #14161a !important;
}

.has-text-black-ter {
  color: #1f2229 !important;
}

.has-background-black-ter {
  background-color: #1f2229 !important;
}

.has-text-grey-darker {
  color: #2e333d !important;
}

.has-background-grey-darker {
  background-color: #2e333d !important;
}

.has-text-grey-dark {
  color: #404654 !important;
}

.has-background-grey-dark {
  background-color: #404654 !important;
}

.has-text-grey {
  color: #69748c !important;
}

.has-background-grey {
  background-color: #69748c !important;
}

.has-text-grey-light {
  color: #abb1bf !important;
}

.has-background-grey-light {
  background-color: #abb1bf !important;
}

.has-text-grey-lighter {
  color: #d6d9e0 !important;
}

.has-background-grey-lighter {
  background-color: #d6d9e0 !important;
}

.has-text-white-ter {
  color: #f3f4f6 !important;
}

.has-background-white-ter {
  background-color: #f3f4f6 !important;
}

.has-text-white-bis {
  color: #f9fafb !important;
}

.has-background-white-bis {
  background-color: #f9fafb !important;
}

.has-text-current {
  color: currentColor !important;
}

.has-text-inherit {
  color: inherit !important;
}

.has-background-current {
  background-color: currentColor !important;
}

.has-background-inherit {
  background-color: inherit !important;
}

.is-flex-direction-row {
  flex-direction: row !important;
}

.is-flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}

.is-flex-direction-column {
  flex-direction: column !important;
}

.is-flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.is-flex-wrap-nowrap {
  flex-wrap: nowrap !important;
}

.is-flex-wrap-wrap {
  flex-wrap: wrap !important;
}

.is-flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.is-justify-content-flex-start {
  justify-content: flex-start !important;
}

.is-justify-content-flex-end {
  justify-content: flex-end !important;
}

.is-justify-content-center {
  justify-content: center !important;
}

.is-justify-content-space-between {
  justify-content: space-between !important;
}

.is-justify-content-space-around {
  justify-content: space-around !important;
}

.is-justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.is-justify-content-start {
  justify-content: start !important;
}

.is-justify-content-end {
  justify-content: end !important;
}

.is-justify-content-left {
  justify-content: left !important;
}

.is-justify-content-right {
  justify-content: right !important;
}

.is-align-content-flex-start {
  align-content: flex-start !important;
}

.is-align-content-flex-end {
  align-content: flex-end !important;
}

.is-align-content-center {
  align-content: center !important;
}

.is-align-content-space-between {
  align-content: space-between !important;
}

.is-align-content-space-around {
  align-content: space-around !important;
}

.is-align-content-space-evenly {
  align-content: space-evenly !important;
}

.is-align-content-stretch {
  align-content: stretch !important;
}

.is-align-content-start {
  align-content: start !important;
}

.is-align-content-end {
  align-content: end !important;
}

.is-align-content-baseline {
  align-content: baseline !important;
}

.is-align-items-stretch {
  align-items: stretch !important;
}

.is-align-items-flex-start {
  align-items: flex-start !important;
}

.is-align-items-flex-end {
  align-items: flex-end !important;
}

.is-align-items-center {
  align-items: center !important;
}

.is-align-items-baseline {
  align-items: baseline !important;
}

.is-align-items-start {
  align-items: start !important;
}

.is-align-items-end {
  align-items: end !important;
}

.is-align-items-self-start {
  align-items: self-start !important;
}

.is-align-items-self-end {
  align-items: self-end !important;
}

.is-align-self-auto {
  align-self: auto !important;
}

.is-align-self-flex-start {
  align-self: flex-start !important;
}

.is-align-self-flex-end {
  align-self: flex-end !important;
}

.is-align-self-center {
  align-self: center !important;
}

.is-align-self-baseline {
  align-self: baseline !important;
}

.is-align-self-stretch {
  align-self: stretch !important;
}

.is-flex-grow-0 {
  flex-grow: 0 !important;
}

.is-flex-grow-1 {
  flex-grow: 1 !important;
}

.is-flex-grow-2 {
  flex-grow: 2 !important;
}

.is-flex-grow-3 {
  flex-grow: 3 !important;
}

.is-flex-grow-4 {
  flex-grow: 4 !important;
}

.is-flex-grow-5 {
  flex-grow: 5 !important;
}

.is-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.is-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.is-flex-shrink-2 {
  flex-shrink: 2 !important;
}

.is-flex-shrink-3 {
  flex-shrink: 3 !important;
}

.is-flex-shrink-4 {
  flex-shrink: 4 !important;
}

.is-flex-shrink-5 {
  flex-shrink: 5 !important;
}

.is-clearfix:after {
  clear: both;
  content: " ";
  display: table;
}

.is-float-left, .is-pulled-left {
  float: left !important;
}

.is-float-right, .is-pulled-right {
  float: right !important;
}

.is-float-none {
  float: none !important;
}

.is-clear-both {
  clear: both !important;
}

.is-clear-left {
  clear: left !important;
}

.is-clear-none {
  clear: none !important;
}

.is-clear-right {
  clear: right !important;
}

.is-gapless, .is-gap-0 {
  gap: 0 !important;
}

.is-gap-0\.5 {
  gap: .25rem !important;
}

.is-gap-1 {
  gap: .5rem !important;
}

.is-gap-1\.5 {
  gap: .75rem !important;
}

.is-gap-2 {
  gap: 1rem !important;
}

.is-gap-2\.5 {
  gap: 1.25rem !important;
}

.is-gap-3 {
  gap: 1.5rem !important;
}

.is-gap-3\.5 {
  gap: 1.75rem !important;
}

.is-gap-4 {
  gap: 2rem !important;
}

.is-gap-4\.5 {
  gap: 2.25rem !important;
}

.is-gap-5 {
  gap: 2.5rem !important;
}

.is-gap-5\.5 {
  gap: 2.75rem !important;
}

.is-gap-6 {
  gap: 3rem !important;
}

.is-gap-6\.5 {
  gap: 3.25rem !important;
}

.is-gap-7 {
  gap: 3.5rem !important;
}

.is-gap-7\.5 {
  gap: 3.75rem !important;
}

.is-gap-8 {
  gap: 4rem !important;
}

.is-column-gap-0 {
  column-gap: 0 !important;
}

.is-column-gap-0\.5 {
  column-gap: .25rem !important;
}

.is-column-gap-1 {
  column-gap: .5rem !important;
}

.is-column-gap-1\.5 {
  column-gap: .75rem !important;
}

.is-column-gap-2 {
  column-gap: 1rem !important;
}

.is-column-gap-2\.5 {
  column-gap: 1.25rem !important;
}

.is-column-gap-3 {
  column-gap: 1.5rem !important;
}

.is-column-gap-3\.5 {
  column-gap: 1.75rem !important;
}

.is-column-gap-4 {
  column-gap: 2rem !important;
}

.is-column-gap-4\.5 {
  column-gap: 2.25rem !important;
}

.is-column-gap-5 {
  column-gap: 2.5rem !important;
}

.is-column-gap-5\.5 {
  column-gap: 2.75rem !important;
}

.is-column-gap-6 {
  column-gap: 3rem !important;
}

.is-column-gap-6\.5 {
  column-gap: 3.25rem !important;
}

.is-column-gap-7 {
  column-gap: 3.5rem !important;
}

.is-column-gap-7\.5 {
  column-gap: 3.75rem !important;
}

.is-column-gap-8 {
  column-gap: 4rem !important;
}

.is-row-gap-0 {
  row-gap: 0 !important;
}

.is-row-gap-0\.5 {
  row-gap: .25rem !important;
}

.is-row-gap-1 {
  row-gap: .5rem !important;
}

.is-row-gap-1\.5 {
  row-gap: .75rem !important;
}

.is-row-gap-2 {
  row-gap: 1rem !important;
}

.is-row-gap-2\.5 {
  row-gap: 1.25rem !important;
}

.is-row-gap-3 {
  row-gap: 1.5rem !important;
}

.is-row-gap-3\.5 {
  row-gap: 1.75rem !important;
}

.is-row-gap-4 {
  row-gap: 2rem !important;
}

.is-row-gap-4\.5 {
  row-gap: 2.25rem !important;
}

.is-row-gap-5 {
  row-gap: 2.5rem !important;
}

.is-row-gap-5\.5 {
  row-gap: 2.75rem !important;
}

.is-row-gap-6 {
  row-gap: 3rem !important;
}

.is-row-gap-6\.5 {
  row-gap: 3.25rem !important;
}

.is-row-gap-7 {
  row-gap: 3.5rem !important;
}

.is-row-gap-7\.5 {
  row-gap: 3.75rem !important;
}

.is-row-gap-8 {
  row-gap: 4rem !important;
}

.is-clipped {
  overflow: hidden !important;
}

.is-overflow-auto {
  overflow: auto !important;
}

.is-overflow-x-auto {
  overflow-x: auto !important;
}

.is-overflow-y-auto {
  overflow-y: auto !important;
}

.is-overflow-clip {
  overflow: clip !important;
}

.is-overflow-x-clip {
  overflow-x: clip !important;
}

.is-overflow-y-clip {
  overflow-y: clip !important;
}

.is-overflow-hidden {
  overflow: hidden !important;
}

.is-overflow-x-hidden {
  overflow-x: hidden !important;
}

.is-overflow-y-hidden {
  overflow-y: hidden !important;
}

.is-overflow-scroll {
  overflow: scroll !important;
}

.is-overflow-x-scroll {
  overflow-x: scroll !important;
}

.is-overflow-y-scroll {
  overflow-y: scroll !important;
}

.is-overflow-visible {
  overflow: visible !important;
}

.is-overflow-x-visible {
  overflow-x: visible !important;
}

.is-overflow-y-visible {
  overflow-y: visible !important;
}

.is-relative {
  position: relative !important;
}

.is-position-absolute {
  position: absolute !important;
}

.is-position-fixed {
  position: fixed !important;
}

.is-position-relative {
  position: relative !important;
}

.is-position-static {
  position: static !important;
}

.is-position-sticky {
  position: sticky !important;
}

.marginless {
  margin: 0 !important;
}

.paddingless {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.m-3 {
  margin: .75rem !important;
}

.mt-3 {
  margin-top: .75rem !important;
}

.mr-3 {
  margin-right: .75rem !important;
}

.mb-3 {
  margin-bottom: .75rem !important;
}

.ml-3 {
  margin-left: .75rem !important;
}

.mx-3 {
  margin-left: .75rem !important;
  margin-right: .75rem !important;
}

.my-3 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-5 {
  margin: 1.5rem !important;
}

.mt-5 {
  margin-top: 1.5rem !important;
}

.mr-5 {
  margin-right: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 1.5rem !important;
}

.ml-5 {
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.my-5 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.ml-6 {
  margin-left: 3rem !important;
}

.mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pr-1 {
  padding-right: .25rem !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.p-3 {
  padding: .75rem !important;
}

.pt-3 {
  padding-top: .75rem !important;
}

.pr-3 {
  padding-right: .75rem !important;
}

.pb-3 {
  padding-bottom: .75rem !important;
}

.pl-3 {
  padding-left: .75rem !important;
}

.px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-5 {
  padding: 1.5rem !important;
}

.pt-5 {
  padding-top: 1.5rem !important;
}

.pr-5 {
  padding-right: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 1.5rem !important;
}

.pl-5 {
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-5 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pr-6 {
  padding-right: 3rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pl-6 {
  padding-left: 3rem !important;
}

.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.p-auto {
  padding: auto !important;
}

.pt-auto {
  padding-top: auto !important;
}

.pr-auto {
  padding-right: auto !important;
}

.pb-auto {
  padding-bottom: auto !important;
}

.pl-auto {
  padding-left: auto !important;
}

.px-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

.py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.is-size-1 {
  font-size: 3rem !important;
}

.is-size-2 {
  font-size: 2.5rem !important;
}

.is-size-3 {
  font-size: 2rem !important;
}

.is-size-4 {
  font-size: 1.5rem !important;
}

.is-size-5 {
  font-size: 1.25rem !important;
}

.is-size-6 {
  font-size: 1rem !important;
}

.is-size-7 {
  font-size: .75rem !important;
}

@media screen and (width <= 768px) {
  .is-size-1-mobile {
    font-size: 3rem !important;
  }

  .is-size-2-mobile {
    font-size: 2.5rem !important;
  }

  .is-size-3-mobile {
    font-size: 2rem !important;
  }

  .is-size-4-mobile {
    font-size: 1.5rem !important;
  }

  .is-size-5-mobile {
    font-size: 1.25rem !important;
  }

  .is-size-6-mobile {
    font-size: 1rem !important;
  }

  .is-size-7-mobile {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 769px), print {
  .is-size-1-tablet {
    font-size: 3rem !important;
  }

  .is-size-2-tablet {
    font-size: 2.5rem !important;
  }

  .is-size-3-tablet {
    font-size: 2rem !important;
  }

  .is-size-4-tablet {
    font-size: 1.5rem !important;
  }

  .is-size-5-tablet {
    font-size: 1.25rem !important;
  }

  .is-size-6-tablet {
    font-size: 1rem !important;
  }

  .is-size-7-tablet {
    font-size: .75rem !important;
  }
}

@media screen and (width <= 1023px) {
  .is-size-1-touch {
    font-size: 3rem !important;
  }

  .is-size-2-touch {
    font-size: 2.5rem !important;
  }

  .is-size-3-touch {
    font-size: 2rem !important;
  }

  .is-size-4-touch {
    font-size: 1.5rem !important;
  }

  .is-size-5-touch {
    font-size: 1.25rem !important;
  }

  .is-size-6-touch {
    font-size: 1rem !important;
  }

  .is-size-7-touch {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 1024px) {
  .is-size-1-desktop {
    font-size: 3rem !important;
  }

  .is-size-2-desktop {
    font-size: 2.5rem !important;
  }

  .is-size-3-desktop {
    font-size: 2rem !important;
  }

  .is-size-4-desktop {
    font-size: 1.5rem !important;
  }

  .is-size-5-desktop {
    font-size: 1.25rem !important;
  }

  .is-size-6-desktop {
    font-size: 1rem !important;
  }

  .is-size-7-desktop {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 1216px) {
  .is-size-1-widescreen {
    font-size: 3rem !important;
  }

  .is-size-2-widescreen {
    font-size: 2.5rem !important;
  }

  .is-size-3-widescreen {
    font-size: 2rem !important;
  }

  .is-size-4-widescreen {
    font-size: 1.5rem !important;
  }

  .is-size-5-widescreen {
    font-size: 1.25rem !important;
  }

  .is-size-6-widescreen {
    font-size: 1rem !important;
  }

  .is-size-7-widescreen {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 1408px) {
  .is-size-1-fullhd {
    font-size: 3rem !important;
  }

  .is-size-2-fullhd {
    font-size: 2.5rem !important;
  }

  .is-size-3-fullhd {
    font-size: 2rem !important;
  }

  .is-size-4-fullhd {
    font-size: 1.5rem !important;
  }

  .is-size-5-fullhd {
    font-size: 1.25rem !important;
  }

  .is-size-6-fullhd {
    font-size: 1rem !important;
  }

  .is-size-7-fullhd {
    font-size: .75rem !important;
  }
}

.has-text-centered {
  text-align: center !important;
}

.has-text-justified {
  text-align: justify !important;
}

.has-text-left {
  text-align: left !important;
}

.has-text-right {
  text-align: right !important;
}

@media screen and (width <= 768px) {
  .has-text-centered-mobile {
    text-align: center !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-centered-tablet {
    text-align: center !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-centered-tablet-only {
    text-align: center !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-centered-touch {
    text-align: center !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-centered-desktop {
    text-align: center !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-centered-desktop-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-centered-widescreen {
    text-align: center !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-centered-widescreen-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-centered-fullhd {
    text-align: center !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-justified-mobile {
    text-align: justify !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-justified-tablet {
    text-align: justify !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-justified-tablet-only {
    text-align: justify !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-justified-touch {
    text-align: justify !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-justified-desktop {
    text-align: justify !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-justified-desktop-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-justified-widescreen {
    text-align: justify !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-justified-fullhd {
    text-align: justify !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-left-mobile {
    text-align: left !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-left-tablet {
    text-align: left !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-left-tablet-only {
    text-align: left !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-left-touch {
    text-align: left !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-left-desktop {
    text-align: left !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-left-desktop-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-left-widescreen {
    text-align: left !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-left-widescreen-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-left-fullhd {
    text-align: left !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-right-mobile {
    text-align: right !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-right-tablet {
    text-align: right !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-right-tablet-only {
    text-align: right !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-right-touch {
    text-align: right !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-right-desktop {
    text-align: right !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-right-desktop-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-right-widescreen {
    text-align: right !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-right-widescreen-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-right-fullhd {
    text-align: right !important;
  }
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.is-italic {
  font-style: italic !important;
}

.is-underlined {
  text-decoration: underline !important;
}

.has-text-weight-light {
  font-weight: 300 !important;
}

.has-text-weight-normal {
  font-weight: 400 !important;
}

.has-text-weight-medium {
  font-weight: 500 !important;
}

.has-text-weight-semibold {
  font-weight: 600 !important;
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.is-family-primary, .is-family-secondary, .is-family-sans-serif {
  font-family: Inter, SF Pro, Segoe UI, Roboto, Oxygen, Ubuntu, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.is-family-monospace, .is-family-code {
  font-family: Inconsolata, Hack, SF Mono, Roboto Mono, Source Code Pro, Ubuntu Mono, monospace !important;
}

.is-display-none, .is-hidden {
  display: none !important;
}

.is-display-block, .is-block {
  display: block !important;
}

@media screen and (width <= 768px) {
  .is-display-block-mobile, .is-block-mobile {
    display: block !important;
  }
}

@media screen and (width >= 769px), print {
  .is-display-block-tablet, .is-block-tablet {
    display: block !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-display-block-tablet-only, .is-block-tablet-only {
    display: block !important;
  }
}

@media screen and (width <= 1023px) {
  .is-display-block-touch, .is-block-touch {
    display: block !important;
  }
}

@media screen and (width >= 1024px) {
  .is-display-block-desktop, .is-block-desktop {
    display: block !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-display-block-desktop-only, .is-block-desktop-only {
    display: block !important;
  }
}

@media screen and (width >= 1216px) {
  .is-display-block-widescreen, .is-block-widescreen {
    display: block !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-display-block-widescreen-only, .is-block-widescreen-only {
    display: block !important;
  }
}

@media screen and (width >= 1408px) {
  .is-display-block-fullhd, .is-block-fullhd {
    display: block !important;
  }
}

.is-display-flex, .is-flex {
  display: flex !important;
}

@media screen and (width <= 768px) {
  .is-display-flex-mobile, .is-flex-mobile {
    display: flex !important;
  }
}

@media screen and (width >= 769px), print {
  .is-display-flex-tablet, .is-flex-tablet {
    display: flex !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-display-flex-tablet-only, .is-flex-tablet-only {
    display: flex !important;
  }
}

@media screen and (width <= 1023px) {
  .is-display-flex-touch, .is-flex-touch {
    display: flex !important;
  }
}

@media screen and (width >= 1024px) {
  .is-display-flex-desktop, .is-flex-desktop {
    display: flex !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-display-flex-desktop-only, .is-flex-desktop-only {
    display: flex !important;
  }
}

@media screen and (width >= 1216px) {
  .is-display-flex-widescreen, .is-flex-widescreen {
    display: flex !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-display-flex-widescreen-only, .is-flex-widescreen-only {
    display: flex !important;
  }
}

@media screen and (width >= 1408px) {
  .is-display-flex-fullhd, .is-flex-fullhd {
    display: flex !important;
  }
}

.is-display-inline, .is-inline {
  display: inline !important;
}

@media screen and (width <= 768px) {
  .is-display-inline-mobile, .is-inline-mobile {
    display: inline !important;
  }
}

@media screen and (width >= 769px), print {
  .is-display-inline-tablet, .is-inline-tablet {
    display: inline !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-display-inline-tablet-only, .is-inline-tablet-only {
    display: inline !important;
  }
}

@media screen and (width <= 1023px) {
  .is-display-inline-touch, .is-inline-touch {
    display: inline !important;
  }
}

@media screen and (width >= 1024px) {
  .is-display-inline-desktop, .is-inline-desktop {
    display: inline !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-display-inline-desktop-only, .is-inline-desktop-only {
    display: inline !important;
  }
}

@media screen and (width >= 1216px) {
  .is-display-inline-widescreen, .is-inline-widescreen {
    display: inline !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-display-inline-widescreen-only, .is-inline-widescreen-only {
    display: inline !important;
  }
}

@media screen and (width >= 1408px) {
  .is-display-inline-fullhd, .is-inline-fullhd {
    display: inline !important;
  }
}

.is-display-inline-block, .is-inline-block {
  display: inline-block !important;
}

@media screen and (width <= 768px) {
  .is-display-inline-block-mobile, .is-inline-block-mobile {
    display: inline-block !important;
  }
}

@media screen and (width >= 769px), print {
  .is-display-inline-block-tablet, .is-inline-block-tablet {
    display: inline-block !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-display-inline-block-tablet-only, .is-inline-block-tablet-only {
    display: inline-block !important;
  }
}

@media screen and (width <= 1023px) {
  .is-display-inline-block-touch, .is-inline-block-touch {
    display: inline-block !important;
  }
}

@media screen and (width >= 1024px) {
  .is-display-inline-block-desktop, .is-inline-block-desktop {
    display: inline-block !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-display-inline-block-desktop-only, .is-inline-block-desktop-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1216px) {
  .is-display-inline-block-widescreen, .is-inline-block-widescreen {
    display: inline-block !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-display-inline-block-widescreen-only, .is-inline-block-widescreen-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1408px) {
  .is-display-inline-block-fullhd, .is-inline-block-fullhd {
    display: inline-block !important;
  }
}

.is-display-inline-flex, .is-inline-flex {
  display: inline-flex !important;
}

@media screen and (width <= 768px) {
  .is-display-inline-flex-mobile, .is-inline-flex-mobile {
    display: inline-flex !important;
  }
}

@media screen and (width >= 769px), print {
  .is-display-inline-flex-tablet, .is-inline-flex-tablet {
    display: inline-flex !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-display-inline-flex-tablet-only, .is-inline-flex-tablet-only {
    display: inline-flex !important;
  }
}

@media screen and (width <= 1023px) {
  .is-display-inline-flex-touch, .is-inline-flex-touch {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1024px) {
  .is-display-inline-flex-desktop, .is-inline-flex-desktop {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-display-inline-flex-desktop-only, .is-inline-flex-desktop-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1216px) {
  .is-display-inline-flex-widescreen, .is-inline-flex-widescreen {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-display-inline-flex-widescreen-only, .is-inline-flex-widescreen-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1408px) {
  .is-display-inline-flex-fullhd, .is-inline-flex-fullhd {
    display: inline-flex !important;
  }
}

.is-display-grid, .is-grid {
  display: grid !important;
}

@media screen and (width <= 768px) {
  .is-display-grid-mobile, .is-grid-mobile {
    display: grid !important;
  }
}

@media screen and (width >= 769px), print {
  .is-display-grid-tablet, .is-grid-tablet {
    display: grid !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-display-grid-tablet-only, .is-grid-tablet-only {
    display: grid !important;
  }
}

@media screen and (width <= 1023px) {
  .is-display-grid-touch, .is-grid-touch {
    display: grid !important;
  }
}

@media screen and (width >= 1024px) {
  .is-display-grid-desktop, .is-grid-desktop {
    display: grid !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-display-grid-desktop-only, .is-grid-desktop-only {
    display: grid !important;
  }
}

@media screen and (width >= 1216px) {
  .is-display-grid-widescreen, .is-grid-widescreen {
    display: grid !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-display-grid-widescreen-only, .is-grid-widescreen-only {
    display: grid !important;
  }
}

@media screen and (width >= 1408px) {
  .is-display-grid-fullhd, .is-grid-fullhd {
    display: grid !important;
  }
}

.is-sr-only {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: none !important;
  width: .01em !important;
  height: .01em !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

@media screen and (width <= 768px) {
  .is-display-none-mobile, .is-hidden-mobile {
    display: none !important;
  }
}

@media screen and (width >= 769px), print {
  .is-display-none-tablet, .is-hidden-tablet {
    display: none !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-display-none-tablet-only, .is-hidden-tablet-only {
    display: none !important;
  }
}

@media screen and (width <= 1023px) {
  .is-display-none-touch, .is-hidden-touch {
    display: none !important;
  }
}

@media screen and (width >= 1024px) {
  .is-display-none-desktop, .is-hidden-desktop {
    display: none !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-display-none-desktop-only, .is-hidden-desktop-only {
    display: none !important;
  }
}

@media screen and (width >= 1216px) {
  .is-display-none-widescreen, .is-hidden-widescreen {
    display: none !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-display-none-widescreen-only, .is-hidden-widescreen-only {
    display: none !important;
  }
}

@media screen and (width >= 1408px) {
  .is-display-none-fullhd, .is-hidden-fullhd {
    display: none !important;
  }
}

.is-visibility-hidden, .is-invisible {
  visibility: hidden !important;
}

@media screen and (width <= 768px) {
  .is-visibility-hidden-mobile, .is-invisible-mobile {
    visibility: hidden !important;
  }
}

@media screen and (width >= 769px), print {
  .is-visibility-hidden-tablet, .is-invisible-tablet {
    visibility: hidden !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-visibility-hidden-tablet-only, .is-invisible-tablet-only {
    visibility: hidden !important;
  }
}

@media screen and (width <= 1023px) {
  .is-visibility-hidden-touch, .is-invisible-touch {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1024px) {
  .is-visibility-hidden-desktop, .is-invisible-desktop {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-visibility-hidden-desktop-only, .is-invisible-desktop-only {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1216px) {
  .is-visibility-hidden-widescreen, .is-invisible-widescreen {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-visibility-hidden-widescreen-only, .is-invisible-widescreen-only {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1408px) {
  .is-visibility-hidden-fullhd, .is-invisible-fullhd {
    visibility: hidden !important;
  }
}

.is-radiusless {
  border-radius: 0 !important;
}

.is-shadowless {
  box-shadow: none !important;
}

.is-clickable {
  cursor: pointer !important;
  pointer-events: all !important;
}

body {
  font-family: SF Pro Display, sans-serif;
  font-size: 12pt;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

h1 {
  font-size: 1.8em;
}

h2 {
  font-size: 1.6em;
}

h3 {
  font-size: 1.4em;
}

h4 {
  font-size: 1.3em;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 1.1em;
}

h1, h2, h3, h4, h5, h6, p {
  margin: .33em 0 0;
}

li {
  margin: .5em 0;
}

ul, ol {
  list-style-position: inside;
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

a {
  text-decoration: underline;
}

.has-branding-background {
  background-color: #272b32 !important;
}
/*# sourceMappingURL=main.css.map */
