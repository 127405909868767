@use "controls";
@use "mixins";

%arrow {
  @include mixins.arrow;
}

%block {
  @include mixins.block;
}

%control {
  @include controls.control;
}

%delete {
  @include mixins.delete;
}

%loader {
  @include mixins.loader;
}

%overlay {
  @include mixins.overlay;
}

%reset {
  @include mixins.reset;
}

%unselectable {
  @include mixins.unselectable;
}
